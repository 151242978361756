import { AxisLabelsFormatterContextObject, TooltipFormatterContextObject, dateFormat } from 'highcharts';

export interface filteredData {
  name: string;
  datas: { type: string; data: [number, number][] }[];
}

const addZero = (num: number) => {
  return String(num).length === 1 ? '0' + num : String(num);
}

export const options = (data: filteredData) => {
  const seriesData = data.datas.map((data: any) => ({ name: data.type, data: data.data }));
  console.log(seriesData);
  return {
    chart: {
      type: "line",
      height: '250px',
      // width: '600px',
      backgroundColor: "transparent",
      zoomType: 'x',
    },
    colors: ["#1de9b6", "#1dc4e9", "#A389D4"],
    title: {
      text: data.name,
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function (this: TooltipFormatterContextObject) {
        const kstOffset = (540 * 60 * 1000);
        let formattedData = dateFormat('%Y-%m-%d', this.point.x + kstOffset);
        console.log(data);
        const unit = data.name.includes('온도') ? '°C' : 'mm/s';
        this.points?.forEach(point => {
          formattedData += `<br/><span style="color:${point.color}">\u25CF</span> ${point.series.name}: <b>${`${point.y} ${unit}`}</b>`;
        })
        return formattedData;
      }
    },
    plotOptions: {
      series: {
        marker: {
          radius: 5,
        },
      }
    },
    xAxis: {
      type: 'datetime',

      labels: {
        formatter: function (this: AxisLabelsFormatterContextObject) {
          // console.log(this.axis.series[0]);
          //TODO: 값이 없는 xAxis의 text 삭제
          const date = new Date(this.value);
          const year = date.getFullYear();
          const month = addZero(date.getMonth() + 1);
          const day = addZero(date.getDate());

          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        }
      }
    },
    time: {
      timezoneOffset: -540
    },
    series: seriesData,
  }
}