export const cvtDate = (date: string) => {
  const originalDate = new Date(date);

  const formattedDate = originalDate.toLocaleDateString('ko-KR', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });

  return formattedDate.slice(0, 12).replaceAll(' ', '');
};

export const makeExcelDownload = async (res: any, pageName: string) => {
  const arrayBuffer = await res.arrayBuffer();
  const blob = new Blob([arrayBuffer]);
  const url = window.URL.createObjectURL(blob);
  const aTag = document.createElement('a');
  aTag.href = url;
  aTag.download = `${pageName}_${cvtDate(new Date().toLocaleDateString())}.xlsx`;
  document.body.appendChild(aTag);
  aTag.click();
  document.body.removeChild(aTag);
  window.URL.revokeObjectURL(url);
}

export const parseJson = (data: any) => {
  return typeof data === 'string'
    ? JSON.parse(data)
    : data;
}
