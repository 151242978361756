import { BaseTable } from '@core/components/table'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Box, Typography } from '@mui/joy'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterDataProps } from '../type'

export const FilterList = ({ data }: { data: FilterDataProps[] }) => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  const columns = useMemo<MRT_ColumnDef<FilterDataProps>[]>(
    () => [
      {
        header: '공장명',
        accessorKey: 'factory'
      },
      {
        header: '관리번호',
        accessorKey: 'code'
      },
      {
        header: '설비명',
        accessorKey: 'name'
      },
      {
        header: '마지막 교체일',
        accessorKey: 'lastDate'
      },
      {
        header: '마지막 교체수량',
        accessorKey: 'lastCount'
      },
      {
        header: '재질',
        accessorKey: 'material'
      },
      {
        header: '특이사항',
        accessorKey: 'note'
      }
    ],
    []
  )

  return (
    <BaseTable
      columns={columns}
      data={data}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ pt: 1, pl: 1 }}>
          <Typography level="h5" fontWeight={700}>
            {t('dashboard.today_filter_list')}
          </Typography>
        </Box>
      )}
    />
  )
}
