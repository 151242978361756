/* eslint-disable react/prop-types */
import {
  BaseRepairPlanModel,
  RepairPlan,
  RepairPlanImagesModel,
  RepairPlanModel,
  RepairPlanSearchForm,
  RepairPlanSearchFormField
} from '@pages/repair/type'
import { String } from '@core/enums/common.enum'
import { Builder } from '@core/helpers/builder.helper'
import {
  ColumnStyle,
  ExcelJSColumns,
  ExceljsModel,
  TableColumnConfigWithExportExcel
} from '@core/models/exceljs.model'
import { exportTableDataToCustomFormCsv, exportTableToExcelFile } from '@core/helpers/table.helper'
import { readXLSXFileWithImages } from '@core/helpers/excel-sheet.helper'
import { confirm, fail, warn } from '@core/components/modal'
import { convertBase64ToArrayBuffer, detectMimeType } from '@core/helpers/file.helper'
import { Stack } from '@core/components/menu/styles'
import { Empty } from '@core/components/empty'
import { ListDivider, ListItemDecorator, Option } from '@mui/joy'
import _ from 'lodash'
import { TFunction } from 'i18next'
import { MenuItem } from '@mui/material'
import { Add, DeleteForever, Edit } from '@mui/icons-material'
import { MRT_Row } from 'material-react-table'
import { TranslateEnum } from '@core/enums/translate.enum'

/**
 * Repair plan management table column config
 */
const repairPlanManagementColumnConfig = (t: TFunction): TableColumnConfigWithExportExcel[] => [
  {
    accessorKey: 'company',
    header: t('plan.table.company', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'factory',
    header: t('plan.table.factory', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'code',
    header: t('plan.table.code', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'name',
    header: t('plan.table.name', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'checkDate',
    header: t('plan.table.check-date', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'category',
    header: t('plan.table.category', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'subCategory',
    header: t('plan.table.sub-category', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'result',
    header: t('plan.table.result', { ns: TranslateEnum.PLAN })
  },
  {
    accessorKey: 'repairPlan',
    header: t('plan.table.repair-plan', { ns: TranslateEnum.PLAN }),
    columns: [
      {
        accessorKey: 'actionPlanDate',
        header: t('plan.table.action-plan-date', { ns: TranslateEnum.PLAN }),
        style: {
          numFmt: ColumnStyle.TEXT
        }
      },
      {
        accessorKey: 'actionPlan',
        header: '조치 계획'
      },
      {
        accessorKey: 'abnormality',
        header: t('plan.table.abnormality', { ns: TranslateEnum.PLAN })
      },
      {
        accessorKey: 'abnormalityPicture',
        header: t('plan.table.abnormality-picture', { ns: TranslateEnum.PLAN }),
        Cell: ({ cell }) => {
          if (cell && cell.getValue<RepairPlanImagesModel>()) {
            return (
              <img
                style={{ width: 80, height: 80 }}
                src={cell.getValue<RepairPlanImagesModel>()?.content}
                alt=""
              />
            )
          } else {
            return String.EMPTY_STRING
          }
        },
        enableEditing: false
      }
    ]
  },
  {
    accessorKey: 'actionResult',
    header: t('plan.table.action-result', { ns: TranslateEnum.PLAN }),
    columns: [
      {
        accessorKey: 'actionCompletionDate',
        header: t('plan.table.action-completion-date', { ns: TranslateEnum.PLAN }),
        style: {
          numFmt: ColumnStyle.TEXT
        }
      },
      {
        accessorKey: 'actionCompletionDetail',
        header: t('plan.table.action-completion-detail', { ns: TranslateEnum.PLAN }),
        style: {
          numFmt: ColumnStyle.TEXT
        }
      },
      {
        accessorKey: 'actionCompletionPictures',
        header: t('plan.table.action-completion-picture', { ns: TranslateEnum.PLAN }),
        Cell: ({ cell }) => {
          if (
            cell &&
            cell.getValue<RepairPlanImagesModel[]>() &&
            cell.getValue<RepairPlanImagesModel[]>().length > 0
          ) {
            const listImagesTemplate = []
            const listImages: any = cell.getValue<RepairPlanImagesModel[]>()
            if (Array.isArray(listImages)) {
              listImages.forEach((el, key) => {
                listImagesTemplate.push(
                  <img
                    key={key}
                    style={{ width: '50px', height: '50px', marginRight: 5 }}
                    src={el && el.content}
                    alt=""
                  />
                )
              })
            } else {
              listImagesTemplate.push(
                <img style={{ width: '50px', height: '50px' }} src={listImages?.content} alt="" />
              )
            }

            return listImagesTemplate
          } else {
            return String.EMPTY_STRING
          }
        }
      }
    ]
  },
  {
    accessorKey: 'requiredMaterial',
    header: t('plan.table.required-material', { ns: TranslateEnum.PLAN }),
    columns: [
      {
        accessorKey: 'materialName',
        header: t('plan.table.material-name', { ns: TranslateEnum.PLAN }),
        style: {
          numFmt: ColumnStyle.TEXT
        }
      },
      {
        accessorKey: 'materialSize',
        header: t('plan.table.material-size', { ns: TranslateEnum.PLAN })
      },
      {
        accessorKey: 'materialCount',
        header: t('plan.table.material-count', { ns: TranslateEnum.PLAN })
      }
    ]
  },
  {
    accessorKey: 'manager',
    header: t('plan.table.manager', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  },
  {
    accessorKey: 'note',
    header: t('plan.table.note', { ns: TranslateEnum.PLAN }),
    style: {
      numFmt: ColumnStyle.TEXT
    }
  }
]

/**
 * Condition Check data is duplicate
 * @param data
 * @param objectCheck
 */
function conditionCheckDuplicateRepairPlan(
  data: BaseRepairPlanModel,
  objectCheck: BaseRepairPlanModel
) {
  if (objectCheck && objectCheck.parentId && data && data.parentId) {
    const { parentId } = objectCheck
    return Number(parentId) === Number(data.parentId)
  }
  return false
}

const exportRepairPlanCustomForm = async (listRepairPlanData: RepairPlanModel[]) => {
  // 날짜별로 변환작업 진행
  const dateObj = listRepairPlanData.reduce(
    (
      acc: {
        [key: string]: RepairPlanModel[]
      },
      curr
    ) => {
      if (!acc[curr.checkDate]) {
        acc[curr.checkDate] = []
      }
      acc[curr.checkDate].push(curr)
      return acc
    },
    {}
  )

  const getWidth = (index: number) => {
    switch (index) {
      case 0:
        return 5
      case 2:
      case 10:
        return 10
      case 1:
      case 5:
      case 6:
      case 13:
        return 15
      case 7:
        return 30
      default:
        return 20
    }
  }

  await exportTableDataToCustomFormCsv({
    data: dateObj,
    columnsWidth: [...Array(17)].map((_, index) => ({
      width: index > 13 ? 5 : getWidth(index)
    })),
    commonAlignment: {
      horizontal: 'center',
      vertical: 'middle'
    },
    commonFont: {
      bold: true,
      underline: true
    },
    commonFill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '00FFFF66' }
    },
    lineBreak: `
`,
    style: {
      style: 'thin'
    }
  })
}

/**
 * Export table with config to Excel file
 * @param listColumnsExport
 * @param listRepairPlanData
 */
async function exportRepairPlanData(
  listColumnsExport: Array<Partial<ExcelJSColumns>>,
  listRepairPlanData: RepairPlanModel[]
) {
  if (listRepairPlanData && listRepairPlanData.length > 0) {
    const exportTableConfig = Builder<ExceljsModel>()
      .sheetName('sheet1')
      .defaultRowHeight(50)
      .headerBorderConfig({
        top: { style: 'thick', color: { argb: 'FFFFFF' } },
        left: { style: 'thick', color: { argb: 'FFFFFF' } },
        bottom: { style: 'thick', color: { argb: 'FFFFFF' } },
        right: { style: 'thick', color: { argb: 'FFFFFF' } }
      })
      .headerFill({
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D6D6D6' }
      })
      .headerFont({
        name: 'Malgun Gothic (본문)',
        family: 4,
        size: 12,
        bold: true
      })
      .columns(listColumnsExport)
      .data(
        listRepairPlanData.map((data: RepairPlanModel) => ({
          ...data,
          abnormalityPicture:
            (data && data?.abnormalityPicture && data?.abnormalityPicture.content) || null,
          actionCompletionPictures:
            (data &&
              data.actionCompletionPictures &&
              data.actionCompletionPictures.length > 0 &&
              data?.actionCompletionPictures?.map((el) => el.content)) ||
            []
        }))
      )
      .fileName(`repair_plan_${new Date().getTime()}.xlsx`)
      .build()
    await exportTableToExcelFile(exportTableConfig)
  }
}

/**
 * Check have field id is duplicate on file excel
 * @param data
 */
function checkErrorFormatExcelFileImport(data: RepairPlanModel[]) {
  console.log(data);
  let errorFormat = false
  const failId: any[] = []
  if (data && data.length > 0) {
    data.forEach((repairPlan) => {
      if (repairPlan && repairPlan.id) {
        const { id } = repairPlan
        const haveDuplicate = data.filter((currentData) => currentData.id === id)
        if (haveDuplicate && haveDuplicate.length > 1) {
          errorFormat = true
          if (!failId.find((el) => id === el)) failId.push(id)
        }
      }
    })
  }

  return { errorFormat, failId }
}

/**
 * Check have duplicate data in file Excel with data in Table
 * @param listRepairPlanData
 * @param results
 */
function checkDuplicateElement(listRepairPlanData: RepairPlanModel[], results: RepairPlanModel[]) {
  let isDuplicate = false
  console.log(listRepairPlanData, results);
  const oldData = listRepairPlanData.filter((oldData: RepairPlanModel) => {
    const duplicate = !results.some(
      (dataCheck: RepairPlanModel) =>
        oldData && dataCheck && Number(oldData.id) === Number(dataCheck.id)
    )

    if (!duplicate) {
      isDuplicate = true
    }
    return duplicate
  })
  return {
    oldData,
    isDuplicate
  }
}

/**
 * Check duplicate row on content of file excel import
 * @param listRepairPlanData
 * @param file
 * @param setIsLoading
 * @param setListRepairPlan
 * @param unsaved
 * @param translate
 */
async function checkDuplicateWhenImportFileExcel(
  listRepairPlanData: RepairPlanModel[],
  file: any,
  setIsLoading: any,
  setListRepairPlan: any,
  unsaved: boolean,
  translate: TFunction
) {
  let results = file;
  console.log(results);
  if (Array.isArray(results)) {
    // need to check format
    if (results.length > 0) {
      if (Object.keys(results[0]).includes('repairPlans')) {
        // Convert data JSON from excel file to model
        results = results.map((el) => {
          const element: RepairPlanModel = { ...el }
          if (el?.abnormalityPicture && el?.abnormalityPicture.length > 0) {
            element.abnormalityPicture = {
              name: `${new Date().getTime()}.${detectMimeType(el?.abnormalityPicture || 'png')}`,
              content: el?.abnormalityPicture
            }
          }

          if (el.actionCompletionPictures && el.actionCompletionPictures?.length > 0) {
            const photos: RepairPlanImagesModel[] = []
            if (el?.actionCompletionPictures instanceof Array) {
              for (let i = 0; i < el?.actionCompletionPictures.length; i++) {
                const base64Img = el?.actionCompletionPictures[i]
                if (base64Img) {
                  photos.push({
                    name: `${new Date().getTime()}.${detectMimeType(base64Img)}`,
                    content: base64Img
                  })
                }
              }
              element.actionCompletionPictures = photos
            } else {
              element.actionCompletionPictures = [
                {
                  name: `${new Date().getTime()}.${detectMimeType(
                    el?.actionCompletionPictures || 'png'
                  )}`,
                  content: el?.actionCompletionPictures
                }
              ]
            }
          }
          return element
        })

        // Check error format in file excel
        // const { errorFormat, failId }: any = checkErrorFormatExcelFileImport(results)
        const { isDuplicate, oldData: dataNoDuplicate }: any = checkDuplicateElement(
          listRepairPlanData,
          results
        )
        console.log(isDuplicate);
        // if (errorFormat) {
        //   fail({
        //     message: translate('plan.message.incorrect-format-excel-file', {
        //       ns: TranslateEnum.PLAN,
        //       failId: failId.toString()
        //     })
        //   })
        //   setIsLoading(false)
        //   unsaved = false
        // }
        if (isDuplicate) {
          confirm({
            title:
              translate('plan.modal-duplicate.title', { ns: TranslateEnum.PLAN }) ||
              String.EMPTY_STRING,
            message: translate('plan.modal-duplicate.message', { ns: TranslateEnum.PLAN }),
            onOk: () => {
              setIsLoading(false)
              // add parentId and checkPlan id if data isDuplicate when import
              results = results.map((dataMap: RepairPlanModel) => {
                let cloneElement = _.cloneDeep(dataMap)
                const { id } = cloneElement
                if (id && id.toString() !== String.EMPTY_STRING) {
                  const matchElementOnCurrentData = listRepairPlanData.find(
                    (el) => el && Number(el.id) === Number(id)
                  )
                  if (matchElementOnCurrentData) {
                    cloneElement = {
                      ...cloneElement,
                      checkPlanId: matchElementOnCurrentData.checkPlanId,
                      ...cloneElement.repairPlans[0]
                    }
                  }
                }

                return cloneElement
              })
              console.log(dataNoDuplicate, results);
              setListRepairPlan([...dataNoDuplicate, ...results])
            },
            onClickClose: () => setIsLoading(false),
            confirmButtonText:
              translate('plan.modal-duplicate.btn', { ns: TranslateEnum.PLAN }) ||
              String.EMPTY_STRING
          })
        } else {
          setIsLoading(false)
          results = results.map((dataMap: RepairPlanModel) => {
            const cloneElement = _.cloneDeep(dataMap)
            return {
              ...cloneElement,
              ...cloneElement.repairPlans[0]
            }
          });
          setListRepairPlan([...dataNoDuplicate, ...results])
        }
      } else {
        warn({
          message: '잘못된 형식의 파일입니다.',
          onOk: () => setIsLoading(false),
          onClosed: () => setIsLoading(false)
        })
      }
    }
  }
}

/**
 * Render common empty repair plan select box
 */
const renderEmptyRepairPlanSelectBox = () => (
  <Option disabled value="">
    <Stack>
      <Empty />
    </Stack>
  </Option>
)

/**
 * Map object repair plan model inside root object {repairPlans: []}
 * @param repairPlan
 */
async function mapListSubRepairPlan(repairPlan: RepairPlan) {
  const {
    id,
    repairTargetId,
    actionCompletionDate,
    abnormality,
    actionPlanDate,
    actionPlanDetail,
    actionCompletionDetail,
    actionPlan,
    materialName,
    materialSize,
    materialCount,
    manager,
    note
  } = repairPlan
  let { abnormalityPicture, actionCompletionPictures } = repairPlan
  if (abnormalityPicture) {
    const imagesInt8Array: Int8Array = await convertBase64ToArrayBuffer(abnormalityPicture?.content)
    abnormalityPicture = [...imagesInt8Array]
  } else {
    abnormalityPicture = undefined
  }

  if (actionCompletionPictures && actionCompletionPictures.length > 0) {
    actionCompletionPictures = await Promise.all(
      actionCompletionPictures.map(async (el: RepairPlanImagesModel | any) => {
        const imagesInt8Array: Int8Array = await convertBase64ToArrayBuffer(el.content)
        return [...imagesInt8Array]
      })
    )
  } else {
    actionCompletionPictures = []
  }

  console.log(repairPlan)

  return _.omitBy(
    {
      id,
      repairTargetId,
      actionPlanDate,
      actionCompletionDate,
      actionCompletionPictures,
      abnormality,
      abnormalityPicture,
      actionPlanDetail,
      actionPlan,
      actionCompletionDetail,
      materialName,
      materialSize,
      materialCount,
      manager,
      note
    },
    _.isUndefined
  )
}

/**
 * Convert data from table to inDto
 * @param listRepairPlanData
 */
const convertDataToInDtoRepairPlan = async (listRepairPlanData: RepairPlanModel[]) => {
  const listRepairDataInTable: BaseRepairPlanModel[] = []
  for (const repairPlanModel of listRepairPlanData) {
    const isHaveElement = listRepairDataInTable.find((el) =>
      conditionCheckDuplicateRepairPlan(el, repairPlanModel)
    )
    if (!isHaveElement) {
      const {
        parentId,
        checkPlanId,
        company,
        factory,
        code,
        name,
        checkDate,
        category,
        subCategory,
        visualReport
      } = repairPlanModel

      let repairPlanMapper: RepairPlan[] | any = []
      if (parentId && checkPlanId) {
        repairPlanMapper = await Promise.all(
          listRepairPlanData
            .filter((s: RepairPlanModel) => conditionCheckDuplicateRepairPlan(s, repairPlanModel))
            .map(async (repairPlan: RepairPlan) => await mapListSubRepairPlan(repairPlan))
        )
        console.log(repairPlanModel, repairPlanMapper);
      } else {
        repairPlanMapper = await Promise.all([
          {
            ...(await mapListSubRepairPlan(repairPlanModel.repairPlans[0]))
          }
        ])
        console.log(repairPlanModel, repairPlanMapper);
      }
      console.log(repairPlanModel.repairPlans);
      const rootObject: any = _.omitBy(
        {
          parentId,
          id: parentId,
          checkPlanId,
          company,
          factory,
          code,
          name,
          checkDate,
          category,
          subCategory,
          visualReport,
          repairPlans: repairPlanMapper
        },
        _.isUndefined
      )
      listRepairDataInTable.push(rootObject)
    }
  }
  return listRepairDataInTable
}

/**
 * Repair plan action row template
 * @param translate
 * @param row
 * @param handleAddNewRepairPlanRow
 * @param handleClickEditMode
 * @param handleDeleteSingleRow
 * @param closeMenu
 */
function repairPlanActionRowTemplate(
  translate: TFunction,
  row: MRT_Row<RepairPlanModel | any>,
  handleAddNewRepairPlanRow: (row: MRT_Row<RepairPlanModel | any>) => void,
  handleClickEditMode: (row: MRT_Row<RepairPlanModel | any>) => void,
  handleDeleteSingleRow: (row: MRT_Row<RepairPlanModel | any>) => void,
  closeMenu: () => void
) {
  return [
    <MenuItem
      key={1}
      onClick={() => {
        handleAddNewRepairPlanRow(row)
        closeMenu()
      }}>
      <ListItemDecorator>
        <Add sx={{ mr: 2 }} color="primary" />
      </ListItemDecorator>
      {translate('plan.table.action.add', { ns: TranslateEnum.PLAN })}
    </MenuItem>,
    <MenuItem
      key={2}
      onClick={() => {
        closeMenu()
        handleClickEditMode(row)
      }}>
      <ListItemDecorator>
        <Edit sx={{ mr: 2 }} color="primary" />
      </ListItemDecorator>
      {translate('plan.table.action.edit', { ns: TranslateEnum.PLAN })}
    </MenuItem>,
    <div key={3}>
      <ListDivider />
      <MenuItem
        sx={{ color: 'red' }}
        onClick={() => {
          handleDeleteSingleRow(row)
          closeMenu()
        }}>
        <ListItemDecorator sx={{ color: 'inherit' }}>
          <DeleteForever sx={{ mr: 2 }} color={'error'} />
        </ListItemDecorator>
        {translate('plan.table.action.delete', { ns: TranslateEnum.PLAN })}
      </MenuItem>
    </div>
  ]
}

/*
 *
 */

const BaseHeaderKey = [
  'id',
  'company',
  'factory',
  'code',
  'name',
  'checkDate',
  'category',
  'subCategory',
  'result',
  'actionPlanDate',
  'abnormality',
  'abnormalityPicture',
  'actionCompletionDate',
  'actionCompletionDetail',
  'actionCompletionPictures',
  'materialName',
  'materialSize',
  'materialCount',
  'manager',
  'note'
]

/**
 * Given a RepairPlanSearchForm object, this function excludes certain properties based on specific conditions
 * and returns a list of the excluded properties.
 *
 * @param inDto - The input RepairPlanSearchForm object to process.
 * @returns An array of property names to be excluded.
 */
function excludePropertiesWhenFilterRepairPlan(inDto: RepairPlanSearchForm) {
  let listPropertiesExclude: string[] = []
  const { companyId, factoryId, equipmentId } = _.omitBy(
    inDto,
    (value) => value === null || value === undefined || value === String.EMPTY_STRING
  )
  if (equipmentId) {
    listPropertiesExclude = [RepairPlanSearchFormField.COMPANY, RepairPlanSearchFormField.FACTORY]
  } else if (factoryId && !equipmentId) {
    listPropertiesExclude = [RepairPlanSearchFormField.COMPANY, RepairPlanSearchFormField.EQUIPMENT]
  } else {
    listPropertiesExclude = [RepairPlanSearchFormField.FACTORY, RepairPlanSearchFormField.EQUIPMENT]
  }
  return listPropertiesExclude
}

export {
  repairPlanManagementColumnConfig,
  convertDataToInDtoRepairPlan,
  renderEmptyRepairPlanSelectBox,
  exportRepairPlanCustomForm,
  exportRepairPlanData,
  checkDuplicateWhenImportFileExcel,
  repairPlanActionRowTemplate,
  excludePropertiesWhenFilterRepairPlan
}
