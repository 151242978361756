import { FC, useMemo } from 'react'
import { Stack } from '@mui/joy'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { RouterLink, SettingRouterLink } from '@core/enums/router.enum'

/**
 * Setting layout component
 * @constructor
 */
const SettingsLayout: FC = () => {
  const authorizationModel = useRecoilValue(authorizationSelector)
  const { isAdmin } = useMemo(() => authorizationModel, [authorizationModel])
  const location = useLocation()
  const { pathname } = location

  /**
   * Setting router guard check permission before forward to Outlet
   */

  const SettingRouterGuard = useMemo(() => {
    const isPrevent =
      !isAdmin &&
      (pathname.includes(SettingRouterLink.COMPANY)
        || pathname.includes(SettingRouterLink.FACTORY)
        || pathname.includes(SettingRouterLink.INSPECT_CUSTOM)
      )

    if (isPrevent) {
      return <Navigate to={RouterLink.HOME} replace />
    }
    return <Outlet />
  }, [])
  return (
    <Stack spacing={2} className="setting-layout-container">
      {SettingRouterGuard}
    </Stack>
  )
}

export default SettingsLayout
