import { TranslateEnum } from '@core/enums/translate.enum'
import { Breadcrumbs, Link } from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

export const BreadCrumbs = () => {
  const { pathname } = useLocation()
  const navigation = useNavigate()
  const { t } = useTranslation([TranslateEnum.COMMON])
  return (
    <Breadcrumbs aria-label="breadcrumbs">
      {pathname.split('-').map((path, index) => {
        if (path === '/') {
          return (
            <Link
              key={index}
              onClick={() => navigation('/')}
              underline="hover"
              color="neutral"
              fontSize="inherit">
              {t(`menu.dashboard`)}
            </Link>
          )
        } else {
          const newPath = path.split('/')
          if (newPath.length > 1) {
            return (
              <Link key={index} color="neutral" fontSize="inherit">
                {t(`menu.${newPath[1]}`)}
              </Link>
            )
          } else {
            return (
              <Link
                key={index}
                onClick={() => navigation(pathname)}
                underline="hover"
                color="neutral"
                fontSize="inherit">
                {t(`menu.${pathname.replace('/', '')}`)}
              </Link>
            )
          }
        }
      })}
    </Breadcrumbs>
  )
}
