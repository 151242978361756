import { Typography, Option } from '@mui/joy'
import { Empty } from '../empty'
import { Menuprops } from './type'
import { Select, Stack } from './styles'
interface CustomSelectProps extends Menuprops {
  value?: string
  defaultOption?: React.ReactNode
  onChange?: (e: any, value: string, key: string) => void
}

export const CustomSelect = ({
  value,
  title = '제목',
  list,
  onChange,
  defaultOption
}: CustomSelectProps) => {
  return (
    <Stack>
      <Typography level="body1" fontWeight={700}>
        {title}
      </Typography>

      <Select
        value={value}
        placeholder={`${title} 선택해주세요.`}
        onChange={onChange}
        slotProps={{
          listbox: {
            sx: {
              maxHeight: 240,
              overflow: 'auto'
            }
          }
        }}>
        {defaultOption ? (
          defaultOption
        ) : list && list.length > 0 ? (
          <>
            <Option value={undefined}>전체조회</Option>
            {list.map((value, key) => (
              <Option value={value.value} key={key}>
                {value.label}
              </Option>
            ))}
          </>
        ) : (
          <Option disabled value="">
            <Stack>
              <Empty />
            </Stack>
          </Option>
        )}
      </Select>
    </Stack>
  )
}
