import { atom, selector } from 'recoil'
import { Builder } from '@core/helpers/builder.helper'
import { ListRepairPlanState } from '@pages/repair/type'

// This atom holds the state of the list of repair plans.
// It is initialized to an empty list and isLoading set to false.
const listRepairPlanState = atom({
  key: 'listRepairPlan',
  default: Builder<ListRepairPlanState>().isLoading(false).listCompany([]).data([]).build()
})

/**
 * List repair plan selector
 */
const listRepairPlanDataSelector = selector({
  key: 'listRepairPlanDataSelector',
  get: ({ get }) => {
    const { data } = get(listRepairPlanState)
    return data
  },
  set: ({ get, set }, newValue: any) => {
    const listRepairPlanCurrentState = get(listRepairPlanState)
    set(listRepairPlanState, {
      ...listRepairPlanCurrentState,
      data: [...newValue]
    })
  }
})

export { listRepairPlanState, listRepairPlanDataSelector }
