import { apis } from '@core/configs/api'
import { request } from '@core/services/base.service'
import { AuthorizationModel } from '@core/models/authorization.model'
import { CryptoHelperInstance } from '@core/helpers/crypto.helper'
import { getCookieByName } from '@core/helpers/cookie.helper'
import { CookieEnum } from '@core/enums/cookie.enum'

export const getCompanyInfo = async (authorization: AuthorizationModel) => {
  const param: any = {}
  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )
  if (authorization && !isAdmin && companyId) {
    param.companyId = companyId
  }
  return request.get(`${apis.COMPANY.path}/structured`, param)
}
