import { FC, useEffect, useState } from 'react'
import { useColorScheme } from '@mui/joy/styles'
import IconButton from '@mui/joy/IconButton'
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import { ThemeType } from '@core/enums/theme.enum'

/**
 * Change theme mode component
 * @constructor
 */
const ThemeMode: FC = () => {
  const { mode, setMode } = useColorScheme()
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  if (!mounted) {
    return <IconButton size="sm" variant="outlined" color="primary" />
  }
  return (
    <IconButton
      id="toggle-mode"
      size="sm"
      variant="outlined"
      color="primary"
      onClick={() => {
        if (mode === ThemeType.LIGHT) {
          setMode(ThemeType.DARK)
        } else {
          setMode(ThemeType.LIGHT)
        }
      }}>
      {mode === ThemeType.LIGHT ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
    </IconButton>
  )
}

export default ThemeMode
