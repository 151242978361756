import { TranslateEnum } from '@core/enums/translate.enum'
import { Card, CircularProgress, Stack, Typography } from '@mui/joy'
import { useTranslation } from 'react-i18next'

interface EquipmenetStatus {
  value: number
}

export const EquipmentStatus = ({ value }: EquipmenetStatus) => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  return (
    <Card sx={{ justifyContent: 'space-around' }}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography level="h5">{t('dashboard.total_equipment')}</Typography>
        <CircularProgress
          color="info"
          determinate
          value={100}
          sx={{ '--CircularProgress-size': '80px' }}>
          {`${value} 대`}
        </CircularProgress>
        <Typography level="body1">{t('dashboard.dust_collector')}</Typography>
      </Stack>
    </Card>
  )
}
