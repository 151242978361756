import { Builder } from '@core/helpers/builder.helper'
import { EnvModel } from '@core/models/env.model'

/**
 * Application environment variable builder from process
 */
const applicationEnvironmentVariable = () => {
  return Builder<EnvModel>()
    .backendUrl(process.env.REACT_APP_BACKEND_URL)
    .appVersion(process.env.REACT_APP_VERSION)
    .isEnableXSRF(process.env.REACT_APP_ENABLE_XSRF === 'true')
    .basePath(process.env.REACT_APP_BASE_PATH)
    .privateKey(process.env.REACT_APP_AES256_KEY)
    .build()
}

export { applicationEnvironmentVariable }
