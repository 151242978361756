export enum RouterLink {
  BASE_PATH = '',
  HOME = '/',
  EQUIPMENT_MANAGE = 'equipment-manage',
  INSPECT_PLAN = 'inspect-plan',
  INSPECT_RESULT = 'inspect-result',
  FILTER_MANAGE = 'filter-manage',
  MANAGE_REPAIR_PLAN = 'manage-repair',
  SERVICE_SYNC = 'service-sync',
}

export enum AuthRouterLink {
  BASE_PATH = 'auth',
  LOGIN = 'login'
}

export enum SettingRouterLink {
  BASE_PATH = '',
  ACCOUNT = 'setting-account',
  COMPANY = 'setting-company',
  FACTORY = 'setting-factory',
  INSPECT_CUSTOM = 'setting-inspect-custom',
}

export enum OptionLink {
  BASE_PATH = '',
  MEASUREMENT_CHART = 'measurement-chart/:id',
}