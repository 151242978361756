import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Link,
  Stack,
  Typography
} from '@mui/joy'
import { FC, useState } from 'react'
import { TranslateEnum } from '@core/enums/translate.enum'
import { useTranslation } from 'react-i18next'
import './style.scss'
import { AccountCircle, EnhancedEncryption, Visibility, VisibilityOff } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { String } from '@core/enums/common.enum'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchemaFormLogin } from '@pages/login/template'
import { LoginFormField, LoginInDto } from '@pages/login/type'
import { useNavigate } from 'react-router-dom'
import { useSubmitLoginMutation } from '@pages/login/mutation'
import { useSetRecoilState } from 'recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationEnum } from '@core/enums/authorization.enum'
import { RouterLink } from '@core/enums/router.enum'
import { AxiosSingleton } from '@core/configs/axios.config'
import { setCookie } from '@core/helpers/cookie.helper'
import { CookieEnum } from '@core/enums/cookie.enum'
import { CryptoHelperInstance } from '@core/helpers/crypto.helper'
const logo = `${process.env.PUBLIC_URL}/images/logo_aerix.png`

/**
 * Login component
 * @constructor
 */
const Login: FC = () => {
  const { t } = useTranslation([TranslateEnum.LOGIN])
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted }
  } = useForm({
    defaultValues: {
      username: String.EMPTY_STRING,
      password: String.EMPTY_STRING,
      remember: false
    },
    resolver: yupResolver(validationSchemaFormLogin(t))
  })
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isDisplayInvalidAccountMessage, setDisplayInvalidAccountMessage] = useState<boolean>(false)
  const { mutate, isLoading } = useSubmitLoginMutation()
  const setAuthorizationInformation = useSetRecoilState(authorizationSelector)
  /**
   * Handle submit form login
   * @param inDto
   */
  const handleSubmitFormLogin = (inDto: LoginInDto) => {
    mutate(inDto, {
      onSuccess: (authorizationDto: AuthorizationModel) => {
        const { accessToken, authority, companyId } = authorizationDto
        if (accessToken) {
          setAuthorizationInformation({
            ...authorizationDto,
            isAdmin: AuthorizationEnum.ADMIN === authority
          })
          setCookie(
            CookieEnum.COMPANY_ID,
            CryptoHelperInstance.encodeByAES56(
              JSON.stringify({ companyId, isAdmin: AuthorizationEnum.ADMIN === authority })
            ),
            { path: '/' }
          )
          AxiosSingleton.setToken(accessToken)
        }
        setDisplayInvalidAccountMessage(false)
        navigate(RouterLink.HOME)
      },
      onError: () => {
        setDisplayInvalidAccountMessage(true)
      }
    })
  }
  return (
    <div data-aos="fade-up" data-aos-delay="600" style={{ width: '100%' }} className="login-page">
      <Grid xs={12} className={'login-container'}>
        <Grid container spacing={2} sx={{ mt: 5 }}>
          <Grid xs={12}>
            <Grid xs={12} className={'logo'}>
              <img src={logo} alt="logo" />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid xs={12}>
            <Typography level={'h4'} sx={{ mt: 2, fontWeight: 600 }} className={'login-title'}>
              {t('login.title')}
            </Typography>
          </Grid>

          <Grid xs={12} sx={{ mt: 1 }}>
            <form onSubmit={handleSubmit(handleSubmitFormLogin)}>
              <Grid container spacing={3} sx={{ pl: 30, pr: 30 }}>
                <Grid xs={12}>
                  <FormControl>
                    <FormLabel sx={{ fontWeight: 500, color: 'white' }}>
                      {t('login.username')}
                    </FormLabel>
                    <Input
                      autoFocus
                      variant={'outlined'}
                      size={'lg'}
                      startDecorator={<AccountCircle color={'inherit'} />}
                      placeholder={`${t('login.username-placeholder')}`}
                      error={Boolean(errors.username)}
                      sx={{ minWidth: 250 }}
                      {...register(LoginFormField.USERNAME)}
                    />
                    {errors && errors.username && (
                      <FormHelperText
                        className={'text-error'}
                        component={'span'}
                        sx={{ color: 'white' }}>
                        {errors.username.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid xs={12}>
                  <FormControl>
                    <FormLabel sx={{ fontWeight: 500, color: 'white' }}>
                      {t('login.password')}
                    </FormLabel>
                    <Input
                      variant={'outlined'}
                      size={'lg'}
                      type={showPassword ? 'text' : 'password'}
                      startDecorator={<EnhancedEncryption color={'inherit'} />}
                      error={Boolean(errors.password)}
                      endDecorator={
                        showPassword ? (
                          <VisibilityOff
                            onClick={() => setShowPassword(!showPassword)}
                            color={'action'}
                          />
                        ) : (
                          <Visibility
                            onClick={() => setShowPassword(!showPassword)}
                            color={'action'}
                          />
                        )
                      }
                      placeholder={`${t('login.password-placeholder')}`}
                      sx={{ minWidth: 250 }}
                      {...register(LoginFormField.PASSWORD)}
                    />
                    {errors && errors.password && (
                      <FormHelperText
                        className={'text-error'}
                        component={'span'}
                        sx={{ color: 'white' }}>
                        {errors.password.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid xs={12}>
                  <Checkbox
                    color="primary"
                    variant="outlined"
                    sx={{ fontWeight: 400, color: 'white' }}
                    label={t('login.remember-me')}
                    {...register(LoginFormField.REMEMBER_ME)}
                  />
                  {/* <Link color="primary" className={'forgot-password-link'}>
                    {t('login.forgot-password')}
                  </Link> */}
                </Grid>

                {!isLoading && isSubmitted && isDisplayInvalidAccountMessage && (
                  <Grid xs={12}>
                    <FormHelperText
                      className={'text-error'}
                      sx={{ fontWeight: 400, color: 'white' }}>
                      {t('login.invalid-account')}
                    </FormHelperText>
                  </Grid>
                )}

                <Grid xs={12}>
                  <Button
                    loading={isLoading}
                    type="submit"
                    fullWidth
                    color="primary"
                    size={'lg'}
                    sx={{ minWidth: 250 }}>
                    {t('login.btn-login')}
                  </Button>
                </Grid>

                <Grid xs={12} paddingTop={0}>
                  <Typography fontSize={'sm'} sx={{ color: 'white' }}>
                    {t('login.guide')}
                    <Link
                      target="_blank"
                      href="https://www.aerix.co.kr/%EC%98%A8%EB%9D%BC%EC%9D%B8-%EB%AC%B8%EC%9D%98/%EC%98%A8%EB%9D%BC%EC%9D%B8-%EB%AC%B8%EC%9D%98/"
                      color="primary"
                      sx={{ color: 'white', textDecoration: 'underline' }}>
                      {t('login.admin')}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Login
