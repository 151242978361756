import { ControllerRenderProps } from 'react-hook-form'

/**
 * Get field props and integrate with react hook form
 * @param field
 * @param value
 */
function getFieldPropsWithForm(field: ControllerRenderProps<any, any> | any, value: any) {
  if (field && value) {
    delete field.value
  }
  return field
}

export { getFieldPropsWithForm }
