import { TranslateEnum } from '@core/enums/translate.enum'
import { Card, CircularProgress, Stack, Typography } from '@mui/joy'
import { RepairPlanModel } from '@pages/repair/type'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const RepairStatus = ({ data }: { data: RepairPlanModel[] }) => {
  const [count, setCount] = useState(0)
  const [total, setTotal] = useState(0)
  const [value, setValue] = useState(0)
  const { t } = useTranslation([TranslateEnum.COMMON])

  useEffect(() => {
    let tempCount = 0
    let tempTotal = 0
    for (const item of data) {
      if (item.repairPlans && item.repairPlans.length > 0) {
        const plans = item.repairPlans
        for (const plan of plans) {
          const date = plan.actionCompletionDate
          if (date !== '') {
            tempCount += 1
          }
        }
        tempTotal += item.repairPlans.length
      }
    }
    setCount(tempCount)
    setTotal(tempTotal)
    if (tempTotal) {
      setValue(Math.round((tempCount / tempTotal) * 100))
    }
  }, [data])

  return (
    <Card sx={{ justifyContent: 'space-around' }}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography level="h5">{t('dashboard.today_repair_status')}</Typography>
        <CircularProgress
          determinate
          value={value ?? 0}
          color={'danger'}
          sx={{ '--CircularProgress-size': '80px' }}>
          {`${count} / ${total}`}
        </CircularProgress>
        <Typography level="body1">{value === 100 ? '수리완료' : '수리중'}</Typography>
      </Stack>
    </Card>
  )
}
