/* eslint-disable react/display-name */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, forwardRef, useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range'
import { Grid, Input } from '@mui/joy'
import { DateRange as DateRangeIcon } from '@mui/icons-material'
import * as locales from 'react-date-range/dist/locale'
import { TranslateLanguageEnum } from '@core/enums/translate.enum'
import './style.scss'
import { Popover } from '@mui/material'
import { DateFormat } from '@core/enums/date.enum'
import { DateRange, MuiDateRangePickerProps } from '@core/components/date-range/type'
import dayjs from 'dayjs'
import { useColorScheme } from '@mui/joy/styles'

/**
 * Common Mui Joy date range picker
 * @param dateRange
 * @param onDateRangeChange
 * @param format
 * @param TextFieldProps
 * @constructor
 */
export const MuiJoyDateRangePicker: FC<MuiDateRangePickerProps> = forwardRef(
  (
    {
      dateRange,
      onDateRangeChange,
      format = DateFormat.YYYY_MM_DD_DATE_PICKER,
      inputProps,
      stateForm,
      isFilter
    }: MuiDateRangePickerProps,
    ref
  ) => {
    const [dateRangeValue, setDateRangeValue] = useState<DateRange>({
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      key: isFilter ? 'filterSelection' : 'selection'
    })

    const { mode } = useColorScheme()

    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'date-range-popover' : undefined

    /**
     * Handle click to input date
     * @param event
     */
    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget)
    }

    /**
     * Handle close popover
     */
    const handleClose = () => {
      setAnchorEl(null)
    }

    /**
     * Handle on select date
     * @param dateRange
     */
    const onChange = (dateRange: DateRange) => {
      if (dateRange) {
        onDateRangeChange(isFilter ? {
          replaceStartDate: dateRange.startDate ? dayjs(dateRange.startDate) : null,
          replaceEndDate: dateRange.endDate ? dayjs(dateRange.endDate) : null
        }
        :{
          startDate: dateRange.startDate ? dayjs(dateRange.startDate) : null,
          endDate: dateRange.endDate ? dayjs(dateRange.endDate) : null
        })
        setDateRangeValue(dateRange)
      }
    }

    /**
     * Format date value from date range
     * @param dateRange
     */
    const getDisplayDateRange = (dateRange: DateRange): string => {
      const startDate = dateRange?.startDate ? dayjs(dateRange.startDate).format(format) : null

      const endDate = dateRange?.endDate ? dayjs(dateRange.endDate).format(format) : null

      return startDate && endDate ? `${startDate} - ${endDate}` : ''
    }

    /**
     * Effect set value when retrieve value date range
     */
    useEffect(() => {
      if (dateRange) {
        setDateRangeValue(dateRange)
        if (stateForm) {
          const { startDate, endDate } = dateRange
          if (stateForm) {
            const { setValueFn, startDateFieldName, endDateFieldName } = stateForm
            setValueFn(startDateFieldName, startDate)
            setValueFn(endDateFieldName, endDate)
          }
        }
      }
    }, [])

    /**
     * Effect close popup when complete change date range
     */
    useEffect(() => {
      if (dateRangeValue.endDate > dateRangeValue.startDate) {
        handleClose()
      }
    }, [dateRangeValue?.endDate])

    return (
      <>
        <Input
          placeholder={'날짜를 선택해주세요.'}
          value={getDisplayDateRange(dateRangeValue)}
          id={id}
          endDecorator={<DateRangeIcon color={'action'} fontSize={'small'} />}
          onClick={handleClick}
          sx={{ minWidth: 235 }}
          {...inputProps}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          sx={{ zIndex: 9999 }}>
          <Grid container spacing={2}>
            <Grid xs={12} className={mode}>
              <DateRangePicker
                ref={ref}
                showDateDisplay={false}
                staticRanges={[]}
                inputRanges={[]}
                editableDateInputs
                locale={locales[TranslateLanguageEnum.KOREA_DATE_LOCALIZATION]}
                onChange={(item: any) => onChange(item && isFilter ? item.filterSelection : item.selection)}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[dateRangeValue]}
                direction="horizontal"
              />
            </Grid>
          </Grid>
        </Popover>
      </>
    )
  }
)
