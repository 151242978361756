import React from 'react'
import { ContentMenu } from '@core/components/menu'
import { DataTable } from '@core/components/table'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Box, Card, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/joy'
import { MRT_Cell, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateOutlined, FileUploadOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import {
  confirm,
  deleteConfirm,
  editConfirm,
  fail,
  savedCheckModal,
  success,
  warn
} from '@core/components/modal'
import {
  exportJsonToXlsx,
  readXLSXFileWithImages,
  readXLSXInspectFile
} from '@core/helpers/excel-sheet.helper'
import { SortingState } from '@tanstack/react-table'
import { Virtualizer } from '@tanstack/react-virtual'
import { Actions, ColumnsHeader, CustomDateRange, TableColumns } from './template'
import { PlanCreate } from '../plan-create'
import { InspectPlanProps } from '../type'
import { deleteInspectPlan, getInspectPlan, postExcelDownload, postExcelUpload, postInspectPlan } from '../query'
import { ContentMenuDataProps } from '@core/components/menu/type'
import { PlanModal } from '../plan-modal'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { useRecoilState, useRecoilValue } from 'recoil'
import { QueryKey } from '@core/enums/query.enum'
import { planEditState } from '../recoil'
import { deleteQuery } from '@core/helpers/query.helper'
import _ from 'lodash'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import { TargetEnum } from '@core/enums/common.enum'
import { CustomConfirmModal } from '@core/components/modal/custom'

let unsaved = false

export const InspectPlanManagement = () => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const { t } = useTranslation([TranslateEnum.COMMON])
  const columns = useMemo(() => ColumnsHeader, [])
  const [tableData, setTableData] = useState<InspectPlanProps[]>([])
  const [info, setInfo] = useState<ContentMenuDataProps>({})
  const [open, setOpen] = useState({
    create: false,
    method: false,
    confirm: false
  })
  const [actionInfo, setActionInfo] = useState<InspectPlanProps | undefined>(undefined)
  const rowVirtualizerInstanceRef = useRef<Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null)
  const [sorting, setSorting] = useState<SortingState>([])
  const [rowSelection, setRowSelection] = useState({})
  const tableInstanceRef = useRef<MRT_TableInstance<InspectPlanProps>>(null)
  const [edit, setEdit] = useRecoilState(planEditState)
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const openMenu = Boolean(anchorEl)
  const [target, setTarget] = useState<string | undefined>(undefined)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const { setValue, getValues, watch } = useForm({
    defaultValues: {
      startDate: '',
      endDate: ''
    }
  })

  const { refetch, isLoading: dataLoading } = useQuery(
    [QueryKey.INSPECTPLAN],
    () => getInspectPlan(authorizationModel, info, getValues),
    {
      enabled: false,
      onSuccess: (data: InspectPlanProps[]) => {
        setTableData(data)
      }
    }
  )

  const saveMutation = useMutation((data: InspectPlanProps[]) => postInspectPlan(data), {
    onSuccess: () => {
      success()
      refetch()
      setRowSelection({})
      setLoading(false)
      unsaved = false
      setOpen((prev) => ({ ...prev, confirm: false }))
    },
    onError: (error: any) => {
      if (error.response) {
        fail({
          message: `${error.response.data}`
        })
      }
      setLoading(false)
    }
  })

  const deleteMuation = useMutation((data: number[]) => deleteInspectPlan(data), {
    onSuccess: () => {
      refetch()
      setRowSelection({})
    },
    onError: (error: any) => {
      if (error.response) {
        fail({
          message: `${error.response.data}`
        })
      }
    }
  })

  const handleSaveCell = (cell: MRT_Cell<InspectPlanProps>, value: string) => {
    tableData[+cell.row.index][cell.column.id] = value
    setTableData([...tableData])
    unsaved = true
  }

  const handleAction = (row: MRT_Row<InspectPlanProps>) => {
    if (unsaved) {
      savedCheckModal()
    } else {
      setActionInfo(row.original)
      setOpen((prev) => ({ ...prev, method: true }))
    }
  }

  const onDelete = () => {
    if (unsaved) {
      savedCheckModal()
    } else {
      deleteConfirm({
        message: (
          <div>
            <div>점검 계획 삭제시 서버에 바로 반영되며, </div>
            <div>관련된 점검 결과, 수리계획 및 결과가 같이 삭제됩니다.</div>
            <Typography fontWeight={500}>정말 삭제하시겠습니까?</Typography>
          </div>
        ),
        onOk: async () => {
          await deleteQuery(
            tableInstanceRef,
            'id',
            deleteMuation,
            tableData,
            setTableData,
            refetch,
            setRowSelection
          )
        }
      })
    }
  }

  // 다운로드
  const cvtDate = (date: string) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });

    return formattedDate.slice(0, 12).replaceAll(' ', '');
  };

  console.log(info);
  const onExport = async () => {
    // const newData = tableData.map((item) => {
    //   const tempObj = { ...item }
    //   delete tempObj.id
    //   delete tempObj.checkList
    //   delete tempObj.checkListDVA
    //   delete tempObj.totalNote

    //   return tempObj
    // })
    // // 헤더 추가
    // await exportJsonToXlsx(TableColumns, newData, 'inspect_plan')
    const prms = {
      company: info.company,
      factory: info.factory,
      startDate: cvtDate(watch().startDate),
      endDate: cvtDate(watch().endDate)
    }
    await postExcelDownload(prms);
  }

  const onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const { files } = event.target

      if (files && files[0]) {
        setLoading(true)
        const formData = new FormData();
        formData.append('file', files[0]);
        const results = await postExcelUpload(formData);
        const copyTableColumns = _.cloneDeep(TableColumns);
        delete copyTableColumns.id;
        delete copyTableColumns.checkListDVA;
        delete copyTableColumns.checkList;

        // results: 엑셀 데이터
        if (Object.keys(results[0]).includes('checker')) {
          // 기존 테이블에서 새로들어온 중복된 데이터 삭제
          if (results.length > 0) {
            let isDuplicate = false
            const oldData = tableData.filter((oldData) => {
              const duplicate = !results.some(
                (newData: any) =>
                  newData.code === oldData.code && newData.checkDate === oldData.checkDate
              )

              if (!duplicate) {
                isDuplicate = true
              }
              return duplicate
            })

            if (isDuplicate) {
              confirm({
                title: '확인',
                message: '중복된 관리번호 및 점검일정이 존재합니다. 덮어쓰시겠습니까?',
                onOk: () => {
                  setTableData([...oldData, ...results])
                  setLoading(false)
                },
                onClickClose: () => setLoading(false),
                onClosed: () => setLoading(false),
                confirmButtonText: '덮어쓰기'
              })
            } else {
              setTableData([...oldData, ...results])
              setLoading(false)
            }
            unsaved = true

          } else {
            warn({
              message: '잘못된 형식의 파일입니다.',
              onOk: () => setLoading(false),
              onClosed: () => setLoading(false)
            })
          }
        }
      }
    }
    event.target.value = ''
    setRowSelection({})
  }

  const onSave = async () => {
    setLoading(true)
    setOpen((prev) => ({ ...prev, confirm: true }))
  }

  const onClose = () => {
    if (edit) {
      editConfirm({
        onOk: onModal
      })
    } else {
      onModal()
    }
  }

  const onModal = () => {
    setOpen({ create: false, method: false, confirm: false })
    setEdit(false)
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick = (target: string) => {
    setTarget(target)
    fileInputRef?.current?.click()
  }

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    rowVirtualizerInstanceRef.current?.scrollToIndex(0)
  }, [sorting])

  useEffect(() => {
    if (!open.method || !open.create) {
      refetch()
    }
  }, [open.method, open.create])

  useEffect(() => {
    refetch()
    unsaved = false
  }, [])

  const isLoading = dataLoading
  return (
    <>
      <Stack spacing={2}>
        <Card>
          <form>
            <ContentMenu
              companyprops={{ enable: true }}
              factoryprops={{ enable: true }}
              setInfo={setInfo}
              customNode={<CustomDateRange setValue={setValue} />}
              actions={
                <Actions
                  onSearch={refetch}
                  onCreate={() => setOpen((prev) => ({ ...prev, create: true }))}
                  onDelete={onDelete}
                  onExport={onExport}
                  custom={
                    <>
                      <Tooltip title="업로드">
                        <>
                          <IconButton
                            aria-controls={'basic-menu'}
                            aria-haspopup="true"
                            aria-expanded={'true'}
                            color="primary"
                            onClick={handleMenuClick}>
                            <input ref={fileInputRef} type="file" hidden onChange={onUpload} />
                            <FileUploadOutlined />
                          </IconButton>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleMenuClose}>
                            <MenuItem onClick={() => handleMenuItemClick(TargetEnum.COMMON)}>
                              공통양식
                            </MenuItem>
                            {/* <MenuItem onClick={() => handleMenuItemClick(TargetEnum.CUSTOM)}>
                              사용자양식
                            </MenuItem> */}
                          </Menu>
                        </>
                      </Tooltip>
                    </>
                  }
                  onSave={onSave}
                  disabled={Object.keys(rowSelection).length === 0}
                />
              }
            />
          </form>
        </Card>
        {loading && <DataTable columns={columns} data={[]} state={{ isLoading: loading }} />}
        {!loading && (
          <DataTable
            columns={columns}
            data={tableData}
            state={{ isLoading, sorting, rowSelection }}
            onRowSelectionChange={setRowSelection}
            onSortingChange={setSorting}
            tableInstanceRef={tableInstanceRef}
            rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
            rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
            columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
            muiTableBodyCellEditTextFieldProps={({ cell }) => ({
              onBlur: (event) => {
                handleSaveCell(cell, event.target.value)
              }
            })}
            renderRowActions={({ row }) => (
              <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip arrow placement="left" title="점검목록">
                  <IconButton onClick={() => handleAction(row)}>
                    <UpdateOutlined color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            renderTopToolbarCustomActions={() => (
              <Box sx={{ pt: 1, pl: 1 }}>
                <Typography level="h5" fontWeight={700}>
                  {t('inspect.inspect_plan_management')}
                </Typography>
              </Box>
            )}
          />
        )}
      </Stack>
      {open.method && <PlanModal open={open.method} onClose={onClose} info={actionInfo} />}
      {open.create && <PlanCreate open={open.create} onClose={onClose} />}
      {open.confirm && (
        <CustomConfirmModal
          open={open.confirm}
          onClose={onClose}
          onConfirm={() => saveMutation.mutate(tableData)}
          loading={saveMutation.isLoading}
        />
      )}
    </>
  )
}
