import DaumPostcode from 'react-daum-postcode'
import React, { SetStateAction } from 'react'
import Modal from './Modal'
import styled from 'styled-components'
import { CompanyFormField } from '@pages/settings/company/type';
interface IProps {
  setOnModal: React.Dispatch<SetStateAction<boolean>>;
  setValue: any;
}
const KakaoAddr: React.FC<IProps> = ({ setOnModal, setValue }) => {
  return (
    <Modal onClose={() => setOnModal(false)}>
      <ModalWrap>
        <DaumPostcode
          autoClose
          onComplete={(data: any) => {
            const addr = data.userSelectedType === 'R' ? data.roadAddress : data.jibunAddress;
            setValue(CompanyFormField.COMPANY_ADDRESS, addr)
            // setValue(CompanyFormField.COMPANY_ZONECODE, data.zonecode)
            setOnModal(false)
          }}
        />
      </ModalWrap>
    </Modal>
  )
}

const ModalWrap = styled.div`
  width: 530px;
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
`;


export default KakaoAddr
