import Modal from '@core/components/modal/common/Modal';
import React, { MouseEvent, forwardRef } from 'react';
import styled from 'styled-components';

const DetailInterval = forwardRef<HTMLDivElement, any>(({ data }, ref) => {
  const innerClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
  console.log(data);
  return (
    <Wrap ref={ref} onClick={innerClick}>
      <TableWrap>
        <TableHeaderTr>
          <th>챔버</th>
          <th>마지막 교체일</th>
          <th>지정 교체주기</th>
          <th>실 교체주기</th>
          <th>차기 교체일</th>
        </TableHeaderTr>

        {data.map((item: any) => (
          <TableBodyTr key={item.chamberName}>
            <td>{`C${item.chamberName}`}</td>
            <td>{item.replaceDate}</td>
            <td>{item.replacePeriod}</td>
            <td>{item.replacePeriodReal}</td>
            <td>{item.nextReplaceDate}</td>
          </TableBodyTr>
        ))}
      </TableWrap>
    </Wrap>
  );
});

const Wrap = styled.div`
  position: absolute;
  background-color: rgb(242, 242, 242);
  padding: 12px 24px;
  width: 700px;
  min-height: 250px;
  height: 250px;
  border-radius: 8px;
  color: #1E1E1E;
  overflow-y: auto;
`;

const TableWrap = styled.table`
  width: 100%;
`;

const TableHeaderTr = styled.tr`
  position: sticky;
  top: 0;
  display: flex;
  padding-bottom: 8px;
  background-color: rgb(242, 242, 242);
  border-bottom: 2px solid rgb(217, 215, 215);
  > th {
    font-weight: 500;
  }

  > th:nth-child(1) {
    flex: 10%;
  }
  > th:nth-child(2) {
    flex: 20%;
  }
  > th:nth-child(3) {
    flex: 20%;
  }
  > th:nth-child(4) {
    flex: 20%;
  }
  > th:nth-child(5) {
    flex: 20%;
  }
`;

const TableBodyTr = styled.tr`
display: flex;
padding: 8px 0;

  > td {
    display: flex;
    align-items: center;
  }

  > td:nth-child(1) {
    flex: 10%;
  }
  > td:nth-child(2) {
    flex: 20%;
  }
  > td:nth-child(3) {
    flex: 20%;
  }
  > td:nth-child(4) {
    flex: 20%;
  }
  > td:nth-child(5) {
    flex: 20%;
  }
`;


const Triangle = styled.div`
  position: absolute;
  top: -20px;
  left: 20px;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent linear-gradient(-225deg, #5D9FFF 0%, #B8DCFF 48%, #6BBBFF 100%) transparent;
`;



export default DetailInterval;