import {
  AddPhotoAlternateOutlined,
  DeleteOutlineOutlined,
  MoreHorizOutlined,
  PlaylistAddOutlined
} from '@mui/icons-material'
import { ListItemContent, ListItemDecorator, Menu, MenuItem } from '@mui/joy'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { TableActionProps } from './type'

export const TableAction = ({
  row,
  setRowIndex,
  onAdd,
  onDelete,
  onUpload,
  customNode
}: TableActionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'date-range-popover' : undefined

  const handlePopoverOpen = (event: any) => {
    setRowIndex(row)
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const onImageDelete = () => {
    onDelete && onDelete()
    handlePopoverClose()
  }

  const onAddRow = () => {
    onAdd && onAdd()
    handlePopoverClose()
  }

  return (
    <div>
      <IconButton
        id="positioned-demo-button"
        aria-controls={open ? 'positioned-demo-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        color="primary"
        onClick={handlePopoverOpen}>
        <MoreHorizOutlined />
      </IconButton>
      <input id="upload-photo" type="file" hidden onChange={onUpload} />
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        placement="bottom-start"
        sx={{ zIndex: 9999 }}>
        {customNode}
        <MenuItem htmlFor="upload-photo" component={'label'}>
          <ListItemDecorator>
            <AddPhotoAlternateOutlined />
          </ListItemDecorator>
          <ListItemContent>사진추가</ListItemContent>
        </MenuItem>

        <MenuItem onClick={onImageDelete}>
          <ListItemDecorator>
            <DeleteOutlineOutlined />
          </ListItemDecorator>
          <ListItemContent>사진삭제</ListItemContent>
        </MenuItem>
        <MenuItem onClick={onAddRow}>
          <ListItemDecorator>
            <PlaylistAddOutlined />
          </ListItemDecorator>
          <ListItemContent>복제</ListItemContent>
        </MenuItem>
      </Menu>
    </div>
  )
}
