import { apis } from '@core/configs/api'
import { request } from '@core/services/base.service'
import { RepairPlanCreateprops, RepairTargetProps } from '../type'

const path = apis.REPAIR_TARGET.path

export const getRepairPlan = ({ info }: RepairPlanCreateprops) => {
  if (info) {
    const { id, checkDate } = info
    return request.get(
      `${path}?checkPlanId=${id}&startDate=${checkDate}&endDate=${checkDate}&isCompleteDate=false`
    )
  }
}

export const postRepairPlan = (data: RepairTargetProps[]) => request.post(path, data)
export const deleteRepairPlan = (data: number[]) => request.delete(apis.REPAIR_PLAN.path, { data })
