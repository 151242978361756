import { QueryFunction, UseMutationResult, useQuery } from '@tanstack/react-query'
import { fail, success } from '@core/components/modal'
import { MRT_Row } from 'material-react-table'
import { Dispatch } from 'react'

/**
 * Wrapper hook use for useQuery with react query
 * @param queryKey
 * @param queryFunction
 * @param options
 */
function useAppQuery(queryKey: any, queryFunction: QueryFunction<any, any>, options: any = {}) {
  const { onError } = options
  return useQuery(queryKey, queryFunction, { ...options, useErrorBoundary: !!onError })
}

const ErrorQuery = (error: any) => {
  if (error.response) {
    fail({
      message: `${error.response.data}`
    })
  }
}

/**
 *
 * @param tableInstanceRef React.RefObject<MRT_TableInstance<any>>
 * @param key
 * @param deleteMuation
 * @param tableData
 * @param setTableDate
 * @param refetch
 * @param setRowSelection
 */

const deleteQuery = async (
  tableInstanceRef: React.RefObject<any>,
  key: string,
  deleteMuation: UseMutationResult<any, unknown, any[], unknown>,
  tableData: any[],
  setTableDate: Dispatch<React.SetStateAction<any>>,
  refetch: any,
  setRowSelection: Dispatch<React.SetStateAction<any>>
) => {
  const rowSelection = tableInstanceRef?.current
    ?.getSelectedRowModel()
    .rows.map((data: MRT_Row<any>) => ({ data: data.original, index: data.index }))

  if (rowSelection && rowSelection.length > 0) {
    const deleteList = rowSelection
      .filter((item: { data: any; index: number }) => item.data[key])
      .map((item: { data: any; index: number }) => item.data[key])

    await deleteMuation.mutateAsync(deleteList, {
      onSuccess: () => {
        const deleteIndex = rowSelection.map((item: { data: any; index: number }) => item.index)
        const newData = tableData.filter((item, index) => deleteIndex.includes(index))
        setTableDate(newData)
        refetch()
        success({
          title: '삭제성공!'
        })
        setRowSelection({})
      },
      onError: ErrorQuery
    })
  }
}

export { useAppQuery, ErrorQuery, deleteQuery }
