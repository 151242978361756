export enum MetaData {
  COMPANY = 'company',
  FACTORY = 'factory',
  EQUIPMENT = 'equipment',
  CODE = 'code',
  NAME = 'name',
  COUNT = 'count',
  MAINTENANCE = 'maintenance',
  ESTABLISHMENTDATE = 'establishmentDate',
  CHECKTYPE = 'checkType',
  NOTE = 'note',
  DISPLACEMENT = 'displacement',
  VELOCITY = 'velocity',
  ACCELERATION = 'acceleration'
}
