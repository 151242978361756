import { warn } from '@core/components/modal'
import { String } from '@core/enums/common.enum'
import { DeleteOutlined, InsertPhotoOutlined, DonutSmallOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/joy'
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

export const ImagePreview = ({
  image,
  target,
  setImages,
  setValue,
  setEdit
}: {
  image: string
  target: string
  setValue: UseFormSetValue<any>
  setImages: Dispatch<
    SetStateAction<{
      [key: string]: string | null
      picture: null | string
      diagram: null | string
    }>
  >
  setEdit: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div className="image-container" style={{ height: '350px' }}>
      <img src={image} alt="preview" />
      <div className="image-blur"></div>
      <span className="image-action">
        <DeleteOutlined
          style={{ fontSize: 40 }}
          onClick={(e) => {
            e.preventDefault()
            setImages((prev) => ({ ...prev, [target.split('.')[1]]: null }))
            setValue(target, null)
            setEdit(true)
          }}
        />
      </span>
    </div>
  )
}

export const UploadBox = ({
  target,
  onUpload
}: {
  target: string
  onUpload: (event: ChangeEvent<HTMLInputElement>, target: string) => void
}) => {
  return (
    <Box className="upload-image-box" component="label" htmlFor={`file-${target}`}>
      <input
        id={`file-${target}`}
        type="file"
        hidden
        onChange={(event) => onUpload(event, target)}
      />
      {target === String.PICTURE ? (
        <InsertPhotoOutlined style={{ fontSize: 40 }} />
      ) : (
        <DonutSmallOutlined style={{ fontSize: 40 }} />
      )}
      <Typography level="body1" fontWeight={500}>
        클릭하여 {`${target === String.PICTURE ? '이미지' : '다이어그램'}`} 업로드
      </Typography>
    </Box>
  )
}
