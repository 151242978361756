import { styled } from '@mui/material/styles'
import { Box, FormHelperText, IconButton, Stack, Typography } from '@mui/material'
import { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react'
import { CustomType, FileUploadProps } from '@core/components/file-upload/type'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { warn } from '@core/components/modal'
import { Delete } from '@mui/icons-material'
import { AspectRatio, Card, CardOverflow, Divider, Grid } from '@mui/joy'
import { convertFileToBase64 } from '@core/helpers/file.helper'
const uploadImg = `${process.env.PUBLIC_URL}/images/cloud-upload.png`

/**
 * Custom box
 */
const CustomBox = styled(Box)({
  '&.MuiBox-root': {
    backgroundColor: '#fff',
    borderRadius: '2rem'
    // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  '&.MuiBox-root:hover, &.MuiBox-root.dragover': {
    opacity: 0.6
  }
})

/**
 * Common file upload with Material
 * @param limit
 * @param multiple
 * @param name
 * @param files
 * @param disable
 * @param onFileDelete
 * @constructor
 */
const FileUpload: FC<FileUploadProps> = ({
  limit,
  multiple,
  name,
  files,
  disable = false,
  onFileDelete
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()
  const { field } = useController({ name, control })
  const [singleFile, setSingleFile] = useState<File[]>([])
  const [fileList, setFileList] = useState<File[]>([])
  const [imagesPreview, setImagesPreview] = useState<string[] | any[]>()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const onDragEnter = () => wrapperRef.current?.classList.add('dragover')
  const onDragLeave = () => wrapperRef.current?.classList.remove('dragover')

  /**
   * Handle file drop
   */
  const onFileDrop = useCallback(
    (e: SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement
      if (!target.files) return

      if (limit === 1) {
        const newFile = Object.values(target.files).map((file: File) => file)
        if (singleFile.length >= 1)
          return warn({
            title: '경고',
            message: '단일 이미지만 허용됨'
          })
        setSingleFile(newFile)
        field.onChange(newFile[0])
      }

      if (multiple) {
        const newFiles = Object.values(target.files).map((file: File) => file)
        if (newFiles) {
          const updatedList = [...fileList, ...newFiles]
          if (updatedList.length > limit || newFiles.length > 3) {
            return warn({
              title: '경고',
              message: `사진은 최대 ${limit}개 등록가능합니다.`
            })
          }
          setFileList(updatedList)
          field.onChange(updatedList)
        }
      }
    },
    [field, fileList, limit, multiple, singleFile]
  )

  /**
   * Handle file remove
   * @param file
   */
  const fileRemove = (file: File) => {
    const updatedList = [...fileList]
    updatedList.splice(fileList.indexOf(file), 1)
    setFileList(updatedList)
    onFileDelete(updatedList)
  }

  /**
   * File single remove
   */
  const fileSingleRemove = () => {
    setSingleFile([])
    onFileDelete([])
  }

  /**
   * Calculate file size
   * @param size
   */
  const calcSize = (size: number) => {
    return size < 1000000 ? `${Math.floor(size / 1000)} KB` : `${Math.floor(size / 1000000)} MB`
  }

  /**
   * Effect when have files
   */
  useEffect(() => {
    if (files && files.length > 0) {
      if (multiple) {
        setFileList(files)
      } else {
        setSingleFile(files)
      }
    }
  }, [files])

  /**
   * Effect set images preview
   */
  useEffect(() => {
    if ((singleFile && singleFile.length > 0) || (fileList && fileList.length > 0)) {
      const listPromiseImagesPreview: Promise<string | unknown>[] = (
        multiple ? fileList : singleFile
      ).map((file: File) => convertFileToBase64(file))
      Promise.all(listPromiseImagesPreview).then((res) => setImagesPreview(res))
    }
  }, [singleFile, fileList])

  return (
    <>
      {!disable && (
        <CustomBox>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '100%',
              height: '13rem',
              border: '2px dashed #4267b2',
              borderRadius: '20px'
            }}
            ref={wrapperRef}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDragLeave}>
            <Stack justifyContent="center" sx={{ p: 1, textAlign: 'center' }}>
              <Typography sx={{ color: '#ccc' }}>
                {limit > 1 ? '업로드할 파일 찾아보기' : '업로드할 파일 찾아보기'}
              </Typography>
              <div>
                <img src={uploadImg} alt="file upload" style={{ width: '5rem' }} />
              </div>
              <Typography variant="body1" component="span">
                <strong>지원되는 파일</strong>
              </Typography>
              <Typography variant="body2" component="span">
                JPG, JPEG, PNG
              </Typography>
            </Stack>
            <Controller
              name={name}
              defaultValue=""
              control={control}
              render={({ field: { name, onBlur, ref } }) => (
                <input
                  type="file"
                  name={name}
                  onBlur={onBlur}
                  ref={ref}
                  onChange={onFileDrop}
                  multiple={multiple}
                  accept="image/jpg, image/png, image/jpeg"
                  style={{
                    opacity: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    cursor: 'pointer'
                  }}
                />
              )}
            />
          </Box>
        </CustomBox>
      )}

      <FormHelperText sx={{ textAlign: 'center', my: 1 }} error={!!errors[name]}>
        {errors[name] ? (errors[name]?.message as unknown as string) : ''}
      </FormHelperText>

      {fileList.length > 0 || singleFile.length > 0 ? (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          {(multiple ? fileList : singleFile).map((item, index) => {
            const imageType = item.type.split('/')[1] as CustomType
            return (
              <Grid xs={6} key={index}>
                <Card variant="outlined" sx={{ width: 400 }}>
                  <CardOverflow>
                    <AspectRatio ratio="2">
                      <img src={imagesPreview && imagesPreview[index]} loading="lazy" alt="" />
                    </AspectRatio>
                  </CardOverflow>
                  <Typography sx={{ fontSize: 'md', mt: 2, mb: 2, height: 50 }}>
                    {item.name}
                  </Typography>
                  <Divider inset="context" />
                  <CardOverflow
                    variant="soft"
                    sx={{
                      display: 'flex',
                      gap: 1.5,
                      py: 1.5,
                      px: 'var(--Card-padding)',
                      bgcolor: 'background.level1'
                    }}>
                    <Typography sx={{ fontWeight: 'md', color: 'text.secondary' }}>
                      {calcSize(item.size)}
                    </Typography>
                    <Divider orientation="vertical" />
                    <Typography sx={{ fontWeight: 'md', color: 'text.secondary' }}>
                      {imageType}
                    </Typography>
                    <Typography sx={{ fontWeight: 'md', color: 'text.secondary' }}>
                      {!disable && (
                        <IconButton
                          onClick={() => {
                            if (multiple) {
                              fileRemove(item)
                            } else {
                              fileSingleRemove()
                            }
                          }}
                          sx={{
                            color: '#df2c0e',
                            position: 'absolute',
                            right: '1rem',
                            top: '50%',
                            transform: 'translateY(-50%)'
                          }}>
                          <Delete />
                        </IconButton>
                      )}
                    </Typography>
                  </CardOverflow>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      ) : null}
    </>
  )
}

export default FileUpload
