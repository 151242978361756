import React, { useEffect, useRef } from 'react'
import { Box, Card, Stack, Tooltip, Typography } from '@mui/joy'
import { ChangeEvent, useState } from 'react'
import './styles.scss'
import { exportJsonToXlsx, readXLSXFileWithImages } from '@core/helpers/excel-sheet.helper'
import { EquipmentDetail } from './equipment-detail'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  confirm,
  deleteConfirm,
  editConfirm,
  savedCheckModal,
  success,
  warn
} from '@core/components/modal'
import { ContentMenu } from '@core/components/menu'
import { DataTable } from '@core/components/table'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Actions, ColumnsHeader, TableColumns } from './template'
import { PlagiarismOutlined, LaunchOutlined } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { MRT_Cell, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { Virtualizer } from '@tanstack/react-virtual'
import { SortingState } from '@tanstack/react-table'
import { ContentMenuDataProps } from '@core/components/menu/type'
import { CreateEquipment } from './equipment-create'
import { deleteEquipmentList, getEquipmentList, postEquipmentList, postExcelDownload, postExcelUpload } from './query'
import { useMutation, useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'
import { editState } from '@pages/recoil'
import { deleteQuery, ErrorQuery } from '@core/helpers/query.helper'
import _ from 'lodash'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { CommonProps } from './equipment-detail/card/type'

let unsaved = false

export const EquipmentManage = () => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  const columns = ColumnsHeader
  const authorizationModel = useRecoilValue(authorizationSelector)
  const [tableData, setTableData] = useState<CommonProps[]>([])
  const [actionInfo, setActionInfo] = useState<{ id: string; type: string }>({
    id: '',
    type: ''
  })
  const [info, setInfo] = useState<ContentMenuDataProps>({})
  const [open, setOpen] = useState({
    equipment: false,
    detail: false
  })
  const [loading, setLoading] = useState(false)
  const tableInstanceRef = useRef<MRT_TableInstance<CommonProps>>(null)
  const rowVirtualizerInstanceRef = useRef<Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null)
  const [sorting, setSorting] = useState<SortingState>([])
  const [rowSelection, setRowSelection] = useState({})
  const [edit, setEdit] = useRecoilState(editState) // 다른 페이지 이동시에 처리해줘야함.

  const { refetch, isLoading: queryLoading } = useQuery(
    [QueryKey.EQUIPMENTS],
    () => getEquipmentList(authorizationModel, info),
    {
      enabled: false,
      onSuccess: (data: CommonProps[]) => {
        // setTableData(data)
        // 연동을 위한 임시 데이터
        const tempData = data.map((item, index) => ({
          ...item,
          sync: index % 2 === 0 ? false : true
        }))
        setTableData(tempData)
      }
    }
  )

  const handleAction = (row: MRT_Row<CommonProps>) => {
    if (unsaved) {
      savedCheckModal()
    } else {
      const { id, checkType: type } = row.original
      console.log(row.original);
      if (id) {
        setActionInfo({
          id,
          type
        })
        setOpen((prev) => ({ ...prev, detail: true }))
      }
    }
  }

  // TODO: API 변경 후 테스트
  const saveMutation = useMutation((data: CommonProps[]) => postEquipmentList(data), {
    onSuccess: () => {
      refetch()
      success()
      unsaved = false
    },
    onError: ErrorQuery
  })

  const deleteMuation = useMutation((data: string[]) => deleteEquipmentList(data))

  const handleSaveCell = (cell: MRT_Cell<CommonProps>, value: string) => {
    const keyArray = cell.column.id.split('.')
    tableData[+cell.row.index][keyArray[0]][keyArray[1]] = value
    setTableData([...tableData])
    unsaved = true
  }

  const onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const { files } = event.target
      if (files && files[0]) {
        setLoading(true)
        const formData = new FormData();
        formData.append('file', files[0]);
        const results = await postExcelUpload(formData);
        //results: 엑셀 데이터
        if (Object.keys(results[0]).includes('checkMethodName')) {
          // 기존 테이블에서 새로들어온 중복된 데이터 삭제
          let isDuplicate = false
          const oldData = tableData.filter((oldData) => {
            const duplicate = !results.some(
              (newData: any) => newData.header.code === oldData.header.code
            )

            if (!duplicate) {
              isDuplicate = true
            }
            return duplicate
          })

          if (isDuplicate) {
            confirm({
              title: '확인',
              message: '중복된 관리번호가 존재합니다. 덮어쓰시겠습니까?',
              onOk: () => {
                setTableData([...oldData, ...(results as CommonProps[])])
                onResetLoading(true)
              },
              onClickClose: () => onResetLoading(),
              onClosed: () => onResetLoading(),
              confirmButtonText: '덮어쓰기'
            })
          } else {
            setTableData([...oldData, ...(results as CommonProps[])])
            onResetLoading(true)
          }
          unsaved = true
        } else {
          warn({
            message: '잘못된 형식의 파일입니다.',
            onOk: () => onResetLoading(),
            onClosed: () => onResetLoading()
          })
        }
      }
    }
    event.target.value = ''
  }

  const onResetLoading = (saved = false) => {
    setLoading(false)
    unsaved = saved
  }

  // 추가
  const onCreate = async (data: CommonProps) => {
    const isDuplicate = tableData.some(
      (item) => item.header.company === data.header.company && item.header.code === data.header.code
    )
    console.log(data);
    const checkTypeValid = data.checkMethodName;
    if (!checkTypeValid) {
      warn({
        title: '경고',
        message: '점검방법을 선택해주세요.'
      })
      return;
    }

    if (isDuplicate) {
      warn({
        title: '경고',
        message: '중복된 설비가 존재합니다. 확인 후 다시시도해주세요.'
      })
    } else {
      unsaved = true
      setTableData((prev) => [...prev, data])
      setOpen((prev) => ({ ...prev, equipment: false }))
    }
  }

  // 삭제
  const onDelete = () => {
    if (unsaved) {
      savedCheckModal()
    } else {
      deleteConfirm({
        message: (
          <div>
            <div>설비 삭제시 서버에 바로 반영되며, </div>
            <div>관련된 점검계획 및 결과, 수리계획 및 결과가 같이 삭제됩니다.</div>
            <Typography fontWeight={500}>정말 삭제하시겠습니까?</Typography>
          </div>
        ),
        onOk: async () => {
          deleteQuery(
            tableInstanceRef,
            'id',
            deleteMuation,
            tableData,
            setTableData,
            refetch,
            setRowSelection
          )
        }
      })
    }
  }

  // 다운로드
  const onExport = async () => {
    const { company, factory } = info;
    await postExcelDownload({ company, factory });
  }

  const onSave = () => {
    confirm({
      onOk: async () => {
        // call save api
        await saveMutation.mutateAsync(tableData)
      }
    })
  }

  const onSync = (row: MRT_Row<CommonProps>) => {
    const { sync } = row.original
    if (sync) {
      // link!
      window.open('http://192.168.0.230', '_blank')
    } else {
      warn({
        message: 'IEMS 서비스와 연동이 필요한 설비입니다.'
      })
    }
  }

  const onClose = () => {
    if (edit) {
      editConfirm({
        onOk: onModal
      })
    } else {
      onModal()
    }
  }

  const onModal = () => {
    setOpen({ equipment: false, detail: false })
    setEdit(false)
  }

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    rowVirtualizerInstanceRef.current?.scrollToIndex(0)
  }, [sorting])

  useEffect(() => {
    if (!open.detail) {
      refetch()
    }
  }, [open.detail])

  useEffect(() => {
    refetch()
    setEdit(false)
    unsaved = false
  }, [])

  const isLoading = queryLoading || loading

  return (
    <>
      <Stack spacing={2}>
        <Card>
          <ContentMenu
            companyprops={{ enable: true }}
            factoryprops={{ enable: true }}
            setInfo={setInfo}
            actions={
              <Actions
                disabled={Object.keys(rowSelection).length === 0}
                onSearch={refetch}
                onCreate={() => setOpen((prev) => ({ ...prev, equipment: true }))}
                onDelete={onDelete}
                onExport={onExport}
                onUpload={onUpload}
                onSave={onSave}
              />
            }
          />
        </Card>

        <DataTable
          columns={columns}
          data={tableData}
          state={{ isLoading, sorting, rowSelection }}
          onRowSelectionChange={setRowSelection}
          onSortingChange={setSorting}
          tableInstanceRef={tableInstanceRef}
          rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
          rowVirtualizerProps={{ overscan: 50 }} //optionally customize the row virtualizer
          muiTableBodyCellEditTextFieldProps={({ cell }) => ({
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value)
            }
          })}
          displayColumnDefOptions={{
            'mrt-row-actions': {
              size: 100,
              muiTableHeadCellProps: {
                align: 'center'
              }
            }
          }}
          renderRowActions={({ row }) => (
            <Box sx={{ display: 'flex', gap: '0.3rem' }}>
              <Tooltip arrow placement="left" title="상세조회">
                <IconButton color="primary" onClick={() => handleAction(row)}>
                  <PlagiarismOutlined />
                </IconButton>
              </Tooltip>
              {/* 
                TODO : IEMS 연동시 활성화
              <Tooltip arrow placement="right" title="IEMS">
                <IconButton
                  color="primary"
                  onClick={() => onSync(row)}
                  disabled={!row.original.sync}>
                  <LaunchOutlined />
                </IconButton>
              </Tooltip> */}
            </Box>
          )}
          renderTopToolbarCustomActions={() => (
            <Box sx={{ pt: 1, pl: 1 }}>
              <Typography level="h5" fontWeight={700}>
                {t('menu.equipment-manage')}
              </Typography>
            </Box>
          )}
        />

        {open.detail && <EquipmentDetail info={actionInfo} open={open.detail} onClose={onClose} />}
        {open.equipment && (
          <CreateEquipment open={open.equipment} onClose={onClose} onSubmit={onCreate} />
        )}
      </Stack>
    </>
  )
}
