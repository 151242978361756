import { FC } from 'react'
import { ImagesIconProps } from '@core/components/icon/type'
import { SvgIcon, SvgIconProps } from '@mui/joy'

/**
 * Images icon component
 * @param name
 * @param className
 * @param onClick
 * @constructor
 */
const ImagesIcon: FC<ImagesIconProps> = ({ name, className, onClick }) => {
  return (
    <img src={`${process.env.PUBLIC_URL}${name}`} className={className} alt="" onClick={onClick} />
  )
}

export default ImagesIcon

export const AerixIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path d="M1.78857 5.91091L4.12414 0.5H22.1644L19.9899 5.91091H1.78857Z" fill="#009FE3" />
      <path d="M4.12427 15.2492L6.29876 9.92554H24.5001L22.3256 15.2492H4.12427Z" fill="#009FE3" />
      <path d="M0.5 24.4999L2.51342 19.3508H20.7148L18.6208 24.4999H0.5Z" fill="#59C6F2" />
    </SvgIcon>
  )
}

export const FilterBackIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M12.6761 21L7.84717 19.3402V2.65975L12.6761 0.999999V21Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3782 11H19.9999"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3904 9.34017L20 10.9999L18.3904 12.6597"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1711 5.60571H18.7929"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1831 3.94612L18.7928 5.60587L17.1831 7.26562"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1711 16.3943H18.7929"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1831 18.054L18.7928 16.3942L17.1831 14.7345"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.20724 7.68057C1.20724 8.02431 0.936982 8.30298 0.603622 8.30298C0.270262 8.30298 0 8.02431 0 7.68057C0 7.33684 0.270262 7.05817 0.603622 7.05817C0.936982 7.05817 1.20724 7.33684 1.20724 7.68057Z"
        fill="black"
      />
      <path
        d="M4.22531 5.39835C4.22531 5.74208 3.95505 6.02075 3.62169 6.02075C3.28833 6.02075 3.01807 5.74208 3.01807 5.39835C3.01807 5.05461 3.28833 4.77594 3.62169 4.77594C3.95505 4.77594 4.22531 5.05461 4.22531 5.39835Z"
        fill="black"
      />
      <path
        d="M5.83493 9.13272C5.83493 9.47645 5.56467 9.75513 5.23131 9.75513C4.89795 9.75513 4.62769 9.47645 4.62769 9.13272C4.62769 8.78899 4.89795 8.51031 5.23131 8.51031C5.56467 8.51031 5.83493 8.78899 5.83493 9.13272Z"
        fill="black"
      />
      <path
        d="M1.20724 14.3195C1.20724 13.9758 0.936982 13.6971 0.603622 13.6971C0.270262 13.6971 0 13.9758 0 14.3195C0 14.6632 0.270262 14.9419 0.603622 14.9419C0.936982 14.9419 1.20724 14.6632 1.20724 14.3195Z"
        fill="black"
      />
      <path
        d="M4.22531 16.6017C4.22531 16.258 3.95505 15.9793 3.62169 15.9793C3.28833 15.9793 3.01807 16.258 3.01807 16.6017C3.01807 16.9454 3.28833 17.2241 3.62169 17.2241C3.95505 17.2241 4.22531 16.9454 4.22531 16.6017Z"
        fill="black"
      />
      <path
        d="M5.83493 12.8673C5.83493 12.5236 5.56467 12.2449 5.23131 12.2449C4.89795 12.2449 4.62769 12.5236 4.62769 12.8673C4.62769 13.2111 4.89795 13.4897 5.23131 13.4897C5.56467 13.4897 5.83493 13.2111 5.83493 12.8673Z"
        fill="black"
      />
      <path
        d="M2.61569 10.9999C2.61569 11.3436 2.34543 11.6223 2.01207 11.6223C1.67871 11.6223 1.40845 11.3436 1.40845 10.9999C1.40845 10.6562 1.67871 10.3775 2.01207 10.3775C2.34543 10.3775 2.61569 10.6562 2.61569 10.9999Z"
        fill="black"
      />
      <path
        d="M10.8652 10.9999C10.8652 11.3436 10.5949 11.6223 10.2616 11.6223C9.92822 11.6223 9.65796 11.3436 9.65796 10.9999C9.65796 10.6562 9.92822 10.3775 10.2616 10.3775C10.5949 10.3775 10.8652 10.6562 10.8652 10.9999Z"
        fill="black"
      />
      <path
        d="M10.8652 5.39835C10.8652 5.74208 10.5949 6.02075 10.2616 6.02075C9.92822 6.02075 9.65796 5.74208 9.65796 5.39835C9.65796 5.05461 9.92822 4.77594 10.2616 4.77594C10.5949 4.77594 10.8652 5.05461 10.8652 5.39835Z"
        fill="black"
      />
      <path
        d="M10.8652 16.6017C10.8652 16.258 10.5949 15.9793 10.2616 15.9793C9.92822 15.9793 9.65796 16.258 9.65796 16.6017C9.65796 16.9454 9.92822 17.2241 10.2616 17.2241C10.5949 17.2241 10.8652 16.9454 10.8652 16.6017Z"
        fill="black"
      />
    </SvgIcon>
  )
}
