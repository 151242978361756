/* eslint-disable @typescript-eslint/no-empty-function */
import { String } from '@core/enums/common.enum';
import React, { useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form'
import { SettingFactorySearchFormInDto } from '../factory/type';
import { useTranslation } from 'react-i18next';
import { TranslateEnum } from '@core/enums/translate.enum';
import { CompanyModel } from '../company/type';
import { CustomModel } from './type';
import InspectSearchForm from './search-form/InspectSearchForm';
import { Box, Card, Stack, Typography } from '@mui/joy';
import { useGetListCompany } from '../query';
import { BaseTable } from '@core/components/table';
import { InspectCustomTableColumns, settingCompanyAndFactoryTableRowAction } from '../template';
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table';
import { useDeleteTemplate, useInspectCustom } from './query';
import InspectCraeteForm from './create-form/InspectCraeteForm';
import { success } from '@core/components/modal';

const InspectCustom = () => {
  const [companyId, setCompanyId] = useState();
  const { data: listCompany = [], refetch: getListCompany } = useGetListCompany();
  const { data: listCustom = [], isLoading, refetch: getListCustom } = useInspectCustom(companyId);
  const [onAddModal, setOnAddModal] = useState(false);
  const [onUpdateModal, setOnUpdateModal] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [rowData, setRowData] = useState({});
  const { t } = useTranslation([TranslateEnum.FACTORY]);
  const columns = useMemo<MRT_ColumnDef<any>[]>(() => InspectCustomTableColumns(), []);
  const tableInstanceRef = useRef<MRT_TableInstance<any>>(null);

  const { mutateAsync: mutateDeleteAsync } = useDeleteTemplate();


  const formSearchContext = useForm<SettingFactorySearchFormInDto>({
    defaultValues: {
      companyId: undefined,
      name: String.EMPTY_STRING
    }
  })

  const isDisableDeleteMultipleRows = useMemo(() => {
    const row = rowSelection && Object.keys(rowSelection)
    return row && row.length === 0
  }, [rowSelection]);

  const handleDeleteListCustom = async () => {
    // 다수의 리스트 삭제
    const selectionIdxArr = Object.keys(rowSelection).map(el => Number(el));
    const cvtIdxArr = selectionIdxArr.map(el => listCustom[el].checkMethodId);
    const res = await mutateDeleteAsync(cvtIdxArr);
    if (res.status === 200) {
      success({ title: '삭제 성공!', message: '요청이 적용되었습니다.' });
      getListCustom();
      setRowSelection({});
    }
  };

  const handleClickUpdateList = (listData: CustomModel) => {
    listData.checkList = JSON.parse(listData.checkList);
    setRowData(listData);
    setOnUpdateModal(true);
  }

  const handleClickDeleteList = async (listData: CustomModel) => {
    const res = await mutateDeleteAsync([listData.checkMethodId]);
    console.log(res);
    if (res.status === 200) {
      success({ title: '삭제 성공!', message: '요청이 적용되었습니다.' });
      getListCustom();
    }
  }

  const searchFormSubmit = async (data: any) => {
    console.log(data);
    await setCompanyId(data.companyId);
    await getListCustom();
  }

  return (
    <Stack spacing={2}>
      <Card>
        <FormProvider {...formSearchContext}>
          <InspectSearchForm
            onSubmit={searchFormSubmit}
            listCompany={listCompany}
            onCreate={() => setOnAddModal(true)}
            onDelete={handleDeleteListCustom}
            disableDeleteAll={isDisableDeleteMultipleRows}
          />
        </FormProvider>
      </Card>
      <BaseTable
        enableRowSelection
        enableRowActions
        enableSelectAll
        data={listCustom}
        columns={columns}
        state={{ isLoading, rowSelection }}
        onRowSelectionChange={setRowSelection}
        tableInstanceRef={tableInstanceRef}
        enableColumnDragging={true}
        muiTableContainerProps={{
          sx: {
            height: '600px'
          }
        }}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ mt: 1, pl: 1 }} component={'span'}>
            <Typography level="h6" fontWeight={600}>
              점검방법 관리
            </Typography>
          </Box>
        )}
        renderRowActionMenuItems={({ row, closeMenu }) =>
          settingCompanyAndFactoryTableRowAction(
            t,
            row,
            () => handleClickUpdateList(row.original),
            () => handleClickDeleteList(row.original),
            closeMenu
          )
        }
      />
      {onAddModal && <InspectCraeteForm
        formType='ADD'
        open={onAddModal}
        listCompany={listCompany}
        refetch={getListCustom}
        onClose={() => setOnAddModal(false)}
      />
      }
      {onUpdateModal && <InspectCraeteForm
        formType='UPDATE'
        open={onUpdateModal}
        listCompany={listCompany}
        rowData={rowData}
        refetch={getListCustom}
        onClose={() => setOnUpdateModal(false)}
      />
      }
    </Stack>
  );
};

export default InspectCustom;