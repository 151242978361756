import { ContentMenuDataProps } from '@core/components/menu/type'
import { apis } from '@core/configs/api'
import { request } from '@core/services/base.service'
import { AuthorizationModel } from '@core/models/authorization.model'
import { CommonProps } from './equipment-detail/card/type'
import { CryptoHelperInstance } from '@core/helpers/crypto.helper'
import { getCookieByName } from '@core/helpers/cookie.helper'
import { CookieEnum } from '@core/enums/cookie.enum'
import _ from 'lodash'
import dayjs from 'dayjs'
import { makeExcelDownload } from '@pages/service/miscFunc'
import { fail } from '@core/components/modal'

const path = apis.EQUIPMENT.path

export const getEquipmentList = async (
  loginInfo: AuthorizationModel,
  info: ContentMenuDataProps
) => {
  const params: any = {}
  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )
  if ((info && info.company) || !isAdmin) {
    params.companyId = isAdmin ? info.company : companyId
  }

  if (info && info.factory) {
    params.factoryId = info.factory
    delete params.companyId
  }

  return request.get(path, _.omitBy(params, _.isNull))
}

export const postEquipmentList = async (data: CommonProps[]) => request.post(path, data) as any
export const putEquipmentList = async (data: CommonProps) => request.put(path, data) as any
export const deleteEquipmentList = async (data: string[]) => request.delete(path, data) as any
export const getEquipmentDetail = async (id: string) => request.get(`${path}?equipmentId=${id}`)

export const postExcelDownload = async (prms: any) => {
  const { company, factory } = prms;
  const cvtPrms = `${company ? `?companyId=${company}` : ''}${factory ? `&factoryId=${factory}` : ''}`;

  try {
    const res: any = await request.post(`/equipment/downloads${cvtPrms}`, {}, { responseType: 'blob' });
    const pageName = '설비 관리';
    makeExcelDownload(res, pageName);
  } catch (e: any) {
    fail({ title: "다운로드 실패!", message: '요청이 실패했습니다.' });
  }
};

export const postExcelUpload = async (formData: FormData) => {
  try {
    const data = await request.post(`/equipment/uploads`, formData, {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    }) as any;
    console.log(data);
    return data;
  } catch (e: any) {
    fail({ title: "업로드 실패!", message: '요청이 실패했습니다.' });
  }
}