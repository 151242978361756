import { BaseTable } from '@core/components/table'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Box, Typography } from '@mui/joy'
import { RepairPlanModel } from '@pages/repair/type'
import { MRT_ColumnDef } from 'material-react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface RepairDataProps {
  company: string
  team: string
  no: string
  name: string
  first_class: string
  second_class: string
  situation: string
  code: string
  job: string
  count: number
}

export const RepairList = ({ data }: { data: RepairPlanModel[] }) => {
  const [tableData, setTableData] = useState<RepairPlanModel[]>([])
  const convertDataToTableData = async (data: RepairPlanModel[]) => {
    const listData: RepairPlanModel[] = []
    for (const el of data) {
      const rootRepairPlan = { ...el }
      const { repairPlans } = rootRepairPlan

      if (repairPlans && repairPlans.length > 0) {
        for (const repairPlansModel of repairPlans) {
          const mergePropertiesOfRepairPlan = {
            ...el,
            ...repairPlansModel,
            parentId: el?.id
          }

          listData.push(mergePropertiesOfRepairPlan)
        }
      }
    }
    return listData
  }

  const getData = async () => {
    const newData = await convertDataToTableData(data)
    setTableData(newData)
  }

  useEffect(() => {
    getData()
  }, [data])
  const { t } = useTranslation([TranslateEnum.COMMON])
  const columns = useMemo<MRT_ColumnDef<RepairPlanModel>[]>(
    () => [
      {
        header: '회사명',
        accessorKey: 'company'
      },
      {
        header: '공장명',
        accessorKey: 'factory'
      },
      {
        header: '관리번호',
        accessorKey: 'code'
      },
      {
        header: '설비명',
        accessorKey: 'name'
      },
      {
        header: '점검일자',
        accessorKey: 'checkDate',
        maxSize: 120
      },
      {
        header: '대분류',
        accessorKey: 'category'
      },
      {
        header: '소분류',
        accessorKey: 'subCategory'
      },
      {
        header: '현상',
        accessorKey: 'abnormality'
      }
    ],
    []
  )
  return (
    <BaseTable
      columns={columns}
      data={tableData}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ pt: 1, pl: 1 }}>
          <Typography level="h5" fontWeight={700}>
            {t('dashboard.today_repair_list')}
          </Typography>
        </Box>
      )}
    />
  )
}
