import CryptoJS from 'crypto-js'
import { applicationEnvironmentVariable } from '@core/configs/env.config'
import { String } from '@core/enums/common.enum'

/**
 * Crypto helper instance
 */
class CryptoHelper {
  private readonly key: string
  constructor() {
    const envConfig = applicationEnvironmentVariable()
    this.key = envConfig.privateKey.padEnd(32, String.EMPTY_STRING)
  }

  /**
   * Encode text with AES256
   * @param value
   */
  encodeByAES56(value: string) {
    const cipher = CryptoJS.AES.encrypt(value, CryptoJS.enc.Utf8.parse(this.key), {
      iv: CryptoJS.enc.Utf8.parse(''),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })
    return cipher.toString()
  }

  decodeByAES256(value: string) {
    const cipher = CryptoJS.AES.decrypt(value, CryptoJS.enc.Utf8.parse(this.key), {
      iv: CryptoJS.enc.Utf8.parse(''),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })
    return cipher.toString(CryptoJS.enc.Utf8)
  }

  /**
   * Decode by AES256 and convert to model
   * @param value
   */
  decodeByAES256AndConvertToModel<T>(value: string) {
    if (value && value.length > 0) {
      return JSON.parse(this.decodeByAES256(value)) as T
    }
    return {} as any
  }
}

const CryptoHelperInstance = new CryptoHelper()

export { CryptoHelperInstance }
