export enum QueryKey {
  COMPANYINFO = 'companyInfo',
  EQUIPMENTS = 'equipments',
  REFRESH_TOKEN = 'refreshToken',
  CHECKTYPE = 'checktype',
  INSPECTPLAN = 'inspectplan',
  INSPECTRESULT = 'inspectresult',
  REPAIRPLAN = 'repairplan',
  EQUIPMENTDETAIL = 'equipmentdetail',
  USER_INFORMATION = 'userInformation',
  CHANGE_USER_INFORMATION = 'changeUserInformation',
  FILTER = 'filter',
  GET_LIST_COMPANY = 'getListCompany',
  GET_ALL_FACTORY = 'getAllFactory',
  FILTER_FACTORY = 'filterFactory',
  INSPECT_CUSTOM = 'inspect_custom',
  TEMPLATE_TYPE = 'template_type'
}
