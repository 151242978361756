import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Modal,
  Stack,
  Typography
} from '@mui/joy'
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  EmailOutlined,
  EnhancedEncryptionOutlined,
  CheckCircle,
  CancelRounded,
  KeyboardArrowLeftOutlined
} from '@mui/icons-material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ValidationSchemaFormServiceSync } from '@pages/login/template'
import { useMutation } from '@tanstack/react-query'
import { postSyncIems } from './query'
import { SyncProps } from './type'
import { StatusEnum, TargetEnum } from '@core/enums/common.enum'
import { ModalBaseProps } from '@core/components/modal/type'
import { IconButton } from '@mui/material'
interface ServiceLoginProps extends ModalBaseProps {
  target: string
}

export const ServiceLogin = ({ open, onClose, target }: ServiceLoginProps) => {
  const [hide, setHide] = useState(true)
  const [status, setStatus] = useState(StatusEnum.IDLE)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<SyncProps>({
    resolver: yupResolver(ValidationSchemaFormServiceSync())
  })

  const { mutate, isLoading } = useMutation((data: SyncProps) => postSyncIems(data), {
    onSuccess: (data) => {
      setStatus(StatusEnum.SUCCESS)
    },
    onError: (err) => {
      setStatus(StatusEnum.FAIL)
    }
  })

  const onShowPassword = () => {
    setHide((prev) => !prev)
  }

  const onSubmit = (data: SyncProps) => {
    // conso
    mutate(data)
  }

  const onBack = () => {
    reset()
    setStatus(StatusEnum.IDLE)
  }

  const getComponent = () => {
    switch (status) {
      case StatusEnum.SUCCESS:
        return (
          <Stack gap={2} alignItems={'center'}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CheckCircle sx={{ fontSize: 80, color: 'green' }} />
            </Box>
            <Typography level={'body5'} color={'neutral'}>
              {`IEMS 서비스 ${target === TargetEnum.CONNECT ? '연동' : '해제'}에 성공하였습니다.`}
            </Typography>
            <Button fullWidth onClick={onClose}>
              닫기
            </Button>
          </Stack>
        )
      case StatusEnum.FAIL:
        return (
          <Stack gap={2} alignItems={'center'}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CancelRounded sx={{ fontSize: 80, color: 'red' }} />
            </Box>
            <Typography level={'body5'} color={'neutral'}>
              {`IEMS 서비스 ${target === TargetEnum.CONNECT ? '연동' : '해제'}에 실패하였습니다.`}
            </Typography>
            <Button fullWidth onClick={onClose}>
              닫기
            </Button>
          </Stack>
        )
      case StatusEnum.IDLE:
        return (
          <Stack spacing={1}>
            <Stack alignItems={'center'} sx={{ marginBottom: 2 }}>
              <Typography level={'body5'} color={'neutral'}>
                {`IEMS 서비스 ${
                  target === TargetEnum.CONNECT ? '연동' : '해제'
                }를 위한 정보를 입력해주세요.`}
              </Typography>
            </Stack>
            <FormControl>
              <Input
                fullWidth
                startDecorator={<EmailOutlined color="action" />}
                placeholder="이메일"
                type="email"
                {...register('email')}
              />
              {errors.email && (
                <FormHelperText sx={{ color: '#D3232F' }}>{errors.email.message}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <Input
                fullWidth
                type={hide ? 'password' : 'text'}
                placeholder="비밀번호"
                startDecorator={<EnhancedEncryptionOutlined color="action" />}
                endDecorator={
                  <>
                    {hide ? (
                      <VisibilityOutlined color={'action'} onClick={onShowPassword} />
                    ) : (
                      <VisibilityOffOutlined color={'action'} onClick={onShowPassword} />
                    )}
                  </>
                }
                {...register('password')}
              />
              {errors.password && (
                <FormHelperText sx={{ color: '#D3232F' }}>{errors.password.message}</FormHelperText>
              )}
            </FormControl>
            <Button type="submit" loading={isLoading}>
              {`${target === TargetEnum.CONNECT ? '연동하기' : '연동 해제하기'}`}
            </Button>
          </Stack>
        )
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          rowGap={2}
          padding={5}
          sx={{
            background: 'white',
            borderRadius: 2,
            boxShadow: '0 20px 27px rgb(0 0 0 / 5%)',
            width: 400
          }}>
          <Grid xs={12}>
            <Grid container>
              <Grid xs={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                {(status === StatusEnum.FAIL || status === StatusEnum.SUCCESS) && (
                  <IconButton onClick={onBack}>
                    <KeyboardArrowLeftOutlined />
                  </IconButton>
                )}
              </Grid>
              <Grid xs={8} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                <Typography level={'h5'} sx={{ fontWeight: 600 }}>
                  {`서비스 ${target === TargetEnum.CONNECT ? '연동' : '해제'}`}
                </Typography>
              </Grid>
              <Grid xs={2}></Grid>
            </Grid>
          </Grid>
          <Grid xs={12}>{getComponent()}</Grid>
        </Grid>
      </form>
    </Modal>
  )
}
