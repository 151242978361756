import { confirm, success } from '@core/components/modal'
import { QueryKey } from '@core/enums/query.enum'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Box, Grid, Radio, RadioGroup, Stack, Table, Textarea } from '@mui/joy'
import { Skeleton } from '@mui/material'
import { RepairPlanCreateprops } from '@pages/repair/type'
import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { postInspectResult } from '../query'
import { planEditState } from '../recoil'
import { InspectDataProps } from '../type'
import { getInspectPlan } from './query'
import { CellInput, EmptyTd, Td, Th } from './styles'
import { firstHead, MethodTop, secondHeader } from './template'
import './styles.scss'
import { ErrorQuery } from '@core/helpers/query.helper'
import { Controller, useForm } from 'react-hook-form'
import { PlanMethodEnum } from '@core/enums/common.enum'
import { DatePicker } from '@core/components/date-picker'

export const PlanMethod = ({ info }: any) => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  const setEdit = useSetRecoilState(planEditState)
  const {
    register,
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { isDirty }
  } = useForm<InspectDataProps>()
  const componentRef = useRef(null)
  const checkList = getValues().checkList
  const checkListDVA = getValues().checkListDVA

  console.log(info);
  const { refetch, isFetching } = useQuery(
    [QueryKey.INSPECTPLAN, 'list'],
    () => getInspectPlan(info),
    {
      enabled: false,
      onSuccess: (data: InspectDataProps) => {
        setEdit(false)
        console.log(data);
        reset(data[0])
      }
    }
  )
  const saveMutation = useMutation((data: InspectDataProps) => postInspectResult(data), {
    onSuccess: () => {
      success({
        onOk: refetch,
        onClosed: refetch
      })
    },
    onError: ErrorQuery
  })

  const getPressureComponent = (
    categoryKey: string,
    subKey: string,
    lastKey: string,
    startIndex: number,
    endIndex: number
  ) => {
    const pressureCells = []
    for (let index = startIndex; index <= endIndex; index++) {
      pressureCells.push(
        <React.Fragment key={index}>
          <td>#{index}</td>
          <td>
            <CellInput
              {...register(
                `${PlanMethodEnum.CHECKLIST}.${categoryKey}.${subKey}.${lastKey}.${index}`
              )}
            />
          </td>
        </React.Fragment>
      )
    }

    return <tr className="pressure-section">{pressureCells}</tr>
  }

  const onSubmit = (data: InspectDataProps) => {
    confirm({
      onOk: async () => {
        await saveMutation.mutateAsync(data)
      }
    })
  }

  useEffect(() => {
    setEdit(isDirty)
  }, [isDirty])

  useEffect(() => {
    refetch()
    setEdit(false)
  }, [])


  return (
    <Stack spacing={2} ref={componentRef}>
      <MethodTop info={info} printRef={componentRef} />
      <form id="table-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack
          spacing={1}
          sx={{
            height: '75vh',
            overflowY: 'auto'
          }}>
          {isFetching ? (
            <>
              <Grid container wrap="nowrap">
                <Box sx={{ width: '50%', mr: 1 }}>
                  <Skeleton variant="rounded" height={300} />
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Skeleton variant="rounded" height={300} />
                </Box>
              </Grid>
              <Skeleton variant="rounded" height={500} />
            </>
          ) : (
            <>
              <Table borderAxis="both" sx={{ textAlign: 'center' }}>
                <thead>{firstHead.map((item) => item)}</thead>
                <tbody>
                  {checkListDVA &&
                    Object.keys(checkListDVA).map((key, index) => {
                      const arrayValue = checkListDVA[key]

                      return (
                        <tr key={index}>
                          <td>{key}</td>
                          {arrayValue.map((_: any, arrayIndex: number) => {
                            if (arrayIndex === 8) {
                              return <EmptyTd key={arrayIndex} />
                            } else {
                              return (
                                <td key={arrayIndex}>
                                  <CellInput
                                    {...register(
                                      `${PlanMethodEnum.CHECKLISTDVA}.${key}.${arrayIndex}`
                                    )}
                                  />
                                </td>
                              )
                            }
                          })}
                        </tr>
                      )
                    })}
                </tbody>
              </Table>

              <Table>
                <thead>
                  <tr>
                    {secondHeader.map((item, key) => (
                      <Th key={key} style={item.style}>
                        {item.title}
                      </Th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {checkList &&
                    Object.keys(checkList).map((categoryKey) => {
                      // categoryKey : 외관상태
                      const category = checkList[categoryKey]
                      const subCategoryKey = Object.keys(category)
                      console.log(categoryKey);
                      return subCategoryKey.map((subKey, subIndex) => {
                        // subCategory : 비산체크
                        // const subCategory = category[subKey]
                        const subCategory = secondHeader
                          .filter(el => !!el.key)
                          .map(el => el.key);

                        return (
                          <tr key={subIndex}>
                            {subIndex === 0 && (
                              <Td rowSpan={subCategoryKey.length}>{categoryKey}</Td>
                            )}
                            <Td>{subKey}</Td>
                            {subCategory.map((lastKey) => {
                              if (
                                lastKey === PlanMethodEnum.ACTIONPLANDATE
                              ) {
                                const dateVal: any = checkList[categoryKey][subKey][lastKey];
                                console.log(dateVal);
                                return (<Td key={lastKey}>
                                  <DatePicker
                                    InputProps={{
                                      ...register(`checkList.${categoryKey}.${subKey}.${lastKey}`)
                                    }}
                                    defaultValue={dateVal ? new Date(dateVal) : undefined}
                                    value={dateVal ? new Date(dateVal) : undefined}
                                  />
                                </Td>)
                              }
                              if (
                                lastKey === PlanMethodEnum.RESULT
                              ) {
                                return (
                                  <Td key={lastKey}>
                                    <Controller
                                      control={control}
                                      name={`checkList.${categoryKey}.${subKey}.${lastKey}`}
                                      render={({ field: { value, onChange } }) => (
                                        <RadioGroup
                                          orientation="horizontal"
                                          sx={{
                                            justifyContent: 'space-around'
                                          }}
                                          value={value ?? PlanMethodEnum.NONE}
                                          onChange={onChange}>
                                          {lastKey === PlanMethodEnum.RESULT && (
                                            <>
                                              <Radio
                                                label={PlanMethodEnum.BAD}
                                                value={PlanMethodEnum.BAD}
                                              />
                                              <Radio
                                                label={PlanMethodEnum.NORMAL}
                                                value={PlanMethodEnum.NORMAL}
                                              />
                                            </>
                                          )}
                                          <Radio
                                            label={PlanMethodEnum.NONE}
                                            value={PlanMethodEnum.NONE}
                                          />
                                        </RadioGroup>
                                      )}
                                    />
                                  </Td>
                                )
                              } else if (
                                subKey === 'Filter Bag 차압 CHECK (㎜H2O)' &&
                                lastKey === PlanMethodEnum.NOTE
                              ) {
                                return (
                                  <Td key={lastKey} style={{ padding: 0 }}>
                                    {getPressureComponent(categoryKey, subKey, lastKey, 1, 4)}
                                    {getPressureComponent(categoryKey, subKey, lastKey, 5, 8)}
                                  </Td>
                                )
                              } else {
                                return (
                                  // register 키값 생성시 ","가 존재하면 안됨
                                  <Td key={lastKey}>
                                    <CellInput
                                      {...register(
                                        `${PlanMethodEnum.CHECKLIST}.${categoryKey}.${subKey}.${lastKey}`
                                      )}
                                    />
                                  </Td>
                                )
                              }
                            })}
                          </tr>
                        )
                      })
                    })}
                  <tr>
                    <Td>{t(`inspect.totalNote`)}</Td>
                    <Td colSpan={7}>
                      <Controller
                        control={control}
                        name="totalNote"
                        render={({ field: { value, onChange } }) => (
                          <Textarea
                            minRows={3}
                            maxRows={3}
                            value={value ?? ''}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
        </Stack>
      </form>
    </Stack>
  )
}
