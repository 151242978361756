import * as yup from 'yup'
import { TFunction } from 'i18next'

/**
 * Validation rule of form login
 * @param translate
 */
const validationSchemaFormLogin = (translate: TFunction) =>
  yup.object({
    username: yup
      .string()
      .required(`${translate('login.username-validate-required')}`)
      .matches(
        /^[a-z0-9._]{4,20}$/,
        '아이디는 4~20자이며 영문, 숫자, 특수문자(.),(_)를 지원합니다.'
      ),
    password: yup
      .string()
      .required(`${translate('login.password-validate-required')}`)
      .matches(
        /^(?=.*[a-zA-Z0-9])(?=.*[$`~!@$!%*#^?.&\\(\\)\-_=+]).{8,16}$/,
        '비밀번호는 8~16자이며 영문, 숫자, 특수문자, 대소문자를 지원하며 반드시 특수문자가 포함되어야 합니다.'
      )
  })

const ValidationSchemaFormServiceSync = () =>
  yup.object({
    password: yup
      .string()
      .required('비밀번호를 입력해주세요.')
      .matches(
        /^(?=.*[a-zA-Z0-9])(?=.*[$`~!@$!%*#^?.&\\(\\)\-_=+]).{8,16}$/,
        '비밀번호는 8~16자이며 영문, 숫자, 특수문자, 대소문자를 지원하며 반드시 특수문자가 포함되어야 합니다.'
      ),
    email: yup
      .string()
      .required('이메일을 입력해주세요.')
      .email('유효하지 않은 형식의 이메일 주소입니다.')
  })

export { validationSchemaFormLogin, ValidationSchemaFormServiceSync }
