import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { snackbarSelector } from '@core/components/snackbar/recoil'
import { SnackBarState } from '@core/components/snackbar/type'
import { CloseOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Grid, IconButton, Snackbar, Typography } from '@mui/material'
import { String } from '@core/enums/common.enum'
import './style.scss'

/**
 * Snack bar component use for display notify
 * @constructor
 */
const ApplicationNotify: FC = () => {
  const [notifyState] = useRecoilState(snackbarSelector)
  const resetSnackbar = useResetRecoilState(snackbarSelector)
  const {
    isOpen,
    message,
    isShowProgress,
    valueProgress,
    duration,
    isWaitingProgress
  }: SnackBarState = notifyState
  const [progress, setProgress] = useState(notifyState.valueProgress || 0)

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    resetSnackbar()
    setProgress(0)
  }

  useEffect(() => {
    let timer: any = null
    if (isOpen && isShowProgress && duration > 0) {
      timer = setInterval(() => {
        setProgress((prevProgress: number) =>
          prevProgress >= (isWaitingProgress ? 99 : 100)
            ? isWaitingProgress
              ? 99
              : 100
            : prevProgress + notifyState.duration / 10
        )
      }, notifyState?.duration)
      return () => {
        clearInterval(timer)
      }
    } else {
      clearInterval(timer)
    }
  }, [duration, isShowProgress, valueProgress, isOpen])

  const action = (
    <>
      <IconButton
        className={'snack-bar-action'}
        size="small"
        aria-label="close"
        sx={{ color: 'white' }}
        onClick={handleClose}>
        <CloseOutlined fontSize="small" />
      </IconButton>
    </>
  )

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isOpen}
      onClose={handleClose}
      autoHideDuration={isWaitingProgress ? duration + 100000 : 3000}
      className={'ngx-snack-bar'}
      sx={{ width: 270, mt: 7, pr: 3 }}>
      <Grid container item direction={'column'}>
        {isShowProgress && (
          <Grid item xs={3} sx={{ ml: 1 }}>
            <Box
              sx={{
                position: 'relative',
                display: 'inline-flex',
                p: 1
              }}>
              <CircularProgress color={'warning'} variant="determinate" value={progress} />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Typography
                  variant="caption"
                  component="div"
                  sx={{ color: 'white', fontSize: '0.625rem' }}>{`${Math.round(
                  progress
                )}%`}</Typography>
              </Box>
            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={isShowProgress ? 8 : 12}
          sx={{ textAlign: 'center', mt: isShowProgress ? 2 : 0 }}>
          <span className={'message'}>{message || String.EMPTY_STRING}</span>
          {action}
        </Grid>
      </Grid>
    </Snackbar>
  )
}

export default ApplicationNotify
