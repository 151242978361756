/* eslint-disable no-undef */
import { confirm, success, warn, deleteConfirm } from '@core/components/modal'
import { DataTable } from '@core/components/table'
import { Stack, Typography } from '@mui/joy'
import { MRT_Cell, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { RepairPlanCreateprops, RepairPlanDataProps, RepairTargetProps } from '../type'
import { ColumnsHeader, PlanCreateTop } from './template'
import './styles.scss'
import { TableAction } from './table-action'
import { sleep } from '@core/helpers/action.helper'
import { useMutation, useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'
import { deleteRepairPlan, getRepairPlan, postRepairPlan } from './query'
import { useSetRecoilState } from 'recoil'
import { planEditState } from '@pages/inspect/recoil'
import { deleteQuery, ErrorQuery } from '@core/helpers/query.helper'
import { convertBase64ToArrayBuffer, urlToBase64 } from '@core/helpers/file.helper'
import { getUrlImagesWithImagesId } from '../query'

export const RepairPlanCreate = ({ info }: RepairPlanCreateprops) => {
  const [data, setData] = useState<RepairPlanDataProps[]>([])
  const [rowSelection, setRowSelection] = useState({})
  const [rowIndex, setRowIndex] = useState(0)
  const tableInstanceRef = useRef<MRT_TableInstance<RepairPlanDataProps>>(null)
  const setEdit = useSetRecoilState(planEditState)
  const columns = ColumnsHeader
  const { refetch, isLoading } = useQuery([QueryKey.REPAIRPLAN], () => getRepairPlan({ info }), {
    enabled: false,
    onSuccess: async (data: RepairTargetProps[]) => {
      const newArray: RepairPlanDataProps[] = []

      for (const item of data) {
        if (item.repairPlans) {
          for (const planData of item.repairPlans) {
            const imagesId = planData.abnormalityPicture
            const tempObj = {
              ...item,
              ...planData,
              actionPlanDate: planData.actionPlanDate,
              abnormalityPicture: imagesId
            }
            let img: any = ''
            if (imagesId) {
              img = await urlToBase64(getUrlImagesWithImagesId(imagesId))
              tempObj.abnormalityPicture = {
                name: imagesId,
                content: img
              }
            }

            if (item.repairPlans) delete tempObj.repairPlans
            newArray.push(tempObj)
          }
        }
      }

      setData(newArray)
    }
  })

  const deleteMuation = useMutation((data: number[]) => deleteRepairPlan(data), {
    onError: ErrorQuery
  })

  const saveMutation = useMutation((data: RepairTargetProps[]) => postRepairPlan(data), {
    onSuccess: () => {
      success()
      refetch()
      setEdit(false)
    },
    onError: ErrorQuery
  })

  const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target
    // data인것처럼 floatrow를 사용
    if (files) {
      if (!files[0].type.startsWith('image/')) {
        warn({
          message: '잘못된 형식의 파일입니다.'
        })
      } else {
        const reader = new FileReader()
        reader.onload = (e) => {
          const result = e.target?.result
          if (result) {
            data[rowIndex].abnormalityPicture = {
              name: null,
              content: result
            }
            setData([...data])
            setEdit(true)
          }
        }
        reader.readAsDataURL(files[0])
      }
    }
    event.target.value = ''
  }

  const onDeleteImage = (row: MRT_Row<RepairPlanDataProps>) => {
    const deleteImage = data.map((item, index) => {
      if (index === row.index) {
        item.abnormalityPicture = null
      }
      return item
    })
    setData(deleteImage)
  }

  // 현재 선택된 Row의 데이터를 복제하는 역할을함
  const onAddRow = async (row: MRT_Row<RepairPlanDataProps>) => {
    const { index } = row
    const newData = [...data]
    const clone = JSON.parse(JSON.stringify(row.original))
    delete clone.id
    newData.splice(index + 1, 0, clone)
    setData([])
    await sleep(0)
    setData([...newData])
    setEdit(true)
  }

  const onDelete = () => {
    deleteConfirm({
      message: (
        <div>
          <div>수리 계획 삭제시 서버에 바로 반영되며, </div>
          <div>관련된 수리 결과가 같이 삭제됩니다.</div>
          <Typography fontWeight={500}>정말 삭제하시겠습니까?</Typography>
        </div>
      ),
      onOk: async () => {
        deleteQuery(tableInstanceRef, 'id', deleteMuation, data, setData, refetch, setRowSelection)
      }
    })
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    confirm({
      onOk: async (event) => {
        if (info) {
          const subCategoryObj: {
            [key: string]: RepairTargetProps
          } = {}
          for (const item of data) {
            let newPhoto = null
            if (item.abnormalityPicture) {
              newPhoto = await convertBase64ToArrayBuffer(item.abnormalityPicture.content)
            }

            const prevData = subCategoryObj[item.repairTargetId]
            const commonInfo = {
              id: item.repairTargetId ?? 0,
              checkPlanId: item.checkPlanId,
              category: item.category,
              subCategory: item.subCategory,
              visualReport: item.visualReport
            }
            const commonObj = {
              repairTargetId: item.repairTargetId,
              id: item.id && item.id,
              abnormality: item.abnormality,
              abnormalityPicture: newPhoto
                ? item.abnormalityPicture.name
                  ? item.abnormalityPicture.name
                  : [...newPhoto]
                : null,
              actionPlan: item.actionPlan,
              actionPlanDate: item.actionPlanDate,
              actionPlanDetail: item.actionPlanDetail,
              actionCompletionDate: item.actionCompletionDate,
              actionCompletionPictures: item.actionCompletionPictures,
              manager: item.manager,
              note: item.note
            }

            if (prevData) {
              subCategoryObj[item.repairTargetId] = {
                ...commonInfo,
                repairPlans: prevData.repairPlans
                  ? [...prevData.repairPlans, commonObj]
                  : [commonObj]
              }
            } else {
              subCategoryObj[item.repairTargetId] = {
                ...commonInfo,
                repairPlans: [commonObj]
              }
            }
          }
          await saveMutation.mutateAsync(Object.values(subCategoryObj))
        }
      }
    })
  }

  const handleSaveCell = (cell: MRT_Cell<RepairPlanDataProps>, value: string) => {
    data[+cell.row.index][cell.column.id] = value
    setData([...data])
    setEdit(true)
  }

  useEffect(() => {
    refetch()
    setEdit(false)
  }, [])

  return (
    <>
      <PlanCreateTop
        info={info}
        onDelete={onDelete}
        disabled={Object.keys(rowSelection).length === 0}
      />
      <form id="repair-form" onSubmit={onSubmit}>
        <Stack>
          <DataTable
            columns={columns}
            data={data}
            editingMode="table"
            enableGrouping
            tableInstanceRef={tableInstanceRef}
            state={{ rowSelection, isLoading }}
            onRowSelectionChange={setRowSelection}
            muiTableContainerProps={{
              sx: {
                height: '70vh'
              }
            }}
            displayColumnDefOptions={{
              'mrt-row-actions': {
                header: '기능'
              }
            }}
            muiTableBodyCellProps={({ cell }) => ({
              onBlur: (event: any) => {
                if (event.target.value) {
                  handleSaveCell(cell, event.target.value)
                }
              }
            })}
            muiTableBodyCellEditTextFieldProps={({ cell }) => ({
              onBlur: (event) => {
                handleSaveCell(cell, event.target.value)
              }
            })}
            renderRowActions={({ row }) => {
              return (
                <TableAction
                  row={row.index}
                  setRowIndex={setRowIndex}
                  onUpload={(e: ChangeEvent<HTMLInputElement>) => onUpload(e)}
                  onDelete={() => onDeleteImage(row)}
                  onAdd={() => onAddRow(row)}
                />
              )
            }}
          />
        </Stack>
      </form>
    </>
  )
}
