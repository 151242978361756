import { getCookieByName } from '@core/helpers/cookie.helper'

/**
 * Set XSRF token when Node ENV is development
 * It will disable when NODE ENV is production
 * Set remote XSRF token using for paring client and server is different domain
 * @param isDev
 * @param response
 */
const setXSRFTokenWhenDevelopment = (isDev: boolean, response: any) => {
  if (isDev) {
    const { headers } = response
    if (headers && headers.xsrf) {
      // set remote cookie when client is different domain with backend
      document.cookie = headers.xsrf
    }
  }
}

/**
 * Get XSRF token from cookie when NODE ENV is development
 * @param isDev
 */
const getXSRFToken = (isDev: boolean) => {
  if (isDev) {
    return getCookieByName('refreshToken')
  }
}

export { setXSRFTokenWhenDevelopment, getXSRFToken }
