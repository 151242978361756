import { Chip, Tooltip, Typography } from '@mui/joy'
import {
  AddOutlined,
  RemoveOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  SaveOutlined,
  SearchOutlined
} from '@mui/icons-material'
import { MRT_ColumnDef } from 'material-react-table'
import { ActionProps } from '@pages/filter/type'
import { IconButton } from '@mui/material'
import { InspectPlanProps } from '@pages/inspect/type'
import { Stack } from '@core/components/menu/styles'
import { MuiJoyDateRangePicker } from '@core/components/date-range'
import { RepairPlanSearchFormField } from '@pages/repair/type'
import dayjs from 'dayjs'
import { DateFormat } from '@core/enums/date.enum'

export const Actions = ({
  onSearch,
  onUpload,
  onExport,
  onSave,
  onCreate,
  onDelete,
  disabled,
  custom
}: ActionProps) => {
  return (
    <>
      <Tooltip title="조회">
        <IconButton color="primary" onClick={onSearch}>
          <SearchOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="추가">
        <IconButton color="primary" onClick={onCreate}>
          <AddOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="삭제">
        <IconButton color="primary" onClick={onDelete} disabled={disabled}>
          <RemoveOutlined />
        </IconButton>
      </Tooltip>

      <Tooltip title="다운로드">
        <IconButton color="primary" onClick={onExport}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
      {custom ? (
        custom
      ) : (
        <Tooltip title="업로드">
          <IconButton color="primary" aria-label="upload icon" component="label">
            <input id="file-upload" type="file" hidden onChange={onUpload} />
            <FileUploadOutlined />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip title="저장" onClick={onSave}>
        <IconButton color="primary">
          <SaveOutlined />
        </IconButton>
      </Tooltip>
    </>
  )
}

interface DateRangeProps {
  title?: string
  disabled?: boolean
  setValue?: any
  isFilter?: boolean
}

export const CustomDateRange = ({ title = '날짜', disabled, setValue, isFilter }: DateRangeProps) => {
  return (
    <Stack>
      <Typography level="body1" fontWeight={700}>
        {title}
      </Typography>
      <MuiJoyDateRangePicker
        isFilter={isFilter}
        inputProps={{ disabled }}
        dateRange={{
          startDate: new Date(),
          endDate: new Date(),
          key: isFilter ? 'filterSelection' : 'selection'
        }}
        stateForm={{
          setValueFn: setValue,
          startDateFieldName: isFilter ? RepairPlanSearchFormField.REPLACE_START_DATE : RepairPlanSearchFormField.START_DATE,
          endDateFieldName: isFilter ? RepairPlanSearchFormField.REPLACE_END_DATE : RepairPlanSearchFormField.END_DATE
        }}
        onDateRangeChange={(dateRange) => {
          setValue(
            isFilter ? RepairPlanSearchFormField.REPLACE_START_DATE : RepairPlanSearchFormField.START_DATE,
            dayjs(isFilter ? dateRange.replaceStartDate : dateRange?.startDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
          )
          setValue(
            isFilter ? RepairPlanSearchFormField.REPLACE_END_DATE : RepairPlanSearchFormField.END_DATE,
            dayjs(isFilter ? dateRange.replaceEndDate : dateRange?.endDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
          )
        }}
      />
    </Stack>
  )
}

export const ColumnsHeader: MRT_ColumnDef<InspectPlanProps>[] = [
  {
    header: '회사명',
    accessorKey: 'company',
    enableEditing: false
  },
  {
    header: '공장명',
    accessorKey: 'factory',
    enableEditing: false
  },
  {
    header: '관리번호',
    accessorKey: 'code',
    enableEditing: false
  },
  {
    header: '설비명',
    accessorKey: 'name',
    minSize: 300,
    enableEditing: false
  },
  {
    header: '점검일',
    accessorKey: 'checkDate',
    enableEditing: false
  },
  {
    header: '점검방법',
    accessorKey: 'checkType',
    enableEditing: false,
  },
  {
    header: '점검자',
    accessorKey: 'checker'
  },
  {
    header: '확인자',
    accessorKey: 'validater'
  },
  {
    header: '특이사항',
    accessorKey: 'totalNote'
  }
]

export const TableColumns: {
  [key: string]: string
} = {
  company: '회사명',
  factory: '공장명',
  code: '관리번호',
  name: '설비명',
  checkType: '점검방법',
  checkDate: '점검일자',
  checker: '점검자',
  validater: '확인자',
  totalNote: '특이사항'
}
