export const initImgData = [
  {
    viewName: '필터백 교체 전',
    type: 'preRepairImage',
    data: null,
    fileName: ''
  },
  {
    viewName: '필터백 교체 후',
    type: 'postRepairImage',
    data: null,
    fileName: ''
  },
  {
    viewName: '(교체 전) 차압계',
    type: 'prePressureImage',
    data: null,
    fileName: ''
  },
  {
    viewName: '(교체 후) 차압계',
    type: 'postPressureImage',
    data: null,
    fileName: ''
  },
  // {
  //   viewName: '필터백 작업 중 1',
  //   type: 'WORKING_1',
  //   data: null,
  //   fileName: ''
  // },
  // {
  //   viewName: '필터백 작업 중 2',
  //   type: 'WORKING_2',
  //   data: null,
  //   fileName: ''
  // },
  // {
  //   viewName: '필터백 작업 중 3',
  //   type: 'WORKING_3',
  //   data: null,
  //   fileName: ''
  // },
  // {
  //   viewName: '필터백 작업 중 4',
  //   type: 'WORKING_4',
  //   data: null,
  //   fileName: ''
  // }
]