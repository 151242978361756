import { CardUnitEnum } from '@core/enums/card.enum'
import { Input, InputNumber, Th } from '@pages/equipment/equipment-detail/styles'
import { UseFormRegister } from 'react-hook-form'

interface EtcStandardProps {
  register: UseFormRegister<any>
  contentKey?: string
  standrdKey: string
  countKey: string
  noteKey: string
  unit?: string
}

export const EtcStandard = ({
  register,
  contentKey,
  standrdKey,
  countKey,
  noteKey,
  unit = CardUnitEnum.EA
}: EtcStandardProps) => {
  return (
    <>
      {contentKey && (
        <Th>
          <Input {...register(contentKey)} />
        </Th>
      )}

      <Th>규&nbsp;&nbsp;&nbsp;&nbsp;격</Th>
      <td colSpan={3}>
        <Input {...register(standrdKey)} />
      </td>
      <Th>수&nbsp;&nbsp;&nbsp;&nbsp;량</Th>
      <td>
        <InputNumber endAdornment={<span>{unit}</span>} {...register(countKey)} />
      </td>
      <td>
        <Input {...register(noteKey)} />
      </td>
    </>
  )
}
