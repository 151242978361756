import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import CheckboxTree from 'react-checkbox-tree'
import {
  ExpandMoreOutlined,
  ExpandLessOutlined,
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
  FactoryOutlined,
  PrecisionManufacturingOutlined,
  ArrowForwardIosOutlined,
  ArrowBackIosNewOutlined
} from '@mui/icons-material'
import './styles.scss'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/joy'
import { ItemsProps, TransferProps } from './type'
import { useSetRecoilState } from 'recoil'
import { editState } from '@pages/recoil'
import { Card, CardHeader, Checkbox } from '@mui/material'

export const Transfer = ({
  data,
  setData,
  secondTitle = '등록목록',
  enableTotal = true
}: TransferProps) => {
  const setEdit = useSetRecoilState(editState)
  const [left, setLeft] = useState<ItemsProps[]>([])
  const [right, setRight] = useState<ItemsProps[]>([
    // { value: '0_3', label: '0공장_설비3' },
    // { value: '0_4', label: '0공장_설비4' }
  ])
  const [expaned, setExpaned] = useState<string[]>([])
  const [leftChecked, setLeftChecked] = useState<string[]>([])
  const [rightChecked, setRightChecked] = useState<string[]>([])

  const handleCheckedRight = () => {
    // 현재선택된애를 오른쪽으로 보내고
    const checkedItem: ItemsProps[] = []

    const newArray = left.map((item) => {
      const children = item.children?.filter((childItem) => {
        if (!leftChecked.includes(childItem.value)) {
          return true
        } else {
          checkedItem.push({
            ...childItem,
            value: `${childItem.value}`,
            label: `${item.label}_${childItem.label}`
          })
          return false
        }
      })

      return {
        value: item.value,
        label: item.label,
        children,
        disabled: children?.length === 0
      }
    })

    setLeft(newArray)
    const rightData = [...right, ...checkedItem]
    setRight(rightData)
    setData(rightData ?? [])
    setLeftChecked([])
    setRightChecked([])
    setEdit(true)
  }

  const handleCheckedLeft = () => {
    // 선택된 오른쪽 목록에서 부모 별로 자식 정렬
    const tempObj: { [keys: string]: ItemsProps[] } = {}

    rightChecked.forEach((item) => {
      const array = item.split('_')
      const parent = array[0]

      // 아이템 정보를 가져오고
      const childInfo = right.filter((subItem) => item === subItem.value)[0]
      if (!tempObj[parent]) {
        tempObj[parent] = [
          {
            ...childInfo,
            label: childInfo.label?.toString().split('_')[1]
          }
        ]
      } else {
        tempObj[parent].push({
          ...childInfo,
          label: childInfo.label?.toString().split('_')[1]
        })
      }
    })

    //왼쪽트리와 오른쪽 트리를 합친다..
    const newArray = left.map((item) => {
      const children = [...(item.children ?? []), ...(tempObj[item.value] ?? [])]
      return {
        ...item,
        children,
        disabled: children.length === 0
      }
    })

    // 오른쪽에서 옮겨간 것을 빼준다.
    const newRight = right.filter((item) => !rightChecked.includes(item.value))

    setLeft(newArray)
    setRight(newRight)
    setData(newRight ?? [])
    setLeftChecked([])
    setRightChecked([])
    setEdit(true)
  }

  useEffect(() => {
    const expanedList = data.map((item) => item.value)
    setLeft(data)
    setRight([])
    setExpaned(expanedList)
    setLeftChecked([])
    setRightChecked([])
  }, [data])

  return (
    <Grid container spacing={5} justifyContent="center" alignItems="center">
      <Grid item>
        <Stack spacing={1}>
          <Typography level="h6">설비목록</Typography>
          {CustomList(left, leftChecked, setLeftChecked, expaned, setExpaned, true, enableTotal)}
        </Stack>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center" rowGap={2}>
          <IconButton
            variant="solid"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right">
            <ArrowForwardIosOutlined />
          </IconButton>
          <IconButton
            variant="solid"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left">
            <ArrowBackIosNewOutlined />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item>
        <Stack spacing={1}>
          <Typography level="h6">{secondTitle}</Typography>
          {CustomList(
            right,
            rightChecked,
            setRightChecked,
            expaned,
            setExpaned,
            false,
            enableTotal
          )}
        </Stack>
      </Grid>
    </Grid>
  )
}

const CustomList = (
  items: ItemsProps[],
  checked: string[],
  setChecked: React.Dispatch<React.SetStateAction<string[]>>,
  expaned: string[],
  setExpaned: React.Dispatch<React.SetStateAction<string[]>>,
  isLeft?: boolean,
  enableTotal?: boolean
) => {
  const [toggle, setToggle] = useState(false)
  const [count, setCount] = useState(0)

  const onCheckedAll = () => {
    if (toggle) {
      setChecked([])
    } else {
      const list: string[] = []
      if (isLeft) {
        items.forEach((main) => main.children?.forEach((sub) => list.push(sub.value)))
      } else {
        items.forEach((main) => list.push(main.value))
      }
      setChecked(list)
    }
    setToggle((prev) => !prev)
  }

  useEffect(() => {
    setToggle(false)
    if (isLeft) {
      let equipCount = 0
      items.forEach((main) =>
        main.children ? (equipCount += main.children.length) : (equipCount += 0)
      )
      setCount(equipCount)
    } else {
      setCount(items.length)
    }
  }, [items])

  return (
    <Card sx={{ width: '25vw', height: '60vh', overflow: 'auto' }}>
      {items.length === 0 ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'100%'}>
          <Typography level="body1">데이터가 존재하지 않습니다.</Typography>
        </Box>
      ) : (
        <>
          {enableTotal && (
            <>
              <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                  <Checkbox
                    onClick={onCheckedAll}
                    disabled={count === 0}
                    checked={checked.length === items.length && items.length !== 0}
                    indeterminate={checked.length !== items.length && checked.length !== 0}
                    inputProps={{
                      'aria-label': 'all items selected'
                    }}
                  />
                }
                title={'전체 선택'}
                disabled={items.length === 0}
                subheader={`총 ${count}대 설비 중 ${checked.length}대 선택`}
              />
              <Divider />
            </>
          )}

          <CheckboxTree
            nodes={items}
            checked={checked}
            expanded={expaned}
            onCheck={(checked) => setChecked(checked)}
            onExpand={(expaned) => setExpaned(expaned)}
            icons={{
              check: <CheckBoxOutlined />,
              uncheck: <CheckBoxOutlineBlankOutlined />,
              halfCheck: <CheckBoxOutlined />,
              expandClose: <ExpandMoreOutlined />,
              expandOpen: <ExpandLessOutlined />,
              expandAll: <span>6</span>,
              collapseAll: <span>7</span>,
              parentOpen: <FactoryOutlined />,
              parentClose: <FactoryOutlined />,
              leaf: <PrecisionManufacturingOutlined />
            }}
          />
        </>
      )}
    </Card>
  )
}
