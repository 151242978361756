import { Input } from '@mui/joy'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { CustomCalender } from './styles'
import { ko } from 'date-fns/locale'
import { DatePickerProps } from './type'
import { ChildProps } from '../menu'
import { Popover } from '@mui/material'
import dayjs from 'dayjs'
import { DateFormat } from '@core/enums/date.enum'

export const DatePicker = forwardRef<ChildProps, DatePickerProps>(
  (
    { defaultValue, value, name, setValue, InputProps, title = '날짜', setReactHookFormValue },
    ref
  ) => {
    const [date, setDate] = useState<Date | undefined>(
      defaultValue === undefined ? undefined : value ?? new Date()
    )
    const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'date-picker-popover' : undefined

    const onChange = (date: Date) => {
      if (date) {
        setDate(date)
        const formateDate = dayjs(date).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
        if (name && setValue) {
          setValue((prev) => ({ ...prev, [name]: formateDate }))
        } else if (name && setReactHookFormValue) {
          setReactHookFormValue && setReactHookFormValue(name, formateDate)
        }
      }
    }

    const getValue = () => date?.toLocaleDateString()

    useImperativeHandle(ref, () => ({
      getValue
    }))

    useEffect(() => {
      if (date) {
        setDate(date)
        const formateDate = dayjs(date).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
        if (name && setValue) {
          setValue((prev) => ({ ...prev, [name]: formateDate }))
        } else if (name && setReactHookFormValue) {
          setReactHookFormValue && setReactHookFormValue(name, formateDate)
        }
        handleClose()
      }
    }, [date])

    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    return (
      <>
        <Input
          readOnly
          value={date ? dayjs(date).format(DateFormat.YYYY_MM_DD_DATE_PICKER) : ''}
          id={id}
          placeholder={`${title} 선택해주세요.`}
          onClick={handleClick}
          {...InputProps}
        />
        <Popover
          sx={{ zIndex: 9999 }}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}>
          <CustomCalender date={date} locale={ko} onChange={onChange} />
        </Popover>
      </>
    )
  }
)
