export enum String {
  EMPTY_STRING = '',
  PICTURE = 'images.picture',
  DIAGRAM = 'images.diagram'
}

const GENERIC_VALUE_TYPE: any = null

export enum PlanMethodEnum {
  RESULT = 'result',
  REPAIRPLAN = 'repairPlan',
  ABNORMALITY = 'abnormality',
  NOTE = 'note',
  VISUALREPORT = 'visualReport',
  NONE = '해당없음',
  NORMAL = '양호',
  BAD = '불량',
  ADD = '추가',
  CHECKLIST = 'checkList',
  CHECKLISTDVA = 'checkListDVA',
  ACTIONPLANDATE = 'actionPlanDate',
  MANAGER = 'manager',
}

export { GENERIC_VALUE_TYPE }

export enum TargetEnum {
  COMMON = 'common',
  CUSTOM = 'custom',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect'
}

export enum StatusEnum {
  SUCCESS = 'sucess',
  FAIL = 'fail',
  IDLE = 'idle'
}
