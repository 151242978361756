import i18next from '@core/configs/i18n.config'
import { TranslateEnum, TranslateLanguageEnum } from '@core/enums/translate.enum'
import { LocalizationDateModel } from '@core/models/localization-date.model'
import { Builder } from '@core/helpers/builder.helper'
import { enUS, koKR } from '@mui/x-date-pickers'

/**
 * Translate text by key and name space
 * @param key
 * @param nameSpace
 */
const translateByNameSpace = (key: string, nameSpace: string | TranslateEnum) => {
  return i18next.t(key, { ns: nameSpace })
}

/**
 * Change language
 * @param language
 */
const changeLanguage = async (language: TranslateLanguageEnum) => {
  await i18next.changeLanguage(language)
}

/**
 * Build adapter localization confirm from language
 * If you want created custom language please add to switch fn
 * @param language
 */
const getaApterLocale = (language: TranslateLanguageEnum | string) => {
  let resultLocalizationConfig: LocalizationDateModel = Builder<LocalizationDateModel>()
    .lang(TranslateLanguageEnum.KOREA_DATE_LOCALIZATION)
    .localTranslate(koKR)
    .build()
  switch (language) {
    case TranslateLanguageEnum.ENGLISH:
      resultLocalizationConfig = {
        lang: language,
        localTranslate: enUS
      }
      break
    case TranslateLanguageEnum.KOREA:
      resultLocalizationConfig = {
        lang: TranslateLanguageEnum.KOREA_DATE_LOCALIZATION,
        localTranslate: koKR
      }
      break
  }

  return resultLocalizationConfig
}

export { translateByNameSpace, changeLanguage, getaApterLocale }
