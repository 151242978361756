import { TranslateEnum } from '@core/enums/translate.enum'
import { Card, CircularProgress, Stack, Typography } from '@mui/joy'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterDataProps } from '../type'

export const FilterBagStatus = ({ data }: { data: FilterDataProps[] }) => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  return (
    <Card sx={{ justifyContent: 'space-around' }}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography level="h5">{t('dashboard.today_filter_status')}</Typography>
        <CircularProgress
          determinate
          value={100}
          color={'success'}
          sx={{ '--CircularProgress-size': '80px' }}>
          {data ? data.length : 0}
        </CircularProgress>
        <Typography level="body1">{data && data.length === 0 ? '교체완료' : '교체중'}</Typography>
      </Stack>
    </Card>
  )
}
