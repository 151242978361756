import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CheckTypeProps, EquipmentCreateModalProps, ReCheckType, SelectOptionType } from '../type'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Stack,
  Typography
} from '@mui/joy'
import { Fade } from '@mui/material'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { companyListSelector, factoryListState } from '../recoil'
import { companyInfoState } from '@core/components/menu/recoil'
import { DatePicker } from '@core/components/date-picker'
import { useQuery } from '@tanstack/react-query'
import { getCheckType } from './query'
import { defaultValues } from './template'
import { editState } from '@pages/recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import { CommonProps } from '../equipment-detail/card/type'
import { PlaceHolderEnum } from '@core/enums/place.holder.enum'

export const CreateEquipment = ({ open, onSubmit, onClose }: EquipmentCreateModalProps) => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const companyInfo = useRecoilValue(companyInfoState)
  const companyList = useRecoilValue(companyListSelector)
  const [factoryObject, setFactoryObject] = useRecoilState(factoryListState)
  const companyId = authorizationModel.isAdmin ? null : authorizationModel.companyId.toString()
  const [checkType, setCheckType] = useState<ReCheckType[]>([])
  const setEdit = useSetRecoilState(editState)
  const [companyName, setCompanyName] = useState<string | null>(null)
  const [pickedCompanyName, setPickedCompanyName] = useState();
  const [pickedFactoryName, setPickedFactoryName] = useState<any>();
  const [chamberCount, setChamberCount] = useState<any>();

  // const { refetch } = useQuery(['checktype'], getCheckType, {
  //   enabled: false,
  //   onSuccess: (data: CheckTypeProps[]) => {
  //     setCheckType(data)
  //     reset()
  //   }
  // })
  console.log(factoryObject, pickedCompanyName, pickedFactoryName);

  useEffect(() => {
    if (pickedFactoryName) {
      setChamberCount(factoryObject[pickedCompanyName!].find(el => el.name === pickedFactoryName)?.maxChamberCount);
    }
  }, [pickedFactoryName]);
  const { refetch } = useQuery(['checktype-r'], getCheckType, {
    onSuccess: (data: ReCheckType[]) => {
      // company별 필터링
      const filteredData = data.filter(el => el.companyName === pickedCompanyName);
      setCheckType(filteredData);
    }
  })

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { isDirty, errors }
  } = useForm<CommonProps>({
    defaultValues
  })

  const company = watch().header.company

  const onChangeCompany = async (e: any, value: any) => {
    // 점검방법도 추가
    if (value === null) {
      setValue('header.company', companyName, { shouldDirty: true })
      setValue('header.factory', null, { shouldDirty: true })
    } else {
      await setPickedCompanyName(value);
      await refetch();
      setValue('header.company', value, { shouldDirty: true })
      setValue('header.factory', null, { shouldDirty: true })
    }
  }

  /**
   *
   * @param e MouseEvent
   * @param value string | null
   * @param key string
   */
  const onChange = (e: any, value: any, key: any) => {
    setValue(key, value, { shouldDirty: true })
  }

  const getFactoryList = () => {
    let optionNode
    if (company) {
      const list = factoryObject[company]
      if (list && list.length > 0) {
        optionNode = factoryObject[company].map((item) => (
          <Option key={item.name} value={item.name}>
            {item.name}
          </Option>
        ))
      } else {
        optionNode = <Option disabled>공장이 존재하지 않습니다.</Option>
      }
    } else {
      optionNode = <Option disabled>회사를 먼저 선택해주세요.</Option>
    }
    return optionNode
  }

  useEffect(() => {
    reset()
  }, [open])

  useEffect(() => {
    setEdit(isDirty)
  }, [isDirty])

  useEffect(() => {
    refetch()
    const tempObj: { [key: string]: SelectOptionType[] } = {}
    let companyName = null

    companyInfo.forEach((company) => {
      if (company.companyId === Number(companyId)) {
        companyName = company.companyName
        setCompanyName(companyName)
      }
      if (company.factories) {
        const array = company.factories.map((item) => ({
          id: item.factoryId,
          name: item.factoryName,
          maxChamberCount: item.maxChamberCount
        }))
        tempObj[company.companyName] = array
      }
    })
    setFactoryObject(tempObj)
    setValue('header.company', companyName)
    setValue('header.factory', null)
    setEdit(false)
  }, [])

  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <ModalDialog variant="outlined" sx={{ width: '40vw' }}>
          <Stack spacing={1}>
            <Typography id="create-modal-dialog-title" level="h6" fontWeight={600}>
              설비 등록
            </Typography>
            <ModalClose variant="plain" />
            <Typography id="create-modal-dialog-description" textColor="text.tertiary">
              등록할 설비 정보를 입력해주세요.
            </Typography>

            <Stack component="form" spacing={2} onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid xs={6}>
                  <Stack spacing={1}>
                    <FormControl>
                      <FormLabel>회사명</FormLabel>
                      <Controller
                        name="header.company"
                        control={control}
                        rules={{
                          required: true
                        }}
                        render={() => (
                          <Select
                            placeholder="회사를 선택해주세요."
                            onChange={onChangeCompany}
                            color={errors.header?.company && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {companyList.length > 0 ? (
                              companyList.map((item) => (
                                <Option key={item.name} value={item.name}>
                                  {item.name}
                                </Option>
                              ))
                            ) : (
                              <Option disabled>{PlaceHolderEnum.COMPENY}</Option>
                            )}
                          </Select>
                        )}
                      />
                      {errors.header?.company && (
                        <FormHelperText sx={{ color: 'red' }}>
                          {PlaceHolderEnum.COMPENY}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>공장명</FormLabel>
                      <Controller
                        name="header.factory"
                        control={control}
                        rules={{
                          required: true
                        }}
                        render={({ field: { name } }) => (
                          <Select
                            placeholder={PlaceHolderEnum.FACTORY}
                            onChange={(e, value) => {
                              onChange(e, value, name);
                              setPickedFactoryName(value);
                            }}
                            color={errors.header?.factory && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {getFactoryList()}
                          </Select>
                        )}
                      />
                      {errors.header?.factory && (
                        <FormHelperText sx={{ color: 'red' }}>
                          {PlaceHolderEnum.FACTORY}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>관리번호</FormLabel>
                      <Input
                        {...register('header.code', {
                          required: true
                        })}
                        placeholder={PlaceHolderEnum.CODE}
                        color={errors.header?.code && 'danger'}
                      />
                      {errors.header?.code && (
                        <FormHelperText sx={{ color: 'red' }}>
                          {PlaceHolderEnum.CODE}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>설비명</FormLabel>
                      <Input
                        {...register('header.name', {
                          required: true
                        })}
                        placeholder={PlaceHolderEnum.NAME}
                        color={errors.header?.name && 'danger'}
                      />
                      {errors.header?.name && (
                        <FormHelperText sx={{ color: 'red' }}>
                          {PlaceHolderEnum.NAME}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel>설비번호</FormLabel>
                      <Input
                        {...register('header.equipmentCode')}
                        placeholder={PlaceHolderEnum.EQUIPMENTCODE}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>형식</FormLabel>
                      <Input {...register('header.type')} placeholder={PlaceHolderEnum.TYPE} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>집진용량</FormLabel>
                      <Input
                        type="number"
                        {...register('header.size')}
                        placeholder={PlaceHolderEnum.SIZE}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>챔버 수 설정</FormLabel>
                      <Input
                        type="number"
                        slotProps={{
                          input: {
                            min: 1,
                            max: chamberCount || 10
                          }
                        }}
                        {...register('header.maxChamberCount')}
                        placeholder={`${PlaceHolderEnum.CHAMBER_MAX_NUM} ${chamberCount ? `(MAX: ${chamberCount})` : ''}`}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid xs={6}>
                  <Stack spacing={1}>
                    <FormControl>
                      <FormLabel>설치년월</FormLabel>
                      <Controller
                        name="header.establishmentDate"
                        control={control}
                        render={({ field: { value, name } }) => (
                          <DatePicker
                            value={value === '' ? undefined : new Date(value)}
                            name={name}
                            setReactHookFormValue={setValue}
                          />
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>제조사</FormLabel>
                      <Input
                        {...register('header.manufacturer')}
                        placeholder={PlaceHolderEnum.MANUFACTURER}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>용도</FormLabel>
                      <Input {...register('header.usage')} placeholder={PlaceHolderEnum.USAGE} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>인.허가</FormLabel>
                      <Input
                        {...register('header.permission')}
                        placeholder={PlaceHolderEnum.PERMISSION}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>발생분진</FormLabel>
                      <Input
                        {...register('header.generatedDust')}
                        placeholder={PlaceHolderEnum.GENERATEDDUST}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>특기사항</FormLabel>
                      <Input {...register('header.note')} placeholder={PlaceHolderEnum.NOTE} />
                    </FormControl>
                    <FormControl>
                      <FormLabel>점검방법</FormLabel>
                      <Controller
                        name="checkMethodName"
                        control={control}
                        render={({ field: { name, value } }) => (
                          <Select
                            placeholder="점검방법을 선택해주세요."
                            onChange={(e, value) => onChange(e, value, name)}
                            value={value}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {checkType.length > 0 ? (
                              checkType.map((item) => (
                                <Option key={item.checkMethodId} value={item.checkMethodName}>
                                  {item.checkMethodName}
                                </Option>
                              ))
                            ) : (
                              <Option disabled>점검방법이 존재하지 않습니다.</Option>
                            )}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>지정 교체주기</FormLabel>
                      <Input
                        type="number"
                        {...register('header.filterBagReplacementPeriodMonth')}
                        placeholder={PlaceHolderEnum.CHANGE_PERIOD_MONTH}
                      />
                    </FormControl>
                  </Stack>
                </Grid>
              </Grid>
              <Button type="submit">등록</Button>
            </Stack>
          </Stack>
        </ModalDialog>
      </Fade>
    </Modal>
  )
}
