import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Card, CircularProgress, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { Builder } from '@core/helpers/builder.helper'
import { GENERIC_VALUE_TYPE, String } from '@core/enums/common.enum'
import {
  BaseRepairPlanModel,
  RepairPlanModel,
  RepairPlanSearchForm,
  RepairPlanSearchFormField,
  RepairPlanSearchFormInDto
} from '@pages/repair/type'
import { BaseTable } from '@core/components/table'
import { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table'
import {
  checkDuplicateWhenImportFileExcel,
  convertDataToInDtoRepairPlan,
  exportRepairPlanCustomForm,
  exportRepairPlanData,
  repairPlanActionRowTemplate,
  repairPlanManagementColumnConfig
} from '@pages/repair/template'
import { exportTableDataToCustomFormCsv, flatColumns } from '@core/helpers/table.helper'
import { ColumnType, ExcelJSColumns } from '@core/models/exceljs.model'
import { useRecoilState, useRecoilValue } from 'recoil'
import { listRepairPlanDataSelector, listRepairPlanState } from '@pages/repair/recoil'
import {
  postExcelDownload,
  postExcelUpload,
  useDeleteRepairPlan,
  useFilterListRepairPlan,
  useGetListRepairPlan,
  useSubmitRepairPlan
} from '@pages/repair/query'
import { confirm, deleteConfirm, fail, savedCheckModal, success } from '@core/components/modal'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'
import PlanCreateModal from '@pages/repair/plan-list/plan-create-modal'
import { useAppQuery } from '@core/helpers/query.helper'
import { QueryKey } from '@core/enums/query.enum'
import { getCompanyInfo } from '@core/components/menu/query'
import { CompanyListProps, FactoryListProps } from '@core/components/menu/type'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import PlanSearchForm from '@pages/repair/plan-list/plan-search-form'
import { columnIdExport } from '@core/data/column.data'
import { Backdrop, IconButton } from '@mui/material'
import { FileDownloadOutlined } from '@mui/icons-material'
import PlanCustomCreateModal from './plan-custom-create-modal'
import { cvtDate } from '@pages/service/miscFunc'

let unsaved = false
/**
 * Repair plan management
 * @constructor
 */
const RepairPlanManagement: FC = () => {
  // Common variable
  const { t } = useTranslation([TranslateEnum.COMMON, TranslateEnum.PLAN])
  const { handleSubmit, setValue, control, getValues } = useForm<RepairPlanSearchForm>({
    defaultValues: {
      companyId: undefined,
      equipmentId: undefined,
      factoryId: undefined,
      startDate: new Date(),
      endDate: new Date(),
      isCompleteDate: false,
      repairStatus: 'ALL'
    }
  })

  // Recoil state
  const [listRepairPlanData, setListRepairPlan] = useRecoilState(listRepairPlanDataSelector)
  const [repairPlanState, setRepairPlanState] = useRecoilState(listRepairPlanState)
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const { listCompany } = repairPlanState
  // Component state
  const [rowSelection, setRowSelection] = useState({})
  const [selectedCompany, setSelectedCompany] = useState<CompanyListProps>()
  const [selectedFactory, setSelectedFactory] = useState<FactoryListProps>()
  const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [rowEdited, setRowEdit] = useState<MRT_Row<RepairPlanModel | any>>()
  const [rowAddInformation, setRowAddInformation] = useState<MRT_Row<RepairPlanModel | any> | any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const [downloading, setDownloading] = useState(false)
  const [customCreateModal, setCustomCreateModal] = useState(false)
  const [isCompleteDate, setCompleteDate] = useState(false)

  // Memorized state
  const { isAdmin = false } = useMemo(() => authorizationModel, [authorizationModel])
  const tableInstanceRef = useRef<MRT_TableInstance<RepairPlanModel | any>>(null)
  const columns = useMemo<MRT_ColumnDef<any>[]>(() => repairPlanManagementColumnConfig(t), [t])
  // React query hook
  const { mutate, isLoading: filterListDataLoading } = useFilterListRepairPlan(t)
  const { mutate: mutateSubmitRepairPlan, isLoading: submitDataLoading } = useSubmitRepairPlan()
  const { mutate: mutateDeleteRepairPlan } = useDeleteRepairPlan()

  const { isLoading: getListDataLoading, refetch } = useGetListRepairPlan(
    {
      enabled: false,
      onSuccess: async (data: RepairPlanModel[]) => {
        setListRepairPlan(data)
      }
    },
    t
  )

  const { refetch: fetchCompany, isLoading: isFetchCompany } = useAppQuery(
    [QueryKey.COMPANYINFO],
    () => getCompanyInfo(authorizationModel),
    {
      enabled: false,
      onSuccess: (data: CompanyListProps[]) => {
        setRepairPlanState({
          ...repairPlanState,
          listCompany: data
        })
        if (!isAdmin) {
          setSelectedCompany(data && data[0])
        }
      }
    }
  )

  /**
   * Handle delete callback
   */
  const handleDeleteCallback = useCallback(
    (callback: any) => {
      deleteConfirm({
        message: (
          <div>
            <div>{t('plan.modal-delete.message1', { ns: TranslateEnum.PLAN })}</div>
            <div>{t('plan.modal-delete.message2', { ns: TranslateEnum.PLAN })}</div>
            <Typography fontWeight={500}>
              {t('plan.modal-delete.message3', { ns: TranslateEnum.PLAN })}
            </Typography>
          </div>
        ),
        onOk: () => {
          callback()
        }
      })
    },
    [tableInstanceRef?.current]
  )

  /**
   * Handle import Excel file with template define
   * @param event
   */
  const handleUploadRepairPlan = async (event: ChangeEvent<HTMLInputElement>) => {
    // postExcelUpload();

    if (event.target && event.target.files) {
      const { files } = event.target
      if (files && files[0]) {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('file', files[0]);
        const fileData = await postExcelUpload(formData);
        console.log(fileData);
        unsaved = true
        await checkDuplicateWhenImportFileExcel(
          listRepairPlanData,
          fileData,
          setIsLoading,
          setListRepairPlan,
          unsaved,
          t
        )
      }
    }
    event.target.value = String.EMPTY_STRING
  }

  /**
   * Handle submit search form
   * @param inDto
   */
  const handleSubmitSearchRepairPlan = (inDto: RepairPlanSearchFormInDto | any) => {
    mutate(inDto, {
      onSuccess: (data) => {
        setListRepairPlan(data)
      }
    })
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  // This function handles the export of data to a CSV file.
  // It takes the data from the fakeDataRepairPlanManagement variable and uses the csvExporter to generate the CSV file.
  const handleClickExcelDown = async (formType?: string) => {
    console.log(getValues());
    const { startDate, endDate } = getValues();
    const prms: any = {
      ...getValues(),
      startDate: cvtDate(startDate),
      endDate: cvtDate(endDate),
    };

    if (formType) prms.formType = formType;

    await postExcelDownload(prms);
    handleMenuClose()
  }

  /**
   * Handle click save
   */
  const handleSaveChange = () => {
    confirm({
      onOk: async () => {
        console.log(listRepairPlanData)
        const inDto: BaseRepairPlanModel[] = await convertDataToInDtoRepairPlan(listRepairPlanData)
        mutateSubmitRepairPlan(inDto, {
          onSuccess: () => {
            success()
            mutate(getValues(), {
              onSuccess: (data) => {
                setListRepairPlan(data)
                unsaved = false
              }
            })
          },
          onError: () => fail()
        })
      }
    })
  }

  /**
   * Mutate delete row in table
   * @param data
   */
  const mutateDeleteData = (data: number[]) => {
    mutateDeleteRepairPlan(data, {
      onSuccess: async () => {
        success({
          message: t('plan.message.delete-complete', { ns: TranslateEnum.PLAN })
        })
        mutate(getValues(), {
          onSuccess: (data) => {
            setListRepairPlan(data)
          }
        })
      },
      onError: () => fail({ message: t('plan.message.fail', { ns: TranslateEnum.PLAN }) })
    })
  }

  //This function is used to delete row from the table which is stored in the currentTableInstance,
  //and also filter the listRepairPlanData and createList array by comparing the selected row.
  //Then the filtered array is stored in listRepairPlan and selectArray.
  const handleDeleteRow = () => {
    if (unsaved) {
      savedCheckModal()
    } else {
      const currentTableInstance = tableInstanceRef?.current
      if (currentTableInstance) {
        handleDeleteCallback(() => {
          const rowSelectOnTable = currentTableInstance.getSelectedRowModel()
          if (rowSelectOnTable && rowSelectOnTable.rows && rowSelectOnTable.rows.length > 0) {
            const rowSelection = rowSelectOnTable?.rows.map((data) => data.original)
            const rowReadyExits = rowSelection.filter((el) => el.id).map((el) => el.id)
            const rowAdd = rowSelection.filter((el) => !el.id)
            if (rowAdd && rowAdd.length > 0) {
              const listRepairPlanFilter = listRepairPlanData.filter(
                (item: RepairPlanModel) => !rowAdd.some((row) => item === row)
              )
              setListRepairPlan(listRepairPlanFilter)
            }
            mutateDeleteData(rowReadyExits)
            setRowSelection({})
          }
        })
      }
    }
  }

  /**
   * Handle delete single row on row action menu item
   * @param row
   */
  const handleDeleteSingleRow = (row: MRT_Row<RepairPlanModel | any>) => {
    if (unsaved) {
      savedCheckModal()
    } else {
      handleDeleteCallback(() => {
        const { original, index: rowIndex } = row
        if (original) {
          const rowOnTable = tableInstanceRef?.current?.getRowModel().rows
          if (rowOnTable && rowOnTable.length > 0) {
            const listRowData: RepairPlanModel[] = rowOnTable.map((data) => data.original)
            if (original && original?.id) {
              mutateDeleteData([original?.id])
            } else {
              const listRepairPlanFilter = listRowData.filter(
                (item: RepairPlanModel, index: number) => item && index !== rowIndex
              )
              setListRepairPlan(listRepairPlanFilter)
            }
          }
        }
      })
    }
  }

  /**
   * Handle add new repair plan row in table
   */
  const handleAddNewRepairPlanRow = (row: MRT_Row<RepairPlanModel | any>) => {
    if (row) {
      const { original } = row
      if (original) {
        setRowAddInformation({
          ...row,
          original: Builder<RepairPlanModel>()
            .parentId(original.parentId)
            .checkPlanId(original?.checkPlanId)
            .company(original?.company)
            .factory(original?.factory)
            .code(original?.code)
            .name(original?.name)
            .checkDate(original?.checkDate)
            .category(original?.category)
            .subCategory(original?.subCategory)
            .visualReport(original?.visualReport)
            .actionPlanDate(original?.actionPlanDate)
            .abnormality(original?.abnormality)
            .abnormalityPicture(original?.abnormalityPicture)
            .build()
        })
        setOpenCreateModal(true)
      }
    }
  }

  /**
   * Handle event click edit row
   * @param row
   */
  const handleClickEditMode = (row: MRT_Row<RepairPlanModel | any>) => {
    setOpenCreateModal(true)
    setRowEdit(row)
  }

  /**
   * Handle on add complete
   * @param data
   * @param isEditMode
   */
  const handleChangeFromModal = (data: RepairPlanModel, isEditMode: boolean) => {
    if (!isEditMode) {
      const { index } = rowAddInformation
      const listData = [...listRepairPlanData]
      listData.splice(index + 1, 0, data)

      setListRepairPlan(listData)
      setRowAddInformation(undefined)
    } else {
      const listRepairPlanClone = [...listRepairPlanData]
      const elementEdit = listRepairPlanClone.findIndex(
        (s: RepairPlanModel, index) => s && index === rowEdited?.index
      )
      listRepairPlanClone[elementEdit] = { ...data }
      setListRepairPlan(listRepairPlanClone)
      setRowEdit(undefined)
    }
    setOpenCreateModal(false)
  }

  /**
   * Handle change company
   * @param onChange
   * @param currentValue
   */
  const handleChangeCompany = (onChange: (val: string) => void, currentValue: any) => {
    onChange(currentValue)
    const valueSelected = listCompany.find((el) => el.companyId === currentValue)
    if (valueSelected) {
      setSelectedCompany(valueSelected)
      setValue(RepairPlanSearchFormField.FACTORY, GENERIC_VALUE_TYPE)
      setValue(RepairPlanSearchFormField.EQUIPMENT, GENERIC_VALUE_TYPE)
      setSelectedFactory(undefined)
    }
  }

  /**
   * Handle change Factory
   * @param onChange
   * @param currentValue
   */
  const handleChangeFactory = (onChange: (val: string) => void, currentValue: any) => {
    onChange(currentValue)
    if (selectedCompany && selectedCompany?.factories) {
      const { factories } = selectedCompany
      if (factories && factories.length > 0) {
        const valueSelected = factories.find((el) => el.factoryId === currentValue)
        setSelectedFactory(valueSelected)
      }
    }
  }

  /**
   * Disable delete all button when don't have row selected
   */
  const disableDeleteAllRow = () => {
    const row = rowSelection && Object.keys(rowSelection)
    return row && row.length === 0
  }

  /**
   * Effect use for loading
   */
  useEffect(() => {
    setIsLoading(getListDataLoading || filterListDataLoading || submitDataLoading || isFetchCompany)
  }, [getListDataLoading, filterListDataLoading, submitDataLoading, isFetchCompany])

  /**
   * Initial effect
   */
  useEffect(() => {
    /**
     * Prepare data for component
     */
    const prepareData: any = async () => {
      await Promise.all([fetchCompany(), refetch()])
    }
    prepareData()
    unsaved = false
  }, [])

  useEffect(() => {
    if (!customCreateModal) {
      refetch()
    }
  }, [customCreateModal])

  return (
    <Stack spacing={2}>
      <Card>
        <Backdrop
          open={downloading}
          sx={{
            zIndex: 9999,
            color: '#fff'
          }}>
          <CircularProgress color="primary" variant="soft" />
        </Backdrop>
        <PlanSearchForm
          onSubmit={handleSubmit(handleSubmitSearchRepairPlan)}
          control={control}
          setValue={setValue}
          isAdmin={isAdmin}
          handleChangeCompany={handleChangeCompany}
          listCompany={listCompany}
          handleChangeFactory={handleChangeFactory}
          selectedCompany={selectedCompany}
          selectedFactory={selectedFactory}
          handleUploadRepairPlan={handleUploadRepairPlan}
          handleSaveChange={handleSaveChange}
          handleDeleteRow={handleDeleteRow}
          disableDeleteAllRow={disableDeleteAllRow()}
          handleCreate={() => setCustomCreateModal(true)}
          isCompleteDate={isCompleteDate}
          setCompleteDate={setCompleteDate}
          custom={
            <>
              <Tooltip title="다운로드">
                <>
                  <IconButton
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    color="primary"
                    onClick={handleMenuClick}>
                    <FileDownloadOutlined />
                  </IconButton>
                  <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                    <MenuItem onClick={() => handleClickExcelDown()}>공통양식</MenuItem>
                    <MenuItem onClick={() => handleClickExcelDown('KORYEO')}>사용자양식</MenuItem>
                  </Menu>
                </>
              </Tooltip>
            </>
          }
        />
      </Card>
      <BaseTable
        enableRowNumbers
        enableRowSelection
        enableRowActions
        enableSelectAll
        state={{ isLoading, rowSelection }}
        onRowSelectionChange={setRowSelection}
        data={listRepairPlanData}
        tableInstanceRef={tableInstanceRef}
        columns={columns}
        enableColumnDragging={true}
        muiTableContainerProps={{
          sx: {
            height: '600px'
          }
        }}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ mt: 1, pl: 1 }} component={'span'}>
            <Typography level="h6" fontWeight={600}>
              {t('menu.manage-repair')}
            </Typography>
          </Box>
        )}
        renderRowActionMenuItems={({ row, closeMenu }) =>
          repairPlanActionRowTemplate(
            t,
            row,
            handleAddNewRepairPlanRow,
            handleClickEditMode,
            handleDeleteSingleRow,
            closeMenu
          )
        }
      />

      {isOpenCreateModal && (
        <PlanCreateModal
          info={rowEdited?.original}
          rowAddSelection={rowAddInformation?.original}
          onSubmitFn={handleChangeFromModal}
          onCloseFn={() => {
            setOpenCreateModal(false)
            setRowEdit(undefined)
          }}
          isOpen={isOpenCreateModal}
        />
      )}
      {customCreateModal && (
        <PlanCustomCreateModal
          onCloseFn={() => {
            setCustomCreateModal(false)
          }}
          isOpen={customCreateModal}
        />
      )}
    </Stack>
  )
}

export default RepairPlanManagement
