import { ContentMenuDataProps } from '@core/components/menu/type'
import { apis } from '@core/configs/api'
import { request } from '@core/services/base.service'
import { UseFormGetValues } from 'react-hook-form'
import { InspectDataProps, InspectPlanProps } from './type'
import dayjs from 'dayjs'
import { DateFormat } from '@core/enums/date.enum'
import { AuthorizationModel } from '@core/models/authorization.model'
import _ from 'lodash'
import { CryptoHelperInstance } from '@core/helpers/crypto.helper'
import { getCookieByName } from '@core/helpers/cookie.helper'
import { CookieEnum } from '@core/enums/cookie.enum'
import { makeExcelDownload } from '@pages/service/miscFunc'
import { fail } from '@core/components/modal'

const path = apis.CHECKPLAN.path
const resultPath = apis.CHECKRESULT.path

export const getInspectPlan = async (
  loginInfo: AuthorizationModel,
  info: ContentMenuDataProps,
  getValues: UseFormGetValues<{
    startDate: string
    endDate: string
  }>
) => {
  const params: any = {
    startDate: dayjs(getValues().startDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER),
    endDate: dayjs(getValues().endDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER),
    companyId: null,
    factoryId: null
  }

  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )
  // 에드민일때
  if ((info && info.company) || (!isAdmin && companyId)) {
    params.companyId = isAdmin ? info.company : companyId
  }

  if (info && info.factory) {
    params.factoryId = info.factory
    delete params.companyId
  }

  return request.get(path, _.omitBy(params, _.isNull))
}
export const postInspectPlan = async (data: InspectPlanProps[]) => request.post(path, data) as any
export const deleteInspectPlan = async (data: number[]) => request.delete(path, data) as any

export const getInspectResult = async (
  loginInfo: AuthorizationModel,
  info: ContentMenuDataProps,
  getValues: UseFormGetValues<{
    startDate: string
    endDate: string
  }>
) => {
  const startDate = dayjs(getValues().startDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
  const endDate = dayjs(getValues().endDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
  const params: any = {}

  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )
  if (info.company || !isAdmin) {
    params.companyId = isAdmin ? info.company : companyId
  }

  if (info.factory) {
    params.factoryId = info.factory
    delete params.companyId
  }

  if (info.equipment) {
    params.equipmentId = info.equipment
    delete params.factoryId
  }

  // 날짜 붙이기
  if (info.company || info.factory || info.equipment || !loginInfo.isAdmin) {
    params.startDate = startDate
    params.endDate = endDate
  } else {
    params.startDate = startDate
    params.endDate = endDate
  }

  return request.get(resultPath, _.omitBy(params, _.isNull))
}
export const postInspectResult = async (data: InspectDataProps) =>
  request.post(resultPath, data) as any

export const postExcelDownload = async (prms: any) => {
  try {
    const { company, factory, startDate, endDate } = prms;
    const cvtPrms = `?startDate=${startDate}&endDate=${endDate}${company ? `&companyId=${company}` : ''}${factory ? `&factoryId=${factory}` : ''}`;
    const res: any = await request.post(`/checkplan/downloads${cvtPrms}`, {}, { responseType: 'blob' });
    const pageName = '점검 계획 관리';
    makeExcelDownload(res, pageName);
  } catch (e: any) {
    fail({ title: "다운로드 실패!", message: '요청이 실패했습니다.' });
  }
};

export const postResultExcelDownload = async (prms: any) => {
  try {
    const { company, factory, equipment, startDate, endDate } = prms;
    const cvtPrms = `?startDate=${startDate}&endDate=${endDate}${company ? `&companyId=${company}` : ''}${factory ? `&factoryId=${factory}` : ''}${equipment ? `&equipmentId=${equipment}` : ''}`;
    const res: any = await request.post(`/checkresult/downloads${cvtPrms}`, {}, { responseType: 'blob' });
    const pageName = '점검 결과 조회';
    makeExcelDownload(res, pageName);
  } catch (e: any) {
    fail({ title: "다운로드 실패!", message: '요청이 실패했습니다.' });
  }
};

export const postExcelUpload = async (formData: FormData) => {
  try {
    const data = await request.post(`/checkplan/uploads`, formData, {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    }) as any;
    return data;
  } catch (e: any) {
    fail({ title: "업로드 실패!", message: '요청이 실패했습니다.' });
  }
}
