import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Input } from '@mui/joy'
import ImgModal from '../imgModal/ImgModal';
import { initImgData } from '../init';
import { FilterSubProps, imgProps } from '../type';
import { FilterDataProps } from '@pages/dashboard/type';
import { string } from 'yup/lib/locale';
import { parseJson } from '@pages/service/miscFunc';
import * as _ from 'lodash';
import { urlToBase64 } from '@core/helpers/file.helper';
import { getUrlImagesWithImagesId } from '@pages/repair/query';
interface IProps {
  data: any;
  chamCount: number;
  rowIdx: number;
  mainRowIdx: number;
  curPage: number;
  articlePerPage: number;
  checkedIdList: any;
  setTableData: Dispatch<SetStateAction<FilterDataProps[]>>;
  onClickCheckbox: any;
}
const ReplacementRow: React.FC<IProps> = ({
  data,
  chamCount,
  mainRowIdx,
  rowIdx,
  curPage,
  articlePerPage,
  checkedIdList,
  onClickCheckbox,
  setTableData
}) => {
  const [imgData, setImgData] = useState<imgProps[]>(initImgData);
  const [onImgModal, setOnImgModal] = useState(false);
  console.log(data.images);
  useEffect(() => {
    const originImgKeys = Object.keys(data.images);
    if (originImgKeys.length > 0) {
      // 서버에서 받아온 이미지 데이터 추가
      const cvtImgData = imgData.map(el => {
        if (originImgKeys.includes(el.type)) {
          return { ...el, data: data.images[el.type] };
        }
        return el;
      });
      setImgData(_.cloneDeep(cvtImgData));
    }
  }, []);

  const cvtChamberData = parseJson(data.replaceCountByChamber).slice(0, chamCount);

  const handleChangeChamber = (e: any, idx: number) => {
    setTableData((prev) => {
      const cvtChamberData = parseJson(prev[mainRowIdx].history[rowIdx].replaceCountByChamber);
      cvtChamberData[idx] = Number(e.target.value) > 0 ? e.target.value : '';
      prev[mainRowIdx].history[rowIdx].replaceCountByChamber = cvtChamberData;
      console.log(prev[mainRowIdx].history[rowIdx].replaceCountByChamber);
      return _.cloneDeep(prev);
    })
  }

  const handleClickAdd = () => {
    const filteredData = imgData.filter(el => el.data);
    setTableData(prev => {
      const target: any = {};
      filteredData.forEach((item: imgProps) => {
        target[item.type] = item.data;
      })
      console.log(target);
      prev[mainRowIdx].history[rowIdx].images = target;
      return _.cloneDeep(prev);
    })
    setOnImgModal(false);
  }

  const imgLen = imgData.filter(el => el.data).length;

  return (
    <TableBodyTr>
      <td>
        <input
          type='checkbox'
          checked={checkedIdList.has(data.id)}
          onChange={(e) => onClickCheckbox(e, data.id)}
        />
      </td>
      <td>{rowIdx + 1 + ((curPage - 1) * articlePerPage)}</td>
      <td>
        <input
          value={data.timesDate}
          onChange={(e) => {
            setTableData(prev => {
              prev[mainRowIdx].history[rowIdx].timesDate = e.target.value;
              return _.cloneDeep(prev);
            })
          }}
          maxLength={10}
          placeholder='yyyy.mm.dd'
        />
      </td>
      <td>
        {cvtChamberData.map((item: any, idx: number) => (
          <ChamberWrap key={idx}>
            <input
              type='checkbox'
              checked={Number(item) > 0}
              readOnly
            />
            <span>{`C${idx + 1}`}</span>
            <input
              type='number'
              placeholder='num'
              value={item}
              onChange={(e) => handleChangeChamber(e, idx)}
            />
            {idx + 1 !== cvtChamberData.length && <DashLine />}
          </ChamberWrap>
        ))}
      </td>
      <td>
        <Button
          size='sm'
          sx={{ width: '180px' }}
          onClick={() => setOnImgModal(true)}
        >
          {imgLen > 0 ? `이미지 수정 (${(imgLen)} / 4)` : '이미지 업로드'}
        </Button>
      </td>
      {onImgModal && <ImgModal
        rowData={data}
        imgData={imgData}
        onClose={() => setOnImgModal(false)}
        onClick={handleClickAdd}
        setImgData={setImgData}
      />}
    </TableBodyTr>
  );
};

const TableHeaderTr = styled.tr`
  display: flex;
  > th {
    font-weight: 500;
  }

  > th:nth-child(1) {
    flex: 10%;
  }
  > th:nth-child(2) {
    flex: 10%;
  }
  > th:nth-child(3) {
    flex: 10%;
  }
  > th:nth-child(4) {
    flex: 50%;
  }
  > th:nth-child(5) {
    flex: 20%;
  }
`;

const TableBodyTr = styled.tr`
display: flex;
padding: 8px 0;

  > td {
    display: flex;
    align-items: center;
  }

  > td:nth-child(1) {
    flex: 10%;
  }
  > td:nth-child(2) {
    flex: 10%;
  }
  > td:nth-child(3) {
    flex: 10%;
    > input {
      border: none;
      border-bottom: 1px solid #9a9a9a;
      outline: none;
    }
  }
  > td:nth-child(4) {
    flex: 50%;
  }
  > td:nth-child(5) {
    flex: 20%;
  }
`;

const ChamberWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  > span:nth-child(2) {
    font-size: 16px;
  }

  > input:nth-child(3) {
    width: 50px;
    height: 20px;
    text-align: center;
  }
`;

const DashLine = styled.div`
  width: 1px;
  height: 16px;
  border-right: 2px solid lightgray;
`;

export default ReplacementRow;