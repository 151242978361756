import { SnackBarState } from '@core/components/snackbar/type'
import { Builder } from '@core/helpers/builder.helper'
import { String } from '@core/enums/common.enum'
import { atom, selector } from 'recoil'
import { AtomKey, RecoilSelectorKey } from '@core/enums/atom.enum'

/**
 * Snackbar initial state
 */
const snackBarInitialState = Builder<SnackBarState>()
  .isOpen(false)
  .isShowProgress(false)
  .isWaitingProgress(false)
  .valueProgress(0)
  .duration(0)
  .message(String.EMPTY_STRING)
  .build()

/**
 * Snackbar recoil state
 */
const snackbarAtom = atom({
  key: AtomKey.SNACKBAR,
  default: snackBarInitialState
})

/**
 * Snackbar selector
 */
const snackbarSelector = selector({
  key: RecoilSelectorKey.SNACKBAR_SELECTOR,
  get: ({ get }) => get(snackbarAtom),
  set: ({ set }, value) => set(snackbarAtom, value)
})

export { snackBarInitialState, snackbarSelector, snackbarAtom }
