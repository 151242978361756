import { FC, useState } from 'react'
import Layout from '@theme/layouts'
import Sidebar from '@theme/components/sidebar'
import { Outlet } from 'react-router-dom'
import Header from '@theme/components/header'
import { Stack } from '@mui/joy'
import { BreadCrumbs } from '@core/components/breadcrumbs'
import { useRecoilState } from 'recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'
import { decodeJwtTokenAndConvertToAuthorizationModel, logOut } from '@core/helpers/auth.helper'
import { request } from '@core/services/base.service'
import { apis } from '@core/configs/api'
import { AuthorizationModel } from '@core/models/authorization.model'
import { AxiosSingleton } from '@core/configs/axios.config'
import { AxiosError } from 'axios'
import { ApiErrorStatusCode } from '@core/enums/api.enum'
import { setCookie } from '@core/helpers/cookie.helper'
import { CookieEnum } from '@core/enums/cookie.enum'
import { CryptoHelperInstance } from '@core/helpers/crypto.helper'
import { AuthorizationEnum } from '@core/enums/authorization.enum'
// import { ErrorMessage } from '@core/enums/error.enum'

/**
 * Primary layout component
 * @constructor
 */
const PrimaryLayout: FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [isHiddenSidebar] = useState(true)
  const [, setAuthorizationModel] = useRecoilState(authorizationSelector)
  const { refetch } = useQuery(
    [QueryKey.REFRESH_TOKEN],
    async () => request.post(apis.REFRESH_TOKEN.path, null),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 2,
      refetchIntervalInBackground: true,
      onError: () => {
        logOut()
      },
      onSuccess: (data: { accessToken: string }) => {
        const { accessToken } = data
        if (accessToken) {
          const authorizationModel: AuthorizationModel =
            decodeJwtTokenAndConvertToAuthorizationModel(accessToken)
          setAuthorizationModel(authorizationModel)
          const { companyId, authority } = authorizationModel
          setCookie(
            CookieEnum.COMPANY_ID,
            CryptoHelperInstance.encodeByAES56(
              JSON.stringify({ companyId, isAdmin: AuthorizationEnum.ADMIN === authority })
            ),
            { path: '/' }
          )
        }
      }
    }
  )
  /**
   * Interceptor response from backend
   */
  AxiosSingleton.getInstance().interceptors.response.use(
    async (response) => {
      return response
    },
    async (error: AxiosError) => {
      if (error.response! && error.response?.status) {
        switch (error.response?.status) {
          case ApiErrorStatusCode.FORBIDDEN:
            if (error && error.response) {
              const { data }: any = error.response
              const prevRequest: any = error?.config
              if (
                data &&
                // data.message &&
                // data.message === ErrorMessage.RETRY_BROWSER &&
                AxiosSingleton.getToken().length <= 0 &&
                !prevRequest?.sent
              ) {
                prevRequest.sent = true
                const newAccessToken: string | any = await refetch()
                const { accessToken } = newAccessToken.data
                prevRequest.headers['Authorization'] = `Bearer ${accessToken}`
                AxiosSingleton.setToken(accessToken)
                return AxiosSingleton.getInstance()(prevRequest)
              }
            }
            break
          default:
            break
        }
      }
      return Promise.reject(error)
    }
  )

  return (
    <>
      {drawerOpen && (
        <Layout.SideDrawer onClose={() => setDrawerOpen(false)}>
          <Sidebar />
        </Layout.SideDrawer>
      )}
      <Layout.Root
        sx={{
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'minmax(64px, 200px) minmax(450px, 1fr)',
            md: 'minmax(160px, 300px) minmax(600px, 1fr) minmax(300px, 420px)'
          },
          ...(drawerOpen && {
            height: '100vh',
            overflow: 'hidden'
          })
        }}>
        <Layout.Header>
          <Header setDrawerOpen={setDrawerOpen} />
        </Layout.Header>
        {!isHiddenSidebar && (
          <Layout.SideNav>
            <Sidebar />
          </Layout.SideNav>
        )}

        <Layout.Main>
          <Stack sx={{ px: 2 }}>
            <BreadCrumbs />
            <Outlet />
          </Stack>
        </Layout.Main>
      </Layout.Root>
    </>
  )
}

export default PrimaryLayout
