import { CompanyModel } from '@pages/settings/company/type'

export interface SettingsFactorySearchFormProps {
  onSubmit: (data: SettingFactorySearchFormInDto) => void
  onDelete: () => void
  onCreate: () => void
  disableDeleteAll: boolean
  listCompany: CompanyModel[]
}

export interface SettingFactorySearchFormInDto {
  companyId: number | string
  name: string
}

export enum SettingFactorySearchFormFields {
  COMPANY_ID = 'companyId',
  FACTORY_NAME = 'name'
}

export interface FactoryModel {
  id?: number
  companyId: number
  name: string
  address?: string
  maxChamberCount?: number
}

export type FactoryInDto = FactoryModel

export interface CreateFactoryFormProps {
  isOpen: boolean
  onCloseFn: () => void
  onSubmit: (data: FactoryInDto, isEditMode?: boolean) => void
  information?: FactoryModel
  listCompany: CompanyModel[]
}

export enum CreateFactoryFormFields {
  COMPANY_ID = 'companyId',
  FACTORY_NAME = 'name',
  EQUIPMENT = 'equipments',
  ADDRESS = 'address',
  CHAMBER_MAX_NUM = 'maxChamberCount'
}
