import { InspectPlanProps } from '@pages/inspect/type'
import { CompanyListProps } from '@core/components/menu/type'

export interface RepairTargetProps {
  [keys: string]: any
  category: string
  checkDate?: string
  checkPlanId: number
  id: number
  subCategory: string
  visualReport: number
  repairPlans?: RepairPlanProps[]
}

export interface RepairPlanProps {
  repairTargetId: number
  id?: number
  abnormality: string
  abnormalityPicture: any
  actionPlan: string
  actionPlanDate: string
  actionPlanDetail: string
  actionCompletionDate: string
  actionCompletionPictures: null[]
  materialName?: string
  materialSize?: string
  materialCount?: string
  manager: string
  note: string
}

export type RepairPlanDataProps = RepairTargetProps & RepairPlanProps

export interface RepairPlanCreateprops {
  info: InspectPlanProps | undefined
}

export interface RepairPlanSearchForm {
  companyId?: string
  equipmentId?: string
  factoryId?: string
  startDate: Date | string | any
  endDate: Date | string | any
  isCompleteDate: boolean,
  repairStatus?: 'ALL' | 'COMPLETE' | 'INCOMPLETE'
}

export enum RepairPlanSearchFormField {
  COMPANY = 'companyId',
  EQUIPMENT = 'equipmentId',
  FACTORY = 'factoryId',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  REPLACE_START_DATE = 'replaceStartDate',
  REPLACE_END_DATE = 'replaceEndDate',
  IS_COMPLETE_DATE = 'isCompleteDate',
  REPAIR_STATUS = 'repairStatus'
}

export interface BaseRepairPlanModel {
  id?: number
  parentId?: number
  checkPlanId?: number
  company: string
  factory: string
  code: string
  name: string
  checkDate: string
  category?: string
  subCategory?: string
  visualReport?: string
  repairPlans: RepairPlan[]
}

export interface RepairPlan {
  [key: string]: any
  repairTargetId?: number
  actionPlan?: string
  actionPlanDate?: string
  actionCompletionDate?: string
  actionCompletionPictures: string[] | RepairPlanImagesModel[] | any[]
  abnormality?: string
  abnormalityPicture?: string | RepairPlanImagesModel | any
  actionPlanDetail?: string
  actionCompletionDetail?: string
  manager?: string
  note?: string
}

export type RepairPlanModel = BaseRepairPlanModel & RepairPlan

export interface ListRepairPlanState {
  data: RepairPlanModel[]
  listCompany: CompanyListProps[]
  isLoading: boolean
}

export interface RepairPlanImagesModel {
  content: string | any
  name: string
}

export type RepairPlanSearchFormInDto = RepairPlanSearchForm
