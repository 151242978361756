import { atom, selector } from 'recoil'
import { ApplicationWrapperState } from '@core/components/application-provider/type'
import { String } from '@core/enums/common.enum'
import { AtomKey, RecoilSelectorKey } from '@core/enums/atom.enum'

/**
 * Application wrapper initial state
 */
const applicationInitialState: ApplicationWrapperState = {
  authorization: {
    username: String.EMPTY_STRING,
    companyId: null,
    accessToken: String.EMPTY_STRING,
    iat: 0,
    exp: 0,
    authority: String.EMPTY_STRING,
    isAdmin: true
  }
}

/**
 * Application recoil state
 */
const applicationState = atom({
  key: AtomKey.APPLICATION_STATE,
  default: applicationInitialState
})

/**
 * Authorization selector
 */
const authorizationSelector = selector({
  key: RecoilSelectorKey.AUTHORIZATION_SELECTOR,
  get: ({ get }) => {
    const { authorization } = get(applicationState)
    return authorization
  },
  set: ({ get, set }, authorization: any) => {
    const applicationWrapperState = get(applicationState)
    set(applicationState, {
      ...applicationWrapperState,
      authorization: {
        ...authorization
      }
    })
  }
})

export { applicationState, authorizationSelector, applicationInitialState }
