import {
  BaseRepairPlanModel,
  RepairPlanImagesModel,
  RepairPlanModel,
  RepairPlanSearchForm
} from '@pages/repair/type'
import { useMutation } from '@tanstack/react-query'
import { useAppQuery } from '@core/helpers/query.helper'
import { request } from '@core/services/base.service'
import { apis } from '@core/configs/api'
import dayjs from 'dayjs'
import { DateFormat } from '@core/enums/date.enum'
import _ from 'lodash'
import { urlToBase64 } from '@core/helpers/file.helper'
import { TFunction } from 'i18next'
import { delay } from '@core/helpers/action.helper'
import { excludePropertiesWhenFilterRepairPlan } from '@pages/repair/template'
import { makeExcelDownload } from '@pages/service/miscFunc'
import { fail } from '@core/components/modal'

export const getUrlImagesWithImagesId = (imagesId: string) => {
  return `/image?id=${imagesId}`
}

/**
 * Convert data json from backend to data in table
 * @param data
 * @param t
 */
const convertDataToTableData = async (data: RepairPlanModel[], t?: TFunction) => {
  const listData: RepairPlanModel[] = []
  console.log(data);
  for (const el of data) {
    const rootRepairPlan = { ...el }
    const { repairPlans } = rootRepairPlan

    if (t && repairPlans && repairPlans.length > 0) {
      for (const repairPlansModel of repairPlans) {
        const mergePropertiesOfRepairPlan = {
          ...el,
          ...repairPlansModel,
          parentId: el?.id
        }
        const { actionCompletionPictures, abnormalityPicture } = repairPlansModel

        // Check if repairPlansModel have abnormalityPicture
        if (abnormalityPicture && abnormalityPicture.length > 0) {
          const imagesId = repairPlansModel.abnormalityPicture
          const img = await urlToBase64(getUrlImagesWithImagesId(imagesId))
          if (img) {
            mergePropertiesOfRepairPlan.abnormalityPicture = {
              name: imagesId,
              content: img
            }
          }
        } else {
          mergePropertiesOfRepairPlan.abnormalityPicture = null
        }

        // Check if repairPlansModel have actionCompletionPictures
        if (actionCompletionPictures && actionCompletionPictures.length > 0) {
          const listActionCompletionPictures: RepairPlanImagesModel[] = []
          for (const imgId of actionCompletionPictures) {
            if (imgId) {
              const img = await urlToBase64(getUrlImagesWithImagesId(imgId))
              if (img) {
                listActionCompletionPictures.push({
                  name: imgId,
                  content: img
                })
              }
            }
          }
          mergePropertiesOfRepairPlan.actionCompletionPictures = listActionCompletionPictures
        } else {
          mergePropertiesOfRepairPlan.actionCompletionPictures = []
        }

        listData.push(mergePropertiesOfRepairPlan)
      }
    }
  }
  console.log(listData)
  return listData
}

/**
 * Get list repair plan action
 * @param options
 */
const useGetListRepairPlan = (
  options: {
    onSuccess: (data: RepairPlanModel[]) => void
    enabled: boolean
  },
  t: TFunction
) => {
  return useAppQuery(
    ['listRepairPlan'],
    async () => {
      const toDay = dayjs(new Date()).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
      const response = request
        .get(`${apis.REPAIR_TARGET.path}`, {
          startDate: toDay,
          endDate: toDay,
          isCompleteDate: false
        })
        .then(async (data: BaseRepairPlanModel[] | any) => await convertDataToTableData(data, t))
      return Promise.resolve(response)
    },
    options
  )
}

/**
 * Filter list repair plan by RepairPlanSearchForm InDto
 */
const useFilterListRepairPlan = (t: TFunction) => {
  return useMutation({
    mutationFn: (data: RepairPlanSearchForm) => {
      const { startDate, endDate } = data
      return request
        .get(apis.REPAIR_TARGET.path, {
          ..._.omit(data, excludePropertiesWhenFilterRepairPlan(data)),
          startDate: dayjs(startDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER),
          endDate: dayjs(endDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
        })
        .then((response: BaseRepairPlanModel[] | any) => convertDataToTableData(response, t))
        .then(delay(200))
    }
  })
}

/**
 * Submit add or update repair plan
 */
const useSubmitRepairPlan = () => {
  return useMutation({
    mutationFn: (data: BaseRepairPlanModel[]) => {
      return request.post(apis.REPAIR_TARGET.path, data)
    }
  })
}

/**
 * Delete row on table
 */
const useDeleteRepairPlan = () => {
  return useMutation({
    mutationFn: (data: number[]) => {
      return request.delete<number[]>(apis.REPAIR_PLAN.path, data)
    }
  })
}

export const postExcelDownload = async (prms: any) => {
  try {
    const { companyId, factoryId, equipmentId, startDate, endDate, formType, isCompleteDate, repairStatus } = prms;
    const cvtPrms = `?startDate=${startDate}&endDate=${endDate}${companyId ? `&companyId=${companyId}` : ''}${factoryId ? `&factoryId=${factoryId}` : ''}${equipmentId ? `&equipmentId=${equipmentId}` : ''}${`&isCompleteDate=${isCompleteDate}`}${formType ? `&formType=${formType}` : '&formType=NORMAL'}${repairStatus ? `&repairStatus=${repairStatus}` : ''}`;
    const res: any = await request.post(`/repairtarget/downloads${cvtPrms}`, {}, { responseType: 'blob' });
    const pageName = '수리 계획 및 결과 관리';
    makeExcelDownload(res, pageName);
  } catch (e: any) {
    fail({ title: "다운로드 실패!", message: '요청이 실패했습니다.' });
  }
};

export const postExcelUpload = async (formData: FormData) => {
  try {
    const data = await request.post(`/repairtarget/uploads`, formData, {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    }) as any;
    console.log(data);
    return data;
  } catch (e: any) {
    fail({ title: "업로드 실패!", message: '요청이 실패했습니다.' });
  }
}


export { useFilterListRepairPlan, useGetListRepairPlan, useSubmitRepairPlan, useDeleteRepairPlan }
