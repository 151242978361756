export interface LoginInDto {
  username: string
  password: string
  remember: boolean
}

export enum LoginFormField {
  USERNAME = 'username',
  PASSWORD = 'password',
  REMEMBER_ME = 'remember'
}

export interface LoginInfoProps {
  [key: string]: any
  companyId: number
  admin: boolean
  username: string
}
