/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Box, Button, Modal, ModalDialog, Stack, Typography } from '@mui/joy'
import { Fade } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { ErrorOutline, CheckCircleOutlined, CancelOutlined } from '@mui/icons-material'
import './style.scss'
import { ConfirmProps, ConfirmSaveChangeProps } from './type'
import ReactDOM, { createRoot } from 'react-dom/client'

const useModal = (
  isVisible: boolean
): [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
    (event: React.KeyboardEvent<any>) => void
  ] => {
  const [isOpen, setIsOpen] = useState(isVisible)

  useEffect(() => {
    setIsOpen(isVisible)
  }, [isVisible])

  const onToggleHandler = (event: React.KeyboardEvent<any>) => {
    setIsOpen(!isOpen)
  }

  return [isOpen, setIsOpen, onToggleHandler]
}

const Confirm = ({
  afterClose = () => { },
  onClickClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { },
  onOk: onClickOk = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>
  ) => { },
  isVisible = true,
  title = '저장하시겠습니까?',
  onClosed = () => { },
  confirmButtonText = '저장',
  cancelButtonText = '취소',
  message = '',
  danger = false,
  icon,
  contents = (
    onClickCloseContent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => { },
    onOkContent = (
      event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.KeyboardEvent<HTMLButtonElement>
    ) => { },
    contentMessage = '',
    loading = false
  ) => (
    <>
      <Box className="warnModalBody" sx={{ paddingX: '1.75rem' }}>
        {contentMessage}
      </Box>
      <Stack
        className="modalFooter"
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        sx={{ mt: '12px' }}>
        <Button variant="outlined" color="neutral" onClick={onClickCloseContent}>
          {cancelButtonText}
        </Button>
        <Button
          onClick={onOkContent}
          onKeyDown={(e: any) => e.key === 'enter' && onOkContent(e)}
          color={danger ? 'danger' : 'primary'}
          loading={loading}>
          {confirmButtonText}
        </Button>
      </Stack>
    </>
  )
}: ConfirmProps) => {
  const [isOpen, setIsOpen] = useModal(isVisible)
  const [isLoading, setLoading] = useState(false)

  const onClickCloseHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onClickClose(event)
    setIsOpen(false)
  }

  const onOkHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    setLoading(true)
    await onClickOk(event)
    setLoading(false)
    setIsOpen(false)
  }

  const onClosedHandler = () => {
    onClosed()
    afterClose()
  }

  useEffect(() => {
    const resetLoading = setTimeout(() => {
      setLoading(false)
    }, 10000)

    return () => clearTimeout(resetLoading)
  }, [isLoading])

  return (
    <Modal open={isOpen} onClose={onClosedHandler} className="modalDialog">
      <Fade in={isOpen}>
        <ModalDialog variant="outlined">
          <Typography
            component="h3"
            level="inherit"
            mb="1em"
            startDecorator={icon ? icon : <ErrorOutline sx={{ color: 'orange' }} />}>
            {title}
          </Typography>
          {contents(onClickCloseHandler, onOkHandler, message, isLoading)}
        </ModalDialog>
      </Fade>
    </Modal>
  )
}

export const destroyFns: Array<() => void> = []

export const destroyAll = () => {
  while (destroyFns.length) {
    const close = destroyFns.pop()
    if (close) {
      close()
    }
  }
}

interface Factory {
  Component?: any
  onClosed?: () => void
  onAfterClosed?: () => void
  [x: string]: any
}

export const factory = ({ Component, ...config }: Factory) => {
  const div = document.createElement('div')
  const root = createRoot(div)
  document.body.appendChild(div)
  const currentConfig: Factory = {
    ...config,
    isVisible: true,
    afterClose: () => {
      if (typeof currentConfig.onAfterClose === 'function') {
        currentConfig.onAfterClose()
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      destroy(config)
    }
  }

  const destroy = ({ ...config }: Factory) => {
    root.unmount()
    div.parentElement?.removeChild(div)

    if (typeof config.onClosed === 'function') {
      config.onClosed()
    }

    for (let i = 0; i < destroyFns.length; i += 1) {
      const fn = destroyFns[i]
      if (fn === close) {
        destroyFns.splice(i, 1)
        break
      }
    }
  }

  const render = ({ ...config }: Factory) => {
    setTimeout(() => {
      return Component ? root.render(<Component {...config} />) : new Error('컴포넌트가 없습니다.')
    })
  }

  const update = (newConfig: Factory) => {
    config = {
      ...currentConfig,
      ...newConfig
    }
    render(config)
  }

  const close = () => {
    const config = {
      ...currentConfig,
      isVisible: false,
      afterClose: () => {
        if (typeof currentConfig.onAfterClose === 'function') {
          currentConfig.onAfterClose()
        }
        destroy(config)
      }
    }
    render(config)
  }

  render(currentConfig)

  destroyFns.push(close)

  return {
    destroy: close,
    update
  }
}

export interface WithWarnConfig extends Factory, Pick<ConfirmProps, 'title' | 'message'> { }

export const withWarn = (config?: WithWarnConfig): ConfirmProps => {
  return {
    ...config,
    contents: (onClickClose, onClickOk, contentMessage) => (
      <>
        {contentMessage && <div className="modal-body">{contentMessage}</div>}
        <Box
          className="modal-footer"
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: contentMessage ? '1em' : 0 }}>
          <Button
            autoFocus
            onClick={onClickOk}
            onKeyDown={(e: any) => e.key === 'enter' && onClickOk && onClickOk(e)}
            color="primary">
            확인
          </Button>
        </Box>
      </>
    )
  }
}

export const confirm = (config?: ConfirmProps) => factory({ ...config, Component: Confirm })
export const deleteConfirm = (config?: ConfirmProps) =>
  factory({ ...config, title: '경고', confirmButtonText: '삭제', danger: true, Component: Confirm })
export const editConfirm = (config?: ConfirmProps) =>
  factory({
    ...config,
    Component: Confirm,
    title: '경고',
    message: '이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.',
    confirmButtonText: '나가기'
  })
export const success = (config?: WithWarnConfig) =>
  factory({
    ...withWarn(config),
    title: config?.title ?? '저장성공!',
    icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    Component: Confirm
  })

export const savedCheckModal = (config?: WithWarnConfig) =>
  factory({
    ...withWarn(config),
    title: '경고',
    message: '저장되지 않은 데이터가 존재합니다. 저장 후 다시시도해주세요.',
    Component: Confirm
  })

export const warn = (config?: WithWarnConfig) =>
  factory({
    ...withWarn(config),
    title: '경고',
    Component: Confirm
  })

export const fail = (config?: WithWarnConfig) =>
  factory({
    ...withWarn(config),
    title: config?.title || '저장실패!',
    icon: <CancelOutlined style={{ color: 'red' }} />,
    Component: Confirm
  })

/**
 * Confirm save change modal
 * @param watch
 * @param onClose
 * @param onAccept
 * @param subscribe
 * @constructor
 */
export const ConfirmSaveChange: FC<ConfirmSaveChangeProps> = ({
  onAccept,
  subscribe = false,
  onClose
}) => {
  const [isSubscribe, setSubscribe] = useState<boolean>(false)

  /**
   * Handle click save change
   */
  const handleSaveChange = () => {
    setSubscribe(false)
    onAccept()
  }

  /**
   * Handle close modal
   */
  const handleCloseModal = () => {
    onClose()
  }

  /**
   * Subscribe parent close event or move over modal event
   */
  useEffect(() => setSubscribe(subscribe), [subscribe])
  return (
    <Modal open={isSubscribe} onClose={onClose}>
      <Fade in={isSubscribe}>
        <ModalDialog variant="outlined">
          <Typography
            component="h2"
            level="inherit"
            fontSize="1.25em"
            mb="0.25em"
            startDecorator={<ErrorOutline sx={{ color: 'orange' }} />}>
            경고
          </Typography>
          <Typography level="body1" fontWeight={500} textColor="text.primary" m={2}>
            이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.
          </Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button variant="outlined" color="neutral" onClick={handleCloseModal}>
              취소
            </Button>
            <Button variant="solid" onClick={handleSaveChange}>
              확인
            </Button>
          </Stack>
        </ModalDialog>
      </Fade>
    </Modal>
  )
}

export default factory
