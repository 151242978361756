import styled from '@emotion/styled'
import { Input as MuiInput } from '@mui/material'
import { forwardRef } from 'react'

export const Th = styled.th`
  border: 1px solid black;

  &.code {
    background-color: #fde4f1;
  }
`
export const MainTh = styled(Th)`
  background-color: #e4ffe0;
`
export const SubTh = styled(Th)`
  background-color: #ffffe2;
`

export const Td = styled.td`
  border: 1px solid black;
`

export const NoneTh = styled.th`
  border: none;
`

export const Header = styled(Th)`
  background-color: #dffefe;
`

export const Input = forwardRef((props: any, ref) => {
  return (
    <MuiInput
      size="sm"
      fullWidth
      {...props}
      inputProps={{ style: { textAlign: 'center' } }}
      ref={ref}
    />
  )
})

export const InputNumber = forwardRef((props: any, ref) => {
  return (
    <MuiInput
      type="number"
      fullWidth
      {...props}
      inputProps={{ style: { textAlign: 'center' } }}
      slotProps={{
        input: {
          step: 0.001
        }
      }}
      ref={ref}
    />
  )
})
