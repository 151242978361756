import { QueryKey } from '@core/enums/query.enum'
import { useAppQuery } from '@core/helpers/query.helper'
import { QueryOptionModel } from '@core/models/query.model'
import { request } from '@core/services/base.service'
import { useMutation } from '@tanstack/react-query'
import { MakeCustomModel, PutCustomModel } from './type'
import { fail, success } from '@core/components/modal'

export const useInspectCustom = (companyId: number | undefined, option?: QueryOptionModel) => {
  return useAppQuery(
    [QueryKey.INSPECT_CUSTOM],
    async () => {
      const res: PutCustomModel[] = await request.get('/checkMethods');
      if (companyId) return res.filter(el => el.companyId === companyId);
      return request.get('/checkMethods')
    },
    option
  )
}

export const useTemplateType = (option?: QueryOptionModel) => {
  return useAppQuery(
    [QueryKey.TEMPLATE_TYPE],
    async () => {
      return request.get('/checktype')
    },
    option
  )
}

export const usePutCustom = () => {
  return useMutation({
    mutationFn: async (prms: PutCustomModel) => {
      return request.put('/checkMethods', prms);
    }
  })
}

export const useDeleteTemplate = () => {
  return useMutation({
    mutationFn: async (prms: number[]) => {
      return request.delete(`/checkMethods`, prms)
    },
    onError: (error: any) => {
      const errMsg = error.response.data.message;
      fail({ title: '삭제 실패!', message: errMsg });
    }
  })
}

export const useMutationCustom = () => {
  return useMutation({
    mutationFn: async (prms: MakeCustomModel) => {
      return request.post('/checkMethods', prms);
    }
  })
}