import { TranslateEnum } from '@core/enums/translate.enum'
import { PopperUnstyled } from '@mui/base'
import { GridViewOutlined, KeyboardArrowDown } from '@mui/icons-material'
import { Box, List, ListItem, ListItemButton, ListItemDecorator, Typography } from '@mui/joy'
import { ClickAwayListener } from '@mui/material'
import { forwardRef, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import menuList from '../meta'
import { HeaderSubMenuProps } from '../type'
import { useRecoilValue } from 'recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import _ from 'lodash'

const useRovingIndex = (options?: any) => {
  const navigate = useNavigate()
  const { initialActiveIndex = 0 } = options || {}
  const [activeIndex, setActiveIndex] = useState<number>(initialActiveIndex)
  const targetRefs = useRef<HTMLElement[]>([])
  const targets = targetRefs.current
  const getTargetProps = (index: number, href = '/') => ({
    ref: (ref: HTMLElement | null) => {
      if (ref) {
        targets[index] = ref
      }
    },
    tabIndex: activeIndex === index ? 0 : -1,
    onClick: () => {
      setActiveIndex(index)
      if (href !== '/' || index === 0) {
        navigate(href)
      }
    }
  })
  return {
    activeIndex,
    setActiveIndex,
    targets,
    getTargetProps
  }
}

const SubMenu = forwardRef<HTMLDivElement, any>(({ menu, ...props }, ref) => {
  const { label, menus } = menu as HeaderSubMenuProps
  const { t } = useTranslation([TranslateEnum.COMMON])
  const [anchorEl, setAnchorEl] = useState(null)
  const { getTargetProps } = useRovingIndex({
    initialActiveIndex: null,
    vertical: true
  })

  const open = Boolean(anchorEl)
  const id = open ? 'about-popper' : undefined
  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box component="div" onMouseLeave={() => setAnchorEl(null)}>
        <ListItemButton
          aria-haspopup
          aria-expanded={open ? 'true' : 'false'}
          ref={ref}
          {...props}
          role="menuitem"
          onFocus={(event: any) => setAnchorEl(event.currentTarget)}
          onMouseEnter={(event: any) => {
            props.onMouseEnter?.(event)
            setAnchorEl(event.currentTarget)
          }}
          sx={(theme) => ({
            ...(open && theme.variants.plainHover.neutral),
            '&:focus-visible': {
              outline: 'none'
            }
          })}>
          <Typography fontSize="lg">{t(`menu.${label}`)}</Typography>
          <KeyboardArrowDown />
        </ListItemButton>
        <PopperUnstyled
          id={id}
          open={open}
          anchorEl={anchorEl}
          disablePortal
          keepMounted
          placement="bottom-start">
          <List
            role="menu"
            aria-label={label}
            variant="outlined"
            sx={{
              bgcolor: 'background.paper',
              my: 2,
              boxShadow: 'md',
              borderRadius: 'sm',
              '--List-radius': '8px',
              '--List-padding': '4px',
              '--List-divider-gap': '4px',
              '--List-decorator-size': '32px',
              width: 250
            }}>
            {menus?.map((sub, index) => (
              <ListItem role="none" key={sub.label}>
                <ListItemButton role="menuitem" {...getTargetProps(index, sub.href)}>
                  <ListItemDecorator>{sub.icon}</ListItemDecorator>
                  <Typography fontSize="lg">{t(`menu.${sub.label}`)}</Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </PopperUnstyled>
      </Box>
    </ClickAwayListener>
  )
})

const GnbMenu = () => {
  const { targets, getTargetProps, setActiveIndex } = useRovingIndex()
  const { t } = useTranslation([TranslateEnum.COMMON])
  const authorization = useRecoilValue(authorizationSelector)
  const { authority } = useMemo(() => authorization, [authorization])

  return (
    <Box component="div">
      <List
        orientation="horizontal"
        role="menubar"
        sx={{
          '--List-radius': '8px',
          '--List-padding': '4px',
          '--List-gap': '15px'
        }}>
        {Object.keys(menuList).map((main, index) => {
          const currentMenu = _.cloneDeep(menuList[main])
          const { label, href, menus, permissions } = currentMenu

          if (menus && menus.length > 0 && permissions && permissions?.includes(authority)) {
            currentMenu.menus = menus?.filter(
              (menu) => menu && menu.permissions && menu.permissions.includes(authority)
            )

            return (
              <ListItem role="none" key={main}>
                <SubMenu
                  menu={currentMenu}
                  onMouseEnter={() => {
                    setActiveIndex(index)
                    targets[index].focus()
                  }}
                  {...getTargetProps(index, href)}
                />
              </ListItem>
            )
          } else if (permissions?.includes(authority)) {
            return (
              <ListItem role="none" key={main}>
                <ListItemButton role="menuitem" {...getTargetProps(index, href)}>
                  <ListItemDecorator>
                    <GridViewOutlined />
                  </ListItemDecorator>
                  <Typography fontSize="lg">{t(`menu.${label}`)}</Typography>
                </ListItemButton>
              </ListItem>
            )
          }
        })}
      </List>
    </Box>
  )
}

export default GnbMenu
