import { SaveOutlined } from '@mui/icons-material'
import { Stack, Tooltip } from '@mui/joy'
import { IconButton } from '@mui/material'
interface ActionsProps {
  onCreate?: (event: any) => void
}
export const Actions = ({ onCreate }: ActionsProps) => {
  return (
    <>
      <Stack direction="row" spacing={1}>
        <Tooltip title="저장" sx={{ zIndex: 9999 }}>
          <IconButton color="primary" onClick={onCreate}>
            <SaveOutlined fontSize="large" />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  )
}
