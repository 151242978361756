import { Box, Button, Modal, ModalDialog, Stack, Typography } from '@mui/joy'
import { Fade } from '@mui/material'
import { ModalBaseProps } from './type'
import { ErrorOutline } from '@mui/icons-material'
interface CustomConfirmModalProps extends ModalBaseProps {
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void
  loading: boolean
}
export const CustomConfirmModal = ({
  open,
  onClose,
  confirmButtonText = '저장',
  onConfirm,
  cancelButtonText = '취소',
  loading
}: CustomConfirmModalProps) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <ModalDialog variant="outlined">
          <Typography
            component="h3"
            level="inherit"
            mb="1em"
            startDecorator={<ErrorOutline sx={{ color: 'orange' }} />}>
            저장하시겠습니까?
          </Typography>
          <Stack
            className="modalFooter"
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            sx={{ mt: '12px' }}>
            <Button variant="outlined" color="neutral" onClick={onClose}>
              {cancelButtonText}
            </Button>
            <Button onClick={onConfirm} color={'primary'} loading={loading}>
              {confirmButtonText}
            </Button>
          </Stack>
        </ModalDialog>
      </Fade>
    </Modal>
  )
}
