import styled from '@emotion/styled'
import { Calendar } from 'react-date-range'

export const Wrapper = styled('div')({
  position: 'relative'
})

export const CustomCalender = styled(Calendar)`
  &:focus {
    outline: none;
  }
`
