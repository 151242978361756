import { authorizationSelector } from '@core/components/application-provider/recoil'
import { ContentMenu } from '@core/components/menu'
import { companyInfoState } from '@core/components/menu/recoil'
import { CustomSelect } from '@core/components/menu/select'
import { ContentMenuDataProps } from '@core/components/menu/type'
import { Transfer } from '@core/components/transfer'
import { ItemsProps } from '@core/components/transfer/type'
import { AuthorizationModel } from '@core/models/authorization.model'
import { Card, Stack, Option } from '@mui/joy'
import { factoryListState } from '@pages/equipment/recoil'
import { SelectOptionType } from '@pages/equipment/type'
import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Actions } from '../template'
import { ServiceLogin } from '../login'
import { TargetEnum } from '@core/enums/common.enum'

export const ServiceSync = () => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const companyInfo = useRecoilValue(companyInfoState)
  const [factoryObject, setFactoryObject] = useRecoilState(factoryListState)
  // 상단에서 선택된 데이터가 들어가게 지정하고
  const [info, setInfo] = useState<ContentMenuDataProps>({
    service: undefined
  })
  // 설비리스트
  const [data, setData] = useState<ItemsProps[]>([])

  // 등록리스트
  const [added, setAdded] = useState<ItemsProps[]>([])

  // 연동
  const [target, setTarget] = useState(TargetEnum.CONNECT)
  const [open, setOpen] = useState(false)

  const onRegister = (register: boolean) => {
    if (register) {
      setTarget(TargetEnum.CONNECT)
    } else {
      setTarget(TargetEnum.DISCONNECT)
    }
    setOpen(true)
  }

  const getFactoryList = (factoryObj: { [keys: string]: SelectOptionType[] }) => {
    let getList: ItemsProps[] = []
    if (!authorizationModel.isAdmin) {
      getList = factoryObj[authorizationModel.companyId].map((item) => ({
        label: item.name,
        value: item.id.toString()
      }))
    } else {
      if (info.company) {
        getList = factoryObj[info.company].map((item) => ({
          label: item.name,
          value: item.id.toString()
        }))
      }
    }
    setData(getList)
  }

  const onChangeService = (event: any, value: string, key: string) => {
    setInfo((prev) => ({ ...prev, [key]: value }))
  }

  useEffect(() => {
    if (info.company) {
      const getList = factoryObject[info.company].map((item) => {
        const obj: ItemsProps = {
          label: `${item.name}`,
          value: item.id.toString()
        }
        if (item.children) {
          const children = item.children.map((sub) => ({
            label: sub.name,
            value: sub.id
          }))
          obj.children = children
          obj.disabled = children.length === 0
        }
        return obj
      })

      setData(getList)
    }
  }, [info.company])

  useEffect(() => {
    const tempObj: any = {}
    companyInfo.forEach((company) => {
      if (company.factories) {
        const array = company.factories.map((item) => ({
          id: item.factoryId.toString(),
          name: item.factoryName,
          children: item.equipments?.map((equip) => ({
            id: `${item.factoryId}_${equip.id}`,
            name: equip.header.name
          }))
        }))
        tempObj[company.companyId] = array
      }
    })

    setFactoryObject(tempObj)
    getFactoryList(tempObj)
  }, [companyInfo])

  return (
    <>
      <Stack spacing={2}>
        <Card>
          <ContentMenu
            companyprops={{ enable: true }}
            setInfo={setInfo}
            customNode={
              <>
                <CustomSelect
                  title="서비스명"
                  value={undefined}
                  defaultOption={<Option value={'iems'}>IEMS</Option>}
                  onChange={(e, value) => onChangeService(e, value, 'service')}
                />
              </>
            }
            customNodeLeft
            actions={
              <Actions onRegister={onRegister} disabled={added.length === 0 || !info.service} />
            }
          />
        </Card>
        <Card>
          <Transfer setData={setAdded} data={data} secondTitle={'연동목록'} enableTotal />
        </Card>
      </Stack>
      {open && <ServiceLogin open={open} onClose={() => setOpen(false)} target={target} />}
    </>
  )
}
