import { QueryKey } from '@core/enums/query.enum'
import { Stack, Typography } from '@mui/joy'
import { Skeleton } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { UseFormReturn } from 'react-hook-form'
import { Input, InputNumber, MainTh, NoneTh, Th } from '../../styles'
import { CommonProps } from '../type'

interface HeaderProps {
  type: boolean
  formControl: UseFormReturn<CommonProps, any>
}
export const Header = ({ type, formControl }: HeaderProps) => {
  const queryClient = useQueryClient()
  const isLoading = queryClient.isFetching([QueryKey.EQUIPMENTDETAIL])

  return (
    <Stack>
      {isLoading ? (
        <Stack spacing={1}>
          <Skeleton variant="text" />
          <Skeleton variant="rounded" height={200} />
        </Stack>
      ) : (
        <>
          <Stack justifyContent={'center'} alignItems={'center'}>
            <Typography level="h4" fontWeight={600}>
              {type ? '집 진 설 비 카 드 (B/F)' : '집 진 설 비 카 드 (SCRUBBER)'}
            </Typography>
          </Stack>
          <table className="tg">
            <colgroup>
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
              <col style={{ width: '12.5%' }} />
            </colgroup>
            <thead>
              <tr>
                <Th className="code">회 사 명</Th>
                <td>
                  <Input {...formControl.register('header.company')} disabled />
                </td>
                <NoneTh colSpan={4} rowSpan={2} style={{ border: 'none' }}></NoneTh>
                <NoneTh colSpan={2} style={{ border: 'none' }}></NoneTh>
              </tr>
              <tr>
                <Th className="code">관 리 번 호</Th>
                <td>
                  <Input {...formControl.register('header.code')} disabled />
                </td>
                <Th className="code">설 비 번 호</Th>
                <td>
                  <Input {...formControl.register('header.equipmentCode')} />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <MainTh>부 서 명</MainTh>
                <td>
                  <Input {...formControl.register('header.factory')} disabled />
                </td>
                <MainTh>설 비 명</MainTh>
                <td colSpan={2}>
                  <Input {...formControl.register('header.name')} />
                </td>
                <MainTh>형 식</MainTh>
                <td colSpan={2}>
                  <Input {...formControl.register('header.type')} />
                </td>
              </tr>
              <tr>
                <MainTh>집진용량</MainTh>
                <td>
                  <InputNumber
                    endAdornment={<span>m³/min</span>}
                    {...formControl.register('header.size')}
                  />
                </td>
                <MainTh>설치년월</MainTh>
                <td>
                  <Input {...formControl.register('header.establishmentDate')} />
                </td>
                <MainTh>MAKER</MainTh>
                <td>
                  <Input {...formControl.register('header.manufacturer')} />
                </td>
                <MainTh>용 도</MainTh>
                <td>
                  <Input {...formControl.register('header.usage')} />
                </td>
              </tr>
              <tr>
                <MainTh>챔버 수</MainTh>
                <td>
                  <Input disabled {...formControl.register('header.maxChamberCount')} />
                </td>
                <MainTh>인.허가</MainTh>
                <td>
                  <Input {...formControl.register('header.permission')} />
                </td>
                <MainTh>발생분진</MainTh>
                <td>
                  <Input {...formControl.register('header.generatedDust')} />
                </td>
                <MainTh>특기사항</MainTh>
                <td>
                  <Input {...formControl.register('header.note')} />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Stack>
  )
}
