import { Typography } from '@mui/joy'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { ChangeEvent, useEffect, useState } from 'react'
import { DatePicker } from '../date-picker'
import { CompanyListProps, ContentMenuProps, SelectOptionProps } from './type'
import { CustomSelect } from './select'
import { Stack } from './styles'
import { getCompanyInfo } from './query'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'
import { MetaData } from '@core/enums/meta-data.enum'
import { companyInfoState } from './recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
export interface ChildProps {
  getValue: () => string | undefined
}

export const ContentMenu = ({
  actions,
  companyprops,
  equipmentprops,
  factoryprops,
  dateprops,
  customNode,
  customNodeLeft = false,
  isFilter,
  setInfo
}: ContentMenuProps) => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const setAllInfo = useSetRecoilState(companyInfoState)
  const [factoryInfo, setFactoryInfo] = useState<{
    [key: string]: SelectOptionProps[]
  }>()
  const [equipmentInfo, setEquipmentInfo] = useState<{
    [key: string]: SelectOptionProps[]
  }>()
  const [data, setData] = useState<{
    [keys: string]: {
      enable: boolean
      title: string
      list: SelectOptionProps[]
    }
  }>({
    companyprops: {
      enable: companyprops?.enable ?? false,
      title: companyprops?.title ?? '회사명',
      list: companyprops?.list ?? []
    },
    equipmentprops: {
      enable: equipmentprops?.enable ?? false,
      title: equipmentprops?.title ?? '설비명',
      list: equipmentprops?.list ?? []
    },
    factoryprops: {
      enable: factoryprops?.enable ?? false,
      title: factoryprops?.title ?? '공장명',
      list: factoryprops?.list ?? []
    },
    dateprops: {
      enable: dateprops?.enable ?? false,
      title: dateprops?.title ?? '날짜',
      list: dateprops?.list ?? []
    }
  })

  const { refetch } = useQuery([QueryKey.COMPANYINFO], () => getCompanyInfo(authorizationModel), {
    enabled: false,
    onSuccess: (data: CompanyListProps[]) => {
      setAllInfo(data)
      // 로그인된 회사의 정보를 가져옴.
      // 관리자면 회사정보, 공장정보, 설비정보를 가져옴
      // 유저면 공장정보, 설비정보 표시

      const companyList = data.map((item) => {
        const factoryList = item.factories?.map((factory) => {
          const equipmentList = factory.equipments?.map((equipment) => ({
            label: equipment.header.name,
            value: equipment?.id?.toString() ?? equipment.header.name
          }))

          setEquipmentInfo((prev) => ({ ...prev, [factory.factoryId]: equipmentList }))

          return {
            label: factory.factoryName,
            value: factory.factoryId.toString()
          }
        })

        setFactoryInfo((prev) => ({ ...prev, [item.companyId]: factoryList }))
        if (!authorizationModel.isAdmin && item.companyId === authorizationModel.companyId) {
          setData((prev) => ({
            ...prev,
            factoryprops: {
              ...prev.factoryprops,
              list: factoryList ?? []
            }
          }))
        }

        return {
          label: item.companyName,
          value: item.companyId.toString()
        }
      })

      setData((prev) => ({
        ...prev,
        companyprops: {
          ...prev.companyprops,
          list: companyList
        }
      }))
    }
  })

  const onChange = (e: ChangeEvent<HTMLInputElement>, value: string, key: string) => {
    if (value === null) return

    setInfo &&
      setInfo((prev) => {
        return { ...prev, [key]: value ? value.toString() : undefined }
      })

    // 관리자 일경우 company 표시
    if (key === 'company') {
      if (factoryInfo) {
        const factoryList = factoryInfo[value]
        setData((prev) => ({
          ...prev,
          factoryprops: {
            ...prev.factoryprops,
            list: factoryList
          },
          equipmentprops: {
            ...prev.equipmentprops,
            list: []
          }
        }))
      }
    } else if (key === 'factory') {
      //공장이 들어오면 설비리스트를 변경해주어야함
      if (equipmentInfo) {
        const equipmentList = equipmentInfo[value]
        setData((prev) => ({
          ...prev,
          equipmentprops: {
            ...prev.equipmentprops,
            list: equipmentList
          }
        }))
      }
    }
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Grid container>
      <Grid xs={isFilter ? 11 : 10} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack direction="row" spacing={4} justifyContent="flex-start">
          {customNodeLeft && customNode}
          {authorizationModel.isAdmin && data?.companyprops?.enable && (
            <CustomSelect
              onChange={(e, value) => onChange(e, value, MetaData.COMPANY)}
              {...data.companyprops}
            />
          )}
          {data?.factoryprops?.enable && (
            <CustomSelect
              onChange={(e, value) => onChange(e, value, MetaData.FACTORY)}
              {...data.factoryprops}
            />
          )}
          {data?.equipmentprops?.enable && (
            <CustomSelect
              onChange={(e, value) => onChange(e, value, MetaData.EQUIPMENT)}
              {...data.equipmentprops}
            />
          )}
          {data?.dateprops?.enable && (
            <Stack>
              <Typography level="body1" fontWeight={700}>
                {data.dateprops.title ? data.dateprops.title : '날짜'}
              </Typography>
              <DatePicker
                name="date"
                setValue={setInfo}
                title={data.dateprops.title}
                defaultValue={new Date()}
              />
            </Stack>
          )}
          {!customNodeLeft && customNode}
        </Stack>
      </Grid>
      <Grid xs={isFilter ? 1 : 2}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          {actions}
        </Stack>
      </Grid>
    </Grid>
  )
}
