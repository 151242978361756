import React, { FC, useEffect, useState } from 'react'
import {
  RepairPlanCreateFormField,
  RepairPlanCreateModalProps
} from '@pages/repair/plan-list/plan-create-modal/type'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  Sheet,
  Stack,
  Typography,
  Option,
  Select,
  FormHelperText
} from '@mui/joy'
import { DatePicker } from '@core/components/date-picker'
import { FormProvider, useForm } from 'react-hook-form'
import { convertFileToBase64 } from '@core/helpers/file.helper'
import { Fade } from '@mui/material'
import { editConfirm } from '@core/components/modal'
import FileUpload from '@core/components/file-upload'
import { PlaceHolderEnum } from '@core/enums/place.holder.enum'
import { AuthorizationModel } from '@core/models/authorization.model'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { companyInfoState } from '@core/components/menu/recoil'
import { companyListSelector, factoryListState } from '@pages/equipment/recoil'
import { SelectOptionType } from '@pages/equipment/type'
import { editState } from '@pages/recoil'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CompanyListProps } from '@core/components/menu/type'
import { getCheckType, postUnregistered } from './query'
import { CustomSelectOptionType } from './type'
import { convertBase64ToArrayBuffer } from '@core/helpers/file.helper'
import { fail } from '@core/components/modal'
import { success } from '@core/components/modal'

/**
 * Plan create modal component
 * @param isOpen
 * @param onCloseFn
 * @param onSubmitFn
 * @param info
 * @param rowAddSelection
 * @constructor
 */
const PlanCustomCreateModal: FC<RepairPlanCreateModalProps> = ({ isOpen = false, onCloseFn }) => {
  const queryClient = useQueryClient()
  const companyInfo = queryClient.getQueryData<CompanyListProps[]>(['companyInfo'])
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const setCompoanyInfo = useSetRecoilState(companyInfoState)
  const companyList = useRecoilValue(companyListSelector)
  const [factoryObject, setFactoryObject] = useRecoilState(factoryListState)
  const [equipmentObject, setEquipmentObject] = useState<{
    [key: string]: SelectOptionType[]
  }>({})
  const [categoryObj, setCategoryObj] = useState<CustomSelectOptionType[]>([])
  const companyId = authorizationModel.isAdmin ? null : authorizationModel.companyId.toString()
  const [isEdit, setEdit] = useRecoilState(editState)
  const [companyName, setCompanyName] = useState<string | null>(null)
  const method = useForm()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    formState: { errors, touchedFields }
  } = method
  const [actionCompletionPictures, setActionCompletionPictures] = useState<File[]>()
  const company = watch().company
  const factory = watch().factory
  const equip = watch().equipment
  const category = watch().category

  const { refetch } = useQuery(['checkType'], () => getCheckType(equip), {
    enabled: false,
    onSuccess: (data) => {
      if (data) {
        const newObj: CustomSelectOptionType[] = Object.keys(data).map((item: string) => ({
          id: item,
          name: item,
          children: data[item].map((sub: any) => ({ id: sub, name: sub }))
        }))
        setCategoryObj(newObj)
      }
    }
  })

  const postMutation = useMutation((data: any) => postUnregistered(data), {
    onSuccess: (data) => {
      setEdit(false)
      success({
        onClosed: () => {
          onCloseFn()
        }
      })
    },
    onError: (err) => {
      fail()
    }
  })

  /**
   * Clear form
   */
  const clearForm = () => {
    setActionCompletionPictures([])
    reset({})
  }

  /**
   * Handle click submit create or update repair plan
   * @param data
   */
  const onSubmit = async (data: any) => {
    const { actionCompletionPictures } = data
    let newPictureArray: any = []
    // base 64로 변환 후 작업진행
    if (actionCompletionPictures && actionCompletionPictures.length > 0) {
      for (const image of actionCompletionPictures) {
        const content = (await convertFileToBase64(image)) as string
        const buffer = await convertBase64ToArrayBuffer(content)
        newPictureArray.push([...buffer])
      }
    } else {
      newPictureArray = []
    }

    data.actionCompletionPictures = newPictureArray

    postMutation.mutate(data)
    clearForm()
  }
  /**
   * Handle close modal
   */
  const handleClose = () => {
    if (isEdit) {
      editConfirm({
        onOk: () => {
          onCloseFn()
          setEdit(false)
        }
      })
    } else {
      onCloseFn()
    }
  }

  const onChangeCompany = (e: any, value: any) => {
    if (value === null) {
      setValue(RepairPlanCreateFormField.COMPANY, companyName, { shouldTouch: true })
      setValue(RepairPlanCreateFormField.FACTORY, null)
      setValue(RepairPlanCreateFormField.EQUIPMENT, null)
    } else {
      setValue(RepairPlanCreateFormField.COMPANY, value, { shouldTouch: true })
      setValue(RepairPlanCreateFormField.FACTORY, null)
      setValue(RepairPlanCreateFormField.EQUIPMENT, null)
    }
  }

  const onChangeFactory = (e: any, value: any) => {
    setValue(RepairPlanCreateFormField.FACTORY, value, { shouldTouch: true })
  }

  const onChange = (e: any, value: any, key: any) => {
    setValue(key, value, { shouldTouch: true })
  }

  const getCommonNode = (key: string, keyObj: any) => {
    let optionNode
    let text = '공장'
    let subText = '회사'
    let list: SelectOptionType[] | CustomSelectOptionType[] = []

    switch (key) {
      case RepairPlanCreateFormField.FACTORY:
        text = '공장'
        subText = '회사'
        list = factoryObject[keyObj]
        break
      case RepairPlanCreateFormField.EQUIPMENT:
        text = '설비'
        subText = '공장'
        list = equipmentObject[keyObj]
        break
      case RepairPlanCreateFormField.CATEGORY:
        text = '대분류'
        subText = '설비'
        list = categoryObj
        break
      case RepairPlanCreateFormField.SUB_CATEGORY:
        {
          text = '소분류'
          subText = '대분류'

          const tempArray = categoryObj.filter((item: CustomSelectOptionType) => item.id === keyObj)

          if (tempArray.length > 0 && tempArray[0].children) {
            list = tempArray[0].children
          }
        }

        break
    }

    if (keyObj) {
      if (list && list.length > 0) {
        optionNode = list.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name}
          </Option>
        ))
      } else {
        optionNode = (
          <Option disabled value={undefined}>
            {`${text}이/가 존재하지 않습니다.`}
          </Option>
        )
      }
    } else {
      optionNode = (
        <Option disabled value={undefined}>
          {`${subText}을/를 먼저 선택해주세요.`}
        </Option>
      )
    }

    return optionNode
  }

  useEffect(() => {
    if (equip) {
      setCategoryObj([])
      refetch()
    }
  }, [equip])

  useEffect(() => {
    if (Object.keys(touchedFields).length > 0) {
      setEdit(true)
    } else {
      setEdit(false)
    }
  }, [Object.keys(touchedFields).length])

  useEffect(() => {
    if (companyInfo) {
      setCompoanyInfo(companyInfo)
      const tempObj: { [key: string]: SelectOptionType[] } = {}
      const equipObj: { [key: string]: SelectOptionType[] } = {}
      let companyName = null

      companyInfo?.forEach((company) => {
        if (company.companyId === Number(companyId)) {
          companyName = company.companyName
          setCompanyName(companyName)
        }
        if (company.factories) {
          const array = company.factories.map((item) => {
            if (item.equipments !== undefined) {
              equipObj[item.factoryId] = item.equipments.map((equip) => ({
                id: Number(equip.id),
                name: equip.header.name
              }))
            }

            return {
              id: item.factoryId,
              name: item.factoryName,
              equipments: item.equipments
            }
          })
          tempObj[company.companyName] = array
        }
      })
      setFactoryObject(tempObj)
      setEquipmentObject(equipObj)
    }
  }, [])

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={handleClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={isOpen}>
          <Sheet
            variant="outlined"
            sx={{
              width: 1000,
              borderRadius: 3,
              p: 3,
              boxShadow: 'lg'
            }}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}>
              수리내역 등록
            </Typography>
            <Typography level="body5" textColor={'neutral.500'} fontWeight="lg" mb={1}>
              타업체에서 수리했던 수리내역을 등록합니다.
            </Typography>
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body'
              }}
            />
            <Stack spacing={2} sx={{ maxHeight: 800, overflowY: 'scroll', overflowX: 'hidden' }}>
              <FormProvider {...method}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid xs={6}>
                      <Stack spacing={1}>
                        <FormControl>
                          <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 700 }}>
                            회사명
                          </FormLabel>
                          <Select
                            {...register(RepairPlanCreateFormField.COMPANY)}
                            placeholder="회사를 선택해주세요."
                            onChange={onChangeCompany}
                            color={errors.company && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {companyList.length > 0 ? (
                              companyList.map((item) => (
                                <Option key={item.name} value={item.name}>
                                  {item.name}
                                </Option>
                              ))
                            ) : (
                              <Option disabled>{PlaceHolderEnum.COMPENY}</Option>
                            )}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <FormLabel id="exhaustion" htmlFor="exhaustion" sx={{ fontWeight: 700 }}>
                            설비명
                          </FormLabel>

                          <Select
                            {...register(RepairPlanCreateFormField.EQUIPMENT)}
                            placeholder="설비를 선택해주세요."
                            onChange={(e, value) =>
                              onChange(e, value, RepairPlanCreateFormField.EQUIPMENT)
                            }
                            color={errors.equipment && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {getCommonNode(RepairPlanCreateFormField.EQUIPMENT, factory)}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>대분류</FormLabel>

                          <Select
                            {...register(RepairPlanCreateFormField.CATEGORY)}
                            placeholder="대분류를 선택해주세요."
                            onChange={(e, value) =>
                              onChange(e, value, RepairPlanCreateFormField.CATEGORY)
                            }
                            color={errors.category && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {getCommonNode(RepairPlanCreateFormField.CATEGORY, equip)}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <FormLabel
                            id="actionCompletionDate"
                            htmlFor="actionCompletionDate"
                            sx={{ fontWeight: 700 }}>
                            완료일자
                          </FormLabel>

                          <DatePicker
                            InputProps={{
                              ...register(RepairPlanCreateFormField.ACTION_COMPLETION_DATE)
                            }}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid xs={6}>
                      <Stack spacing={1}>
                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>공장명</FormLabel>
                          <Select
                            {...register(RepairPlanCreateFormField.FACTORY)}
                            placeholder={PlaceHolderEnum.FACTORY}
                            onChange={onChangeFactory}
                            color={errors.factory && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {getCommonNode(RepairPlanCreateFormField.FACTORY, company)}
                          </Select>

                          {errors.factory && (
                            <FormHelperText sx={{ color: 'red' }}>
                              {PlaceHolderEnum.FACTORY}
                            </FormHelperText>
                          )}
                        </FormControl>

                        <FormControl sx={{ opacity: 0 }}>
                          <FormLabel sx={{ fontWeight: 700 }}>프레임</FormLabel>

                          <Input />
                        </FormControl>

                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>소분류</FormLabel>

                          <Select
                            {...register(RepairPlanCreateFormField.SUB_CATEGORY)}
                            placeholder="소분류를 선택해주세요."
                            onChange={(e, value) =>
                              onChange(e, value, RepairPlanCreateFormField.SUB_CATEGORY)
                            }
                            color={errors.subCategory && 'danger'}
                            slotProps={{
                              listbox: {
                                sx: {
                                  maxHeight: 240,
                                  overflow: 'auto'
                                }
                              }
                            }}>
                            {getCommonNode(RepairPlanCreateFormField.SUB_CATEGORY, category)}
                          </Select>
                        </FormControl>

                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>조치 내용</FormLabel>

                          <Input
                            placeholder="조치 내용"
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.ACTION_COMPLETION_DETAIL)}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid xs={12}>
                      <FormControl>
                        <FormLabel sx={{ fontWeight: 700 }}>조치 결과 사진</FormLabel>

                        <FileUpload
                          limit={2}
                          multiple
                          files={actionCompletionPictures}
                          name={RepairPlanCreateFormField.ACTION_COMPLETION_PICTURES}
                          onFileDelete={(files: File[]) => setActionCompletionPictures(files)}
                        />
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <Stack spacing={1}>
                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>자재명</FormLabel>
                          <Input
                            placeholder="자재명"
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MATERIAL_NAME)}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>수량</FormLabel>

                          <Input
                            placeholder="수량"
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MATERIAL_COUNT)}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>비고</FormLabel>

                          <Input
                            placeholder="비고"
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.NOTE)}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid xs={6}>
                      <Stack spacing={1}>
                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>규격 및 재질</FormLabel>

                          <Input
                            placeholder="규격 및 재질"
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MATERIAL_SIZE)}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel sx={{ fontWeight: 700 }}>담당자</FormLabel>

                          <Input
                            placeholder="담당자"
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MANAGER)}
                          />
                        </FormControl>
                      </Stack>
                    </Grid>

                    <Grid xs={12}>
                      <Button sx={{ width: 106 }} color="warning" onClick={handleClose}>
                        취소
                      </Button>
                      <Button sx={{ ml: 2, width: 106 }} color="primary" type="submit">
                        저장
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Stack>
          </Sheet>
        </Fade>
      </Modal>
    </>
  )
}

export default PlanCustomCreateModal
