import { useAppQuery } from '@core/helpers/query.helper'
import { QueryKey } from '@core/enums/query.enum'
import { request } from '@core/services/base.service'
import { apis } from '@core/configs/api'
import { QueryOptionModel } from '@core/models/query.model'
import { useMutation } from '@tanstack/react-query'
import { ChangePasswordInDto, SettingAccountInformationInDto } from '@pages/settings/account/type'
import { delay } from '@core/helpers/action.helper'
import { CompanyIndDto, CompanyModel } from '@pages/settings/company/type'
import { CreateFactoryFormFields, FactoryInDto, FactoryModel } from '@pages/settings/factory/type'
import _ from 'lodash'

/**
 * Use get user information query
 * @param option
 */
const useGetUserInformation = (option: QueryOptionModel) => {
  return useAppQuery(
    [QueryKey.USER_INFORMATION],
    async () => {
      return request.get(apis.USER.path)
    },
    option
  )
}

/**
 * Use submit change user information
 */
const useSubmitChangeUserInformation = () => {
  return useMutation({
    mutationFn: async (inDto: SettingAccountInformationInDto) => {
      return request.put(apis.USER.path, inDto).then(delay(300))
    }
  })
}

/**
 * Use submit change password
 */
const useSubmitChangePassword = () => {
  return useMutation({
    mutationFn: async (inDto: ChangePasswordInDto) => {
      return request.put(`${apis.USER.path}/password`, inDto).then(delay(300))
    }
  })
}

/**
 * Get list setting company
 * @note: Only role admin can call this query
 * @param option
 */
const useGetListCompany = (option?: QueryOptionModel) => {
  return useAppQuery(
    [QueryKey.GET_LIST_COMPANY],
    async () => {
      return request.get<CompanyModel[]>(apis.COMPANY.path)
    },
    option
  )
}

/**
 * Delete company mutation
 */
const useMutationDeleteCompany = () => {
  return useMutation({
    mutationFn: async (inDto: number[]) => {
      return request.delete(apis.COMPANY.path, inDto).then(delay(300))
    }
  })
}

/**
 * Add company mutation
 */
const useMutateAddCompany = () => {
  return useMutation({
    mutationFn: async (inDto: CompanyIndDto) => {
      return request.post(apis.COMPANY.path, inDto)
    }
  })
}

/**
 * Update company mutation
 */
const useMutateUpdateCompany = () => {
  return useMutation({
    mutationFn: async (inDto: CompanyIndDto) => {
      return request.put(apis.COMPANY.path, inDto).then(delay(300))
    }
  })
}

/**
 * Get all factory
 * @param option
 */
const useGetAllFactory = (id: string | number | undefined, option: QueryOptionModel) => {
  return useAppQuery(
    [QueryKey.GET_ALL_FACTORY],
    async () => {
      let path = `${apis.FACTORY.path}`
      if (id) {
        path += `?companyId=${id}`
      }
      return request.get<FactoryModel[]>(path).then(delay(300))
    },
    option
  )
}

/**
 * Handle add new factory mutation
 */
const useMutateAddFactory = () => {
  return useMutation({
    mutationFn: async (inDto: FactoryInDto) => {
      return request
        .post(apis.FACTORY.path, _.omit(inDto, [CreateFactoryFormFields.EQUIPMENT]))
        .then(delay(300))
    }
  })
}

/**
 * Handle delete factory mutation
 */
const useMutateDeleteFactory = () => {
  return useMutation({
    mutationFn: async (inDto: number[]) => {
      return request.delete(apis.FACTORY.path, inDto).then(delay(300))
    }
  })
}

export {
  useGetUserInformation,
  useSubmitChangeUserInformation,
  useSubmitChangePassword,
  useGetListCompany,
  useMutationDeleteCompany,
  useMutateAddCompany,
  useMutateUpdateCompany,
  useGetAllFactory,
  useMutateAddFactory,
  useMutateDeleteFactory
}
