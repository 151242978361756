import { warn } from '@core/components/modal'
import { String } from '@core/enums/common.enum'
import { QueryKey } from '@core/enums/query.enum'
import { convertBase64ToArrayBuffer } from '@core/helpers/file.helper'
import { Typography, Stack } from '@mui/joy'
import { Skeleton } from '@mui/material'
import { editState } from '@pages/recoil'
import { useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { ImagePreview, UploadBox } from '../../template'
interface PictureProps {
  imageObj: any
  setValue: UseFormSetValue<any>
}

export const Picture = ({ imageObj, setValue }: PictureProps) => {
  const queryClient = useQueryClient()
  const setEdit = useSetRecoilState(editState)
  // 파일 이름 및 요청 URL 저장
  const [images, setImages] = useState<{
    [key: string]: null | string
    picture: null | string
    diagram: null | string
  }>({
    picture: null,
    diagram: null
  })

  // 이미지 업로드

  const onUpload = (event: ChangeEvent<HTMLInputElement>, target: string) => {
    const { files } = event.target

    if (files) {
      if (!files[0].type.startsWith('image/')) {
        warn({
          title: '경고',
          message: '잘못된 형식의 파일입니다.'
        })
      } else {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const result = e.target?.result
          console.log(result);
          if (result) {
            const previewTarget = target.split('.')[1]

            setImages((prev) => ({ ...prev, [previewTarget]: result as string }))
            const arryBuffer = await convertBase64ToArrayBuffer(result as string)
            setValue(target, [...arryBuffer])
            setEdit(true)
          }
        }
        reader.readAsDataURL(files[0])
      }
    }
  }

  useEffect(() => {
    if (imageObj) {
      const { picture, diagram } = imageObj
      if (picture && picture.content) {
        const { content } = picture

        setImages((prev) => ({ ...prev, picture: content }))
      }

      if (diagram && diagram.content) {
        const { content } = diagram
        setImages((prev) => ({ ...prev, diagram: content }))
      }
    }
  }, [imageObj])

  const isLoading = queryClient.isFetching([QueryKey.EQUIPMENTDETAIL])

  return (
    <Stack spacing={1}>
      {isLoading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="rounded" height={200} />
        </>
      ) : (
        <>
          <Typography level="body1" fontWeight={600}>
            1.설비 외관 및 다이어그램
          </Typography>
          <table className="tg" style={{ textAlign: 'center' }}>
            <colgroup>
              <col width="50%" />
              <col width="50%" />
            </colgroup>
            <tbody>
              <tr>
                <td style={{ position: 'relative' }}>
                  {images.picture ? (
                    <ImagePreview
                      image={images.picture}
                      target={String.PICTURE}
                      setImages={setImages}
                      setEdit={setEdit}
                      setValue={setValue}
                    />
                  ) : (
                    <UploadBox target={String.PICTURE} onUpload={onUpload} />
                  )}
                </td>
                <td style={{ position: 'relative' }}>
                  {images.diagram ? (
                    <ImagePreview
                      image={images.diagram}
                      target={String.DIAGRAM}
                      setImages={setImages}
                      setEdit={setEdit}
                      setValue={setValue}
                    />
                  ) : (
                    <UploadBox target={String.DIAGRAM} onUpload={onUpload} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </Stack>
  )
}
