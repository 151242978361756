import { Button, Input, Option, Modal, Select } from '@mui/joy';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import { CompanyModel } from '@pages/settings/company/type';
import { useTranslation } from 'react-i18next';
import { TranslateEnum } from '@core/enums/translate.enum';
import { useMutationCustom, usePutCustom, useTemplateType } from '../query';
import DndContainer from '@core/components/dndContainer/DndContainer';
import { CustomModel, TemplateTypeModel } from '../type';
import RemoveIcon from '@mui/icons-material/Remove';
import ForwardIcon from '@mui/icons-material/Forward';
import AddIcon from '@mui/icons-material/Add';
import { success, fail } from '@core/components/modal';
import { initCheckDVA } from '../init';

interface IProps {
  formType: 'ADD' | 'UPDATE';
  open: boolean;
  listCompany: CompanyModel[];
  rowData?: any;
  onClose: () => void;
  refetch: () => void;
}

const InspectCraeteForm: React.FC<IProps> = ({
  open,
  formType,
  listCompany,
  rowData,
  onClose,
  refetch: listCustomRefresh
}) => {
  const isAdd = formType === 'ADD';
  const isUpdate = formType === 'UPDATE';
  // const isCustomUpdate = 
  const { t } = useTranslation([TranslateEnum.FACTORY]);
  // dnd 관련
  const { data: templateData = [], isLoading, refetch } = useTemplateType();

  const [companyId, setCompanyId] = useState(rowData?.companyId || 0);
  const [templateId, setTemplateId] = useState(rowData?.templateTypeId || 0);
  const [isEnforceTemp, setIsEnforceTemp] = useState(false);
  const [methodName, setMethodName] = useState(rowData?.checkMethodName || '');

  const [customData, setCustomData] = useState<any[]>(isUpdate ? [rowData] : []);
  // const [customUpdateData, setCustomUpdateData] = useState();
  const [clickedMajor, setClickedMajor] = useState('');

  const [isAddMajorItem, setIsAddMajorItem] = useState(false);
  const [isAddSubItem, setIsAddSubItem] = useState(false);

  const [deleteMajorList, setDeleteMajorList] = useState<string[]>([]);
  const [deleteSubList, setDeleteSubList] = useState<string[]>([]);

  const { mutateAsync } = useMutationCustom();
  const { mutateAsync: mutatePutAsync } = usePutCustom();

  useEffect(() => {
    setIsAddMajorItem(false);
    setIsAddSubItem(false);
    // if문으로 templateId와 업데이트에서 가져온 templateId가 같으면 멈추기
    const isInitAdd = isAdd && templateData.length > 0;
    const isChangeUpdateTemp = isEnforceTemp;
    if (isInitAdd) {
      setCustomData(templateData.filter((el: any) => el.id === templateId));
      setClickedMajor('');
    }

    if (isChangeUpdateTemp) {
      setCustomData(templateData.filter((el: any) => el.id === templateId));
      setClickedMajor('');
    }
  }, [templateId]);

  useEffect(() => {
    setIsAddSubItem(false);
  }, [clickedMajor]);

  const handleDeleteMajorList = () => {
    const checkList = customData[0].checkList;
    deleteMajorList.forEach(el => {
      delete checkList[el];
    })
    setClickedMajor('');
    setCustomData((prev) => [{
      ...prev[0],
      checkList,
    }])
  };

  const handleDeleteSubList = () => {
    const checkList = customData[0].checkList[clickedMajor];
    deleteSubList.forEach(el => {
      delete checkList[el];
    })
    setCustomData((prev) => [{
      ...prev[0],
      checkList: {
        ...customData[0].checkList,
        [clickedMajor]: {
          ...checkList
        }
      },
    }])
  }

  const majorData = customData.length > 0 ? Object.keys(customData[0].checkList) : [];
  const hasSubData = customData.length > 0 && clickedMajor.length > 0;
  const subData = hasSubData ? Object.keys(customData[0].checkList[clickedMajor]) : [];
  console.log(customData);
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = customData[0].checkList;
    const majorData = Object.keys(data);
    if (majorData.length === 0) {
      // 대분류가 없을 때
      fail({ title: '등록 실패!', message: '대분류 항목이 존재하지 않습니다.' });
      return;
    } else {
      // 소분류 작성된 항목이 없을 때
      const isEmpty = majorData.find(key => Object.keys(data[key]).length === 0) !== undefined;
      if (isEmpty) {
        fail({ title: '등록 실패!', message: '대분류 항목 중, 작성되지 않은 소분류가 존재합니다.' });
        return;
      }
    }

    if (companyId === 0
      || templateId === 0
      || methodName.length === 0
    ) {
      fail({ title: '등록 실패!', message: '정보를 모두 입력해주세요.' });
      return;
    }
    const prms: any = {
      companyId,
      checkTypeId: templateId,
      name: methodName,
      checkList: JSON.stringify(customData[0].checkList),
      checkListDva: JSON.stringify(initCheckDVA),
    }
    let res;
    if (isAdd) {
      res = await mutateAsync(prms);
    }
    if (!isAdd) {
      prms.checkMethodId = rowData.checkMethodId;
      res = await mutatePutAsync(prms);
    }
    if (res) {
      success({ title: '등록 성공!', message: '요청이 적용되었습니다.' })
      listCustomRefresh();
      onClose();
    } else {
      fail({ title: '등록 실패!', message: '요청이 실패하였습니다.' })
    }
  }

  return (
    <Modal open={open}>
      <Wrap onSubmit={handleSubmit}>
        <HeaderWrap>
          <span>{`점검방법 ${isAdd ? '추가' : '수정'}`}</span>
          <CloseBtn onClick={onClose} />
        </HeaderWrap>

        <InfoWrap>
          <div>
            <span>회사명</span>
            <Select
              placeholder={t('factory.searchForm.companyPlaceholder')}
              onChange={(_, val) => setCompanyId(Number(val))}
              value={companyId}
            >
              {listCompany.map((item: CompanyModel) => (
                <Option key={item.companyId} value={item.companyId}>
                  {item.companyName}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <span>템플릿 타입</span>
            <Select
              placeholder='타입을 입력해주세요.'
              disabled={formType === 'UPDATE'}
              onChange={(_, d: any) => {
                setIsEnforceTemp(isUpdate ? true : false);
                setTemplateId(Number(d));
              }}
              value={templateId}
            >
              {templateData.map((item: any) => {
                return (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                )
              })}
            </Select>
            {formType === 'UPDATE' && <AlarmP>*점검방법 수정 시, 템플릿 타입은 변경할 수 없습니다.</AlarmP>}
          </div>
          <div>
            <span>점검방법명</span>
            <Input
              placeholder='명칭을 입력해주세요.'
              value={methodName}
              onChange={(e) => setMethodName(e.target.value)}
            />
          </div>
        </InfoWrap>

        <DndWrap>
          <div>
            <DndTitle>
              <span>대분류 설정</span>
              <AddBtn onClick={() => setIsAddMajorItem(true)} />
              <RemoveBtn
                $disabled={deleteMajorList.length === 0}
                onClick={handleDeleteMajorList}
              />
            </DndTitle>
            <DndContainer
              type='major'
              data={majorData}
              isAddMajorItem={isAddMajorItem}
              deleteMajorList={deleteMajorList}
              setData={setCustomData}
              setClickedMajor={setClickedMajor}
              setIsAddMajorItem={setIsAddMajorItem}
              setDeleteMajorList={setDeleteMajorList}
            />
          </div>
          <ArrowIconWrap>
            <div>{clickedMajor}</div>
            <ForwardIcon />
          </ArrowIconWrap>
          <div>
            <DndTitle>
              <span>소분류 설정</span>
              <AddBtn onClick={() => setIsAddSubItem(true)} />
              <RemoveBtn
                $disabled={deleteSubList.length === 0}
                onClick={handleDeleteSubList}
              />
            </DndTitle>
            <DndContainer
              type='sub'
              data={subData}
              isAddSubItem={isAddSubItem}
              clickedMajor={clickedMajor}
              deleteSubList={deleteSubList}
              setData={setCustomData}
              setIsAddSubItem={setIsAddSubItem}
              setDeleteSubList={setDeleteSubList}
            />
          </div>
        </DndWrap>
        <ButtonWrap>
          <Button
            variant='solid'
            color='neutral'
            type='button'
            onClick={onClose}
          >
            취소
          </Button>
          <Button
            variant="solid"
            type='submit'
          >
            {`${isAdd ? '등록' : '수정'}`}
          </Button>
        </ButtonWrap>
      </Wrap>

    </Modal>
  );
};

const Wrap = styled.form`
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 900px;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 100px;
  padding: 18px 36px 36px 36px;
  border-radius: 16px;
  z-index: 10000;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    font-size: 19px;
    font-weight: bold;
  }
`;

const InfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;

  > div {
    display: flex;
    align-items: center;
    > span {
      width: 110px;
    }
  }
`;

const DndWrap = styled.div`
  width: 100%;
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  > div:nth-child(1) {
    flex: 40%;
  }
  > div:nth-child(2) {
    flex: 20%;
  }
  > div:nth-child(3) {
    flex: 40%;
  }
`;

const DndTitle = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;

const ArrowIconWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 130px;

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

const AddBtn = styled(AddIcon)`
  cursor: pointer;
`;

const RemoveBtn = styled(RemoveIcon) <{ $disabled: boolean }>`
  cursor: pointer;
  ${({ $disabled }) => $disabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const CloseBtn = styled(ClearIcon)`
  cursor: pointer;
`;

const AlarmP = styled.p`
  color: red;
  font-size: 13.5px;
  padding: 0;
  margin-left: 18px;
`;
export default InspectCraeteForm;