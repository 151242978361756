import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Card, Stack, Typography } from '@mui/joy'
import { FormProvider, useForm } from 'react-hook-form'
import {
  FactoryInDto,
  FactoryModel,
  SettingFactorySearchFormInDto
} from '@pages/settings/factory/type'
import SettingsFactorySearchForm from '@pages/settings/factory/search-form'
import {
  useGetAllFactory,
  useGetListCompany,
  useMutateAddFactory,
  useMutateDeleteFactory
} from '@pages/settings/query'
import { CompanyModel } from '@pages/settings/company/type'
import { String } from '@core/enums/common.enum'
import { BaseTable } from '@core/components/table'
import { MRT_ColumnDef, MRT_TableInstance } from 'material-react-table'
import { factoryValidationSchema, settingFactoryTableColumns } from '@pages/settings/template'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'
import { confirm, deleteConfirm, fail, success } from '@core/components/modal'
import CreateFactoryForm from '@pages/settings/factory/create-form'
import { QueryOptionModel } from '@core/models/query.model'
import { ErrorMessage } from '@core/enums/error.enum'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

/**
 * Setting factory component
 * @constructor
 */
const SettingFactory: FC = () => {
  // Common variables
  const { t } = useTranslation([TranslateEnum.FACTORY])
  const formSearchContext = useForm<SettingFactorySearchFormInDto>({
    defaultValues: {
      companyId: undefined,
      name: String.EMPTY_STRING
    }
  })
  const formAddContext = useForm<FactoryInDto>({
    defaultValues: {
      companyId: undefined,
      name: String.EMPTY_STRING
    },
    resolver: yupResolver(factoryValidationSchema(t))
  })

  const companyId = formSearchContext.watch().companyId

  // Component state
  const [listCompany, setListCompany] = useState<CompanyModel[]>([])
  const [listFactory, setListFactory] = useState<FactoryModel[]>([])
  const [rowSelection, setRowSelection] = useState({})
  const [isLoading, setLoading] = useState<boolean>(true)
  const tableInstanceRef = useRef<MRT_TableInstance<FactoryModel | any>>(null)
  const [isOpenModalAddAndUpdate, setIsOpenModalAddAndUpdate] = useState<boolean>(false)
  const [rowEdited, setRowEdit] = useState<FactoryModel>()
  // Table variables
  const columns = useMemo<MRT_ColumnDef<FactoryModel | any>[]>(
    () => settingFactoryTableColumns(t),
    [t]
  )
  const isDisableDeleteMultipleRows = useMemo(() => {
    const row = rowSelection && Object.keys(rowSelection)
    return row && row.length === 0
  }, [rowSelection])

  // React Query
  const { refetch: getListCompany, isFetching: isLoadingGetListCompany } = useGetListCompany({
    enabled: false,
    onSuccess: (data: CompanyModel[]) => {
      setListCompany(data)
    }
  })

  const { refetch: getListFactory, isFetching: isLoadingGetListFactory } = useGetAllFactory(
    companyId,
    {
      enabled: false,
      onSuccess: (data: FactoryModel[]) => {
        setListFactory(data)
      }
    }
  )

  const { mutate: mutateCreateFactory } = useMutateAddFactory()
  const { mutate: mutateDeleteFactory } = useMutateDeleteFactory()

  /**
   * Handle save change factory form
   */
  const handleSaveChanges = (data: FactoryInDto, isEditMode?: boolean) => {
    confirm({
      onOk: () => {
        mutateCreateFactory(data, {
          onSuccess: () => {
            success({
              onOk: () => setIsOpenModalAddAndUpdate(false)
            })
          },
          onError: () => {
            fail({
              title: '등록실패!'
            })
          }
        })
      }
    })
  }

  /**
   * Handle click close popup add and update factory
   */
  const handleClosePopUpAddAndUpdate = useCallback(() => {
    setIsOpenModalAddAndUpdate(false)
    setRowEdit(undefined)
  }, [rowEdited])

  /**
   * Call delete factory and call get list factory
   */
  const handleMutateDeleteFactory = useCallback(
    (listIds: number[]) => {
      mutateDeleteFactory(listIds, {
        onSuccess: () => {
          success({ message: '삭제성공!', onOk: () => getListFactory() })
        },
        onError: (err) => fail({ message: '삭제실패' })
      })
    },
    [rowSelection]
  )

  /**
   * Handle delete company call back
   * This function show message confirm delete company
   * Use for delete single row, multiple rows
   */
  const handleDeleteCallback = (callback: () => void) => {
    deleteConfirm({
      message: (
        <div>
          <div>선택된 항목 중 설비가 존재하면 정상적으로 삭제가 되지않습니다.</div>
          <Typography fontWeight={500}>{t('factory.modal.delete.confirmDelete')}</Typography>
        </div>
      ),
      onOk: callback
    })
  }

  /**
   * Handle delete factory call back
   */
  const handleDeleteFactory = () => {
    const currentTableInstance = tableInstanceRef?.current
    if (currentTableInstance) {
      handleDeleteCallback(() => {
        const rowSelectOnTable = currentTableInstance.getSelectedRowModel()
        if (rowSelectOnTable && rowSelectOnTable.rows && rowSelectOnTable.rows.length > 0) {
          const rowSelection = rowSelectOnTable?.rows.map((data) => data.original)
          handleMutateDeleteFactory(
            rowSelection.filter((el: FactoryModel) => el.id).map((el) => el.id)
          )
          setRowSelection({})
        }
      })
    }
  }

  /**
   * Initialize effect
   */
  useEffect(() => {
    const prepareData: any = async () => {
      await Promise.all([getListCompany(), getListFactory()])
    }
    prepareData()
  }, [])

  useEffect(() => {
    if (!isOpenModalAddAndUpdate) {
      getListFactory()
    }
  }, [isOpenModalAddAndUpdate])

  /**
   * Effect loading
   */
  useEffect(() => {
    setLoading(isLoadingGetListFactory || isLoadingGetListCompany)
  }, [isLoadingGetListFactory, isLoadingGetListCompany])

  return (
    <Stack spacing={2} className="setting-factory-container">
      <Card>
        <FormProvider {...formSearchContext}>
          <SettingsFactorySearchForm
            onSubmit={() => getListFactory()}
            listCompany={listCompany}
            onCreate={() => setIsOpenModalAddAndUpdate(true)}
            onDelete={handleDeleteFactory}
            disableDeleteAll={isDisableDeleteMultipleRows}
          />
        </FormProvider>
      </Card>
      <BaseTable
        enableRowSelection
        enableSelectAll
        data={listFactory}
        columns={columns}
        state={{ isLoading, rowSelection }}
        onRowSelectionChange={setRowSelection}
        tableInstanceRef={tableInstanceRef}
        enableColumnDragging
        muiTableContainerProps={{
          sx: {
            height: '600px'
          }
        }}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ mt: 1, pl: 1 }} component={'span'}>
            <Typography level="h6" fontWeight={600}>
              {t('factory.title')}
            </Typography>
          </Box>
        )}
      />
      {isOpenModalAddAndUpdate && (
        <CreateFactoryForm
          isOpen={isOpenModalAddAndUpdate}
          onCloseFn={handleClosePopUpAddAndUpdate}
          onSubmit={handleSaveChanges}
          information={rowEdited}
          listCompany={listCompany}
        />
      )}
    </Stack>
  )
}

export default SettingFactory
