import { Borders, Fill, Font, Column, Alignment, Protection } from 'exceljs'
import { MRT_ColumnDef } from 'material-react-table'

export interface ExceljsModel {
  sheetName: string
  defaultRowHeight?: number
  headerBorderConfig: Partial<Borders>
  headerFill: Fill
  headerFont: Partial<Font>
  columns: Array<Partial<ExcelJSColumns>>
  data: any
  fileName: string
}

export interface ExcelJSColumns extends Column {
  type: ColumnType
}

export interface ColumnExportStyle {
  numFmt?: string
  font?: Partial<Font>
  alignment?: Partial<Alignment>
  protection?: Partial<Protection>
  border?: Partial<Borders>
  fill?: Fill
}

export interface TableColumnConfigWithExportExcel extends MRT_ColumnDef<any> {
  style?: ColumnExportStyle
  columns?: TableColumnConfigWithExportExcel[]
}

export enum ColumnType {
  TEXT,
  NUMBER,
  IMAGES,
  ARRAY_IMAGES
}

export enum ColumnStyle {
  TEXT = '@',
  Date = 'yyyy/mm/dd'
}
