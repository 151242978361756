import {
  FileDownloadOutlined,
  FileUploadOutlined,
  SaveOutlined,
  SearchOutlined,
  AddOutlined,
  DeleteOutlined
} from '@mui/icons-material'
import { Tooltip } from '@mui/joy'
import { IconButton } from '@mui/material'
import { FilterDataProps } from '@pages/dashboard/type'
import { MRT_ColumnDef } from 'material-react-table'
import { ActionProps, FilterSubProps } from './type'

export const Actions = ({ onUpload, onExport, onSave, onSearch }: ActionProps) => {
  return (
    <>
      <Tooltip title="조회">
        <IconButton color="primary" onClick={onSearch}>
          <SearchOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="다운로드">
        <IconButton color="primary" onClick={onExport}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
      {/* <Tooltip title="업로드">
        <IconButton color="primary" aria-label="upload icon" component="label">
          <input id="file-upload" type="file" hidden onChange={onUpload} />
          <FileUploadOutlined />
        </IconButton>
      </Tooltip> */}
      <Tooltip title="저장" onClick={onSave}>
        <IconButton color="primary">
          <SaveOutlined />
        </IconButton>
      </Tooltip>
    </>
  )
}

export const ColumnsHeader: MRT_ColumnDef<FilterDataProps>[] = [
  {
    header: '회사명',
    accessorKey: 'company',
    enableEditing: false
  },
  {
    header: '공장명',
    accessorKey: 'factory',
    enableEditing: false
  },
  {
    header: '관리번호',
    accessorKey: 'code',
    enableEditing: false
  },
  {
    header: '설비명',
    accessorKey: 'name',
    minSize: 300,
    enableEditing: false
  },
  {
    header: '마지막 교체일',
    accessorKey: 'lastDate',
    enableResizing: false,
    maxSize: 220,
    enableEditing: false,
    size: 190
  },
  {
    header: '마지막 교체수량',
    accessorKey: 'lastCount',
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableResizing: false,
    enableEditing: false,
    maxSize: 150
  },

  {
    header: '지정 교체주기',
    accessorKey: 'changingInterval',
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableResizing: false,
    enableEditing: false,
    maxSize: 140
  },
  {
    header: '실 교체주기',
    accessorKey: 'changedInterval',
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableResizing: false,
    enableEditing: false,
    maxSize: 130
  },
  {
    header: '차기 교체일',
    accessorKey: 'nextChangeDate',
    enableResizing: false,
    enableEditing: false
  },
  {
    header: '재질',
    accessorKey: 'material',
    enableEditing: false
  },
  {
    header: '총수량',
    accessorKey: 'count',
    enableEditing: false
  },
  {
    header: '직경(Φ)',
    accessorKey: 'standardDiameter',
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableResizing: false,
    maxSize: 100,
    enableEditing: false
  },
  {
    header: '길이(mm)',
    accessorKey: 'standardLength',
    enableColumnDragging: false,
    enableColumnFilter: false,
    enableColumnActions: false,
    enableResizing: false,
    maxSize: 100,
    enableEditing: false
  },
  {
    header: '특이사항',
    accessorKey: 'note',
    enableColumnDragging: false
  }
]

export const TableColumns: {
  [key: string]: string
} = {
  company: '회사명',
  factory: '공장명',
  code: '관리번호',
  name: '설비명',
  lastDate: '마지막 교체일',
  lastCount: '마지막 교체수량',
  changedInterval: '실 교체주기',
  changingInterval: '지정 교체 주기',
  nextChangeDate: '차기 교체일',
  material: '재질',
  count: '총수량',
  standardDiameter: '직경(Φ)',
  standardLength: '길이(mm)',
  note: '비고',
  timesDate: '교체일',
  timesCount: '교체수량',
  timesChamber: '교체챔버'
}

// export const SubColumnHeader: MRT_ColumnDef<FilterSubProps>[] = [
//   {
//     header: '교체일',
//     accessorKey: 'timesDate'
//   },
//   {
//     header: '교체수량',
//     accessorKey: 'timesCount'
//   },
//   {
//     header: '교체챔버',
//     accessorKey: 'timesChamber'
//   }
// ]
