import { DatePicker } from '@core/components/date-picker'
import { TranslateEnum } from '@core/enums/translate.enum'
import { DeleteOutlineOutlined, SaveOutlined } from '@mui/icons-material'
import { Breadcrumbs, Stack, Tooltip, Typography } from '@mui/joy'
import { IconButton } from '@mui/material'
import { InspectPlanProps } from '@pages/inspect/type'
import { TFunction } from 'i18next'
import { MRT_ColumnDef } from 'material-react-table'
import { RepairPlanDataProps } from '../type'

interface PlanCreateTopProps {
  info: InspectPlanProps | undefined
  onDelete?: () => void
  disabled: boolean
}

export const PlanCreateTop = ({ info, onDelete, disabled = false }: PlanCreateTopProps) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <Stack>
          <Typography level="h5" fontWeight={600}>
            수리 계획 추가
          </Typography>
          <Breadcrumbs sx={{ px: 0.3, paddingBottom: 0 }}>
            <Typography level="body1" fontWeight={500}>
              {info?.company}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.factory}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.code}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.name}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.checkDate}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Tooltip title="삭제" sx={{ zIndex: 9999 }}>
            <IconButton color="primary" onClick={onDelete} disabled={disabled}>
              <DeleteOutlineOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="저장" sx={{ zIndex: 9999 }}>
            <IconButton color="primary" form="repair-form" type="submit">
              <SaveOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  )
}

export const ColumnsHeader: MRT_ColumnDef<RepairPlanDataProps>[] = [
  // TODO: 의견 물어보기
  {
    header: '대분류',
    accessorKey: 'category',
    enableEditing: false
  },
  {
    header: '소분류',
    accessorKey: 'subCategory',
    enableEditing: false
  },
  {
    header: '확인결과',
    accessorKey: 'result',
    enableEditing: false
  },
  {
    header: '조치 계획',
    accessorKey: 'actionPlan',
    enableEditing: false
  },
  {
    header: '조치계획일',
    accessorKey: 'actionPlanDate',
    enableEditing: false
  },
  {
    header: '현상',
    accessorKey: 'abnormality',
    enableEditing: false
  },
  {
    header: '사진',
    accessorKey: 'abnormalityPicture',
    enableEditing: false,
    minSize: 200,
    Cell: ({ row }) => {
      const value = row.original.abnormalityPicture
      if (value) {
        return <img height={150} src={value.content} loading="lazy" />
      } else {
        return <div>이미지없음</div>
      }
    }
  },
  // TODO : 사용자 요청시 다시 활성화
  // {
  //   header: '작업내용',
  //   accessorKey: 'actionPlanDetail'
  // },
  {
    header: '담당자',
    accessorKey: 'manager',
    enableEditing: false
  },
  {
    header: '비고',
    accessorKey: 'note',
    enableEditing: false
  }
]

export const TableColumns: {
  [key: string]: string
} = {
  company: '회사명',
  factory: '공장명',
  code: '관리번호',
  name: '설비명',
  checkDate: '점검일자',
  category: '대분류',
  subCategory: '소분류',
  result: '확인결과',
  actionPlanDate: '계획일정',
  actionPlanStatus: '현상',
  actionPlanPhoto: '사진',
  codeNum: 'NO',
  codeContent: '작업명',
  codeCount: '수량',
  manager: '담당자',
  note: '비고'
}
