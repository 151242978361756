import React, { FC, useEffect, useState } from 'react'
import {
  RepairPlanCreateFormField,
  RepairPlanCreateModalProps
} from '@pages/repair/plan-list/plan-create-modal/type'
import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  Sheet,
  Stack,
  Typography
} from '@mui/joy'
import { DatePicker } from '@core/components/date-picker'
import { FormProvider, useForm } from 'react-hook-form'
import { RepairPlanImagesModel, RepairPlanModel } from '@pages/repair/type'
import {
  checkFileImagesIsUrlOrBase64,
  convertFileToBase64,
  convertUrlToFile
} from '@core/helpers/file.helper'
import { Fade } from '@mui/material'
import { ConfirmSaveChange } from '@core/components/modal'
import { FileContentType } from '@core/enums/file.enum'
import FileUpload from '@core/components/file-upload'

/**
 * Plan create modal component
 * @param isOpen
 * @param onCloseFn
 * @param onSubmitFn
 * @param info
 * @param rowAddSelection
 * @constructor
 */
const RepairPlanCreateModal: FC<RepairPlanCreateModalProps> = ({
  isOpen = false,
  onCloseFn,
  onSubmitFn,
  info,
  rowAddSelection
}) => {
  const method = useForm()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    getValues,
    formState: { isSubmitted, dirtyFields }
  } = method
  const [abnormalityPicture, setAbnormalityPicture] = useState<File[]>([])
  const [actionCompletionPictures, setActionCompletionPictures] = useState<File[]>()
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isTriggerCloseModal, setTriggerCloseModal] = useState<boolean>(false)

  /**
   * Clear form
   */
  const clearForm = () => {
    setAbnormalityPicture([])
    setActionCompletionPictures([])
    reset({})
  }

  /**
   * Handle click submit create or update repair plan
   * @param data
   */
  const handleSubmitCreateRepairPlan = async (data: RepairPlanModel | any) => {
    const inDto: RepairPlanModel = { ...data }

    if (abnormalityPicture && abnormalityPicture[0]) {
      if (abnormalityPicture[0] instanceof File) {
        inDto.abnormalityPicture = {
          name: abnormalityPicture[0]?.name,
          content: await convertFileToBase64(abnormalityPicture[0])
        }
      }
    }
    if (actionCompletionPictures && actionCompletionPictures.length > 0) {
      const listImgBase64: RepairPlanImagesModel[] = []
      for (let i = 0; i < actionCompletionPictures.length; i++) {
        if (
          actionCompletionPictures &&
          actionCompletionPictures[i] &&
          actionCompletionPictures[i] instanceof File
        ) {
          listImgBase64.push({
            name: actionCompletionPictures[i]?.name,
            content: await convertFileToBase64(actionCompletionPictures[i])
          })
        } else {
          listImgBase64.push({
            name: actionCompletionPictures[i]?.name,
            content: actionCompletionPictures[i]
          })
        }
      }
      inDto.actionCompletionPictures = listImgBase64
    } else {
      inDto.actionCompletionPictures = []
    }
    console.log(inDto)
    onSubmitFn && onSubmitFn(inDto, isEditMode)
    clearForm()
  }

  /**
   * On confirm save change
   */
  const onConfirmSaveChange = async () => {
    const inDto = getValues()
    await handleSubmitCreateRepairPlan(inDto)
    setTriggerCloseModal(false)
  }

  /**
   * Handle close modal
   */
  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0 && !isSubmitted) {
      setTriggerCloseModal(true)
    } else {
      setTriggerCloseModal(false)
      onCloseFn()
      clearForm()
      setEditMode(false)
    }
  }

  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      if (name === RepairPlanCreateFormField.ABNORMALITY_PICTURE) {
        setAbnormalityPicture(value.abnormalityPicture)
      } else if (name === RepairPlanCreateFormField.ACTION_COMPLETION_PICTURES) {
        setActionCompletionPictures(value?.actionCompletionPictures)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  /**
   * Effect check have information and change mode of form
   */
  useEffect(() => {
    const rowBindToForm = info || rowAddSelection
    if (rowBindToForm) {
      reset(rowBindToForm)
      const { actionCompletionPictures: listActionCompletePictures, abnormalityPicture } =
        rowBindToForm
      if (abnormalityPicture) {
        const { name, content: url } = abnormalityPicture
        if (checkFileImagesIsUrlOrBase64(name) === FileContentType.URL) {
          convertUrlToFile(url, name).then((file) => setAbnormalityPicture([file]))
        } else {
          convertUrlToFile(url, name).then((file) => setAbnormalityPicture([file]))
        }
      }

      if (listActionCompletePictures && listActionCompletePictures.length > 0) {
        const listPromiseImage: Promise<File>[] = []
        listActionCompletePictures.forEach(async (el) => {
          const { name, content: url } = el
          if (checkFileImagesIsUrlOrBase64(url) === FileContentType.URL) {
            listPromiseImage.push(convertUrlToFile(url, name))
          } else {
            listPromiseImage.push(convertUrlToFile(url, name))
          }
        })
        Promise.all(listPromiseImage).then((listImg) => {
          setActionCompletionPictures(listImg)
        })
      }
    }
    if (info) {
      setEditMode(true)
    }
  }, [info, rowAddSelection])

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={handleClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={isOpen}>
          <Sheet
            variant="outlined"
            sx={{
              width: 1500,
              borderRadius: 3,
              p: 3,
              boxShadow: 'lg'
            }}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}>
              {isEditMode ? '수리 계획 수정' : '수리 계획 등록'}
            </Typography>
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body'
              }}
            />
            <Stack spacing={2} sx={{ maxHeight: 850, overflowY: 'scroll', overflowX: 'hidden' }}>
              <FormProvider {...method}>
                <form onSubmit={handleSubmit(handleSubmitCreateRepairPlan)}>
                  <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 700 }}>
                            회사명
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 회사..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.COMPANY)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="factory" htmlFor="factory" sx={{ fontWeight: 700 }}>
                            공장명
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 공장..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.FACTORY)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="outlet" htmlFor="outlet" sx={{ fontWeight: 700 }}>
                            관리번호
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 콘센트..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.CODE)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="exhaustion" htmlFor="exhaustion" sx={{ fontWeight: 700 }}>
                            설비명
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 소진..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.NAME)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>
                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="inspectionDate"
                            htmlFor="inspectionDate"
                            sx={{ fontWeight: 700 }}>
                            점검일자
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <DatePicker
                            InputProps={{
                              ...register(RepairPlanCreateFormField.CHECK_DATE),
                              disabled: true
                            }}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="category" htmlFor="category" sx={{ fontWeight: 700 }}>
                            대분류
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 범주..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.CATEGORY)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="subDivision"
                            htmlFor="subDivision"
                            sx={{ fontWeight: 700 }}>
                            소분류
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 세분화..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.SUB_CATEGORY)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="visualInspection"
                            htmlFor="visualInspection"
                            sx={{ fontWeight: 700 }}>
                            육안점검
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 육안 검사..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.VISUAL_REPORT)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="schedulePlan"
                            htmlFor="schedulePlan"
                            sx={{ fontWeight: 700 }}>
                            일정
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <DatePicker
                            InputProps={{
                              ...register(RepairPlanCreateFormField.ACTION_PLAN_DATE),
                              disabled: true
                            }}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="phenomenon" htmlFor="phenomenon" sx={{ fontWeight: 700 }}>
                            현상
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 현상..."
                            variant="outlined"
                            disabled
                            {...register(RepairPlanCreateFormField.ABNORMALITY)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={12}>
                      <FormControl orientation="horizontal">
                        <Grid xs={2} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="abnormalityPicture"
                            htmlFor="abnormalityPicture"
                            sx={{ fontWeight: 700 }}>
                            사진
                          </FormLabel>
                        </Grid>

                        <Grid xs={11} sx={{ ml: -6.5 }}>
                          <FileUpload
                            limit={1}
                            multiple={false}
                            disable={true}
                            files={abnormalityPicture}
                            onFileDelete={(files: File[]) => setAbnormalityPicture(files)}
                            name={RepairPlanCreateFormField.ABNORMALITY_PICTURE}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="actionCompletionDate"
                            htmlFor="actionCompletionDate"
                            sx={{ fontWeight: 700 }}>
                            완료일자
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <DatePicker
                            InputProps={{
                              ...register(RepairPlanCreateFormField.ACTION_COMPLETION_DATE)
                            }}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel id="action" htmlFor="action" sx={{ fontWeight: 700 }}>
                            조치 내용
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 조치 내용..."
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.ACTION_COMPLETION_DETAIL)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={12}>
                      <FormControl orientation="horizontal">
                        <Grid xs={2} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="actionCompletionPictures"
                            htmlFor="actionCompletionPictures"
                            sx={{ fontWeight: 700 }}>
                            조치 결과 사진
                          </FormLabel>
                        </Grid>

                        <Grid xs={11} sx={{ ml: -6.5 }}>
                          <FileUpload
                            limit={2}
                            multiple
                            files={actionCompletionPictures}
                            name={RepairPlanCreateFormField.ACTION_COMPLETION_PICTURES}
                            onFileDelete={(files: File[]) => setActionCompletionPictures(files)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="materialName"
                            htmlFor="materialName"
                            sx={{ fontWeight: 700 }}>
                            자재명
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 자재명..."
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MATERIAL_NAME)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="materialSpecification"
                            htmlFor="materialSpecification"
                            sx={{ fontWeight: 700 }}>
                            규격 및 재질
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 규격 및 재질..."
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MATERIAL_SIZE)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="materialQuantity"
                            htmlFor="materialQuantity"
                            sx={{ fontWeight: 700 }}>
                            수량
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 수량..."
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MATERIAL_COUNT)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={6}>
                      <FormControl orientation="horizontal">
                        <Grid xs={3} sx={{ display: 'flex' }}>
                          <FormLabel
                            id="personInCharge"
                            htmlFor="personInCharge"
                            sx={{ fontWeight: 700 }}>
                            담당자(정비? 조업?)
                          </FormLabel>
                        </Grid>

                        <Grid xs={9}>
                          <Input
                            placeholder="입력 담당자(정비? 조업?)..."
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.MANAGER)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={12}>
                      <FormControl orientation="horizontal">
                        <Grid xs={2} sx={{ display: 'flex' }}>
                          <FormLabel id="note" htmlFor="note" sx={{ fontWeight: 700 }}>
                            비고
                          </FormLabel>
                        </Grid>

                        <Grid xs={12} sx={{ ml: -4.2 }}>
                          <Input
                            placeholder="입력 비고..."
                            variant="outlined"
                            {...register(RepairPlanCreateFormField.NOTE)}
                          />
                        </Grid>
                      </FormControl>
                    </Grid>

                    <Grid xs={12}>
                      <Button sx={{ width: 106 }} color="warning" onClick={handleClose}>
                        취소
                      </Button>
                      <Button sx={{ ml: 2, width: 106 }} color="primary" type="submit">
                        확인
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </FormProvider>
            </Stack>
          </Sheet>
        </Fade>
      </Modal>
      <ConfirmSaveChange
        onClose={() => setTriggerCloseModal(false)}
        subscribe={isTriggerCloseModal}
        onAccept={async () => await onConfirmSaveChange()}
      />
    </>
  )
}

export default RepairPlanCreateModal
