import { FC, useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  SettingAccountInformationFormField,
  SettingAccountInformationFormProps,
  SettingAccountInformationInDto
} from '@pages/settings/account/type'
import { String } from '@core/enums/common.enum'
import {
  FormControl,
  FormLabel,
  Grid,
  Input,
  Option,
  Select,
  Button,
  FormHelperText
} from '@mui/joy'
import { getFieldPropsWithForm } from '@core/helpers/form.helper'
import {
  Accessibility,
  Apartment,
  SaveOutlined,
  Email,
  PersonPin,
  RestartAltOutlined
} from '@mui/icons-material'
import PhoneInput from 'react-phone-input-2'
import { yupResolver } from '@hookform/resolvers/yup'
import { settingAccountValidationSchema } from '@pages/settings/template'

/**
 * Account information form
 * @param onSubmit
 * @param userInformation
 * @param isLoading
 * @constructor
 */
const AccountInformationForm: FC<SettingAccountInformationFormProps> = ({
  onSubmit,
  userInformation,
  isLoading
}) => {
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: { errors }
  } = useForm<SettingAccountInformationInDto>({
    defaultValues: {
      fullName: String.EMPTY_STRING,
      companyId: 0,
      companyName: String.EMPTY_STRING,
      email: String.EMPTY_STRING,
      phone: String.EMPTY_STRING,
      roles: String.EMPTY_STRING
    },
    resolver: yupResolver(settingAccountValidationSchema)
  })

  const { companyName } = useMemo(() => userInformation, [userInformation])

  /**
   * Reset form value
   */
  const resetForm = useCallback(() => reset(userInformation), [userInformation])

  /**
   * Effect check have user information and bind to form
   */
  useEffect(() => {
    if (userInformation && Object.keys(userInformation).length > 0) {
      reset(userInformation)
    }
  }, [userInformation])
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} className="setting-account-information" sx={{ mt: 2 }}>
        <Grid xs={12}>
          <FormControl orientation={'vertical'}>
            <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
              업체명
            </FormLabel>
            <Controller
              name={SettingAccountInformationFormField.COMPANY_NAME}
              control={control}
              render={({ field: { onChange, value, ...field } }) => (
                <Select
                  {...getFieldPropsWithForm(field, value)}
                  placeholder="회사명 선택해주세요."
                  onChange={(_, value) => onChange(value)}
                  disabled
                  startDecorator={<Apartment />}
                  defaultValue="0"
                  slotProps={{
                    button: {
                      id: 'company'
                    }
                  }}>
                  <Option value="0">{companyName}</Option>
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl orientation={'vertical'}>
            <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
              이메일
            </FormLabel>

            <Input
              type="email"
              startDecorator={<Email />}
              {...register(SettingAccountInformationFormField.EMAIL)}
            />
            {errors && errors.email && (
              <FormHelperText component={'span'} className={'text-error'}>
                {errors.email.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl orientation={'vertical'}>
            <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
              성명
            </FormLabel>

            <Input
              type="text"
              startDecorator={<PersonPin />}
              disabled
              {...register(SettingAccountInformationFormField.FULL_NAME)}
            />
            {errors && errors.fullName && (
              <FormHelperText component={'span'} className={'text-error'}>
                {errors.fullName.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl orientation={'vertical'}>
            <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
              전화 번호
            </FormLabel>

            <Controller
              control={control}
              name={SettingAccountInformationFormField.PHONE}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <PhoneInput
                  {...field}
                  country={'kr'}
                  inputStyle={{
                    width: '100%',
                    height: 40,
                    borderRadius: 8,
                    borderColor: '#D8D8DF',
                    fontSize: '1rem'
                  }}
                  disableCountryCode
                />
              )}
            />

            {errors && errors.phone && (
              <FormHelperText component={'span'} className={'text-error'}>
                {errors.phone.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl orientation={'vertical'}>
            <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
              역할
            </FormLabel>

            <Input
              type="text"
              startDecorator={<Accessibility />}
              disabled
              {...register(SettingAccountInformationFormField.ROLE)}
            />
          </FormControl>
        </Grid>

        <Grid xs={12} sx={{ mb: 2 }} display="flex" justifyContent="flex-end">
          <Button
            color="warning"
            loadingPosition="end"
            onClick={resetForm}
            endDecorator={<RestartAltOutlined />}
            sx={{ mr: 2 }}
            variant="solid">
            초기화
          </Button>
          <Button
            loading={isLoading}
            type="submit"
            loadingPosition="end"
            endDecorator={<SaveOutlined />}
            variant="solid">
            저장
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default AccountInformationForm
