import { apis } from '@core/configs/api'
import { request } from '@core/services/base.service'
import { InspectPlanProps } from '../type'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'

export const getInspectPlan = async (info: InspectPlanProps | undefined) => {
  if (info) {
    const { id } = info
    const queryString = `${apis.CHECKPLAN.path}?id=${id}`

    return request.get(queryString)
  }
}

export const useChartData = (prms: {
  checkPlanId: number;
  startDate: string;
  endDate: string;
}) => {
  const { checkPlanId, startDate, endDate } = prms;
  return useQuery([QueryKey.INSPECTPLAN, 'chartData'], async () => {
    return request.get(`/checkplan/chartData?checkPlanId=${checkPlanId}&startDate=${startDate}&endDate=${endDate}`);
  })
}