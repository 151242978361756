import { BaseTable } from '@core/components/table'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Box, Chip, Typography } from '@mui/joy'
import { InspectPlanProps } from '@pages/inspect/type'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface InsepctListProps {
  data: InspectPlanProps[]
}

export const InspectList = ({ data }: InsepctListProps) => {
  const { t } = useTranslation([TranslateEnum.COMMON])

  const columns = useMemo<MRT_ColumnDef<InspectPlanProps>[]>(
    () => [
      {
        header: '회사명',
        accessorKey: 'company'
      },
      {
        header: '공장명',
        accessorKey: 'factory'
      },
      {
        header: '관리번호',
        accessorKey: 'code'
      },
      {
        header: '설비명',
        accessorKey: 'name',
        minSize: 200
      },
      {
        header: '점검방법',
        accessorKey: 'checkType'
      },
      {
        header: '특이사항',
        accessorKey: 'etc',
        minSize: 200
      }
    ],
    []
  )
  return (
    <BaseTable
      columns={columns}
      data={data}
      renderTopToolbarCustomActions={() => (
        <Box sx={{ pt: 1, pl: 1 }}>
          <Typography level="h5" fontWeight={700}>
            {t('dashboard.today_inspect_list')}
          </Typography>
        </Box>
      )}
    />
  )
}
