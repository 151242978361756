import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TextField, TextFieldProps } from '@mui/material'
import { forwardRef } from 'react'

export const Th = styled.th`
  border: 1px solid black;
  text-align: center !important;
  vertical-align: middle !important;
  background-color: #f6f6f6 !important;
`

const commonStyleCell = () => css`
  width: 0.5% !important;
  border-top: none !important;
  border-bottom: none !important;
`

export const EmptyTh = styled.th`
  ${commonStyleCell()}
`

export const EmptyTd = styled.td`
  ${commonStyleCell()}
`

export const Td = styled.td<{ last?: boolean }>`
  border: 1px solid lightgray;
  text-align: center;
`

export const CellInput = forwardRef((props: TextFieldProps, ref: any) => (
  <TextField variant="standard" style={{ width: '100%' }} {...props} ref={ref} />
))
