import { ContentMenuDataProps } from '@core/components/menu/type'
import { apis } from '@core/configs/api'
import { request } from '@core/services/base.service'
import { UseFormGetValues } from 'react-hook-form'
import dayjs from 'dayjs'
import { DateFormat } from '@core/enums/date.enum'
import { AuthorizationModel } from '@core/models/authorization.model'
import { FilterDataProps } from '@pages/dashboard/type'
import { makeExcelDownload } from '@pages/service/miscFunc'

const path = apis.FILTER.path

export const getFilterHistory = async (
  loginInfo: AuthorizationModel,
  info: ContentMenuDataProps,
  getValues: UseFormGetValues<{
    startDate: string
    endDate: string
    replaceStartDate: string
    replaceEndDate: string
  }>
) => {
  const startDate = dayjs(getValues().startDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
  const endDate = dayjs(getValues().endDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)

  const replaceStartDate = dayjs(getValues().replaceStartDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
  const replaceEndDate = dayjs(getValues().replaceEndDate).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
  const params: any = {}

  // 에드민일때
  if ((info && info.company) || (loginInfo && !loginInfo.isAdmin)) {
    params.companyId = loginInfo.isAdmin ? info.company : loginInfo.companyId
  }

  if (info && info.factory) {
    params.factoryId = info.factory
    delete params.companyId
  }

  if (info && info.equipment) {
    params.equipmentId = info.equipment
    delete params.companyId
    delete params.factoryId
  }

  // 날짜 붙이기
  if (info && info.checked) {
    params.nextReplaceStartDate = startDate
    params.nextReplaceEndDate = endDate
  }

  if (info && info.filterChecked) {
    params.replaceStartDate = replaceStartDate
    params.replaceEndDate = replaceEndDate
  }

  return request.get(path, params)
}
export const postFilterHistory = async (data: FilterDataProps[]) => request.post(path, data) as any
export const deleteFilterHistory = async (data: number[]) => request.delete(path, data) as any
export const postFilterImg = async (data: FormData) => request.post(`filterbag/image-uploads`, data);

export const postExcelDownload = async (prms: any) => {
  try {
    let cvtPrms = '';
    const keys = Object.keys(prms);
    keys.forEach(el => {
      if (prms[el]) {
        cvtPrms += cvtPrms.length === 0 ? `?${el}=${prms[el]}` : `&${el}=${prms[el]}`;
      }
    })

    const res: any = await request.post(`/filterbag/downloads${cvtPrms}`, {}, { responseType: 'blob' });
    const pageName = '필터백 관리';
    makeExcelDownload(res, pageName);
  } catch (e: any) {
    fail({ title: "다운로드 실패!", message: '요청이 실패했습니다.' });
  }
};

export const postImgExcelDownload = async (prms: any) => {
  try {
    let cvtPrms = '';
    const keys = Object.keys(prms);
    keys.forEach(el => {
      if (prms[el]) {
        cvtPrms += cvtPrms.length === 0 ? `?${el}=${prms[el]}` : `&${el}=${prms[el]}`;
      }
    })

    const res: any = await request.post(`/filterbag/image-downloads${cvtPrms}`, {}, { responseType: 'blob' });
    const pageName = '필터백 교체 작업 이미지';
    makeExcelDownload(res, pageName);
  } catch (e: any) {
    fail({ title: "다운로드 실패!", message: '요청이 실패했습니다.' });
  }
};

export const postExcelUpload = async (formData: FormData) => {
  try {
    const data = await request.post(`/checkplan/uploads`, formData, {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    }) as any;
    return data;
  } catch (e: any) {
    fail({ title: "업로드 실패!", message: '요청이 실패했습니다.' });
  }
}
