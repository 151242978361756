import { FC, useCallback } from 'react'
import { Button, FormControl, FormHelperText, FormLabel, Grid, Input } from '@mui/joy'
import { useForm } from 'react-hook-form'
import {
  ChangePasswordFormField,
  ChangePasswordInDto,
  ChangePasswordProps
} from '@pages/settings/account/type'
import { String } from '@core/enums/common.enum'
import {
  RestartAltOutlined,
  SaveOutlined,
  LockClock,
  LockOpen,
  Password
} from '@mui/icons-material'
import { yupResolver } from '@hookform/resolvers/yup'
import { changePasswordValidationSchema } from '@pages/settings/template'

/**
 * Change password component
 * @param onSubmit
 * @param isLoading
 * @constructor
 */
const ChangePassword: FC<ChangePasswordProps> = ({ onSubmit, isLoading }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<ChangePasswordInDto>({
    defaultValues: {
      password: String.EMPTY_STRING,
      newPassword: String.EMPTY_STRING,
      confirmPassword: String.EMPTY_STRING
    },
    resolver: yupResolver(changePasswordValidationSchema())
  })

  /**
   * Handle click reset form
   */
  const resetForm = useCallback(() => {
    reset()
  }, [])

  return (
    <Grid container spacing={2} className="change-password-container">
      <Grid xs={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} className="change-password-form">
            <Grid xs={12}>
              <FormControl orientation={'vertical'}>
                <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
                  현재 비밀번호
                </FormLabel>
                <Input
                  type="password"
                  startDecorator={<Password />}
                  {...register(ChangePasswordFormField.CURRENT_PASSWORD)}
                />
                {errors && errors.password && (
                  <FormHelperText className={'text-error'} component={'span'}>
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid xs={12}>
              <FormControl orientation={'vertical'}>
                <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
                  새 비밀번호
                </FormLabel>
                <Input
                  type="password"
                  startDecorator={<LockOpen />}
                  {...register(ChangePasswordFormField.NEW_PASSWORD)}
                />
                {errors && errors.newPassword && (
                  <FormHelperText className={'text-error'} component={'span'}>
                    {errors.newPassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid xs={12}>
              <FormControl orientation={'vertical'}>
                <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
                  비밀번호 확인
                </FormLabel>
                <Input
                  type="password"
                  startDecorator={<LockClock />}
                  {...register(ChangePasswordFormField.CONFIRM_PASSWORD)}
                />
                {errors && errors.confirmPassword && (
                  <FormHelperText className={'text-error'} component={'span'}>
                    {errors.confirmPassword.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid xs={12} sx={{ mb: 2 }} display="flex" justifyContent="flex-end">
              <Button
                color="warning"
                onClick={resetForm}
                loadingPosition="end"
                endDecorator={<RestartAltOutlined />}
                sx={{ mr: 2 }}
                variant="solid">
                초기화
              </Button>
              <Button
                loading={isLoading}
                type="submit"
                loadingPosition="end"
                endDecorator={<SaveOutlined />}
                variant="solid">
                저장
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default ChangePassword
