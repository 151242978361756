import { atom } from 'recoil'
import { CheckTypeProps } from './type'

export const checkTypeState = atom<CheckTypeProps>({
  key: 'checkTypeState',
  default: {}
})

export const planEditState = atom({
  key: 'planEditState',
  default: false
})
