import { companyInfoState } from '@core/components/menu/recoil'
import { atom, selector } from 'recoil'
import { SelectOptionType } from './type'

export const alertOpenState = atom({
  key: 'alertOpenState',
  default: false
})

export const companyListSelector = selector({
  key: 'companyListSelector',
  get: ({ get }) => {
    // company값을 파라미터로 받아서 컴퍼니 리스트를 리턴해준다.
    const allInfo = get(companyInfoState)
    const companyList: SelectOptionType[] =
      allInfo.map((item) => ({
        id: item.companyId,
        name: item.companyName
      })) ?? []

    return companyList
  }
})

export const factoryListState = atom<{ [keys: string]: SelectOptionType[] }>({
  key: 'factoryListState',
  default: {}
})

export const isSavedState = atom({
  key: 'isSavedState',
  default: false
})
