import { Stack as MuiStack, Select as MuiSelect } from '@mui/joy'

export const Stack = (props: any) => (
  <MuiStack
    direction="row"
    justifyContent="center"
    alignItems="center"
    spacing={1}
    sx={{
      li: {
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }
    }}
    {...props}
  />
)

export const Select = (props: any) => {
  return (
    <MuiSelect
      sx={{
        width: 200,
        button: {
          id: 'select-company-button',
          'aria-labelledby': 'select-company-label select-company-button',
          overflow: 'hidden'
        },
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      }}
      {...props}
      />
  )
}
