import { RouterModel } from '@core/components/navigator/type'
import PrimaryLayout from '@theme/layouts/primary'
import Dashboard from '@pages/dashboard'
import AuthLayout from '@theme/layouts/auth'
import Login from '@pages/login'
import ProtectedRoute from '@core/components/protected-route'
import { InspectPlanManagement, InspectResult } from '@pages/inspect'
import { EquipmentManage } from '@pages/equipment'
import { FilterBagHistory } from '@pages/filter'
import RepairPlanManagement from '@pages/repair/plan-list'
import { AuthRouterLink, OptionLink, RouterLink, SettingRouterLink } from '@core/enums/router.enum'
import SettingsLayout from '@pages/settings'
import AccountSetting from '@pages/settings/account'
import SettingCompany from '@pages/settings/company'
import SettingFactory from '@pages/settings/factory'
import NotFound from '@pages/error/not-found'
import { ServiceSync } from '@pages/service'
import MeasurementChart from '@pages/inspect/plan-method/measurement-chart/MeasurementChart'
import InspectCustom from '@pages/settings/inspect-custom/InspectCustom'

/**
 * Router configs component
 */
const routerConfigs = (): RouterModel[] => {
  return [
    {
      element: (
        <ProtectedRoute>
          <PrimaryLayout />
        </ProtectedRoute>
      ),
      path: RouterLink.BASE_PATH,
      children: [
        {
          path: RouterLink.HOME,
          element: <Dashboard />
        },
        {
          path: RouterLink.EQUIPMENT_MANAGE,
          element: <EquipmentManage />
        },
        {
          path: RouterLink.INSPECT_PLAN,
          element: <InspectPlanManagement />,
        },
        {
          path: RouterLink.INSPECT_RESULT,
          element: <InspectResult />
        },
        {
          path: RouterLink.FILTER_MANAGE,
          element: <FilterBagHistory />
        },
        {
          path: RouterLink.MANAGE_REPAIR_PLAN,
          element: <RepairPlanManagement />
        },
        {
          path: SettingRouterLink.BASE_PATH,
          element: <SettingsLayout />,
          children: [
            {
              path: SettingRouterLink.ACCOUNT,
              element: <AccountSetting />
            },
            {
              path: SettingRouterLink.COMPANY,
              element: <SettingCompany />
            },
            {
              path: SettingRouterLink.FACTORY,
              element: <SettingFactory />
            },
            {
              path: SettingRouterLink.INSPECT_CUSTOM,
              element: <InspectCustom />
            }
          ]
        },
        {
          path: RouterLink.SERVICE_SYNC,
          element: <ServiceSync />
        }
      ]
    },
    {
      element: <AuthLayout />,
      path: AuthRouterLink.BASE_PATH,
      children: [
        {
          path: AuthRouterLink.LOGIN,
          element: <Login />
        }
      ]
    },
    {
      path: OptionLink.BASE_PATH,
      children: [
        {
          path: OptionLink.MEASUREMENT_CHART,
          element: <MeasurementChart />
        },
      ]
    },
    {
      path: '*',
      element: <NotFound />,
      children: []
    }
  ]
}
export { routerConfigs }
