import { Stack, Tooltip, Typography } from '@mui/joy'
import { PrintOutlined, SaveOutlined } from '@mui/icons-material'
import React from 'react'
import ReactToPrint from 'react-to-print'
import { DetailTopProps } from '../type'
import { IconButton } from '@mui/material'

export const DetailTop = ({ printRef }: DetailTopProps) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography level="h5"> 상세조회</Typography>
        <Stack direction="row" spacing={1}>
          <ReactToPrint
            trigger={() => (
              <Tooltip title="인쇄" sx={{ zIndex: 9999 }}>
                <IconButton color="primary">
                  <PrintOutlined sx={{ fontSize: 30 }} />
                </IconButton>
              </Tooltip>
            )}
            content={() => printRef?.current ?? null}
          />
          <Tooltip title="저장" sx={{ zIndex: 9999 }}>
            <IconButton form="detail-save" color="primary" type="submit">
              <SaveOutlined sx={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  )
}
