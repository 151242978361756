export enum TranslateEnum {
  LOGIN = 'login',
  COMMON = 'common',
  AUTH = 'auth',
  PLAN = 'plan',
  COMPANY = 'company',
  FACTORY = 'factory',
  ERROR = 'error'
}

export enum TranslateLanguageEnum {
  KOREA = 'kr',
  KOREA_DATE_LOCALIZATION = 'ko',
  ENGLISH = 'en'
}
