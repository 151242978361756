export enum CardUnitEnum {
  KG = 'kg/cm²',
  A = 'A',
  KW = 'kW',
  M3 = 'm³/min',
  EA = 'EA',
  DE = 'DE',
  P = 'P',
  TEMP = '℃',
  M2 = 'm²',
  V = 'V',
  MMAQ = 'mmAq',
  L = 'L',
  SET = 'SET'
}
