import { Stack, Textarea, Typography, Box } from '@mui/joy'
import { Skeleton, TextField } from '@mui/material'
import { Header, Input, InputNumber, MainTh, SubTh, Th } from '../../styles'
import '../styles.scss'
import { ClearOutlined } from '@mui/icons-material'
import { CardUnitEnum } from '@core/enums/card.enum'
import {
  DamperBRG,
  DamperStandard,
  DrainBrg,
  DrainPower,
  DrainStandard,
  EtcStandard,
  Header as ContentHeader,
  Picture as ContentPicture
} from '../common'
import { CommonBaseProps } from '../type'

export const BagFilter = ({ formControl, isLoading }: CommonBaseProps) => {
  return (
    <Stack spacing={2}>
      <ContentHeader type formControl={formControl} />
      <ContentPicture
        imageObj={
          formControl.getValues().images ?? {
            picture: '',
            diagram: ''
          }
        }
        setValue={formControl.setValue}
      />
      <Stack spacing={1}>
        {isLoading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="rounded" height={200} />
          </>
        ) : (
          <>
            <Typography level="body1" fontWeight={600}>
              2.설비 사양 & 규격
            </Typography>
            <table className="tg">
              <colgroup>
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
                <col style={{ width: '10%' }} />
              </colgroup>
              <thead>
                <tr>
                  <Header>구 분</Header>
                  <Header colSpan={3}>사 양 &amp; 규 격</Header>
                  <Header>구 분</Header>
                  <Header colSpan={4}>사 양 &amp; 규 격</Header>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <MainTh rowSpan={11}>FAN</MainTh>
                  <SubTh>용량</SubTh>
                  <td colSpan={2}>
                    <Input
                      endAdornment={<span>m³/min</span>}
                      {...formControl.register('specification.fan.size')}
                    />
                  </td>
                  <MainTh rowSpan={20}>부속기기</MainTh>
                  <SubTh colSpan={2} rowSpan={5}>
                    FILTER BAG
                  </SubTh>
                  <Th>규격</Th>
                  <td>
                    <Stack flexDirection="row">
                      <InputNumber
                        startAdornment={<span>Φ</span>}
                        {...formControl.register(
                          'specification.accessories.filterBag.standard.width'
                        )}
                      />
                      <Box display={'flex'} justifyContent="center" alignItems={'center'}>
                        <ClearOutlined style={{ fontSize: '10px' }} />
                      </Box>
                      <InputNumber
                        endAdornment={<span>L</span>}
                        {...formControl.register(
                          'specification.accessories.filterBag.standard.height'
                        )}
                      />
                    </Stack>
                  </td>
                </tr>
                <tr>
                  <SubTh>형식</SubTh>
                  <td colSpan={2}>
                    <Input {...formControl.register('specification.fan.type')} />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>EA</span>}
                      {...formControl.register('specification.accessories.filterBag.count')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>R.P.M</SubTh>
                  <td colSpan={2}>
                    <InputNumber {...formControl.register('specification.fan.rpm')} />
                  </td>
                  <Th rowSpan={2}>재질</Th>
                  <td rowSpan={2}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      variant="outlined"
                      {...formControl.register('specification.accessories.filterBag.material')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>정압</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>mmAq</span>}
                      {...formControl.register('specification.fan.staticPressure')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>송풍방식</SubTh>
                  <td colSpan={2}>
                    <Input {...formControl.register('specification.fan.blowingMethod')} />
                  </td>
                  <Th>기타</Th>
                  <td>
                    <Input {...formControl.register('specification.accessories.filterBag.note')} />
                  </td>
                </tr>
                <tr>
                  <SubTh rowSpan={2}>BRG NO.</SubTh>
                  <Th>부하</Th>
                  <td>
                    <InputNumber {...formControl.register('specification.fan.brgNo.load')} />
                  </td>
                  <SubTh rowSpan={9}>PULSING SYSTEN</SubTh>
                  <Th rowSpan={3}>DIAPHRAGM V/V</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register(
                        'specification.accessories.pulsingSystem.diaphragm.standard'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <Th>반부하</Th>
                  <td>
                    <InputNumber {...formControl.register('specification.fan.brgNo.unload')} />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>EA</span>}
                      {...formControl.register(
                        'specification.accessories.pulsingSystem.diaphragm.count'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>BRG 형식</SubTh>
                  <td colSpan={2}>
                    <Input {...formControl.register('specification.fan.brgType')} />
                  </td>
                  <Th>MAKER</Th>
                  <td>
                    <Input
                      {...formControl.register(
                        'specification.accessories.pulsingSystem.diaphragm.manufacturer'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>냉각방식</SubTh>
                  <td colSpan={2}>
                    <Input {...formControl.register('specification.fan.coolingMethod')} />
                  </td>
                  <Th rowSpan={2}>SOLENOID V/V</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register(
                        'specification.accessories.pulsingSystem.solenoid.standard'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh rowSpan={2}>
                    MAIN <br /> {'(DAMPER)'}
                  </SubTh>
                  <td colSpan={2} rowSpan={2}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      fullWidth
                      variant="outlined"
                      {...formControl.register('specification.fan.main')}
                    />
                  </td>
                  <Th>전압</Th>
                  <td>
                    <InputNumber
                      startAdornment={<span>AC</span>}
                      endAdornment={<span>V</span>}
                      {...formControl.register(
                        'specification.accessories.pulsingSystem.solenoid.voltage'
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <Th>TIMER CARD</Th>
                  <td colSpan={2}>
                    <Input
                      {...formControl.register('specification.accessories.pulsingSystem.timerCard')}
                    />
                  </td>
                </tr>
                <tr>
                  <MainTh rowSpan={8}>Motor</MainTh>
                  <SubTh>동력</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>kW</span>}
                      {...formControl.register('specification.motor.power')}
                    />
                  </td>
                  <Th>AIRHEADER</Th>
                  <td colSpan={2}>
                    <Input
                      {...formControl.register('specification.accessories.pulsingSystem.airHeader')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>전류</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>A</span>}
                      {...formControl.register('specification.motor.electric')}
                    />
                  </td>
                  <Th>BALL VALVE</Th>
                  <td colSpan={2}>
                    <Input
                      {...formControl.register('specification.accessories.pulsingSystem.ballValve')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>전압</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>V</span>}
                      {...formControl.register('specification.motor.voltage')}
                    />
                  </td>
                  <Th>기타</Th>
                  <td colSpan={2}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      variant="outlined"
                      fullWidth
                      {...formControl.register('specification.accessories.pulsingSystem.note')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>극수</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>P</span>}
                      {...formControl.register('specification.motor.polarity')}
                    />
                  </td>
                  <SubTh rowSpan={12}>불출장치</SubTh>
                  <Th rowSpan={2}>SLIDE GATE</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register('specification.accessories.drain.slidGate.standard')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh rowSpan={2}>동력전달</SubTh>
                  <td colSpan={2}>
                    <Input {...formControl.register('specification.motor.transmission.content')} />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>EA</span>}
                      {...formControl.register('specification.accessories.drain.slidGate.count')}
                    />
                  </td>
                </tr>
                <tr>
                  <Th>규격</Th>
                  <td>
                    <Input {...formControl.register('specification.motor.transmission.standard')} />
                  </td>
                  <Th rowSpan={2}>R/V</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register('specification.accessories.drain.rv.standard')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh rowSpan={2}>기타</SubTh>
                  <td colSpan={2} rowSpan={2}>
                    <TextField
                      multiline
                      minRows={2}
                      maxRows={2}
                      fullWidth
                      variant="outlined"
                      {...formControl.register('specification.motor.note')}
                    />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>DE</span>}
                      {...formControl.register('specification.accessories.drain.rv.count')}
                    />
                  </td>
                </tr>
                <tr>
                  <Th rowSpan={2}>S/C</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register('specification.accessories.drain.sc.standard')}
                    />
                  </td>
                </tr>
                <tr>
                  <MainTh rowSpan={2}>STACK</MainTh>
                  <SubTh>DIA</SubTh>
                  <td colSpan={2}>
                    <Input
                      startAdornment={<span>Φ</span>}
                      {...formControl.register('specification.stack.dia')}
                    />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>DE</span>}
                      {...formControl.register('specification.accessories.drain.sc.count')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>SILENCER</SubTh>
                  <td colSpan={2}>
                    <Input {...formControl.register('specification.stack.silencer')} />
                  </td>
                  <MainTh rowSpan={6}>부속기기</MainTh>
                  <Th rowSpan={2}>F/C</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register('specification.accessories.drain.fc.standard')}
                    />
                  </td>
                </tr>
                <tr>
                  <MainTh rowSpan={5}>본체</MainTh>
                  <SubTh rowSpan={2}>
                    처리
                    <br />
                    가스량
                  </SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>m³/min</span>}
                      {...formControl.register('specification.mainBody.gasProcessed.content')}
                    />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>DE</span>}
                      {...formControl.register('specification.accessories.drain.fc.count')}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: 'none' }}>
                    <Typography textAlign={'right'}>{'(at '}</Typography>
                  </td>
                  <td style={{ borderLeft: 'none' }}>
                    <InputNumber
                      endAdornment={<span>{'℃'}</span>}
                      {...formControl.register('specification.mainBody.gasProcessed.temperature')}
                    />
                  </td>
                  <Th rowSpan={2}>B/E</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register('specification.accessories.drain.be.standard')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh>여과면적</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>m²</span>}
                      {...formControl.register('specification.mainBody.filterAera')}
                    />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>DE</span>}
                      {...formControl.register('specification.accessories.drain.be.count')}
                    />
                  </td>
                </tr>
                <tr>
                  <SubTh rowSpan={2}>여과속도</SubTh>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>m/min</span>}
                      {...formControl.register('specification.mainBody.FilterSpeed.speed')}
                    />
                  </td>
                  <Th rowSpan={2}>P/M</Th>
                  <Th>규격</Th>
                  <td>
                    <Input
                      {...formControl.register('specification.accessories.drain.pm.standard')}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ borderRight: 'none' }}>
                    <Typography textAlign={'right'}>{'( 역세시 : '}</Typography>
                  </td>
                  <td style={{ borderLeft: 'none' }}>
                    <Input
                      endAdornment={<span>{')'}</span>}
                      {...formControl.register('specification.mainBody.FilterSpeed.backwash')}
                    />
                  </td>
                  <Th>수량</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>DE</span>}
                      {...formControl.register('specification.accessories.drain.pm.count')}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </Stack>
      <Stack spacing={1} sx={{ pageBreakBefore: 'always' }}>
        {isLoading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="rounded" height={200} />
          </>
        ) : (
          <>
            <Typography level="body1" fontWeight={600}>
              3. 기 타
            </Typography>
            <table className="tg">
              <colgroup>
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '8%' }} />
                <col style={{ width: '12%' }} />
                <col style={{ width: '12%' }} />
              </colgroup>
              <thead>
                <tr>
                  <Header>구&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;분</Header>
                  <Header colSpan={9}>상&nbsp;&nbsp;세&nbsp;&nbsp;사&nbsp;&nbsp;양</Header>
                  <Header>비&nbsp;&nbsp;고</Header>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <MainTh rowSpan={22}>불출장치</MainTh>
                  <SubTh rowSpan={2}>R/V NO.</SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    contentKey: 'etc.drain.rv.content',
                    brgKey: 'etc.drain.rv.brg.content',
                    rsChainKey: 'etc.drain.rv.brg.rsChain',
                    couplingKey: 'etc.drain.rv.brg.coupling',
                    noteKey: 'etc.drain.rv.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.rv.power.content',
                  reduceKey: 'etc.drain.rv.power.reductionRadio',
                  sprocketKey: 'etc.drain.rv.power.sprocket',
                  noteKey: 'etc.drain.rv.power.note'
                })}
                <tr>
                  <SubTh rowSpan={2}>SILO R/V NO.</SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    title: <>규&nbsp;&nbsp;&nbsp;&nbsp;격</>,
                    contentKey: 'etc.drain.silo.content',
                    brgKey: 'etc.drain.silo.standard.content',
                    rsChainKey: 'etc.drain.silo.standard.rsChain',
                    couplingKey: 'etc.drain.silo.standard.coupling',
                    noteKey: 'etc.drain.silo.standard.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.silo.power.content',
                  reduceKey: 'etc.drain.silo.power.reductionRadio',
                  sprocketKey: 'etc.drain.silo.power.sprocket',
                  noteKey: 'etc.drain.silo.power.note'
                })}
                <tr>
                  <SubTh rowSpan={4}>S/C NO.</SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    contentKey: 'etc.drain.sc.content.content',
                    brgKey: 'etc.drain.sc.content.brg.content',
                    rsChainKey: 'etc.drain.sc.content.brg.rsChain',
                    couplingKey: 'etc.drain.sc.content.brg.coupling',
                    noteKey: 'etc.drain.sc.content.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.sc.content.power.content',
                  reduceKey: 'etc.drain.sc.content.power.reductionRadio',
                  sprocketKey: 'etc.drain.sc.content.power.sprocket',
                  noteKey: 'etc.drain.sc.content.power.note'
                })}
                <tr>
                  {DrainBrg({
                    register: formControl.register,
                    contentKey: 'etc.drain.sc.subContent.content',
                    brgKey: 'etc.drain.sc.subContent.brg.content',
                    rsChainKey: 'etc.drain.sc.subContent.brg.rsChain',
                    couplingKey: 'etc.drain.sc.subContent.brg.coupling',
                    noteKey: 'etc.drain.sc.subContent.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.sc.subContent.power.content',
                  reduceKey: 'etc.drain.sc.subContent.power.reductionRadio',
                  sprocketKey: 'etc.drain.sc.subContent.power.sprocket',
                  noteKey: 'etc.drain.sc.subContent.power.note'
                })}
                <tr>
                  <SubTh rowSpan={2}>F/C NO.</SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    contentKey: 'etc.drain.fc.content',
                    brgKey: 'etc.drain.fc.brg.content',
                    rsChainKey: 'etc.drain.fc.brg.rsChain',
                    couplingKey: 'etc.drain.fc.brg.coupling',
                    noteKey: 'etc.drain.fc.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.fc.power.content',
                  reduceKey: 'etc.drain.fc.power.reductionRadio',
                  sprocketKey: 'etc.drain.fc.power.sprocket',
                  noteKey: 'etc.drain.fc.power.note'
                })}
                <tr>
                  <SubTh rowSpan={2}>COMMON</SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    contentKey: 'etc.drain.common.content',
                    brgKey: 'etc.drain.common.brg.content',
                    rsChainKey: 'etc.drain.common.brg.rsChain',
                    couplingKey: 'etc.drain.common.brg.coupling',
                    noteKey: 'etc.drain.common.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.common.power.content',
                  reduceKey: 'etc.drain.common.power.reductionRadio',
                  sprocketKey: 'etc.drain.common.power.sprocket',
                  noteKey: 'etc.drain.common.power.note'
                })}
                <tr>
                  <SubTh colSpan={2} rowSpan={2}>
                    BUCKET ELEVATOR
                  </SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    brgKey: 'etc.drain.bucket.brg.content',
                    rsChainKey: 'etc.drain.bucket.brg.rsChain',
                    couplingKey: 'etc.drain.bucket.brg.coupling',
                    noteKey: 'etc.drain.bucket.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.bucket.power.content',
                  reduceKey: 'etc.drain.bucket.power.reductionRadio',
                  sprocketKey: 'etc.drain.bucket.power.sprocket',
                  noteKey: 'etc.drain.bucket.power.note'
                })}
                <tr>
                  <SubTh colSpan={2} rowSpan={3}>
                    PUG MILL
                  </SubTh>
                  {DrainBrg({
                    register: formControl.register,
                    brgKey: 'etc.drain.pugMill.brg.content',
                    rsChainKey: 'etc.drain.pugMill.brg.rsChain',
                    couplingKey: 'etc.drain.pugMill.brg.coupling',
                    noteKey: 'etc.drain.pugMill.brg.note'
                  })}
                </tr>
                {DrainPower({
                  register: formControl.register,
                  powerKey: 'etc.drain.pugMill.power.content',
                  reduceKey: 'etc.drain.pugMill.power.reductionRadio',
                  sprocketKey: 'etc.drain.pugMill.power.sprocket',
                  noteKey: 'etc.drain.pugMill.power.note'
                })}
                <tr>
                  <Th>BLADE</Th>
                  {DrainStandard({
                    register: formControl.register,
                    standardKey: 'etc.drain.pugMill.blade.standard',
                    countKey: 'etc.drain.pugMill.blade.count',
                    noteKey: 'etc.drain.pugMill.blade.note'
                  })}
                </tr>
                <tr>
                  <SubTh rowSpan={5}>기&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;타</SubTh>
                  <Th colSpan={2}>AIR KNOCKER</Th>
                  {DrainStandard({
                    register: formControl.register,
                    standardKey: 'etc.drain.etc.air.standard',
                    countKey: 'etc.drain.etc.air.count',
                    noteKey: 'etc.drain.etc.air.note'
                  })}
                </tr>
                <tr>
                  <Th colSpan={2}>VIBRATOR</Th>
                  {DrainStandard({
                    register: formControl.register,
                    standardKey: 'etc.drain.etc.vibrator.standard',
                    countKey: 'etc.drain.etc.vibrator.count',
                    noteKey: 'etc.drain.etc.vibrator.note'
                  })}
                </tr>
                <tr>
                  <Th colSpan={2}>MAG HAMMER</Th>
                  {DrainStandard({
                    register: formControl.register,
                    standardKey: 'etc.drain.etc.blastWindow.standard',
                    countKey: 'etc.drain.etc.blastWindow.count',
                    noteKey: 'etc.drain.etc.blastWindow.note'
                  })}
                </tr>
                <tr>
                  <Th colSpan={2}>기타1</Th>
                  {DrainStandard({
                    register: formControl.register,
                    standardKey: 'etc.drain.etc.noteOne.standard',
                    countKey: 'etc.drain.etc.noteOne.count',
                    noteKey: 'etc.drain.etc.noteOne.note'
                  })}
                </tr>
                <tr>
                  <Th colSpan={2}>기타2</Th>
                  {DrainStandard({
                    register: formControl.register,
                    standardKey: 'etc.drain.etc.noteTwo.standard',
                    countKey: 'etc.drain.etc.noteTwo.count',
                    noteKey: 'etc.drain.etc.noteTwo.note'
                  })}
                </tr>
                <tr>
                  <MainTh rowSpan={14}>DAMPER</MainTh>
                  <SubTh rowSpan={2}>INLET</SubTh>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.inlet.standard.content',
                    countKey: 'etc.damper.inlet.standard.count',
                    typeKey: 'etc.damper.inlet.standard.type',
                    noteKey: 'etc.damper.inlet.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.inlet.brg.content',
                  airKey: 'etc.damper.inlet.brg.air',
                  noteKey: 'etc.damper.inlet.brg.note'
                })}
                <tr>
                  <SubTh rowSpan={2}>OUTLET</SubTh>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.outlet.standard.content',
                    countKey: 'etc.damper.outlet.standard.count',
                    typeKey: 'etc.damper.outlet.standard.type',
                    noteKey: 'etc.damper.outlet.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.outlet.brg.content',
                  airKey: 'etc.damper.outlet.brg.air',
                  noteKey: 'etc.damper.outlet.brg.note'
                })}
                <tr>
                  <SubTh rowSpan={2}>MAIN DAMPER</SubTh>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.mainDamper.standard.content',
                    countKey: 'etc.damper.mainDamper.standard.count',
                    typeKey: 'etc.damper.mainDamper.standard.type',
                    noteKey: 'etc.damper.mainDamper.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.mainDamper.brg.content',
                  airKey: 'etc.damper.mainDamper.brg.air',
                  noteKey: 'etc.damper.mainDamper.brg.note'
                })}
                <tr>
                  <SubTh rowSpan={8}>
                    BRANCH
                    <br />
                    (HOOD수량)
                  </SubTh>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.branch.one.standard.content',
                    countKey: 'etc.damper.branch.one.standard.count',
                    typeKey: 'etc.damper.branch.one.standard.type',
                    noteKey: 'etc.damper.branch.one.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.branch.one.brg.content',
                  airKey: 'etc.damper.branch.one.brg.air',
                  noteKey: 'etc.damper.branch.one.brg.note'
                })}
                <tr>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.branch.two.standard.content',
                    countKey: 'etc.damper.branch.two.standard.count',
                    typeKey: 'etc.damper.branch.two.standard.type',
                    noteKey: 'etc.damper.branch.two.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.branch.two.brg.content',
                  airKey: 'etc.damper.branch.two.brg.air',
                  noteKey: 'etc.damper.branch.two.brg.note'
                })}
                <tr>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.branch.three.standard.content',
                    countKey: 'etc.damper.branch.three.standard.count',
                    typeKey: 'etc.damper.branch.three.standard.type',
                    noteKey: 'etc.damper.branch.three.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.branch.three.brg.content',
                  airKey: 'etc.damper.branch.three.brg.air',
                  noteKey: 'etc.damper.branch.three.brg.note'
                })}
                <tr>
                  {DamperStandard({
                    register: formControl.register,
                    standrdKey: 'etc.damper.branch.four.standard.content',
                    countKey: 'etc.damper.branch.four.standard.count',
                    typeKey: 'etc.damper.branch.four.standard.type',
                    noteKey: 'etc.damper.branch.four.standard.note'
                  })}
                </tr>
                {DamperBRG({
                  register: formControl.register,
                  brgKey: 'etc.damper.branch.four.brg.content',
                  airKey: 'etc.damper.branch.four.brg.air',
                  noteKey: 'etc.damper.branch.four.brg.note'
                })}
                <tr>
                  <MainTh rowSpan={4}>UTILITY</MainTh>
                  <SubTh rowSpan={2}>AIR COMP</SubTh>
                  <Th>동&nbsp;&nbsp;력</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>kW</span>}
                      {...formControl.register('etc.utility.airCompressor.power.content')}
                    />
                  </td>
                  <Th>생산능력</Th>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>m³/min</span>}
                      {...formControl.register('etc.utility.airCompressor.power.ability')}
                    />
                  </td>
                  <Th>PRESSURE</Th>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>kg/cm²</span>}
                      {...formControl.register('etc.utility.airCompressor.power.pressure')}
                    />
                  </td>
                  <td>
                    <Input {...formControl.register('etc.utility.airCompressor.power.note')} />
                  </td>
                </tr>
                <tr>
                  <Th>MAIN LINE</Th>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>{CardUnitEnum.A}</span>}
                      {...formControl.register('etc.utility.airCompressor.mainLine.content')}
                    />
                  </td>
                  <Th colSpan={2}>BRANCH</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>{CardUnitEnum.A}</span>}
                      {...formControl.register('etc.utility.airCompressor.mainLine.branch')}
                    />
                  </td>
                  <td colSpan={2}></td>
                  <td>
                    <Input {...formControl.register('etc.utility.airCompressor.mainLine.note')} />
                  </td>
                </tr>
                <tr>
                  <SubTh>WATER</SubTh>
                  <Th>인입 LINE</Th>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>{CardUnitEnum.A}</span>}
                      {...formControl.register('etc.utility.water.line')}
                    />
                  </td>
                  <Th colSpan={2}>PRESSURE</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>{CardUnitEnum.KG}</span>}
                      {...formControl.register('etc.utility.water.pressure')}
                    />
                  </td>
                  <Th>용도</Th>
                  <td>
                    <Input {...formControl.register('etc.utility.water.method')} />
                  </td>
                  <td>
                    <Input {...formControl.register('etc.utility.water.note')} />
                  </td>
                </tr>
                <tr>
                  <SubTh>STEAM</SubTh>
                  <Th>인입 LINE</Th>
                  <td colSpan={2}>
                    <InputNumber
                      endAdornment={<span>{CardUnitEnum.A}</span>}
                      {...formControl.register('etc.utility.steam.line')}
                    />
                  </td>
                  <Th colSpan={2}>PRESSURE</Th>
                  <td>
                    <InputNumber
                      endAdornment={<span>{CardUnitEnum.KG}</span>}
                      {...formControl.register('etc.utility.steam.pressure')}
                    />
                  </td>
                  <Th>용도</Th>
                  <td>
                    <Input {...formControl.register('etc.utility.steam.method')} />
                  </td>
                  <td>
                    <Input {...formControl.register('etc.utility.steam.note')} />
                  </td>
                </tr>
                <tr>
                  <MainTh rowSpan={6}>기&nbsp;&nbsp;&nbsp;&nbsp;타</MainTh>
                  <SubTh rowSpan={2}>EXP JOINT</SubTh>
                  <Th>NO.</Th>
                  {EtcStandard({
                    register: formControl.register,
                    contentKey: 'etc.etc.joint.one.content',
                    standrdKey: 'etc.etc.joint.one.standard',
                    countKey: 'etc.etc.joint.one.count',
                    noteKey: 'etc.etc.joint.one.note'
                  })}
                </tr>
                <tr>
                  <Th>NO.</Th>
                  {EtcStandard({
                    register: formControl.register,
                    contentKey: 'etc.etc.joint.two.content',
                    standrdKey: 'etc.etc.joint.two.standard',
                    countKey: 'etc.etc.joint.two.count',
                    noteKey: 'etc.etc.joint.two.note'
                  })}
                </tr>
                <tr>
                  <SubTh colSpan={3}>MANOMETER</SubTh>
                  {EtcStandard({
                    register: formControl.register,
                    standrdKey: 'etc.etc.manometer.standard',
                    countKey: 'etc.etc.manometer.count',
                    noteKey: 'etc.etc.manometer.note'
                  })}
                </tr>
                <tr>
                  <SubTh colSpan={3} rowSpan={2}>
                    AIR SERVICE UNIT
                  </SubTh>
                  {EtcStandard({
                    register: formControl.register,
                    standrdKey: 'etc.etc.airServiceUnit.one.standard',
                    countKey: 'etc.etc.airServiceUnit.one.count',
                    noteKey: 'etc.etc.airServiceUnit.one.note'
                  })}
                </tr>
                <tr>
                  {EtcStandard({
                    register: formControl.register,
                    standrdKey: 'etc.etc.airServiceUnit.two.standard',
                    countKey: 'etc.etc.airServiceUnit.two.count',
                    noteKey: 'etc.etc.airServiceUnit.two.note'
                  })}
                </tr>
                <tr>
                  <SubTh colSpan={3}>TOP DOOR</SubTh>
                  {EtcStandard({
                    register: formControl.register,
                    standrdKey: 'etc.etc.topDoor.standard',
                    countKey: 'etc.etc.topDoor.count',
                    noteKey: 'etc.etc.topDoor.note'
                  })}
                </tr>
                <tr>
                  <MainTh>특이사항</MainTh>
                  <td colSpan={10}>
                    <Textarea minRows={5} {...formControl.register('note')} />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </Stack>
    </Stack>
  )
}
