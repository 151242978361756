import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  CreateFactoryFormFields,
  CreateFactoryFormProps,
  FactoryInDto
} from '@pages/settings/factory/type'
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  Option,
  Select,
  Sheet,
  Stack,
  Typography
} from '@mui/joy'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Controller, useForm } from 'react-hook-form'
import { Fade } from '@mui/material'
import { getFieldPropsWithForm } from '@core/helpers/form.helper'
import { renderEmptyRepairPlanSelectBox } from '@pages/repair/template'
import { String } from '@core/enums/common.enum'
import { Business, Store } from '@mui/icons-material'
import { ConfirmSaveChange, warn } from '@core/components/modal'
import _ from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { factoryValidationSchema } from '@pages/settings/template'
import FactoryIcon from '@mui/icons-material/Factory';

/**
 * Create, update a factory component
 * @param isOpen
 * @param onCloseFn
 * @param onSubmit
 * @param information
 * @param listCompany
 * @constructor
 */
const CreateFactoryForm: FC<CreateFactoryFormProps> = ({
  isOpen,
  onCloseFn,
  onSubmit,
  information,
  listCompany
}) => {
  console.log(information, listCompany)
  // Common variables
  const { t: translate } = useTranslation([TranslateEnum.FACTORY])
  const {
    handleSubmit,
    register,
    control,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors, dirtyFields, isSubmitted, isValid }
  } = useForm<FactoryInDto>({
    resolver: yupResolver(factoryValidationSchema(translate))
  })

  // Component state
  const [isSaveChange, setIsSaveChange] = useState<boolean>(false)
  const [isEditMode, setEditMode] = useState<boolean>(false)

  // Event handlers
  /**
   * Handle close modal and check form is touched
   */
  const handleClose = useCallback(() => {
    if (Object.keys(dirtyFields).length > 0 && !isSubmitted) {
      setIsSaveChange(true)
    } else {
      setIsSaveChange(false)
      onCloseFn()
      reset({})
      setEditMode(false)
    }
  }, [dirtyFields, isSubmitted])

  /**
   * Handle submit form
   */
  const handleSubmitFactory =
    (data: FactoryInDto) => {
      if (Number(data.maxChamberCount) > 10 && Number(data.maxChamberCount) <= 0) {
        warn({ title: '추가 실패!', message: '챔버 최대 수는 1부터 10까지 입력이 가능합니다.' });
        return;
      } else {
        onSubmit(
          {
            ...data,
            companyId: Number(data && data.companyId)
          },
          isEditMode
        )
      }
    }

  /**
   * Confirm save change when click out form
   */
  const confirmSaveChange = async () => {
    await trigger()
    if (isValid) {
      const inDto = getValues()
      handleSubmitFactory(inDto)
      setIsSaveChange(false)
    }
  }

  /**
   * Effect when edit factory
   */
  useEffect(() => {
    if (information) {
      reset(
        _.mapValues(information, (value: any) => (value === null ? String.EMPTY_STRING : value))
      )
      setEditMode(true)
    }
  }, [information])

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={handleClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={isOpen}>
          <Sheet
            variant="outlined"
            sx={{
              width: 600,
              borderRadius: 3,
              p: 3,
              boxShadow: 'lg'
            }}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}>
              {isEditMode
                ? translate('factory.modal.titleUpdate')
                : translate('factory.modal.titleAdd')}
            </Typography>
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body'
              }}
            />

            <Stack spacing={2} sx={{ minHeight: 410, overflowY: 'auto', overflowX: 'hidden' }}>
              <form onSubmit={handleSubmit(handleSubmitFactory)}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid xs={12}>
                    <FormControl orientation={'horizontal'}>
                      <Grid xs={3}>
                        <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
                          {translate('factory.searchForm.companyTitle')}
                        </FormLabel>
                      </Grid>

                      <Grid xs={9}>
                        <Controller
                          name={CreateFactoryFormFields.COMPANY_ID}
                          control={control}
                          render={({ field: { onChange, value, ...field } }) => (
                            <Select
                              {...getFieldPropsWithForm(field, value)}
                              placeholder={translate('factory.searchForm.companyPlaceholder')}
                              value={value}
                              onChange={(_, value) => {
                                onChange(value)
                              }}
                              sx={{ width: '100%' }}
                              slotProps={{
                                button: {
                                  id: 'company'
                                }
                              }}>
                              {listCompany && listCompany.length > 0 ? (
                                <>
                                  {listCompany.map((el, index) => (
                                    <Option value={el.companyId} key={index}>
                                      {el.companyName}
                                    </Option>
                                  ))}
                                </>
                              ) : (
                                renderEmptyRepairPlanSelectBox()
                              )}
                            </Select>
                          )}
                        />
                        {errors && errors.companyId && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.companyId.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid xs={12}>
                    <FormControl orientation="horizontal">
                      <Grid xs={3}>
                        <FormLabel id="name" htmlFor="name" sx={{ fontWeight: 'bold' }}>
                          {translate('factory.searchForm.factoryName')}
                        </FormLabel>
                      </Grid>
                      <Grid xs={9}>
                        <Input
                          fullWidth
                          placeholder={
                            translate('factory.searchForm.factoryNamePlaceholder') ||
                            String.EMPTY_STRING
                          }
                          startDecorator={<Store />}
                          {...register(CreateFactoryFormFields.FACTORY_NAME)}
                        />
                        {errors && errors.name && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.name.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid xs={12}>
                    <FormControl orientation="horizontal">
                      <Grid xs={3}>
                        <FormLabel id="name" htmlFor="name" sx={{ fontWeight: 'bold' }}>
                          공장주소
                        </FormLabel>
                      </Grid>
                      <Grid xs={9}>
                        <Input
                          fullWidth
                          placeholder={'공장주소를 입력해주세요.'}
                          startDecorator={<Business />}
                          sx={{ marginBottom: '4px' }}
                          {...register(CreateFactoryFormFields.ADDRESS)}
                        />
                        <Checkbox
                          label="회사 주소와 동일"
                          size="sm"
                          onClick={(e: any) => {
                            const companyId = getValues().companyId;
                            if (companyId && e.target.checked) {
                              const filteredData = listCompany.find(item => item.companyId === companyId);
                              setValue(CreateFactoryFormFields.ADDRESS, filteredData!.companyAddress || '');
                            } else {
                              setValue(CreateFactoryFormFields.ADDRESS, '');
                            }
                          }}
                        />
                        {errors && errors.address && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.address.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid xs={12}>
                    <FormControl orientation="horizontal">
                      <Grid xs={3}>
                        <FormLabel id="name" htmlFor="name" sx={{ fontWeight: 'bold' }}>
                          챔버 최대 수
                        </FormLabel>
                      </Grid>
                      <Grid xs={9}>
                        <Input
                          fullWidth
                          type="number"
                          placeholder='최대 수를 입력해주세요.'
                          slotProps={{
                            input: {
                              min: 1,
                              max: 10
                            }
                          }}
                          startDecorator={<FactoryIcon />}
                          {...register(CreateFactoryFormFields.CHAMBER_MAX_NUM)}
                        />
                        {errors && errors.maxChamberCount && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.maxChamberCount.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid
                    xs={12}
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}>
                    <Button sx={{ width: 106 }} color="warning" onClick={handleClose}>
                      {translate('factory.modal.btnCancel')}
                    </Button>
                    <Button sx={{ ml: 2, width: 106 }} color="primary" type="submit">
                      {translate('factory.modal.btnSubmit')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Stack>
          </Sheet>
        </Fade>
      </Modal>
      <ConfirmSaveChange
        onClose={() => setIsSaveChange(false)}
        subscribe={isSaveChange}
        onAccept={async () => await confirmSaveChange()}
      />
    </>
  )
}

export default CreateFactoryForm
