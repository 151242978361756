/**
 * convert Date form YYYY.MM.DD
 * @param date Date
 */
import dayjs from 'dayjs'
import { DateFormat } from '@core/enums/date.enum'

export const convertDateFormat = (date: Date) => {
  return dayjs(date).format(DateFormat.YYYY_MM_DD_DATE_PICKER)
}
