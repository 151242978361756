import { FC, useEffect, useMemo } from 'react'
import { useRecoilValue } from 'recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AxiosSingleton } from '@core/configs/axios.config'

/**
 * Application wrapper component
 * @param children
 * @constructor
 */
const ApplicationProvider: FC<any> = ({ children }) => {
  const authorization = useRecoilValue(authorizationSelector)
  const { accessToken } = useMemo(() => {
    return authorization
  }, [authorization])

  useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      AxiosSingleton.setToken(accessToken)
    }
  }, [accessToken])
  return children
}
export default ApplicationProvider
