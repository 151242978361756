import styled from 'styled-components';
import { AerixIcon } from '@core/components/icon';
// import { chartData } from '@core/data/chartMockData';
import { DateRange as DateRangeIcon } from '@mui/icons-material'
import { Box, Card, Checkbox, IconButton, Input, Stack, Typography } from '@mui/joy';
import { FormControlLabel } from '@mui/material';
import _ from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { DateFormat } from '@core/enums/date.enum';
import LineChart from './LineChart';
import { options } from './common/chartOptions';
import ChartDate from './ChartDate';
import dayjs from 'dayjs';
import { DateRange } from '@core/components/date-range/type';
import { useChartData } from '../query';
import { useLocation } from 'react-router-dom';
type optionsD = {
  name: string;
  datas: { type: string; data: [number, number][] }[];
}

const localeDate = (date: Date) => {
  const krTime = date.getTime() + (-new Date().getTimezoneOffset() * 1000 * 60);
  return new Date(krTime).toISOString().slice(0, 10);
}

const initCheckList = [
  {
    name: 'FAN 진동 부하',
    path: ['fan', 'open'],
    checked: [0, 1, 2]
  },
  {
    name: 'FAN 진동 반부하',
    path: ['fan', 'close'],
    checked: [0, 1, 2]
  },
  {
    name: 'FAN 온도',
    path: ['fan', 'temp'],
    checked: [0, 1]
  },
  {
    name: 'MOTOR 진동 부하',
    path: ['motor', 'open'],
    checked: [0, 1, 2]
  },
  {
    name: 'MOTOR 진동 반부하',
    path: ['motor', 'close'],
    checked: [0, 1, 2]
  },
  {
    name: 'MOTOR 온도',
    path: ['motor', 'temp'],
    checked: [0, 1]
  },
];

const MeasurementChart = () => {
  const [checkList, setCheckList] = useState(initCheckList);
  const [onDatePicker, setOnDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  });
  const location = useLocation();
  const checkPlanId = location.pathname.split("/")[2];
  console.log(checkPlanId);
  const { data: chartData = [], refetch } = useChartData({
    checkPlanId: Number(checkPlanId),
    startDate: localeDate(dateRange.startDate).replaceAll('-', '.'),
    endDate: localeDate(dateRange.endDate).replaceAll('-', '.')
  }) as any;

  useEffect(() => {
    // 날짜 변경될 때 refetch
    refetch();
  }, [dateRange.startDate, dateRange.endDate]);

  const checkboxLabel = ['H', 'V', 'A'];
  const tempCheckboxLabel = ['open', 'close'];
  const typeLabel = ['D 변위', 'V 속도', 'A 가속도'];

  const handleChangeCheckList = (checked: boolean, itemIdx: number, checkOrderIdx: number) => {
    console.log(checked, itemIdx, checkOrderIdx);
    if (checked) {
      checkList[itemIdx].checked.push(checkOrderIdx);
      checkList[itemIdx].checked = checkList[itemIdx].checked.sort((a, b) => a - b);
      setCheckList(_.cloneDeep(checkList));
      return;
    }
    checkList[itemIdx].checked = checkList[itemIdx].checked
      .filter(el => el !== checkOrderIdx)
      .sort((a, b) => a - b);

    setCheckList(_.cloneDeep(checkList));
    return;
  }

  const getDisplayDateRange = (dateRange: DateRange): string => {
    const format = DateFormat.YYYY_MM_DD_DATE_PICKER;
    const startDate = dateRange?.startDate ? dayjs(dateRange.startDate).format(format) : null

    const endDate = dateRange?.endDate ? dayjs(dateRange.endDate).format(format) : null

    return startDate && endDate ? `${startDate} - ${endDate}` : ''
  }

  const pickedCheckbox = (secondPath: string) => secondPath !== 'temp' ? checkboxLabel : tempCheckboxLabel;

  return (
    <Wrap onClick={() => setOnDatePicker(false)}>
      <Header>
        <ProjectWrap>
          <AerixIcon />
          <span>AERIX Smart O&M</span>
        </ProjectWrap>
        <DateWrap>
          <label>기간</label>
          <Input
            endDecorator={<DateRangeIcon color={'action'} fontSize={'small'} />}
            value={getDisplayDateRange(dateRange)}
            onClick={(e: any) => {
              e.stopPropagation();
              setOnDatePicker(!onDatePicker);
            }}
          />
          {onDatePicker && (
            <ChartDate
              dateRange={dateRange}
              setDateRange={setDateRange}
              onClose={() => setOnDatePicker(false)}
            />
          )}
        </DateWrap>
      </Header>

      <ContentWrap>
        {checkList.map((item, itemIdx) => {
          return (
            <GraphWrap
              key={item.name}
            >
              <CheckboxWrap>
                <span>{item.name}</span>
                {pickedCheckbox(item.path[1]).map((item, checkOrderIdx) => {
                  // 체크박스 주제
                  const isChecked = checkList[itemIdx].checked.includes(checkOrderIdx);
                  return (
                    <MyCheckboxLabel
                      key={item}
                      control={<Checkbox checked={isChecked} />}
                      label={item}
                      onChange={(e: any) => handleChangeCheckList(e.target.checked, itemIdx, checkOrderIdx)}
                    />
                  )
                })}
              </CheckboxWrap>
              <CardWrap>
                {item.checked.map((selectedIdx: any) => {
                  // selectedIdx : 선택된 H, V, A중 하나
                  const graphPath = item.path; // [fan, open]
                  const selectedLabel = item.path[1] !== 'temp' ? checkboxLabel[selectedIdx] : tempCheckboxLabel[selectedIdx]; // H, V, A or open/close
                  // const typeLabel = ['D 변위', 'V 속도', 'A 가속도'];
                  const findValue = (data: any, typeIdx: number) => {
                    const firstPath = graphPath[0];
                    const secondPath = graphPath[1];
                    const contentValue = data[firstPath][secondPath][selectedLabel][typeIdx];
                    return contentValue.length > 0 ? Number(contentValue) : null;
                  };

                  const makeData: any = typeLabel.map((typeName: string, typeIdx: number) => {
                    return {
                      type: typeName,
                      data: chartData.map((item: any) => [new Date(item.date).getTime(), findValue(item, typeIdx)])
                    }
                  });

                  const optionsData: optionsD = {
                    name: `${item.name} ${selectedLabel}`,
                    datas: makeData
                  };

                  return (
                    <LineChart options={options(optionsData)} key={selectedIdx} />
                  )
                })}
              </CardWrap>
            </GraphWrap>
          )
        })}
      </ContentWrap>
    </Wrap >
  );
};

const Wrap = styled(Stack)`
  padding: 16px;
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
`;

const ProjectWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 18px;

  > span {
    font-weight: bold;
  }
`;

const DateWrap = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 16px;
  gap: 16px;
`;

const ContentWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  padding: 8px;
  overflow-y: scroll;
`;

const GraphWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px
`;

const CheckboxWrap = styled(Box)`
  display: flex;
  margin-top: 16px;
  padding: 24px 36px;
  background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
  width: 50%;
  min-width: 600px;
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
  font-size: 17px;

  > span:nth-child(1) {
    width: 180px;
  }
`;

const MyCheckboxLabel = styled(FormControlLabel)`
  gap: 12px;
  margin-left: 8px;
`;

const CardWrap = styled(Card)`
  display: flex; 
  flex-direction: column;
  gap: 18px;
`;

export default MeasurementChart;