import { LoginInDto } from '@pages/login/type'
import { useMutation } from '@tanstack/react-query'
import { request } from '@core/services/base.service'
import { apis } from '@core/configs/api'
import { decodeJwtTokenAndConvertToAuthorizationModel } from '@core/helpers/auth.helper'

/**
 * Mutation handle submit form login
 */
const useSubmitLoginMutation = () => {
  return useMutation({
    mutationFn: (body: LoginInDto) => {
      return request.post(apis.AUTHORIZATION_APPLICATION.path, body).then((response: any) => {
        const { accessToken } = response
        return decodeJwtTokenAndConvertToAuthorizationModel(accessToken)
      })
    }
  })
}

export { useSubmitLoginMutation }
