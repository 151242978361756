import { FC, useCallback, useEffect, useState } from 'react'
import { Avatar, Box, Card, CardOverflow, Divider, Grid, Typography } from '@mui/joy'
import { EnhancedEncryption, SupervisedUserCircle } from '@mui/icons-material'
import {
  ChangePasswordInDto,
  SettingAccountInformationInDto,
  UserInformationModel
} from '@pages/settings/account/type'
import AccountInformationForm from '@pages/settings/account/information'
import {
  useGetUserInformation,
  useSubmitChangePassword,
  useSubmitChangeUserInformation
} from '@pages/settings/query'
import { confirm, fail, success } from '@core/components/modal'
import ChangePassword from '@pages/settings/account/change-password'

/**
 * Account setting component
 * @constructor
 */
const AccountSetting: FC = () => {
  // component state
  const [userInformation, setUserInformation] = useState<UserInformationModel>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // React query
  const { refetch, isLoading: getUserInfoLoading } = useGetUserInformation({
    enabled: false,
    onSuccess: (data: UserInformationModel) => {
      setUserInformation(data)
    }
  })
  const { mutate: mutateChangeUserInformation, isLoading: isLoadingChangeUserInformation } =
    useSubmitChangeUserInformation()
  const { mutate: mutateChangePassword, isLoading: isLoadingChangePassword } =
    useSubmitChangePassword()

  /**
   * Handle submit change user information
   */
  const handleSubmitChangeUserInformation = useCallback((data: SettingAccountInformationInDto) => {
    confirm({
      onOk: () => {
        mutateChangeUserInformation(data, {
          onSuccess: async () => {
            await refetch()
            success()
          },
          onError: () => fail()
        })
      }
    })
  }, [])

  /**
   * Handle submit change password
   */
  const handleSubmitChangePassword = useCallback((data: ChangePasswordInDto) => {
    confirm({
      onOk: () => {
        mutateChangePassword(data, {
          onSuccess: () => success(),
          onError: () => fail()
        })
      }
    })
  }, [])

  /**
   * Initial effect
   */
  useEffect(() => {
    refetch()
  }, [])

  /**
   * Effect check loading
   */
  useEffect(() => {
    setIsLoading(getUserInfoLoading || isLoadingChangeUserInformation)
  }, [getUserInfoLoading, isLoadingChangeUserInformation])
  return (
    <Card>
      <Grid container spacing={2} className="account-setting-container">
        <Grid xs={12}>
          <Typography id="create-modal-dialog-title" level="h6" fontWeight={600}>
            계정 설정
          </Typography>
        </Grid>

        <Grid xs={6} sx={{ mt: 2, mb: 2 }}>
          <Card
            variant="outlined"
            sx={{
              minWidth: 300,
              minHeight: '35.2rem',
              '--Card-radius': (theme) => theme.vars.radius.xs
            }}>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', pb: 1.5, gap: 1 }}>
              <Box
                sx={{
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    m: '-2px',
                    borderRadius: '50%'
                  }
                }}
                component="div">
                <Avatar
                  size="sm"
                  sx={{ p: 0.5, border: '2px solid', borderColor: 'background.body' }}>
                  <EnhancedEncryption color="info" />
                </Avatar>
              </Box>
              <Typography fontWeight="lg">비밀번호 변경</Typography>
            </Box>
            <Divider />
            <CardOverflow>
              <ChangePassword
                isLoading={isLoadingChangePassword}
                onSubmit={handleSubmitChangePassword}
              />
            </CardOverflow>
          </Card>
        </Grid>
        <Grid xs={6} sx={{ mt: 2, mb: 2 }}>
          <Card
            variant="outlined"
            sx={{
              minWidth: 300,
              '--Card-radius': (theme) => theme.vars.radius.xs
            }}>
            <Box component="div" sx={{ display: 'flex', alignItems: 'center', pb: 1.5, gap: 1 }}>
              <Box
                sx={{
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    m: '-2px',
                    borderRadius: '50%'
                  }
                }}
                component="div">
                <Avatar
                  size="sm"
                  sx={{ p: 0.5, border: '2px solid', borderColor: 'background.body' }}>
                  <SupervisedUserCircle color="info" />
                </Avatar>
              </Box>
              <Typography fontWeight="lg">계정 정보 설정</Typography>
            </Box>
            <Divider />
            <CardOverflow>
              <AccountInformationForm
                userInformation={userInformation}
                onSubmit={handleSubmitChangeUserInformation}
                isLoading={isLoading}
              />
            </CardOverflow>
          </Card>
        </Grid>
      </Grid>
    </Card>
  )
}

export default AccountSetting
