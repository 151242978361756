import { TranslateLanguageEnum } from '@core/enums/translate.enum';
import React, { useState, SetStateAction } from 'react';
import { DateRangePicker } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale'
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  dateRange: {
    startDate: Date;
    endDate: Date;
    key: string;
  },
  setDateRange: React.Dispatch<SetStateAction<{
    startDate: Date;
    endDate: Date;
    key: string;
  }>>,
  onClose: () => void,
}

const DATE_GAP_NUM = [1, 7, 30, 90];

const ChartDate: React.FC<IProps> = ({ dateRange, setDateRange, onClose }) => {
  const [onDatePicker, setOnDatePicker] = useState(false);

  const handleChangeCheckedDate = (num: number) => {
    const startNum = new Date().getTime() - (1000 * 60 * 60 * 24 * num);
    setOnDatePicker(false);
    setDateRange({
      ...dateRange,
      startDate: new Date(startNum),
      endDate: new Date(),
    })
  };

  const handleChangeDatePicker = (e: any) => {
    if (e.selection.startDate < e.selection.endDate) {
      setOnDatePicker(false);
    }
    setDateRange(e.selection);
  };

  return (
    <Wrap onClick={(e: any) => { e.stopPropagation() }}>
      <HeaderWrap>
        <span>기간 선택</span>
        <CloseBtn onClick={onClose} />
      </HeaderWrap>
      <MainWrap>
        {DATE_GAP_NUM.map((dateNum: number) => {
          return (
            <CheckWrap key={dateNum}>
              <input
                type='radio'
                name='date'
                onClick={() => handleChangeCheckedDate(dateNum)}
              />
              <span>{`지난 ${dateNum}일`}</span>
            </CheckWrap>
          );
        })}
        <CheckWrap>
          <input
            type='radio'
            name='date'
            checked={onDatePicker}
            onClick={() => setOnDatePicker(true)}
          />
          <span>사용자 설정 범위</span>
        </CheckWrap>
        {onDatePicker &&
          <CustomDatePicker
            locale={locales[TranslateLanguageEnum.KOREA_DATE_LOCALIZATION]}
            ranges={[dateRange]}
            onChange={handleChangeDatePicker}
            direction="horizontal"
            showDateDisplay={false}
            months={1}
          />
        }
      </MainWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: absolute;
  top: 45px;
  right: 20px;
  display: flex;
  flex-direction: column;
  width: 268px;
  z-index: 100;
  background-image: linear-gradient(to left, #BDBBBE 0%, #9D9EA3 100%), radial-gradient(88% 271%, rgba(255, 255, 255, 0.25) 0%, rgba(254, 254, 254, 0.25) 1%, rgba(0, 0, 0, 0.25) 100%), radial-gradient(50% 100%, rgba(255, 255, 255, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
  background-blend-mode: normal, lighten, soft-light;  
  color: white;
  border-radius: 8px;
  padding: 4px 8px;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 4px;
  border-bottom: 1px solid #fff;
`;

const MainWrap = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CheckWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;

  > input {
    cursor: pointer;
  }
`;

const CustomDatePicker = styled(DateRangePicker)`
  position: absolute;
  top: 0;
  right: 360px;
  width: 240px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const CloseBtn = styled(CloseIcon)`
  fill: #ffffff;
  cursor: pointer;
`;

export default ChartDate;