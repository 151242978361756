import { ContentMenu } from '@core/components/menu'
import { BaseTable } from '@core/components/table'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Box, Card, Checkbox, Stack, Tooltip, Typography } from '@mui/joy'
import { MRT_Cell } from 'material-react-table'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Actions, ColumnsHeader, TableColumns } from './template'
import { confirm, success, warn } from '@core/components/modal'
import { FilterHistory } from './history'
import { SortingState } from '@tanstack/react-table'
import { Virtualizer } from '@tanstack/react-virtual'
import { sleep } from '@core/helpers/action.helper'
import { CustomDateRange } from '@pages/inspect/plan-management/template'
import { useForm } from 'react-hook-form'
import { ContentMenuDataProps } from '@core/components/menu/type'
import { FilterDataProps } from '@pages/dashboard/type'
import { useMutation, useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'
import { getFilterHistory, postExcelDownload, postFilterHistory } from './query'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import _ from 'lodash'
import { ErrorQuery } from '@core/helpers/query.helper'
import { saveState } from '@pages/recoil'
import { cvtDate } from '@pages/service/miscFunc'

export const FilterBagHistory = () => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  const authorizationModel = useRecoilValue(authorizationSelector)
  const columns = ColumnsHeader
  const [info, setInfo] = useState<ContentMenuDataProps>({
    checked: false,
    filterChecked: false,
  })
  const [tableData, setTableData] = useState<FilterDataProps[]>([])
  const rowVirtualizerInstanceRef = useRef<Virtualizer<HTMLDivElement, HTMLTableRowElement>>(null)
  const [isLoading, setLoading] = useState(false)
  const [sorting, setSorting] = useState<SortingState>([])
  const setUnsaved = useSetRecoilState(saveState)

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });


  const { setValue, getValues } = useForm({
    defaultValues: {
      startDate: '',
      endDate: '',
      replaceStartDate: '',
      replaceEndDate: '',
    }
  })

  const { refetch } = useQuery(
    [QueryKey.FILTER],
    () => getFilterHistory(authorizationModel, info, getValues),
    {
      enabled: false,
      onSuccess: async (data: FilterDataProps[]) => {
        setLoading(true)
        setTableData([])
        await sleep(0)
        setTableData(data)
        setLoading(false)
      }
    }
  )

  const saveMutation = useMutation((data: FilterDataProps[]) => postFilterHistory(data), {
    onSuccess: () => {
      success()
      refetch()
      setUnsaved(false)
    },
    onError: ErrorQuery
  })

  // 다운로드
  const onExport = async () => {
    const { company, factory, equipment } = info;
    const { startDate, endDate, replaceStartDate, replaceEndDate } = getValues();

    const prms: any = {
      companyId: company,
      factoryId: factory,
      equipmentId: equipment,
    }

    if (info.checked) {
      prms.nextReplaceStartDate = cvtDate(startDate);
      prms.nextReplaceEndDate = cvtDate(endDate);
    }

    if (info.filterChecked) {
      prms.replaceStartDate = cvtDate(replaceStartDate);
      prms.replaceEndDate = cvtDate(replaceEndDate);
    }
    await postExcelDownload(prms);
  };

  const onSave = () => {
    confirm({
      onOk: async () => {
        // call save api

        const filteredData = tableData.map(item => {
          item.history.map(el => {
            // uuid 지우기
            if (typeof el.id === 'string') {
              delete el.id;
            }

            if (Array.isArray(el.replaceCountByChamber)) {
              // 챔버 수 10개 맞추기
              if (el.replaceCountByChamber.length !== 10) {
                const len = el.replaceCountByChamber.length;
                const emptyArr = Array.from({ length: 10 - len }).map(el => "");
                el.replaceCountByChamber = JSON.stringify([...el.replaceCountByChamber, ...emptyArr]);
                return el;
              }
              el.replaceCountByChamber = JSON.stringify(el.replaceCountByChamber);
              return el;
            }
            return el;
          });

          return item;
        });
        await saveMutation.mutateAsync(filteredData)
      }
    })
  }

  useEffect(() => {
    //scroll to the top of the table when the sorting changes
    rowVirtualizerInstanceRef.current?.scrollToIndex(0)
  }, [sorting])

  useEffect(() => {
    refetch()
    setUnsaved(false)
  }, [])

  return (
    <>
      <Stack spacing={2}>
        <Card>
          <ContentMenu
            isFilter
            companyprops={{ enable: true }}
            factoryprops={{ enable: true }}
            equipmentprops={{ enable: true }}
            customNode={
              <>
              <Stack
                flexDirection={'row'}
                justifyContent="center"
                alignItems={'center'}
                columnGap={1}
                >
                <Tooltip title="사용/미사용">
                  <Checkbox
                    onChange={(e) => setInfo((prev) => ({ ...prev, checked: e.target.checked }))}
                    sx={{ mt: 0.3 }}
                  />
                </Tooltip>
                <CustomDateRange
                  title={'차기 교체일'}
                  disabled={!info.checked}
                  setValue={setValue}
                />
              </Stack>
              <Stack
                flexDirection={'row'}
                justifyContent="center"
                alignItems={'center'}
                columnGap={1}
                >
                <Tooltip title="사용/미사용">
                  <Checkbox
                    onChange={(e) => setInfo((prev) => ({ ...prev, filterChecked: e.target.checked }))}
                    sx={{ mt: 0.3 }}
                  />
                </Tooltip>
                <CustomDateRange
                  title={'필터백 교체일'}
                  disabled={!info.filterChecked}
                  setValue={setValue}
                  isFilter
                />
              </Stack>
              </>
              
              
            }
            setInfo={setInfo}
            actions={
              <Actions onExport={onExport} onSave={onSave} onSearch={refetch} />
              // <Actions onExport={onExport} onUpload={onUpload} onSave={onSave} onSearch={refetch} />
            }
          />
        </Card>

        {isLoading && (
          <BaseTable
            columns={columns}
            data={[]}
            state={{ isLoading }}
            muiTableContainerProps={{
              sx: {
                height: '640px'
              }
            }}
          />
        )}
        {!isLoading && (
          <BaseTable
            columns={columns}
            data={tableData}
            enableEditing
            editingMode="cell"
            initialState={{ pagination }}
            onPaginationChange={setPagination}
            state={{ isLoading, sorting, density: 'compact', pagination }}
            onSortingChange={setSorting}
            enableRowActions={false}
            enableRowSelection={false}
            rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
            renderDetailPanel={({ row }) => {
              return <FilterHistory data={row} setTableData={setTableData} refetch={refetch} />
            }}
            renderTopToolbarCustomActions={() => (
              <Box sx={{ pt: 1, pl: 1 }}>
                <Typography level="h5" fontWeight={700}>
                  {t('filter.filter_list')}
                </Typography>
              </Box>
            )}
            muiTableContainerProps={{
              sx: {
                height: '640px'
              }
            }}
          />
        )}
      </Stack>
    </>
  )
}
