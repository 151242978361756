import { FC } from 'react'
import { PlanSearchFormProps } from '@pages/repair/plan-list/plan-search-form/type'
import { Box, Checkbox, FormControl, FormLabel, Grid, Option, Radio, RadioGroup, Select, Stack, Tooltip, Typography } from '@mui/joy'
import { Stack as CustomStack } from '@core/components/menu/styles'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Controller } from 'react-hook-form'
import { RepairPlanSearchFormField } from '@pages/repair/type'
import { getFieldPropsWithForm } from '@core/helpers/form.helper'
import { renderEmptyRepairPlanSelectBox } from '@pages/repair/template'
import { MuiJoyDateRangePicker } from '@core/components/date-range'
import FormActions from '@core/components/form-actions'
import { useTranslation } from 'react-i18next'

/**
 * Plan search form
 * @param onSubmit
 * @param isAdmin
 * @param handleChangeCompany
 * @param listCompany
 * @param handleChangeFactory
 * @param selectedCompany
 * @param selectedFactory
 * @param handleUploadRepairPlan
 * @param handleExportDataToCsv
 * @param handleSaveChange
 * @param handleDeleteRow
 * @param disableDeleteAllRow
 * @param control
 * @param setValue
 * @constructor
 */
const PlanSearchForm: FC<PlanSearchFormProps> = ({
  onSubmit,
  isAdmin,
  handleChangeCompany,
  listCompany,
  handleChangeFactory,
  selectedCompany,
  selectedFactory,
  handleUploadRepairPlan,
  handleCreate,
  handleSaveChange,
  handleDeleteRow,
  disableDeleteAllRow = true,
  control,
  setValue,
  isCompleteDate,
  setCompleteDate,
  custom
}) => {
  const { t } = useTranslation([TranslateEnum.COMMON, TranslateEnum.PLAN])
  const listBoxProps = {
    sx: {
      maxHeight: 240,
      overflow: 'auto'
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid container>
        <Grid xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
          <Stack direction="row" spacing={4} justifyContent="flex-start">
            {isAdmin && (
              <CustomStack>
                <FormControl orientation={'horizontal'}>
                  <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
                    {t('plan.company', { ns: TranslateEnum.PLAN })}
                  </FormLabel>
                  <Controller
                    name={RepairPlanSearchFormField.COMPANY}
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                      <Select
                        {...getFieldPropsWithForm(field, value)}
                        placeholder={t('plan.company-placeholder', { ns: TranslateEnum.PLAN })}
                        onChange={(_, value) => {
                          handleChangeCompany(onChange, value)
                        }}
                        slotProps={{
                          button: {
                            id: 'company'
                          },
                          listbox: { ...listBoxProps }
                        }}>
                        {listCompany && listCompany.length > 0 ? (
                          <>
                            {listCompany.map((el, index) => (
                              <Option value={el.companyId} key={index}>
                                {el.companyName}
                              </Option>
                            ))}
                          </>
                        ) : (
                          renderEmptyRepairPlanSelectBox()
                        )}
                      </Select>
                    )}
                  />
                </FormControl>
              </CustomStack>
            )}

            <CustomStack>
              <FormControl orientation={'horizontal'}>
                <FormLabel id="factory-label" htmlFor="factory" sx={{ fontWeight: 'bold' }}>
                  {t('plan.factory', { ns: TranslateEnum.PLAN })}
                </FormLabel>
                <Controller
                  name={RepairPlanSearchFormField.FACTORY}
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Select
                      {...getFieldPropsWithForm(field, value)}
                      onChange={(_, value) => handleChangeFactory(onChange, value)}
                      placeholder={t('plan.factory-placeholder', { ns: TranslateEnum.PLAN })}
                      slotProps={{
                        button: {
                          id: 'factory'
                        },
                        listbox: { ...listBoxProps }
                      }}>
                      {selectedCompany &&
                        selectedCompany.factories &&
                        selectedCompany.factories.length > 0 ? (
                        <>
                          {selectedCompany.factories.map((el, index) => (
                            <Option value={el.factoryId} key={index}>
                              {el.factoryName}
                            </Option>
                          ))}
                        </>

                      ) : (
                        renderEmptyRepairPlanSelectBox()
                      )}
                    </Select>
                  )}
                />
              </FormControl>
            </CustomStack>
            <CustomStack>
              <FormControl orientation={'horizontal'}>
                <FormLabel id="equipment-label" htmlFor="equipment" sx={{ fontWeight: 'bold' }}>
                  {t('plan.equipment', { ns: TranslateEnum.PLAN })}
                </FormLabel>
                <Controller
                  name={RepairPlanSearchFormField.EQUIPMENT}
                  control={control}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Select
                      {...getFieldPropsWithForm(field, value)}
                      onChange={(_, value) => onChange(value)}
                      placeholder={t('plan.equipment-placeholder', { ns: TranslateEnum.PLAN })}
                      slotProps={{
                        button: {
                          id: 'equipment'
                        },
                        listbox: { ...listBoxProps }
                      }}>
                      {selectedFactory &&
                        selectedFactory.equipments &&
                        selectedFactory.equipments.length > 0 ? (
                        <>
                          {selectedFactory.equipments.map((el, index) => (
                            <Option value={el?.id?.toString() ?? el.header.name} key={index}>
                              {el && el.header && el.header.name}
                            </Option>
                          ))}
                        </>
                      ) : (
                        renderEmptyRepairPlanSelectBox()
                      )}
                    </Select>
                  )}
                />
              </FormControl>
            </CustomStack>
            <CustomStack>
              <Box
                sx={{ display: 'flex', gap: '12px' }}
              >
                <Radio
                  label='점검일자'
                  name='selected-radio'
                  checked={!isCompleteDate}
                  onChange={() => {
                    setValue(RepairPlanSearchFormField.IS_COMPLETE_DATE, !isCompleteDate)
                    setCompleteDate(false)
                  }}
                />
                <Radio
                  label='완료일자'
                  name='selected-radio'
                  checked={isCompleteDate}
                  onChange={() => {
                    setValue(RepairPlanSearchFormField.IS_COMPLETE_DATE, !isCompleteDate)
                    setCompleteDate(true)
                  }}
                />
              </Box>
              <FormControl orientation={'horizontal'}>
                <MuiJoyDateRangePicker
                  dateRange={{
                    startDate: new Date(),
                    endDate: new Date(),
                    key: 'selection'
                  }}
                  stateForm={{
                    setValueFn: setValue,
                    startDateFieldName: RepairPlanSearchFormField.START_DATE,
                    endDateFieldName: RepairPlanSearchFormField.END_DATE
                  }}
                  onDateRangeChange={(dateRange) => {
                    setValue(RepairPlanSearchFormField.START_DATE, dateRange?.startDate)
                    setValue(RepairPlanSearchFormField.END_DATE, dateRange?.endDate)
                  }}
                />
              </FormControl>
            </CustomStack>
            {!isCompleteDate && (
              <CustomStack>
                <FormControl orientation={'horizontal'}>
                  <FormLabel sx={{ fontWeight: 'bold' }}>
                    수리상태
                  </FormLabel>
                  <Controller
                    name={RepairPlanSearchFormField.REPAIR_STATUS}
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                      <Select
                        defaultValue='ALL'
                        value={value}
                        onChange={(_, value) => onChange(value)}
                        sx={{ width: '90px' }}
                      >
                        <Option value='ALL'>전체</Option>
                        <Option value='COMPLETE'>완료</Option>
                        <Option value='INCOMPLETE'>미완료</Option>
                      </Select>
                    )}
                  />
                </FormControl>
              </CustomStack>)}
          </Stack>
        </Grid>
        <Grid xs={2}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <FormActions
              disableDelete={disableDeleteAllRow}
              onUpload={handleUploadRepairPlan}
              custom={custom}
              onSave={handleSaveChange}
              onDelete={handleDeleteRow}
              onCreate={handleCreate}
            />
          </Stack>
        </Grid>
      </Grid>
    </form>
  )
}

export default PlanSearchForm
