import { ContentMenu } from '@core/components/menu'
import { ContentMenuDataProps } from '@core/components/menu/type'
import { DataTable } from '@core/components/table'
import { QueryKey } from '@core/enums/query.enum'
import { TranslateEnum } from '@core/enums/translate.enum'
import { sleep } from '@core/helpers/action.helper'
import { exportJsonToXlsx } from '@core/helpers/excel-sheet.helper'
import { ObjectToArrayFunc } from '@core/helpers/pages.helper'
import { Card, Stack } from '@mui/joy'
import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { CustomDateRange } from '../plan-management/template'
import { getInspectResult, postResultExcelDownload } from '../query'
import { ArrayFuncProps, InspectPlanProps, InspectResultProps } from '../type'
import { Actions, ColumnsHeader, TableColumns } from './template'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'

export const InspectResult = () => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const column = useMemo(() => ColumnsHeader, [])
  const [info, setInfo] = useState<ContentMenuDataProps>({})
  const [tableData, setTableData] = useState<ArrayFuncProps[]>([])

  const { setValue, getValues, watch } = useForm({
    defaultValues: {
      startDate: '',
      endDate: ''
    }
  })

  const { refetch, isLoading } = useQuery(
    [QueryKey.INSPECTRESULT],
    () => getInspectResult(authorizationModel, info, getValues),
    {
      enabled: false,
      onSuccess: async (data: (InspectPlanProps & InspectResultProps)[]) => {
        const newFormData = ObjectToArrayFunc(data)
        setTableData(newFormData)
      }
    }
  )

  const cvtDate = (date: string) => {
    const originalDate = new Date(date);

    const formattedDate = originalDate.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    return formattedDate.slice(0, 12).replaceAll(' ', '');
  };

  const onExport = async () => {
    const prms = {
      company: info.company,
      factory: info.factory,
      equipment: info.equipment,
      startDate: cvtDate(watch().startDate),
      endDate: cvtDate(watch().endDate)
    }
    await postResultExcelDownload(prms);
  }

  useEffect(() => {
    refetch()
  }, [])

  console.log(info);
  return (
    <>
      <Stack spacing={2}>
        <Card>
          <ContentMenu
            companyprops={{ enable: true }}
            factoryprops={{ enable: true }}
            equipmentprops={{ enable: true }}
            customNode={<CustomDateRange setValue={setValue} />}
            setInfo={setInfo}
            actions={<Actions onExport={onExport} onSearch={refetch} />}
          />
        </Card>

        <DataTable
          columns={column}
          data={tableData}
          enableEditing={false}
          enableRowSelection={false}
          enableRowActions={false}
          state={{ isLoading }}
        />
      </Stack>
    </>
  )
}
