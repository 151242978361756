import { FileDownloadOutlined, SearchOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/joy'
import { IconButton } from '@mui/material'
import { ActionProps } from '@pages/filter/type'
import { MRT_ColumnDef } from 'material-react-table'
import { ArrayFuncProps } from '../type'

export const Actions = ({ onExport, onSearch }: ActionProps) => {
  return (
    <>
      <Tooltip title="조회">
        <IconButton color="primary" onClick={onSearch}>
          <SearchOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="다운로드">
        <IconButton color="primary" onClick={onExport}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
    </>
  )
}

export const ColumnsHeader: MRT_ColumnDef<ArrayFuncProps>[] = [
  {
    header: '회사명',
    accessorKey: 'company'
  },
  {
    header: '공장명',
    accessorKey: 'factory'
  },
  {
    header: '관리번호',
    accessorKey: 'code'
  },
  {
    header: '설비명',
    accessorKey: 'name',
    minSize: 300
  },
  {
    header: '점검일',
    accessorKey: 'checkDate'
  },
  {
    header: '점검방법',
    accessorKey: 'checkType'
  },

  {
    header: '대분류',
    accessorKey: 'category'
  },
  {
    header: '소분류',
    accessorKey: 'subCategory'
  },
  {
    header: '조치계획',
    accessorKey: 'actionPlan',
  },
  {
    header: '확인결과',
    accessorKey: 'result',
    Cell: ({ cell }) => {
      const value = cell.getValue()
      if (value) {
        switch (Number(value)) {
          case 0:
            return '해당없음'
          case 1:
            return '불량'
          case 2:
            return '양호'
        }
      } else {
        return ''
      }
    }
  },
  {
    header: '현상',
    accessorKey: 'abnormality'
  },
  {
    header: '비고',
    accessorKey: 'note',
    Cell: ({ cell }) => {
      const value = cell.getValue<string[] | string>()
      if (Array.isArray(value)) {
        let str = ''
        value.forEach((item, index) => {
          index !== 0 ? (str += `#${index}:${item} | `) : null
        })
        return str.substring(0, str.length - 2)
      } else {
        return value
      }
    }
  },
  {
    header: '점검자',
    accessorKey: 'validater'
  },
  {
    header: '확인자',
    accessorKey: 'checker'
  },
  {
    header: '기타사항',
    accessorKey: 'totalNote'
  }
]

export const TableColumns: {
  [key: string]: string
} = {
  company: '회사명',
  factory: '공장명',
  code: '관리번호',
  name: '설비명',
  checkDate: '점검일자',
  checkType: '점검방법',
  validater: '점검자',
  checker: '확인자',
  category: '대분류',
  subCategory: '소분류',
  actionPlan: '조치계획',
  result: '확인결과',
  abnormality: '현상',
  note: '비고',
  totalNote: '기타사항'
}
