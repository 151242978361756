import React, { useState, useEffect, ChangeEvent } from 'react';
interface IProps {
  data: any[];
  curPage: number;
  articlePerPage: number;
}

const useCheckbox = ({ data, curPage, articlePerPage }: IProps) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedIdList, setCheckedIdList] = useState<Set<number>>(new Set());
  console.log(checkedIdList)
  const frontNum = (curPage - 1) * articlePerPage;
  const endNum = articlePerPage * curPage;

  const rangeData = data.slice(frontNum, endNum);
  const rangeIdListData = rangeData.map((item) => item.id);

  const copyCheckedIdList = new Set(checkedIdList);

  useEffect(() => {
    // 첫 렌더 및 페이지 변경 시, checkedAll의 상태
    const isCheckedAll = rangeIdListData.filter(item => [...copyCheckedIdList].includes(item));
    if (isCheckedAll.length === rangeIdListData.length
      && data.length > 0
    ) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [curPage]);

  const handleClickAllCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const checkedStatus = e.target.checked;

    const cvtArrIdList = [...checkedIdList];

    if (checkedStatus) {
      const setAddData = new Set([...cvtArrIdList, ...rangeIdListData]);
      setCheckedIdList(setAddData);
      setCheckedAll(true);
      return;
    }

    const filteredUnDeleteData = cvtArrIdList.filter((id => !rangeIdListData.includes(id as number)));
    const setDeleteData = new Set([...filteredUnDeleteData]);
    setCheckedIdList(setDeleteData);
    setCheckedAll(false);
  }

  const handleClickCheckbox = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    console.log(e.target.checked);
    const checkedStatus = e.target.checked;

    if (checkedStatus) {
      copyCheckedIdList.add(id);
      const isCheckedAll = rangeIdListData.filter(item => [...copyCheckedIdList].includes(item));
      if (isCheckedAll.length === rangeIdListData.length) {
        // 전체 체크박스 수동으로 클릭 시, 길이가 같으면
        setCheckedAll(true);
      }
      setCheckedIdList(copyCheckedIdList);
      return;
    }
    copyCheckedIdList.delete(id);
    setCheckedAll(false);
    setCheckedIdList(copyCheckedIdList);
  };

  return {
    checkedIdList,
    checkedAll,
    onClickCheckbox: handleClickCheckbox,
    onClickAllCheckbox: handleClickAllCheckbox
  };
};

export default useCheckbox;
