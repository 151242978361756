import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  Modal,
  ModalClose,
  Sheet,
  Stack,
  Typography
} from '@mui/joy'
import { AddCompanyFormProps, CompanyFormField, CompanyIndDto } from '@pages/settings/company/type'
import { Fade } from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'
import { String } from '@core/enums/common.enum'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'
import { Business, PhoneAndroid, Store } from '@mui/icons-material'
import { ConfirmSaveChange } from '@core/components/modal'
import { yupResolver } from '@hookform/resolvers/yup'
import { companyValidationSchema } from '@pages/settings/template'
import KakaoAddr from '@core/components/modal/common/KakaoAddr'

/**
 * Create company form
 * @param isOpen
 * @param onCloseFn
 * @param onSubmit
 * @param information
 * @constructor
 */
const CreateCompanyForm: FC<AddCompanyFormProps> = ({
  isOpen,
  onCloseFn,
  onSubmit,
  information
}) => {
  const { t } = useTranslation([TranslateEnum.COMPANY])
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors, dirtyFields, isSubmitted, isValid }
  } = useForm<CompanyIndDto>({
    resolver: yupResolver(companyValidationSchema(t))
  })
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isTriggerCloseModal, setTriggerCloseModal] = useState<boolean>(false)
  const [isKakaoAddr, setIsKakaoAddr] = useState(false)
  /**
   * Handle close function
   */
  const handleClose = useCallback(() => {
    if (Object.keys(dirtyFields).length > 0 && !isSubmitted) {
      setTriggerCloseModal(true)
    } else {
      setTriggerCloseModal(false)
      onCloseFn()
      reset({})
      setEditMode(false)
    }
  }, [dirtyFields, isSubmitted])

  /**
   * Handle click submit change company. Use for mode add and update
   */
  const handleSubmitCompany = useCallback(
    (data: CompanyIndDto) => {
      onSubmit(data, isEditMode)
    },
    [isEditMode]
  )

  /**
   * Confirm save change when click out form
   */
  const confirmSaveChange = async () => {
    await trigger()
    if (isValid) {
      const inDto = getValues()
      handleSubmitCompany(inDto)
      setTriggerCloseModal(false)
    }
  }

  /**
   * Effect when edit company
   */
  useEffect(() => {
    if (information) {
      reset(information)
      setEditMode(true)
    } else {
      reset()
      setEditMode(false)
    }
  }, [information])

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={isOpen}
        onClose={handleClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={isOpen}>
          <Sheet
            variant="outlined"
            sx={{
              width: 800,
              borderRadius: 3,
              p: 3,
              boxShadow: 'lg'
            }}>
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}>
              {isEditMode ? t('company.modal.titleUpdate') : t('company.modal.titleAdd')}
            </Typography>
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body'
              }}
            />
            <Stack spacing={2} sx={{ minHeight: 320, overflowY: 'auto', overflowX: 'hidden' }}>
              <form onSubmit={handleSubmit(handleSubmitCompany)}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid xs={12}>
                    <FormControl orientation="horizontal">
                      <Grid xs={3} sx={{ display: 'flex' }}>
                        <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 700 }}>
                          {t('company.searchForm.companyName')}
                        </FormLabel>
                      </Grid>
                      <Grid xs={9}>
                        <Input
                          disabled={isEditMode}
                          fullWidth
                          placeholder={
                            t('company.searchForm.companyNamePlaceHolder') || String.EMPTY_STRING
                          }
                          startDecorator={<Store />}
                          {...register(CompanyFormField.COMPANY_NAME)}
                        />
                        {errors && errors.companyName && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.companyName.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl orientation="horizontal">
                      <Grid xs={3}>
                        <FormLabel
                          id="company-address-label"
                          htmlFor="company-address"
                          sx={{ fontWeight: 'bold' }}>
                          {t('company.searchForm.companyAddress')}
                        </FormLabel>
                      </Grid>
                      <Grid xs={9}>
                        <Input
                          fullWidth
                          placeholder={
                            t('company.searchForm.companyAddressPlaceholder') || String.EMPTY_STRING
                          }
                          onClick={() => { getValues().companyAddress.length === 0 && setIsKakaoAddr(true) }}
                          startDecorator={<Business />}
                          {...register(CompanyFormField.COMPANY_ADDRESS)}
                        />
                        {isKakaoAddr && <KakaoAddr setOnModal={setIsKakaoAddr} setValue={setValue} />}
                        {errors && errors.companyAddress && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.companyAddress.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid xs={12}>
                    <FormControl orientation="horizontal">
                      <Grid xs={3}>
                        <FormLabel
                          id="company-phone-label"
                          htmlFor="company-phone"
                          sx={{ fontWeight: 'bold', minWidth: '4rem' }}>
                          {t('company.searchForm.companyPhone')}
                        </FormLabel>
                      </Grid>
                      <Grid xs={9}>
                        <Input
                          fullWidth
                          type="text"
                          placeholder={
                            t('company.searchForm.companyPhonePlaceHolder') || String.EMPTY_STRING
                          }
                          {...register(CompanyFormField.COMPANY_PHONE)}
                          startDecorator={<PhoneAndroid />}
                        />

                        {errors && errors.companyPhone && (
                          <FormHelperText component={'span'} className={'text-error'}>
                            {errors.companyPhone.message}
                          </FormHelperText>
                        )}
                      </Grid>
                    </FormControl>
                  </Grid>

                  <Grid
                    xs={12}
                    sx={{
                      mt: 2,
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center'
                    }}>
                    <Button sx={{ width: 106 }} color="warning" onClick={handleClose}>
                      {t('company.modal.btnCancel')}
                    </Button>
                    <Button sx={{ ml: 2, width: 106 }} color="primary" type="submit">
                      {t('company.modal.btnSubmit')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Stack>
          </Sheet>
        </Fade>
      </Modal>
      <ConfirmSaveChange
        onClose={() => setTriggerCloseModal(false)}
        subscribe={isTriggerCloseModal}
        onAccept={async () => await confirmSaveChange()}
      />
    </>
  )
}

export default CreateCompanyForm
