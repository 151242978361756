import { apis } from '@core/configs/api'
import { convertDateFormat } from '@core/helpers/date.helper'
import { request } from '@core/services/base.service'
import { AuthorizationModel } from '@core/models/authorization.model'
import _ from 'lodash'
import { CryptoHelperInstance } from '@core/helpers/crypto.helper'
import { getCookieByName } from '@core/helpers/cookie.helper'
import { CookieEnum } from '@core/enums/cookie.enum'

export const getTodayInspectPlan = async () => {
  const date = new Date()
  const params = {
    startDate: convertDateFormat(date),
    endDate: convertDateFormat(date),
    companyId: null
  }

  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )

  if (!isAdmin && companyId) {
    params.companyId = companyId
  }

  return request.get(apis.CHECKPLAN.path, _.omitBy(params, _.isNull))
}

export const getTodayInspectResult = async () => {
  const date = new Date()
  const params = {
    startDate: convertDateFormat(date),
    endDate: convertDateFormat(date),
    companyId: null
  }

  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )
  if (!isAdmin && companyId) {
    params.companyId = companyId
  }

  return request.get(apis.CHECKRESULT.path, _.omitBy(params, _.isNull))
}

export const getTodayRepairPlan = async () => {
  const date = new Date()
  const params = {
    startDate: convertDateFormat(date),
    endDate: convertDateFormat(date),
    companyId: null,
    isCompleteDate: false
  }

  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )

  if (!isAdmin && companyId) {
    params.companyId = companyId
  }

  return request.get(apis.REPAIR_TARGET.path, _.omitBy(params, _.isNull))
}

export const getTodayFilterbag = async () => {
  const date = new Date()
  const params = {
    startDate: convertDateFormat(date),
    endDate: convertDateFormat(date),
    companyId: null
  }

  const { companyId, isAdmin } =
    CryptoHelperInstance.decodeByAES256AndConvertToModel<AuthorizationModel>(
      getCookieByName(CookieEnum.COMPANY_ID)
    )

  if (!isAdmin && companyId) {
    params.companyId = companyId
  }
  return request.get(apis.FILTER.path, _.omitBy(params, _.isNull))
}
