import {
  FileDownloadOutlined,
  FileUploadOutlined,
  SaveOutlined,
  SearchOutlined,
  AddOutlined,
  RemoveOutlined
} from '@mui/icons-material'
import { Chip, Tooltip } from '@mui/joy'
import { ActionsProps } from './type'
import { MRT_ColumnDef } from 'material-react-table'
import { IconButton } from '@mui/material'
import { ExcelJSColumns, ExceljsModel } from '@core/models/exceljs.model'
import { Builder } from '@core/helpers/builder.helper'
import { exportTableToExcelFile } from '@core/helpers/table.helper'
import { CommonProps } from './equipment-detail/card/type'
export const Actions = ({
  onSearch,
  onUpload,
  onExport,
  onSave,
  onCreate,
  onDelete,
  disabled = false
}: ActionsProps) => {
  return (
    <>
      <Tooltip title="조회">
        <IconButton color="primary" onClick={onSearch}>
          <SearchOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="추가">
        <IconButton color="primary" onClick={onCreate}>
          <AddOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="삭제">
        <IconButton color="primary" onClick={onDelete} disabled={disabled}>
          <RemoveOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="다운로드">
        <IconButton color="primary" onClick={onExport}>
          <FileDownloadOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="업로드">
        <IconButton color="primary" aria-label="upload icon" component="label">
          <input id="file-upload" type="file" hidden onChange={onUpload} />
          <FileUploadOutlined />
        </IconButton>
      </Tooltip>
      <Tooltip title="저장" onClick={onSave}>
        <IconButton color="primary">
          <SaveOutlined />
        </IconButton>
      </Tooltip>
    </>
  )
}

export const ColumnsHeader: MRT_ColumnDef<CommonProps>[] = [
  {
    header: '회사명',
    accessorKey: 'header.company',
    enableEditing: false
  },
  {
    header: '공장명',
    accessorKey: 'header.factory',
    enableEditing: false
  },
  {
    header: '관리번호',
    accessorKey: 'header.code',
    enableEditing: false
  },
  {
    header: '설비명',
    accessorKey: 'header.name'
  },
  {
    header: '설비번호',
    accessorKey: 'header.equipmentCode'
  },
  {
    header: '집진용량',
    accessorKey: 'header.size'
  },
  {
    header: '용도',
    accessorKey: 'header.usage'
  },
  {
    header: '형식',
    accessorKey: 'header.type'
  },
  {
    header: '챔버 수',
    accessorKey: 'header.maxChamberCount',
    enableEditing: false
  },
  {
    header: '지정 교체주기',
    accessorKey: 'header.filterBagReplacementPeriodMonth'
  },
  {
    header: '점검방법',
    accessorKey: 'checkMethodName',
    enableEditing: false,
  },
  {
    header: '특기사항',
    accessorKey: 'header.note'
  }
]

export const TableColumns: {
  [key: string]: string
} = {
  company: '회사명',
  factory: '공장명',
  code: '관리번호',
  name: '설비명',
  equipmentCode: '설비번호',
  type: '형식',
  size: '집진용량',
  establishmentDate: '설치날짜',
  manufacturer: '제조사',
  usage: '용도',
  permission: '인.허가',
  generatedDust: '발생분진',
  checkMethodName: '점검방법',
  note: '특기사항',
  maxChamberCount: '챔버 수',
  filterBagReplacementPeriodMonth: '지정 교체주기'
}

/**
 * Export table with config to Excel file
 * @param listColumnsExport
 * @param listRepairPlanData
 */
export async function exportEquipmentData(
  listColumnsExport: Array<Partial<ExcelJSColumns>>,
  listRepairPlanData: any[]
) {
  if (listRepairPlanData && listRepairPlanData.length > 0) {
    const exportTableConfig = Builder<ExceljsModel>()
      .sheetName('sheet1')
      .defaultRowHeight(15)
      .headerFill({
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D6D6D6' }
      })
      .headerFont({
        name: 'Malgun Gothic (본문)',
        family: 4,
        size: 12,
        bold: true
      })
      .columns(listColumnsExport)
      .data(listRepairPlanData)
      .fileName(`equipment${new Date().getTime()}.xlsx`)
      .build()
    await exportTableToExcelFile(exportTableConfig)
  }
}
