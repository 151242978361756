import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Card, Stack, Typography } from '@mui/joy'
import SettingCompanySearchForm from '@pages/settings/company/search-form'
import {
  CompanyIndDto,
  CompanyModel,
  SettingCompanySearchFormInDto
} from '@pages/settings/company/type'
import { BaseTable } from '@core/components/table'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'
import {
  settingCompanyAndFactoryTableRowAction,
  settingCompanyTableColumns
} from '@pages/settings/template'
import { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table'
import {
  useGetListCompany,
  useMutateAddCompany,
  useMutateUpdateCompany,
  useMutationDeleteCompany
} from '@pages/settings/query'
import { confirm, deleteConfirm, fail, success } from '@core/components/modal'
import { FormProvider, useForm } from 'react-hook-form'
import { String } from '@core/enums/common.enum'
import CreateCompanyForm from '@pages/settings/company/create-form'
import { QueryOptionModel } from '@core/models/query.model'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { ErrorMessage } from '@core/enums/error.enum'

/**
 * Setting company component
 * @module: Setting
 * @author: Tommy
 * @constructor
 */
const SettingCompany: FC = () => {
  // Common variable
  const { t } = useTranslation([TranslateEnum.COMMON, TranslateEnum.COMPANY])
  const formSearchContext = useForm<SettingCompanySearchFormInDto>({
    defaultValues: {
      companyName: String.EMPTY_STRING
    }
  })

  // Component state
  const [isLoading, setLoading] = useState<boolean>(true)
  const [rowSelection, setRowSelection] = useState({})
  const [listCompany, setListCompany] = useState<CompanyModel[]>([])
  const [isOpenModal, setOpenModal] = useState<boolean>(false)
  const [rowEdited, setRowEdit] = useState<CompanyModel>()

  // Table variable
  const columns = useMemo<MRT_ColumnDef<CompanyModel | any>[]>(
    () => settingCompanyTableColumns(t),
    [t]
  )
  const tableInstanceRef = useRef<MRT_TableInstance<CompanyModel | any>>(null)
  const isDisableDeleteMultipleRows = useMemo(() => {
    const row = rowSelection && Object.keys(rowSelection)
    return row && row.length === 0
  }, [rowSelection])

  // React query
  const { refetch: getListCompany, isLoading: isLoadingGetListCompany } = useGetListCompany({
    enabled: false,
    onSuccess: (data: CompanyModel[]) => {
      setListCompany(data)
    }
  })

  const { mutate: mutateDeleteCompany, isLoading: isLoadingDeleteCompany } =
    useMutationDeleteCompany()

  const { mutate: mutateAddCompany } = useMutateAddCompany()
  const { mutate: mutateUpdateCompany } = useMutateUpdateCompany()

  /**
   * Handle delete company call back
   * This function show message confirm delete company
   * Use for delete single row, multiple rows
   */
  const handleDeleteCallback = useCallback(
    (callback: () => void) => {
      deleteConfirm({
        message: (
          <div>
            <div>{t('company.modal.delete.warningDelete', { ns: TranslateEnum.COMPANY })}</div>
            <Typography fontWeight={500}>
              {t('company.modal.delete.confirmDelete', { ns: TranslateEnum.COMPANY })}
            </Typography>
          </div>
        ),
        onOk: callback
      })
    },
    [tableInstanceRef?.current]
  )

  /**
   * Call delete company and call get list company again
   */
  const handleMutateDeleteCompany = useCallback(
    (listIds: number[]) => {
      mutateDeleteCompany(listIds, {
        onSuccess: () => {
          success({ message: '삭제성공!', onOk: () => getListCompany() })
        },
        onError: (err) => fail({ message: '삭제실패' })
      })
    },
    [rowSelection]
  )

  /**
   * Handle delete company
   */
  const handleDeleteCompany = useCallback(() => {
    const currentTableInstance = tableInstanceRef?.current
    if (currentTableInstance) {
      handleDeleteCallback(() => {
        const rowSelectOnTable = currentTableInstance.getSelectedRowModel()
        if (rowSelectOnTable && rowSelectOnTable.rows && rowSelectOnTable.rows.length > 0) {
          const rowSelection = rowSelectOnTable?.rows.map((data) => data.original)
          handleMutateDeleteCompany(
            rowSelection.filter((el) => el.companyId).map((el) => el.companyId)
          )
          setRowSelection({})
        }
      })
    }
  }, [])

  /**
   * Handle delete single company
   */
  const handleDeleteSingleCompany = useCallback((row: MRT_Row<any>) => {
    handleDeleteCallback(() => {
      const { original } = row
      if (original && original?.companyId) {
        handleMutateDeleteCompany([original?.companyId])
      }
    })
  }, [])

  /**
   * Handle create company
   */
  const handleCreateCompany = useCallback(() => {
    setOpenModal(true)
  }, [isOpenModal, rowEdited])

  /**
   * Handle submit change company
   */
  const handleSubmitChangeCompany = (data: CompanyIndDto, isEdit: boolean) => {
    confirm({
      onOk: () => {
        mutateAddCompany(data, {
          onSuccess: () => {
            setRowEdit(undefined)
            success({
              onOk: () => {
                getListCompany()
              }
            })
          },
          onError: () => {
            fail()
          }
        })

        setOpenModal(false)
      }
    })
  }

  /**
   * Handle click edit company actions
   */
  const handleClickEditCompanyActions = useCallback(
    (row: MRT_Row<CompanyModel | any>) => {
      if (row && row.original) {
        setRowEdit(row?.original)
        setOpenModal(true)
      }
    },
    [rowEdited, isOpenModal]
  )

  /**
   * Handle click close modal add and update company
   */
  const handleClosePopup = useCallback(() => {
    setOpenModal(false)
    setRowEdit(undefined)
  }, [rowEdited])

  /**
   * Initial Effect
   */
  useEffect(() => {
    const prepareData: any = async () => {
      await getListCompany()
    }
    prepareData()
  }, [])

  /**
   * Effect loading
   */
  useEffect(() => {
    setLoading(isLoadingGetListCompany || isLoadingDeleteCompany)
  }, [isLoadingGetListCompany, isLoadingDeleteCompany])

  return (
    <Stack spacing={2} className="setting-company-container">
      <Card>
        <FormProvider {...formSearchContext}>
          <SettingCompanySearchForm
            onCreate={handleCreateCompany}
            onDelete={handleDeleteCompany}
            onSubmit={() => getListCompany()}
            disableDeleteAll={isDisableDeleteMultipleRows}
          />
        </FormProvider>
      </Card>
      <BaseTable
        enableRowSelection
        enableRowActions
        enableSelectAll
        data={listCompany}
        columns={columns}
        state={{ isLoading, rowSelection }}
        onRowSelectionChange={setRowSelection}
        tableInstanceRef={tableInstanceRef}
        enableColumnDragging={true}
        muiTableContainerProps={{
          sx: {
            height: '600px'
          }
        }}
        renderTopToolbarCustomActions={() => (
          <Box sx={{ mt: 1, pl: 1 }} component={'span'}>
            <Typography level="h6" fontWeight={600}>
              {t('company.title', { ns: TranslateEnum.COMPANY })}
            </Typography>
          </Box>
        )}
        renderRowActionMenuItems={({ row, closeMenu }) =>
          settingCompanyAndFactoryTableRowAction(
            t,
            row,
            () => handleClickEditCompanyActions(row),
            () => handleDeleteSingleCompany(row),
            closeMenu
          )
        }
      />
      {isOpenModal && (
        <CreateCompanyForm
          isOpen={isOpenModal}
          onCloseFn={handleClosePopup}
          onSubmit={handleSubmitChangeCompany}
          information={rowEdited}
        />
      )}
    </Stack>
  )
}

export default SettingCompany
