import { FormControl, FormLabel, Grid, Option, Select, Stack } from '@mui/joy';
import React, { FC } from 'react';
import { Stack as CustomStack } from '@core/components/menu/styles'
import { renderEmptyRepairPlanSelectBox } from '@pages/repair/template';
import { Controller, useFormContext } from 'react-hook-form'
import { TranslateEnum } from '@core/enums/translate.enum';
import { useTranslation } from 'react-i18next';
import FormActions from '@core/components/form-actions';
import { getFieldPropsWithForm } from '@core/helpers/form.helper';
import { CompanyModel } from '@pages/settings/company/type';
import { SettingFactorySearchFormInDto } from '@pages/settings/factory/type';

interface IProps {
  onSubmit: (data: any) => void
  onDelete: () => void
  onCreate: () => void
  disableDeleteAll: boolean
  listCompany: CompanyModel[]
}

const InspectSearchForm: FC<IProps> = ({
  onSubmit,
  onCreate,
  onDelete,
  disableDeleteAll = true,
  listCompany,
}) => {
  const { handleSubmit, control, register } = useFormContext<SettingFactorySearchFormInDto>()
  const { t } = useTranslation([TranslateEnum.FACTORY])

  return (
    <Grid xs={12}>
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <Grid container spacing={2}>
          <Grid xs={10}>
            <Stack direction="row" spacing={4} justifyContent="flex-start">
              <CustomStack>
                <FormControl orientation={'horizontal'}>
                  <FormLabel id="company-label" htmlFor="company" sx={{ fontWeight: 'bold' }}>
                    {t('factory.searchForm.companyTitle')}
                  </FormLabel>
                  <Controller
                    name='companyId'
                    control={control}
                    render={({ field: { onChange, value, ...field } }) => (
                      <Select
                        {...getFieldPropsWithForm(field, value)}
                        placeholder={t('factory.searchForm.companyPlaceholder')}
                        onChange={(_, value) => {
                          onChange(value)
                        }}
                        slotProps={{
                          button: {
                            id: 'company'
                          }
                        }}>
                        {listCompany && listCompany.length > 0 ? (
                          <>
                            {
                              <Option value={undefined} key={0}>
                                전체조회
                              </Option>
                            }
                            {listCompany.map((el, index) => (
                              <Option value={el.companyId} key={el.companyId}>
                                {el.companyName}
                              </Option>
                            ))}
                          </>
                        ) : (
                          renderEmptyRepairPlanSelectBox()
                        )}
                      </Select>
                    )}
                  />
                </FormControl>
              </CustomStack>
            </Stack>
          </Grid>

          <Grid xs={2}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              <FormActions
                onCreate={onCreate}
                onDelete={onDelete}
                disableDelete={disableDeleteAll}
              />
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default InspectSearchForm;