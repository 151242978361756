import { CommonProps } from './type'
import { confirm } from '@core/components/modal'
import { UseMutationResult } from '@tanstack/react-query'

export const onSubmit = (
  data: CommonProps,
  saveMutation: UseMutationResult<any, unknown, CommonProps, unknown>
) => {
  if (data.id) {
    // id 존재시에만 저장
    confirm({
      onOk: async () => {
        // call save api
        const { picture, diagram } = data.images
        if (picture && typeof picture === 'object') {
          if (!Array.isArray(picture)) {
            if (picture.name && picture.name !== '') {
              data.images.picture = picture.name
            }
          }
        }

        if (diagram && typeof diagram === 'object') {
          if (!Array.isArray(diagram)) {
            if (diagram.name && diagram.name !== '') {
              data.images.diagram = diagram.name
            }
          }
        }

        await saveMutation.mutateAsync(data)
      }
    })
  }
}
