import { deleteConfirm, savedCheckModal, success, warn } from '@core/components/modal'
import { BaseTable } from '@core/components/table'
import { QueryKey } from '@core/enums/query.enum'
import { sleep } from '@core/helpers/action.helper'
import { ErrorQuery } from '@core/helpers/query.helper'
import { AddOutlined, DeleteOutlined, FileDownloadOutlined } from '@mui/icons-material'
import ListIcon from '@mui/icons-material/List';
import { Box, Button, Grid, Tooltip, Typography } from '@mui/joy'
import { IconButton } from '@mui/material'
import { FilterDataProps } from '@pages/dashboard/type'
import { saveState } from '@pages/recoil'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { MRT_Cell, MRT_Row, MRT_TableInstance } from 'material-react-table'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { deleteFilterHistory, postFilterImg, postImgExcelDownload } from '../query'
import { FilterSubProps } from '../type'
import _ from 'lodash'
import styled from 'styled-components'
import ImgModal from '../imgModal/ImgModal'
import DetailInterval from '../detailInterval/DetailInterval'
import { initImgData } from '../init'
import ReplacementRow from '../replacement/ReplacementRow'
import useCheckbox from '@pages/service/useCheckbox'
import uuid from 'react-uuid'
import Pagination from '../pagination'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import { CompanyListProps } from '@core/components/menu/type'
import { getCompanyInfo } from '@core/components/menu/query'
interface FilterHistoryProps {
  data: MRT_Row<FilterDataProps>
  setTableData: Dispatch<SetStateAction<FilterDataProps[]>>
  refetch: any
}

export const FilterHistory = ({ data, setTableData, refetch }: FilterHistoryProps) => {
  const { history } = data.original
  const originData = data.original;

  const intervalRef = useRef<any>();
  const [onInterval, setOnInterval] = useState(false);
  const [curPage, setCurPage] = useState(1);
  const [articlePerPage, setArticlePerPage] = useState(5);
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const [info, setInfo] = useState<any>();

  const {
    checkedIdList,
    checkedAll,
    onClickCheckbox,
    onClickAllCheckbox
  } = useCheckbox({
    data: history,
    curPage,
    articlePerPage
  });

  const { data: companyInfo } = useQuery([QueryKey.COMPANYINFO], () => getCompanyInfo(authorizationModel), {
    onSuccess: (data: CompanyListProps[]) => {
      const { company: companyName, factory: factoryName, name: equipmentName } = originData;
      const companyInfo: any = data.find(el => el.companyName === companyName);
      const factoryInfo: any = companyInfo.factories.find((el: any) => el.factoryName === factoryName);
      const equipInfo = factoryInfo.equipments.find((el: any) => el.header.name === equipmentName);
      setInfo({
        companyId: companyInfo.companyId,
        factoryId: factoryInfo.factoryId,
        equipmentId: equipInfo.id
      });
    }
  });

  const onCreate = () => {
    const { index } = data
    // index는 교체이력 위에 idx
    console.log(index);
    setTableData((prev) => {
      if (prev[index].history) {
        prev[index].history = [
          {
            id: uuid(),
            timesDate: '',
            replaceCountByChamber: Array.from({ length: Number(originData.maxChamberCount) }).map(el => ''),
            images: {}
          },
          ...prev[index].history,
        ]
      }
      return _.cloneDeep(prev);
    })
  }

  const deleteMutation = useMutation((data: number[]) => deleteFilterHistory(data), {
    onSuccess: () => {
      refetch()
      // setRowSelection({})
      success({
        title: '삭제성공!'
      })
    },
    onError: ErrorQuery
  })

  const onDelete = () => {
    deleteConfirm({
      message: (
        <div>
          <div>교체이력 삭제시 서버에 바로 반영됩니다. </div>
          <Typography fontWeight={500}>정말 삭제하시겠습니까?</Typography>
        </div>
      ),
      onOk: async () => {
        const unsavedLen = [...checkedIdList].filter(el => typeof el === 'string').length;
        if (unsavedLen > 0) {
          savedCheckModal();
          return;
        }
        await deleteMutation.mutateAsync([...checkedIdList]);
      }
    })
  }

  const onExport = async () => {
    const prms = info;
    await postImgExcelDownload(prms);
  }
  const filteredList = history.slice((curPage - 1) * 5, articlePerPage * curPage)

  return (
    <Wrap sx={{ border: '1px solid lightgray' }} onClick={() => setOnInterval(false)}>
      <HeaderWrap>
        <span>교체이력</span>
        <Tooltip title="이력추가">
          <IconButton color="primary" onClick={onCreate}>
            <AddOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="이력삭제">
          <IconButton
            color="error"
            onClick={onDelete}
            disabled={checkedIdList.size === 0}>
            <DeleteOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip title="다운로드">
          <IconButton color="primary" onClick={onExport}>
            <FileDownloadOutlined />
          </IconButton>
        </Tooltip>
        <IntervalWrap>
          <Tooltip title='챔버별 상세 주기'>
            <IconButton
              color='info'
              onClick={(e: any) => {
                e.stopPropagation();
                setOnInterval(!onInterval);
              }}
            >
              <ListIcon />
            </IconButton>
          </Tooltip>
          {onInterval && <DetailInterval
            ref={intervalRef}
            data={data.original.lastReplacementsByChamber}
          />}
        </IntervalWrap>
      </HeaderWrap>

      <MainWrap>
        <TableWrap>
          <TableHeaderTr>
            <th>
              <input
                type='checkbox'
                checked={checkedAll}
                onChange={(e) => onClickAllCheckbox(e)}
              />
            </th>
            <th>#</th>
            <th>교체일</th>
            <th>챔버별 교체수량</th>
            <th>필터백 작업 이미지 업로드</th>
          </TableHeaderTr>
          {/* tr을 map돌기 */}
          {filteredList.map((item, rowIdx: number) => {
            return <ReplacementRow
              key={item.id}
              data={item}
              mainRowIdx={data.index}
              rowIdx={rowIdx}
              curPage={curPage}
              articlePerPage={articlePerPage}
              chamCount={Number(originData.maxChamberCount)}
              checkedIdList={checkedIdList}
              onClickCheckbox={onClickCheckbox}
              setTableData={setTableData}
            />
          })}
          <Pagination
            curPage={curPage}
            articlePerPage={articlePerPage}
            setCurPage={setCurPage}
            data={history}
          />
        </TableWrap>
      </MainWrap>
    </Wrap >
  )
}

// 1. 컴포넌트를 만들고
// 2. 그 컴포넌트 안에서 작업한 것을 tableData로 직행


const Wrap = styled(Box)`
  border: 1px solid lightgray;
  padding-left: 12px;
`;

const HeaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  > span {
    font-size: 18px;
    font-weight: 300;
  }
`;

const MainWrap = styled.div`

`;

const TableWrap = styled.table`
  width: 100%;
`;

const TableHeaderTr = styled.tr`
  display: flex;
  > th {
    font-weight: 500;
  }

  > th:nth-child(1) {
    flex: 10%;
  }
  > th:nth-child(2) {
    flex: 10%;
  }
  > th:nth-child(3) {
    flex: 10%;
  }
  > th:nth-child(4) {
    flex: 50%;
  }
  > th:nth-child(5) {
    flex: 20%;
  }
`;

const TableBodyTr = styled.tr`
display: flex;
padding: 8px 0;

  > td {
    display: flex;
    align-items: center;
  }

  > td:nth-child(1) {
    flex: 10%;
  }
  > td:nth-child(2) {
    flex: 10%;
  }
  > td:nth-child(3) {
    flex: 10%;
  }
  > td:nth-child(4) {
    flex: 50%;
  }
  > td:nth-child(5) {
    flex: 20%;
  }
`;

const ChamberWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  > span:nth-child(2) {
    font-size: 16px;
  }

  > input:nth-child(3) {
    width: 50px;
    height: 18px;
  }
`;

const DashLine = styled.div`
  width: 1px;
  height: 16px;
  border-right: 2px solid lightgray;
`;

const IntervalWrap = styled.div`
  position: relative;
`;