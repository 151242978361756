import { Stack } from '@mui/joy'
import { FC, useEffect, useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { InspectList, RepairList, FilterList } from './list'
import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@core/enums/query.enum'
import { getCompanyInfo } from '@core/components/menu/query'
import { useRecoilValue } from 'recoil'
import { CompanyListProps } from '@core/components/menu/type'
import { EquipmentStatus, FilterBagStatus, InspectStatus, RepairStatus } from './status'
import {
  getTodayFilterbag,
  getTodayInspectPlan,
  getTodayInspectResult,
  getTodayRepairPlan
} from './query'
import { InspectPlanProps } from '@pages/inspect/type'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import { RepairPlanModel } from '@pages/repair/type'
import { FilterDataProps } from './type'

/**
 * Dashboard component
 * @constructor
 */
const Dashboard: FC = () => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const [totalEquipment, setTotalEquipment] = useState(0)
  const { refetch } = useQuery([QueryKey.COMPANYINFO], () => getCompanyInfo(authorizationModel), {
    onSuccess: (data: CompanyListProps[]) => {
      let count = 0
      if (data && data.length > 0) {
        data.forEach((company) => {
          company.factories?.forEach((factory) => {
            count += factory.equipments?.length ?? 0
          })
        })
      }

      setTotalEquipment(count)
    }
  })

  const { data: todayInspectPlan } = useQuery([QueryKey.INSPECTPLAN], () => getTodayInspectPlan())

  const { data: todayInspectResult } = useQuery([QueryKey.INSPECTRESULT], () =>
    getTodayInspectResult()
  )

  const { data: todayRepairPlan } = useQuery([QueryKey.REPAIRPLAN], () => getTodayRepairPlan())
  const { data: filterBagManage } = useQuery([QueryKey.FILTER], () => getTodayFilterbag())

  useEffect(() => {
    refetch()
  }, [])
  return (
    <Stack spacing={3}>
      <Grid container spacing={3}>
        <Grid xs={12} md={6} lg={3}>
          <EquipmentStatus value={totalEquipment} />
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <InspectStatus
            plan={(todayInspectPlan as InspectPlanProps[]) ?? []}
            result={(todayInspectResult as InspectPlanProps[]) ?? []}
          />
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <RepairStatus data={(todayRepairPlan as RepairPlanModel[]) ?? []} />
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <FilterBagStatus data={(filterBagManage as FilterDataProps[]) ?? []} />
        </Grid>
      </Grid>
      <Grid>
        <InspectList data={(todayInspectPlan as InspectPlanProps[]) ?? []} />
      </Grid>
      <Grid>
        <RepairList data={(todayRepairPlan as RepairPlanModel[]) ?? []} />
      </Grid>
      <Grid>
        <FilterList data={(filterBagManage as FilterDataProps[]) ?? []} />
      </Grid>
    </Stack>
  )
}

export default Dashboard
