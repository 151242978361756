export enum PlaceHolderEnum {
  COMPENY = '회사를 선택해주세요.',
  FACTORY = '부서를 선택해주세요.',
  CODE = '관리번호를 입력해주세요.',
  NAME = '설비명을 입력해주세요.',
  EQUIPMENTCODE = '설비번호를 입력해주세요',
  TYPE = '형식을 입력해주세요.',
  SIZE = '집진용량을 입력해주세요.',
  MANUFACTURER = '제조사를 입력해주세요.',
  USAGE = '용도를 입력해주세요.',
  PERMISSION = '인.허가를 입력해주세요.',
  GENERATEDDUST = '발생분진을 입력해주세요.',
  NOTE = '특기사항을 입력해주세요.',
  CHECKTYPE = '점검방법을 선택해주세요.',
  CHAMBER_MAX_NUM = '챔버 수를 입력해주세요.',
  CHANGE_PERIOD_MONTH = '필터백 교체 주기를 월 단위로 입력해주세요.'
}
