import Modal from '@core/components/modal/common/Modal';
import React, { SetStateAction, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ClearIcon from '@mui/icons-material/Clear';
import { Button } from '@mui/joy';
import { postFilterImg } from '../query';
import { imgProps } from '../type';
import * as _ from 'lodash';
import { warn } from '@core/components/modal';
import { convertBase64ToArrayBuffer, convertUint8ArrayToBase64Images, urlToBase64 } from '@core/helpers/file.helper';
import { getUrlImagesWithImagesId } from '@pages/repair/query';
interface IProps {
  rowData: any;
  imgData: imgProps[];
  onClose: () => void;
  onClick: () => void;
  setImgData: React.Dispatch<SetStateAction<imgProps[]>>;
}
const ImgModal: React.FC<IProps> = ({ rowData, imgData, onClick, onClose, setImgData }) => {
  const [previewData, setPreviewData] = useState(new Array(imgData.length).fill(''));
  const [hoveredStates, setHoveredStates] = useState<boolean[]>(new Array(imgData.length).fill(false));

  const handleClickImgUpload = async (e: any, idx: number) => {
    const file = e.target.files?.[0];
    if (file.type) {
      if (!file.type.startsWith('image/')) {
        warn({ title: '경고', message: '잘못 된 형식의 파일입니다.' })
      } else {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const result = e.target?.result;
          console.log(result);
          if (result) {
            const arrayBuffer = await convertBase64ToArrayBuffer(result as string);
            console.log(arrayBuffer);
            setImgData((prev: imgProps[]) => {
              prev[idx] = {
                ...prev[idx],
                data: [...arrayBuffer],
                key: '',
                fileName: file.name
              }
              return _.cloneDeep(prev);
            })
          }
        }
        reader.readAsDataURL(file);
      }
    }
  }

  const handleDeleteImgUpload = (e: any, idx: number) => {
    setImgData((prev) => {
      prev[idx] = {
        ...prev[idx],
        data: null,
        key: '',
        fileName: ''
      }
      return _.cloneDeep(prev);
    });
  }

  useEffect(() => {
    for (let i = 0; i < imgData.length; i++) {
      const target = imgData[i].data;
      if (typeof target === 'string' && target.length > 0) {
        urlToBase64(getUrlImagesWithImagesId(target)).then(res => {
          previewData[i] = res;
          setPreviewData(previewData);
        });
      }
    }
  }, []);

  console.log(previewData);

  return (
    <Modal onClose={onClose}>
      <Wrap>
        <HeaderWrap>
          <span>필터백 작업 이미지 업로드</span>
          <DeleteBtn onClick={onClose} />
        </HeaderWrap>

        <MainWrap>
          {imgData.map((item, idx) => {
            return (
              <ItemWrap key={item.viewName} $hasFile={!!item.data}>
                <div>
                  <span>{item.viewName}</span>
                  <button
                    onMouseEnter={() => setHoveredStates((prev) => prev.map((_, i) => i === idx))}
                    onMouseLeave={() => setHoveredStates((prev) => prev.map(() => false))}
                  >
                    미리보기
                  </button>
                  {item.data && hoveredStates[idx] && (
                    <img
                      src={(typeof item.data === 'string' && item.data.length > 0)
                        ? previewData[idx]
                        : convertUint8ArrayToBase64Images(item.data, 'image/png')
                      }
                      alt='view'
                    />
                  )}
                </div>
                <div>
                  <label htmlFor={item.viewName}>
                    <input
                      id={item.viewName}
                      type='file'
                      style={{ display: 'none' }}
                      onChange={(e: any) => handleClickImgUpload(e, idx)}
                    />
                    {item.data ? '파일 수정' : '파일 업로드'}
                  </label>
                  {!!item.data && <DeleteBtn
                    sx={{ width: '18px', height: '18px' }}
                    onClick={(e: any) => handleDeleteImgUpload(e, idx)}
                  />}
                </div>
              </ItemWrap>
            )
          })}
        </MainWrap>

        <BtnWrap>
          <Button
            variant='outlined'
            sx={{ width: '15%', height: '40px' }}
            onClick={onClose}
          >
            취소
          </Button>
          <Button
            sx={{ width: '15%' }}
            onClick={onClick}
          >
            추가
          </Button>
        </BtnWrap>
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;
  width: 700px;
  height: 400px;
  padding: 24px;
  border-radius: 8px;
  background-color: white;
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'GmarketSans_R';
  font-size: 19px;
  align-items: center;
  color: black;

  > img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const MainWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  > div {
    flex: calc(50% - 18px);
    margin-bottom: 18px;
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 24px;
`;

const ItemWrap = styled.div<{ $hasFile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div:nth-child(1) {
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    > span {
      font-size: 16px;
      width: 120px;
    }

    > button {
      color: #ffffff;
      border: none;
      background-color: #698ae2;
      border-radius: 4px;
      padding: 3px 12px;
      font-size: 12.5px;
      cursor: pointer;
    }

    > img {
      position: absolute;
      top: 0;
      left: 210px;
      width: 250px;
      height: 250px;
      z-index: 999999;
      background-color: #ffffff;
      border: 1px solid lightgrey;
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    height: 40px;
    gap: 8px;

    > label {
      border: 2px solid rgb(244, 244, 244);
      width: 90%;
      padding: 4px 12px;
      text-align: center;
      color: #8a92a6;
      cursor: pointer;
      border-radius: 4px;

      ${({ $hasFile }) => $hasFile ? css`
      color: #ffffff;
      background-color: #698ae2;
      border: 2px solid #698ae2;
      transition: 0.25s;
      cursor: auto;
      ` : undefined}

      &:hover {
        color: #ffffff;
        background-color: #436ddb;
        border: 2px solid #436ddb;
        transition: 0.25s;
        cursor: pointer;
      }
    }
  }
`;

const DeleteBtn = styled(ClearIcon)`
  cursor: pointer;
`;

export default ImgModal;