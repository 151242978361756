import { LinkOutlined, LinkOffOutlined } from '@mui/icons-material'
import { Stack, Tooltip } from '@mui/joy'
import { IconButton } from '@mui/material'
interface ActionsProps {
  onRegister?: (register: boolean) => void
  disabled?: boolean
}
export const Actions = ({ onRegister, disabled }: ActionsProps) => {
  return (
    <>
      {onRegister && (
        <Stack direction="row" spacing={1}>
          <Tooltip title="서비스 해제" sx={{ zIndex: 9999 }}>
            <IconButton color="error" disabled={disabled} onClick={() => onRegister(false)}>
              <LinkOffOutlined fontSize="large" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      {onRegister && (
        <Stack direction="row" spacing={1}>
          <Tooltip title="서비스 연동" sx={{ zIndex: 9999 }}>
            <IconButton color="primary" disabled={disabled} onClick={() => onRegister(true)}>
              <LinkOutlined fontSize="large" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </>
  )
}
