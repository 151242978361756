import { success } from '@core/components/modal'
import { QueryKey } from '@core/enums/query.enum'
import { putEquipmentList } from '@pages/equipment/query'
import { editState } from '@pages/recoil'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { BagFilter } from '../bagfilter'
import { onSubmit } from '../helper'
import { Scrubber } from '../scrubber'
import { CommonCardProps, CommonProps } from '../type'

export const Content = ({ data, refetch, type }: CommonCardProps) => {
  const queryClient = useQueryClient()
  const setEdit = useSetRecoilState(editState)
  const formControl = useForm<CommonProps>()

  const saveMutation = useMutation((data: CommonProps) => putEquipmentList(data), {
    onSuccess: () => {
      success()
      refetch()
      setEdit(false)
    }
  })

  useEffect(() => {
    setEdit(formControl.formState.isDirty)
  }, [formControl.formState.isDirty])

  useEffect(() => {
    if (data) {
      formControl.reset(data)
    }
  }, [data])
  console.log(type);
  const isLoading = queryClient.isFetching([QueryKey.EQUIPMENTDETAIL])
  return (
    <form
      id="detail-save"
      onSubmit={formControl.handleSubmit((data) => onSubmit(data, saveMutation))}>
      {type === 'BF' ? (
        <BagFilter formControl={formControl} isLoading={isLoading} />
      ) : (
        <Scrubber formControl={formControl} isLoading={isLoading} />
      )}
    </form>
  )
}
