import { MRT_Localization } from 'material-react-table'

export const MRT_Localization_KR: MRT_Localization = {
  actions: '액션',
  and: '그리고',
  cancel: '취소',
  changeFilterMode: '필터모드 변경',
  changeSearchMode: '검색모드 변경',
  clearFilter: '필터 초기화',
  clearSearch: '검색 초기화',
  clearSort: '정렬 초기화',
  clickToCopy: '복사하기',
  collapse: '펼치기',
  collapseAll: '모두 펼치기',
  columnActions: '열 액션',
  copiedToClipboard: '클립보드로 복사',
  dropToGroupBy: '그룹으로 지정할 {column} 항목을 여기에 드랍해주세요.',
  edit: '수정',
  expand: '펼치기',
  expandAll: '모두 펼치기',
  filterArrIncludes: '포함하다',
  filterArrIncludesAll: '모두 포함하다',
  filterArrIncludesSome: '포함하다',
  filterBetween: '사이',
  filterBetweenInclusive: '사이를 포함',
  filterByColumn: '{column} 필터',
  filterContains: '포함',
  filterEmpty: '빈',
  filterEndsWith: '끝나다',
  filterEquals: '동등',
  filterEqualsString: '동등',
  filterFuzzy: '흐린',
  filterGreaterThan: 'Greater Than',
  filterGreaterThanOrEqualTo: 'Greater Than Or Equal To',
  filterInNumberRange: 'Between',
  filterIncludesString: 'Contains',
  filterIncludesStringSensitive: 'Contains',
  filterLessThan: 'Less Than',
  filterLessThanOrEqualTo: 'Less Than Or Equal To',
  filterMode: '필터모드: {filterType}',
  filterNotEmpty: 'Not Empty',
  filterNotEquals: 'Not Equals',
  filterStartsWith: 'Starts With',
  filterWeakEquals: 'Equals',
  filteringByColumn: 'Filtering by {column} - {filterType} {filterValue}',
  goToFirstPage: '첫번째 페이지로 이동',
  goToLastPage: '마지막 페이지로 이동',
  goToNextPage: '다음 페이지로 이동',
  goToPreviousPage: '이전 페이지로 이동',
  grab: 'Grab',
  groupByColumn: '{column}으로 그룹화',
  groupedBy: '그룹 항목 ',
  hideAll: '모두 숨기기',
  hideColumn: '{column} 열 숨기기',
  max: '최대',
  min: '최소',
  move: '이동',
  noRecordsToDisplay: '데이터가 존재하지 않습니다.',
  noResultsFound: '결과를 찾을 수 없습니다.',
  of: 'of',
  or: '또는',
  pinToLeft: '왼쪽으로 고정',
  pinToRight: '오른쪽으로 고정',
  resetColumnSize: '열 사이즈 초기화',
  resetOrder: 'Reset order',
  rowActions: 'Row Actions',
  rowNumber: '#',
  rowNumbers: 'Row Numbers',
  rowsPerPage: '페이지당 행 개수',
  save: '저장',
  search: '검색',
  selectedCountOfRowCountRowsSelected: '행 {rowCount}개 중 {selectedCount}개 선택',
  select: '선택',
  showAll: '모두 보이기',
  showAllColumns: '모든 열 보이기',
  showHideColumns: '열 보이기/숨기기',
  showHideFilters: '필터 보이기/숨기기',
  showHideSearch: '검색 보이기/숨기기',
  sortByColumnAsc: '오름차순 {column} 정렬',
  sortByColumnDesc: '내림차순 {column} 정렬',
  sortedByColumnAsc: '오름차순 정렬됨',
  sortedByColumnDesc: '내림차순 정렬됨',
  thenBy: ', then by ',
  toggleDensity: '테이블 크기 변경',
  toggleFullScreen: '전체/창 모드',
  toggleSelectAll: 'Toggle select all',
  toggleSelectRow: 'Toggle select row',
  toggleVisibility: 'Toggle visibility',
  ungroupByColumn: 'Ungroup by {column}',
  unpin: '고정해제',
  unpinAll: '모두 고정해제',
  unsorted: '정렬되지 않음'
}
