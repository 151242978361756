import { Modal, ModalClose, Sheet, Stack } from '@mui/joy'
import { Fade } from '@mui/material'
import { useRef, useState } from 'react'
import { SelectOptionType } from '../type'
import { useEffect } from 'react'
import { DetailTop } from './top'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { companyInfoState } from '@core/components/menu/recoil'
import { factoryListState } from '../recoil'
import { getCheckType } from '../equipment-create/query'
import { QueryKey } from '@core/enums/query.enum'
import { editState } from '@pages/recoil'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'
import { CommonProps } from './card/type'
import { getEquipmentDetail } from '../query'
import { urlToBase64 } from '@core/helpers/file.helper'
import { getUrlImagesWithImagesId } from '@pages/repair/query'
import { Content } from './card/common/content'

interface ShowDetailProps {
  info: {
    id: string
    type: string
  }
  open: boolean
  onClose?: () => void
  onEdit?: () => React.MouseEventHandler<HTMLAnchorElement>
}

export const EquipmentDetail = ({ info, open, onClose }: ShowDetailProps) => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const companyId = authorizationModel.isAdmin ? null : authorizationModel.companyId.toString()
  const companyInfo = useRecoilValue(companyInfoState)
  const setFactoryObject = useSetRecoilState(factoryListState)
  const setEdit = useSetRecoilState(editState)
  const [data, setData] = useState<CommonProps | undefined>(undefined)

  const { refetch } = useQuery([QueryKey.CHECKTYPE], getCheckType, {
    enabled: false
  })
  console.log(info);
  const { data: equipData, refetch: equipmentDetailFetch } = useQuery(
    [QueryKey.EQUIPMENTDETAIL],
    () => getEquipmentDetail(info.id),
    {
      enabled: false,
      onSuccess: async (data: CommonProps) => {
        const { picture, diagram } = data.images
        if (picture && typeof picture === 'string') {
          data.images.picture = {
            name: picture,
            content: (await urlToBase64(getUrlImagesWithImagesId(picture))) as string
          }
        }

        if (diagram && typeof diagram === 'string') {
          data.images.diagram = {
            name: diagram,
            content: (await urlToBase64(getUrlImagesWithImagesId(diagram))) as string
          }
        }
        setData(data)
      }
    }
  )

  console.log(equipData)

  useEffect(() => {
    refetch()
    const tempObj: { [key: string]: SelectOptionType[] } = {}

    companyInfo.forEach((company) => {
      if (company.factories) {
        const array = company.factories.map((item) => ({
          id: item.factoryId,
          name: item.factoryName
        }))
        tempObj[company.companyName] = array
      }
    })
    setFactoryObject(tempObj)
    setEdit(false)
  }, [])

  useEffect(() => {
    if (open) {
      equipmentDetailFetch()
    }
  }, [open])

  // print
  const componentRef = useRef(null)

  // 재요청
  return (
    <>
      <Modal
        keepMounted
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={open}>
          <Sheet
            variant="outlined"
            sx={{
              width: '90vw',
              borderRadius: 3,
              p: 3,
              boxShadow: 'lg'
            }}>
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body'
              }}
            />
            <Stack spacing={2}>
              <DetailTop printRef={componentRef} />
              <Stack
                spacing={1}
                sx={{ height: '85vh', overflowY: 'auto' }}
                ref={componentRef}
                className="scroll-container">
                <Content data={data} refetch={refetch} type={equipData?.checkType || ''} />
              </Stack>
            </Stack>
          </Sheet>
        </Fade>
      </Modal>
    </>
  )
}
