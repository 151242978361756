import React from 'react'
import ReactDOM from 'react-dom/client'
import Application from './App'
import reportWebVitals from './reportWebVitals'
import { StyledEngineProvider } from '@mui/material/styles'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <StyledEngineProvider injectFirst>
    <Application />
  </StyledEngineProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
const sendToAnalytics = ({ id, name, value }: any) => {
  const { ga }: any = window
  if (ga) {
    ga('send', 'event', {
      eventCategory: 'Web Vitals',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
      eventLabel: id,
      nonInteraction: true
    })
  }
}
reportWebVitals(sendToAnalytics)
