import { useState } from 'react'
import { Button, Modal, ModalClose, Sheet, Stack } from '@mui/joy'
import { Fade, MobileStepper } from '@mui/material'
import { PlanMethodProps } from '../type'
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material'
import { PlanMethod } from '../plan-method'
import { RepairPlanCreate } from '@pages/repair/plan-create'
import { useRecoilValue } from 'recoil'
import { planEditState } from '../recoil'
import { warn } from '@core/components/modal'

export const PlanModal = ({ open, onClose, info }: PlanMethodProps) => {
  const edit = useRecoilValue(planEditState)
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    if (edit) warn({ message: '수정된 정보가 있습니다. 저장 후 진행해주세요.' })
    else setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  console.log(info);
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={open}>
          <Sheet
            variant="outlined"
            sx={{
              width: '95vw',
              borderRadius: 3,
              p: 3,
              paddingBottom: 1,
              boxShadow: 'lg'
            }}>
            <ModalClose
              variant="outlined"
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
                borderRadius: '50%',
                bgcolor: 'background.body'
              }}
            />
            <Stack spacing={2}>
              {activeStep === 0 ? <PlanMethod info={info} /> : <RepairPlanCreate info={info} />}
              <Stack justifyContent={'center'} alignItems="center">
                <MobileStepper
                  variant="dots"
                  steps={2}
                  position="static"
                  activeStep={activeStep}
                  sx={{ width: 400, flexGrow: 1 }}
                  nextButton={
                    <Button
                      variant="plain"
                      onClick={handleNext}
                      disabled={activeStep === 1}
                      endDecorator={<KeyboardArrowRightOutlined />}>
                      수리계획
                    </Button>
                  }
                  backButton={
                    <Button
                      variant="plain"
                      onClick={handleBack}
                      disabled={activeStep === 0}
                      startDecorator={<KeyboardArrowLeftOutlined />}>
                      점검목록
                    </Button>
                  }
                />
              </Stack>
            </Stack>
          </Sheet>
        </Fade>
      </Modal>
    </>
  )
}
