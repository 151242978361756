import { Input, InputNumber, Th } from '@pages/equipment/equipment-detail/styles'
import { UseFormRegister } from 'react-hook-form'

interface DrainBrgProps {
  register: UseFormRegister<any>
  title?: React.ReactNode
  contentKey?: string
  brgKey: string
  rsChainKey: string
  couplingKey: string
  noteKey: string
}

export const DrainBrg = ({
  register,
  title,
  contentKey,
  brgKey,
  rsChainKey,
  couplingKey,
  noteKey
}: DrainBrgProps) => {
  return (
    <>
      {contentKey && (
        <td rowSpan={2}>
          <Input {...register(contentKey)} />
        </td>
      )}
      <Th>{title ? title : 'BRG NO.'}</Th>
      <td colSpan={2}>
        <Input {...register(brgKey)} />
      </td>
      <Th>RS CHAIN</Th>
      <td>
        <Input {...register(rsChainKey)} />
      </td>
      <Th>COUPLING</Th>
      <td>
        <Input {...register(couplingKey)} />
      </td>
      <td>
        <Input {...register(noteKey)} />
      </td>
    </>
  )
}

interface DrainPowerProps {
  register: UseFormRegister<any>
  powerKey: string
  reduceKey: string
  sprocketKey: string
  noteKey: string
}

export const DrainPower = ({
  register,
  powerKey,
  reduceKey,
  sprocketKey,
  noteKey
}: DrainPowerProps) => {
  return (
    <tr>
      <Th>동&nbsp;&nbsp;&nbsp;&nbsp;력</Th>
      <td colSpan={2}>
        <InputNumber endAdornment={<span>kW</span>} {...register(powerKey)} />
      </td>
      <Th>감속비</Th>
      <td>
        <Input {...register(reduceKey)} />
      </td>
      <Th>SPROCKET</Th>
      <td>
        <Input {...register(sprocketKey)} />
      </td>
      <td>
        <Input {...register(noteKey)} />
      </td>
    </tr>
  )
}

interface DrainStandardProps {
  register: UseFormRegister<any>
  standardKey: string
  countKey: string
  noteKey: string
}

export const DrainStandard = ({ register, standardKey, countKey, noteKey }: DrainStandardProps) => {
  return (
    <>
      <Th>규&nbsp;&nbsp;&nbsp;&nbsp;격</Th>
      <td colSpan={3}>
        <Input {...register(standardKey)} />
      </td>
      <Th>수&nbsp;&nbsp;&nbsp;&nbsp;량</Th>
      <td>
        <InputNumber endAdornment={<span>EA</span>} {...register(countKey)} />
      </td>
      <td>
        <Input {...register(noteKey)} />
      </td>
    </>
  )
}
