export interface SettingCompanySearchFormInDto {
  companyName: string
}

export enum SettingCompanySearchFormField {
  COMPANY_NAME = 'companyName'
}

export interface SettingCompanySearchFormProps {
  onSubmit: (data: SettingCompanySearchFormInDto) => void
  onDelete: () => void
  onCreate: () => void
  disableDeleteAll: boolean
}

export interface AddCompanyFormProps {
  isOpen: boolean
  onCloseFn: () => void
  onSubmit: (data: CompanyIndDto, isEdit: boolean) => void
  information?: CompanyModel
}

export interface CompanyModel {
  companyId: number
  companyAddress: string
  companyName: string
  companyPhone: string
}

export enum CompanyFormField {
  COMPANY_ID = 'companyId',
  COMPANY_NAME = 'companyName',
  COMPANY_ADDRESS = 'companyAddress',
  COMPANY_PHONE = 'companyPhone',
  COMPANY_ZONECODE = 'companyZonecode'
}

export type CompanyIndDto = CompanyModel
