import { Card } from '@mui/joy';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
interface IProps {
  options: any;
}
const LineChart: React.FC<IProps> = ({ options }) => {
  return (
    <Card>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </Card>
  );
};

export default LineChart;