import { Divider, Modal, ModalClose, ModalDialog, Stack, Typography, Input } from '@mui/joy'
import { Fade } from '@mui/material'
import { ContentMenu } from '@core/components/menu'
import { Transfer } from '@core/components/transfer'
import { Actions } from './template'
import { ChangeEvent, useEffect, useState } from 'react'
import { ItemsProps } from '@core/components/transfer/type'
import { InspectPlanProps, PlanCreateProps } from '../type'
import { ContentMenuDataProps } from '@core/components/menu/type'
import { confirm, success, warn } from '@core/components/modal'
import { useRecoilState, useRecoilValue } from 'recoil'
import { companyInfoState } from '@core/components/menu/recoil'
import { SelectOptionType } from '@pages/equipment/type'
import { factoryListState } from '@pages/equipment/recoil'
import { Stack as CustomStack } from '@core/components/menu/styles'
import { useMutation } from '@tanstack/react-query'
import { postInspectPlan } from '../query'
import { ErrorQuery } from '@core/helpers/query.helper'
import { authorizationSelector } from '@core/components/application-provider/recoil'
import { AuthorizationModel } from '@core/models/authorization.model'

export const PlanCreate = ({ open, onClose }: PlanCreateProps) => {
  const authorizationModel: AuthorizationModel = useRecoilValue(authorizationSelector)
  const companyInfo = useRecoilValue(companyInfoState)
  const [factoryObject, setFactoryObject] = useRecoilState(factoryListState)
  // 상단에서 선택된 데이터가 들어가게 지정하고
  const [info, setInfo] = useState<ContentMenuDataProps>({
    checker: undefined
  })
  // 설비리스트
  const [data, setData] = useState<ItemsProps[]>([])
  // 등록리스트
  const [added, setAdded] = useState<ItemsProps[]>([])

  const saveMutation = useMutation((data: InspectPlanProps[]) => postInspectPlan(data), {
    onSuccess: () => {
      success()
    },
    onError: ErrorQuery
  })

  const onMakePostData = (data: ItemsProps[]) =>

    data.map((item) => ({
      equipmentId: item.value.split('_')[1],
      company: item.company ?? '',
      factory: item.factory ?? '',
      code: item.code ?? '',
      name: item.name ?? '',
      checkDate: info.date ?? '',
      checker: info.checker ?? '',
      validater: '',
      note: ''
    }))

  const onCreateAction = () => {
    const newData = onMakePostData(added)
    if (added.length > 0) {
      if (info['date'] && info['checker']) {
        confirm({
          onOk: async () => {
            await saveMutation.mutateAsync(newData)
          }
        })
      } else {
        confirm({
          title: '경고',
          message: `비어있는 정보가 존재합니다. 저장하시겠습니까?`,
          onOk: async () => {
            await saveMutation.mutateAsync(newData)
          },
          confirmButtonText: '저장'
        })
      }
    } else {
      warn({
        title: '경고',
        message: '선택된 등록리스트가 없습니다.'
      })
    }
  }

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target
    setInfo((prev) => ({ ...prev, [key]: value }))
  }

  const getFactoryList = (factoryObj: { [keys: string]: SelectOptionType[] }) => {
    let getList: ItemsProps[] = []
    if (!authorizationModel.isAdmin) {
      getList = factoryObj[authorizationModel.companyId].map((item) => ({
        label: item.name,
        value: item.id.toString()
      }))
    } else {
      if (info.company) {
        getList = factoryObj[info.company].map((item) => ({
          label: item.name,
          value: item.id.toString()
        }))
      }
    }
    setData(getList)
  }

  useEffect(() => {
    if (info.company) {
      const getList = factoryObject[info.company].map((item) => {
        const obj: ItemsProps = {
          label: item.name,
          value: item.id.toString()
        }
        if (item.children) {
          const children = item.children.map((sub) => ({
            label: sub.name,
            value: sub.id
          }))
          obj.children = children
          obj.disabled = children.length === 0
        }
        return obj
      })
      setData(getList)
    }
  }, [info.company])

  useEffect(() => {
    const tempObj: any = {}
    companyInfo.forEach((company) => {
      if (company.factories) {
        const array = company.factories.map((item) => ({
          id: item.factoryId.toString(),
          name: item.factoryName,
          children: item.equipments?.map((equip) => ({
            id: `${item.factoryId}_${equip.id}`,
            name: equip.header.name
          }))
        }))
        tempObj[company.companyId] = array
      }
    })

    setFactoryObject(tempObj)
    getFactoryList(tempObj)
  }, [])

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={onClose}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Fade in={open}>
          <ModalDialog variant="outlined" sx={{ width: '75vw' }}>
            <Stack spacing={2}>
              <Typography id="create-modal-dialog-title" level="h6" fontWeight={600}>
                점검 계획 등록
              </Typography>
              <ModalClose variant="plain" />
              <ContentMenu
                companyprops={{ enable: true }}
                dateprops={{ enable: true, title: '점검일' }}
                customNode={
                  <>
                    <CustomStack>
                      <Typography level="body1" fontWeight={700}>
                        점검자
                      </Typography>
                      <Input
                        onChange={(event: any) => onChangeInput(event, 'checker')}
                        placeholder="점검자를 입력해주세요."
                      />
                    </CustomStack>
                  </>
                }
                actions={<Actions onCreate={onCreateAction} />}
                setInfo={setInfo}
              />
              <Divider />
              <Stack spacing={2}>
                <Transfer setData={setAdded} data={data} />
              </Stack>
            </Stack>
          </ModalDialog>
        </Fade>
      </Modal>
    </>
  )
}
