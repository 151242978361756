import { FC } from 'react'
import { FormControl, FormLabel, Grid, Input, Stack } from '@mui/joy'
import { Stack as CustomStack } from '@core/components/menu/styles'
import { Store } from '@mui/icons-material'
import { useFormContext } from 'react-hook-form'
import {
  SettingCompanySearchFormField,
  SettingCompanySearchFormInDto,
  SettingCompanySearchFormProps
} from '@pages/settings/company/type'
import { String } from '@core/enums/common.enum'
import { TranslateEnum } from '@core/enums/translate.enum'
import FormActions from '@core/components/form-actions'
import { useTranslation } from 'react-i18next'

/**
 * Setting company search form component
 * @param onSubmit
 * @param onCreate
 * @param onDelete
 * @param disableDeleteAll
 * @constructor
 */
const SettingCompanySearchForm: FC<SettingCompanySearchFormProps> = ({
  onSubmit,
  onCreate,
  onDelete,
  disableDeleteAll = true
}) => {
  const { t } = useTranslation([TranslateEnum.COMPANY])
  const { handleSubmit } = useFormContext<SettingCompanySearchFormInDto>()
  return (
    <Grid xs={12} className="search-company-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid xs={10}></Grid>

          <Grid xs={2}>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
              <FormActions
                onCreate={onCreate}
                onDelete={onDelete}
                disableDelete={disableDeleteAll}
              />
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Grid>
  )
}

export default SettingCompanySearchForm
