import { CommonProps } from '../equipment-detail/card/type'

export const defaultValues: CommonProps = {
  header: {
    company: null,
    factory: null,
    code: '',
    equipmentCode: '',
    name: '',
    type: '',
    size: '',
    establishmentDate: '',
    manufacturer: '',
    usage: '',
    permission: '',
    generatedDust: '',
    note: '',
    maxChamberCount: null,
    filterBagReplacementPeriodMonth: null,
  },
  images: {
    picture: null,
    diagram: null
  },
  checkType: ''
}
