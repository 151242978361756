import { RepairPlanModel } from '@pages/repair/type'

export interface RepairPlanCreateModalProps {
  info?: RepairPlanModel
  rowAddSelection?: RepairPlanModel
  isOpen: boolean
  onCloseFn: () => void
  onSubmitFn?: (data: RepairPlanModel, isEditMode: boolean) => void
}

export enum RepairPlanCreateFormField {
  COMPANY = 'company',
  FACTORY = 'factory',
  EQUIPMENT = 'equipment',
  CODE = 'code',
  NAME = 'name',
  CHECK_DATE = 'checkDate',
  CATEGORY = 'category',
  SUB_CATEGORY = 'subCategory',
  VISUAL_REPORT = 'visualReport',
  ACTION_PLAN_DATE = 'actionPlanDate',
  ABNORMALITY = 'abnormality',
  ABNORMALITY_PICTURE = 'abnormalityPicture',
  ACTION_COMPLETION_DATE = 'actionCompletionDate',
  ACTION_COMPLETION_DETAIL = 'actionCompletionDetail',
  ACTION_COMPLETION_PICTURES = 'actionCompletionPictures',
  MATERIAL_NAME = 'materialName',
  MATERIAL_SIZE = 'materialSize',
  MATERIAL_COUNT = 'materialCount',
  MANAGER = 'manager',
  NOTE = 'note'
}
