export enum SettingAccountInformationFormField {
  COMPANY_ID = 'companyId',
  COMPANY_NAME = 'companyName',
  EMAIL = 'email',
  FULL_NAME = 'fullName',
  PHONE = 'phone',
  ROLE = 'roles'
}

export enum ChangePasswordFormField {
  CURRENT_PASSWORD = 'password',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword'
}

export type SettingAccountInformationInDto = UserInformationModel

export interface UserInformationModel {
  companyId?: number
  companyName?: string
  email?: string
  fullName?: string
  phone?: string
  roles?: string
}

export interface ChangePasswordInDto {
  newPassword: string
  password: string
  confirmPassword: string
}

export interface SettingAccountInformationFormProps {
  onSubmit: (data: SettingAccountInformationInDto) => void
  userInformation: UserInformationModel
  isLoading: boolean
}

export interface ChangePasswordProps {
  onSubmit: (inDto: ChangePasswordInDto) => void
  isLoading: boolean
}

export interface ShowPasswordInFormChangePasswordState {
  isShowCurrentPassword: boolean
  isShowNewPassword: boolean
  isShowConfirmPassword: boolean
}
