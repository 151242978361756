import { useMemo } from 'react'
import MaterialReactTable, { MaterialReactTableProps } from 'material-react-table'
import { MRT_Localization_KR } from './ko'
import { createTheme, ThemeProvider, useTheme } from '@mui/material'
import { useColorScheme } from '@mui/joy/styles'

export type TableProps<T extends Record<string, any>> = Pick<
  MaterialReactTableProps<T>,
  Exclude<
    keyof MaterialReactTableProps<T>,
    'getCoreRowModel' | 'onStateChange' | 'renderFallbackValue'
  >
>

/**
 * Common base table
 * @param props
 * @constructor
 */
export const BaseTable = <T extends Record<string, any>>(props: TableProps<T>) => {
  const globalTheme = useTheme()
  const { mode }: string | any = useColorScheme()
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode
        }
      }),
    [globalTheme]
  )

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        enableGrouping
        enableColumnResizing
        enableStickyHeader
        localization={MRT_Localization_KR}
        muiTableBodyCellSkeletonProps={{
          sx: {
            backgroundColor: 'var(--joy-palette-grey-200)'
          }
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: '500px'
          }
        }}
        muiTablePaperProps={{
          sx: { borderRadius: 3 }
        }}
        muiTopToolbarProps={{
          sx: {
            borderRadius: 3
          }
        }}
        muiBottomToolbarProps={{
          sx: {
            borderRadius: 3
          }
        }}
        {...props}
      />
    </ThemeProvider>
  )
}

/**
 * Common data table
 * @param props
 * @constructor
 */
export const DataTable = <T extends Record<string, any>>(props: TableProps<T>) => {
  return (
    <BaseTable
      enableRowSelection
      enableRowActions
      editingMode="cell"
      enableEditing
      initialState={{ density: 'compact' }}
      enablePagination={false}
      enableBottomToolbar={false}
      enableGlobalFilterModes
      globalFilterFn="contains"
      enableRowVirtualization
      enablePinning
      rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
      columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer
      muiTableContainerProps={{
        sx: {
          height: '680px'
        }
      }}
      {...props}
    />
  )
}
