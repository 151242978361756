import { FC } from 'react'
import { FormActionsProps } from '@core/components/form-actions/type'
import { Tooltip } from '@mui/joy'
import {
  AddOutlined,
  FileDownloadOutlined,
  FileUploadOutlined,
  RemoveOutlined,
  SaveOutlined,
  SearchOutlined
} from '@mui/icons-material'
import { IconButton } from '@mui/material'
/**
 * Form action component
 * @param onUpload
 * @param onDownload
 * @param onSave
 * @param onCreate
 * @param onDelete
 * @param disableDelete
 * @constructor
 */
const FormActions: FC<FormActionsProps> = ({
  onUpload,
  onDownload,
  onSave,
  onCreate,
  onDelete,
  disableDelete = true,
  custom
}) => {
  return (
    <>
      <Tooltip title="조회">
        <IconButton color="primary" type="submit">
          <SearchOutlined />
        </IconButton>
      </Tooltip>
      {onCreate && (
        <Tooltip title="추가">
          <IconButton color="primary" onClick={onCreate}>
            <AddOutlined />
          </IconButton>
        </Tooltip>
      )}

      {onDelete && (
        <Tooltip title="삭제">
          <IconButton color="primary" onClick={onDelete} disabled={disableDelete}>
            <RemoveOutlined />
          </IconButton>
        </Tooltip>
      )}

      {onDownload && (
        <Tooltip title="다운로드">
          <IconButton color="primary" onClick={onDownload}>
            <FileDownloadOutlined />
          </IconButton>
        </Tooltip>
      )}
      {custom && custom}
      {onUpload && (
        <Tooltip title="업로드">
          <IconButton color="primary" aria-label="upload icon" component="label">
            <input id="file-upload" type="file" hidden onChange={onUpload} />
            <FileUploadOutlined />
          </IconButton>
        </Tooltip>
      )}

      {onSave && (
        <Tooltip title="저장">
          <IconButton color="primary" onClick={onSave}>
            <SaveOutlined />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default FormActions
