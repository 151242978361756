/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as yup from 'yup'
import { TFunction } from 'i18next'
import { MRT_ColumnDef, MRT_Row } from 'material-react-table'
import { CompanyModel } from '@pages/settings/company/type'
import { MenuItem } from '@mui/material'
import { ListDivider, ListItemDecorator } from '@mui/joy'
import { DeleteForever, Edit } from '@mui/icons-material'
import { TranslateEnum } from '@core/enums/translate.enum'
import { String } from '@core/enums/common.enum'
import { FactoryModel } from '@pages/settings/factory/type'
/**
 * Setting account validation schema
 */
const settingAccountValidationSchema = yup.object().shape({
  email: yup.string().email('올바른 이메일 형식을 입력하세요.').required('이메일이 필요합니다.'),
  fullName: yup.string().required('전체 이름이 필요합니다.'),
  phone: yup.string().required('전화번호는 필수 항목입니다.')
})

// @ts-ignore
const changePasswordValidationSchema = () => {
  const regexPassword = /^(?=.*[a-zA-Z0-9])(?=.*[$`~!@$!%*#^?.&\\(\\)\-_=+]).{8,16}$/
  const messageErrorRegexPassword =
    '비밀번호는 8~16자이며 영문, 숫자, 특수문자, 대소문자를 지원하며 반드시 특수문자가 포함되어야 합니다.'
  return yup.object().shape({
    password: yup
      .string()
      .required('현재 암호가 필요합니다')
      .matches(regexPassword, messageErrorRegexPassword),
    newPassword: yup
      .string()
      .required('새 비밀번호가 필요합니다.')
      .matches(regexPassword, messageErrorRegexPassword),
    confirmPassword: yup
      .string()
      .required('비밀번호 확인이 필요합니다')
      .matches(regexPassword, messageErrorRegexPassword)
      .oneOf([yup.ref('newPassword')], '비밀번호 확인이 일치하지 않음')
  })
}

/**
 * Setting company table columns config
 * @param translate
 */
const settingCompanyTableColumns = (translate: TFunction): MRT_ColumnDef<CompanyModel | any>[] => {
  return [
    {
      accessorKey: 'companyName',
      header: translate('company.table.name', { ns: TranslateEnum.COMPANY })
    },
    {
      accessorKey: 'companyAddress',
      header: translate('company.table.address', { ns: TranslateEnum.COMPANY })
    },
    {
      accessorKey: 'companyPhone',
      header: translate('company.table.phone', { ns: TranslateEnum.COMPANY })
    }
  ]
}

/**
 * Setting factory table columns config
 * @param translate
 */
const settingFactoryTableColumns = (translate: TFunction): MRT_ColumnDef<FactoryModel | any>[] => {
  return [
    {
      accessorKey: 'companyName',
      header: '회사명'
    },
    {
      accessorKey: 'name',
      header: '공장명'
    },
    {
      accessorKey: 'address',
      header: '공장주소'
    },
    {
      accessorKey: 'maxChamberCount',
      header: '최대 챔버 수'
    }
  ]
}

export const InspectCustomTableColumns = () => {
  return [
    {
      accessorKey: 'companyName',
      header: '회사 이름'
    },
    {
      accessorKey: 'checkMethodName',
      header: '점검방법명'
    },
    {
      accessorKey: 'templateType',
      header: '템플릿 타입'
    },
  ]
}

/**
 * Render setting company and factory table row actions
 * @param translate
 * @param row
 * @param onUpdate
 * @param onDelete
 * @param closeMenu
 */
const settingCompanyAndFactoryTableRowAction = (
  translate: TFunction,
  row: MRT_Row<any>,
  onUpdate: (row: MRT_Row<any>) => void,
  onDelete: (row: MRT_Row<any>) => void,
  closeMenu: () => void
) => {
  return [
    <MenuItem
      key={2}
      onClick={() => {
        closeMenu()
        onUpdate(row)
      }}>
      <ListItemDecorator>
        <Edit sx={{ mr: 2 }} color="primary" />
      </ListItemDecorator>
      {translate('table.updateBtn', { ns: TranslateEnum.COMMON })}
    </MenuItem>,
    <div key={3}>
      <ListDivider />
      <MenuItem
        sx={{ color: 'red' }}
        onClick={() => {
          onDelete(row)
          closeMenu()
        }}>
        <ListItemDecorator sx={{ color: 'inherit' }}>
          <DeleteForever sx={{ mr: 2 }} color={'error'} />
        </ListItemDecorator>
        {translate('table.deleteBtn', { ns: TranslateEnum.COMMON })}
      </MenuItem>
    </div>
  ]
}

/**
 * Company validation schema use for React Hook Form
 * @param translate
 */
const companyValidationSchema = (translate: TFunction) => {
  return yup.object().shape({
    companyName: yup
      .string()
      .required(
        translate('company.validation.companyName', { ns: TranslateEnum.COMPANY }) ||
        String.EMPTY_STRING
      )
  })
}

/**
 * Factory validation schema use for React Hook Form
 * @param translate
 */
const factoryValidationSchema = (translate: TFunction) => {
  return yup.object().shape({
    companyId: yup
      .string()
      .required(translate('factory.validation.companyId') || String.EMPTY_STRING),
    name: yup.string().required(translate('factory.validation.name') || String.EMPTY_STRING),
    address: yup.string().required('공장 주소를 입력해주세요.' || String.EMPTY_STRING),
    maxChamberCount: yup.string().required('최대 챔버 수를 입력해주세요.' || String.EMPTY_STRING)
  })
}

export {
  settingAccountValidationSchema,
  factoryValidationSchema,
  changePasswordValidationSchema,
  settingCompanyTableColumns,
  settingCompanyAndFactoryTableRowAction,
  companyValidationSchema,
  settingFactoryTableColumns
}
