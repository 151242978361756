import { Input, InputNumber, Th } from '@pages/equipment/equipment-detail/styles'
import { UseFormRegister } from 'react-hook-form'

interface IDmaperStandardProps {
  register: UseFormRegister<any>
  standrdKey: string
  countKey: string
  typeKey: string
  noteKey: string
}

export const DamperStandard = ({
  register,
  standrdKey,
  countKey,
  typeKey,
  noteKey
}: IDmaperStandardProps) => {
  return (
    <>
      <Th>규&nbsp;&nbsp;격</Th>
      <td colSpan={2}>
        <Input {...register(standrdKey)} />
      </td>
      <Th>수&nbsp;&nbsp;량</Th>
      <td>
        <InputNumber endAdornment={<span>EA</span>} {...register(countKey)} />
      </td>
      <Th>TYPE</Th>
      <td colSpan={2}>
        <Input {...register(typeKey)} />
      </td>
      <td>
        <Input {...register(noteKey)} />
      </td>
    </>
  )
}

interface IDamperBRGProps {
  register: UseFormRegister<any>
  brgKey: string
  airKey: string
  noteKey: string
}

export const DamperBRG = ({ register, brgKey, airKey, noteKey }: IDamperBRGProps) => {
  return (
    <tr>
      <Th>BRG NO</Th>
      <td colSpan={2}>
        <Input {...register(brgKey)} />
      </td>
      <Th colSpan={2}>AIR CYLINDER</Th>
      <td colSpan={3}>
        <Input {...register(airKey)} />
      </td>
      <td>
        <Input {...register(noteKey)} />
      </td>
    </tr>
  )
}
