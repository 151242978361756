/* eslint-disable no-undef */
import { MetaData } from '@core/enums/meta-data.enum'
import { PrintOutlined, SaveOutlined, InsertChart } from '@mui/icons-material'
import { Breadcrumbs, Stack, Tooltip, Typography } from '@mui/joy'
import { IconButton } from '@mui/material'
import { CSSProperties } from 'react'
import ReactToPrint from 'react-to-print'
import { InspectPlanProps } from '../type'
import { EmptyTh, Th } from './styles'

interface MethodTopProps {
  info: InspectPlanProps | undefined
  printRef?: React.MutableRefObject<null>
}

export const MethodTop = ({ info, printRef }: MethodTopProps) => {
  console.log(info);
  const handleClickChart = () => {
    window.open(`${process.env.REACT_APP_FRONTEND_URL}/measurement-chart/${info!.id}`, "_blank", 'width=1400, height=800');
    //todo: 새 창 열고, 정보와 데이터 출력할 수 있는 url 만들어주기
  }

  console.log(info);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
        <Stack>
          <Typography level="h5" fontWeight={600}>
            점검 목록
          </Typography>
          <Breadcrumbs sx={{ px: 0.3, paddingBottom: 0 }}>
            <Typography level="body1" fontWeight={500}>
              {info?.company}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.factory}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.code}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.name}
            </Typography>
            <Typography level="body1" fontWeight={500}>
              {info?.checkDate}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Tooltip title="차트" sx={{ zIndex: 9999 }}>
            <IconButton color="primary" onClick={handleClickChart}>
              <InsertChart />
            </IconButton>
          </Tooltip>
          <ReactToPrint
            trigger={() => (
              <Tooltip title="인쇄" sx={{ zIndex: 9999 }}>
                <IconButton color="primary">
                  <PrintOutlined />
                </IconButton>
              </Tooltip>
            )}
            content={() => printRef?.current ?? null}
          />
          <Tooltip title="저장">
            <IconButton color="primary" form="table-form" type="submit">
              <SaveOutlined />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    </>
  )
}

/*
 * 첫번째 테이블 헤더
 */
export const firstHead = [
  <tr key={0}>
    <Th rowSpan={3} style={{ width: '8%' }}>
      Fan 진동측정
    </Th>
    <Th colSpan={6}>FAN 진동(mm/s)</Th>
    <Th colSpan={2}>FAN 온도</Th>
    <EmptyTh />
    <Th colSpan={6}>MOTOR 진동(mm/s)</Th>
    <Th colSpan={2}>MOTOR 온도</Th>
    <Th rowSpan={3} style={{ width: '8%' }}>
      비고
    </Th>
  </tr>,
  <tr key={1}>
    <Th colSpan={3}>부하</Th>
    <Th colSpan={3}>반부하</Th>
    <Th rowSpan={2}>부하</Th>
    <Th rowSpan={2}>반부하</Th>
    <EmptyTh />
    <Th colSpan={3}>부하</Th>
    <Th colSpan={3}>반부하</Th>
    <Th rowSpan={2}>부하</Th>
    <Th rowSpan={2}>반부하</Th>
  </tr>,
  <tr key={2}>
    <Th>H</Th>
    <Th>V</Th>
    <Th>A</Th>
    <Th>H</Th>
    <Th>V</Th>
    <Th>A</Th>
    <EmptyTh />
    <Th>H</Th>
    <Th>V</Th>
    <Th>A</Th>
    <Th>H</Th>
    <Th>V</Th>
    <Th>A</Th>
  </tr>
]

export const firstContents: {
  [keys: string]: string
} = {
  displacement: MetaData.DISPLACEMENT,
  velocity: MetaData.VELOCITY,
  acceleration: MetaData.ACCELERATION
}

/*
 * 두번째 테이블 헤더
 */

export const secondHeader: {
  title: string
  style?: CSSProperties | undefined
  key?: string
}[] = [
    {
      title: '대분류',
      style: {
        width: '8%'
      }
    },
    {
      title: '소분류'
    },
    {
      title: '확인결과',
      key: 'result',
    },
    {
      title: '현상',
      key: 'abnormality',
    },
    {
      title: '조치 계획',
      key: 'actionPlan'
    },
    {
      title: '조치 계획일',
      style: {
        width: '10%'
      },
      key: 'actionPlanDate'
    },
    {
      title: '담당자',
      style: {
        width: '10%'
      },
      key: 'manager'
    },
    {
      title: '비고',
      style: {
        width: '18%'
      },
      key: 'note'
    }
  ]

/*
 * 두번째 테이블 바디
 */
export const secondContents: {
  [key: string]: {
    main: string
    sub: string[]
  }[]
} = {
  bf: [
    {
      main: 'outside_status',
      sub: ['stack_scatter_check', 'equipment_outside']
    },
    {
      main: 'fan',
      sub: [
        'fan_status',
        'bearing_status',
        'pulse',
        'tube_status',
        'pulse_status',
        'air_line_status',
        'pressure_status'
      ]
    },
    {
      main: 'dust_drain',
      sub: ['valve_status', 'conveyor_status', 'chain_status']
    },
    {
      main: 'panel',
      sub: ['instruments_status']
    },
    {
      main: 'vibration_isolation_performance',
      sub: ['damper_status', 'filter_bag_diff']
    },
    {
      main: 'flux',
      sub: ['exhaust', 'air_current']
    }
  ],
  scr: [
    {
      main: 'outside_status',
      sub: ['stack_scatter_check', 'equipment_outside']
    },
    {
      main: 'fan',
      sub: ['fan_status', 'bearing_status']
    },
    {
      main: 'scrubber',
      sub: [
        'execute_pressure',
        'nozzle_status',
        'water_tank_status',
        'rating_status',
        'pump_status'
      ]
    },
    {
      main: 'chemical_piping',
      sub: ['chemical_tank_status', 'chemical_pump_status']
    },
    {
      main: 'panel',
      sub: ['instruments_status']
    },
    {
      main: 'flux',
      sub: ['exhaust', 'air_current']
    }
  ]
}
