import { FC, useCallback, useMemo } from 'react'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/joy/Typography'
import HeaderMenu from '@theme/components/header/menu'
import { HeaderProps } from '@theme/components/header/type'
import ThemeMode from '@theme/components/header/theme-mode'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import { AerixIcon } from '@core/components/icon'
import GnbMenu from '@theme/components/header/menu/gnb'
import { clearRefreshTokenAndCompanyIdInCookie } from '@core/helpers/auth.helper'
import { useRecoilState } from 'recoil'
import {
  applicationInitialState,
  authorizationSelector
} from '@core/components/application-provider/recoil'
import { useNavigate } from 'react-router-dom'
import { AuthRouterLink, RouterLink } from '@core/enums/router.enum'
import { useTranslation } from 'react-i18next'
import { TranslateEnum } from '@core/enums/translate.enum'

/**
 * Header component
 * @param setDrawerOpen
 * @constructor
 */
const Header: FC<HeaderProps> = ({ setDrawerOpen }) => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  const [authorizationModel, setAuthorizationModel] = useRecoilState(authorizationSelector)
  const { username } = useMemo(() => authorizationModel, [authorizationModel])
  const navigate = useNavigate()
  const navigateHome = useCallback(() => navigate(RouterLink.HOME), [navigate])
  const logOutUser = useCallback(() => {
    setAuthorizationModel(applicationInitialState.authorization)
    clearRefreshTokenAndCompanyIdInCookie()
    navigate(`/${AuthRouterLink.BASE_PATH}/${AuthRouterLink.LOGIN}`)
  }, [navigate])
  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1.5
        }}
        component="div">
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 1.5
          }}>
          <IconButton
            variant="outlined"
            size="sm"
            onClick={() => setDrawerOpen(true)}
            sx={{ display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <IconButton size="sm" variant="plain" sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>
            <AerixIcon onClick={navigateHome} />
          </IconButton>
          <Typography
            level="body1"
            fontWeight={700}
            onClick={navigateHome}
            sx={{ cursor: 'pointer' }}>
            AERIX Smart O&M
          </Typography>
        </Box>
        <GnbMenu />
      </Box>
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', gap: 1.5 }}>
        <ThemeMode />
        <HeaderMenu
          id="app-selector"
          control={
            <IconButton size="sm" variant="outlined" color="primary" aria-label="Apps">
              <SupervisedUserCircleIcon />
              <span style={{ paddingLeft: '5px', marginRight: 5 }}>{`${
                username && username.toUpperCase()
              }`}</span>
            </IconButton>
          }
          menus={[
            {
              label: t('menu.logout'),
              callback: () => logOutUser()
            }
          ]}
        />
      </Box>
    </>
  )
}

export default Header
