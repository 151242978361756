import { ArrayFuncProps, InspectResultProps } from '@pages/inspect/type'
const ObjectToArrayFunc = (datas: InspectResultProps[]) => {
  const newArray: ArrayFuncProps[] = []
  datas.forEach((data) => {
    // 카테고리 데이터의 키를 먼저 돌아, outside_status, fan 순진행
    Object.keys(data.checkList).forEach((key, mainIndex) => {
      // outside_status, fan;
      Object.keys(data.checkList[key]).forEach((subkey, subIndex) => {
        // stack_scatter_check, equipment_outside
        const category = key
        const subCategory = subkey
        let tempObj = {}
        if (mainIndex === 0) {
          if (subIndex === 0) {
            // 메인과 서브의 정보를 다 넣고
            const {
              company,
              factory,
              code,
              name,
              checkDate,
              checkType,
              validater,
              checker,
              totalNote
            } = data
            tempObj = {
              company,
              factory,
              code,
              name,
              checkDate,
              checkType,
              validater,
              checker,
              totalNote,
              category,
              subCategory,
              ...data.checkList[key][subkey]
            }
          } else {
            tempObj = {
              subCategory,
              ...data.checkList[key][subkey]
            }
          }
        } else {
          if (subIndex === 0) {
            tempObj = {
              category,
              subCategory,
              ...data.checkList[key][subkey]
            }
          } else {
            tempObj = {
              subCategory,
              ...data.checkList[key][subkey]
            }
          }
        }
        newArray.push(tempObj)
      })
    })
  })
  return newArray
}

export { ObjectToArrayFunc }
