import { TranslateEnum } from '@core/enums/translate.enum'
import { Card, CircularProgress, Stack, Typography } from '@mui/joy'
import { InspectPlanProps } from '@pages/inspect/type'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface InspectStatusProps {
  plan: InspectPlanProps[]
  result: InspectPlanProps[]
}

export const InspectStatus = ({ plan, result }: InspectStatusProps) => {
  const { t } = useTranslation([TranslateEnum.COMMON])
  const [value, setValue] = useState(Math.round((result.length / plan.length) * 100))

  useEffect(() => {
    setValue(Math.round((result.length / plan.length) * 100))
  }, [plan, result])

  return (
    <Card sx={{ justifyContent: 'space-around' }}>
      <Stack justifyContent="center" alignItems="center" spacing={2}>
        <Typography level="h5">{t('dashboard.today_inspect_status')}</Typography>
        <CircularProgress
          determinate
          value={value ? value : 0}
          sx={{ '--CircularProgress-size': '80px' }}>
          {`${result.length} / ${plan.length}`}
        </CircularProgress>
        <Typography level="body1">{value === 100 ? '점검완료' : '점검중'}</Typography>
      </Stack>
    </Card>
  )
}
