import { Button, Input } from '@mui/joy';
import React, { useState, SetStateAction } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { TemplateTypeModel } from '@pages/settings/inspect-custom/type';
import * as _ from 'lodash';

const initSubList: any = {
  result: '해당없음',
  abnormality: '',
  actionPlan: '',
  actionPlanDate: '',
  manager: '',
  note: ''
};

interface IProps {
  type: 'major' | 'sub';
  data: any;
  clickedMajor?: string;
  isAddMajorItem?: boolean;
  isAddSubItem?: boolean;
  deleteMajorList?: string[];
  deleteSubList?: string[];
  setData: React.Dispatch<SetStateAction<any>>;
  setClickedMajor?: React.Dispatch<SetStateAction<string>>;
  setIsAddMajorItem?: React.Dispatch<SetStateAction<boolean>>;
  setIsAddSubItem?: React.Dispatch<SetStateAction<boolean>>;
  setDeleteMajorList?: React.Dispatch<SetStateAction<string[]>>;
  setDeleteSubList?: React.Dispatch<SetStateAction<string[]>>;
}

const DndContainer: React.FC<IProps> = ({
  data,
  type,
  clickedMajor,
  isAddMajorItem,
  isAddSubItem,
  deleteMajorList,
  deleteSubList,
  setData,
  setClickedMajor,
  setIsAddMajorItem,
  setIsAddSubItem,
  setDeleteMajorList,
  setDeleteSubList
}) => {
  const [majorName, setMajorName] = useState('');
  const [subName, setSubName] = useState('');

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = [...data];
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);
    // 여기서 전체 데이터에서 자리 맞추어야 할 것 같음
    // items가 대분류의 바뀐 위치
    if (type === 'major') {
      setData((prev: TemplateTypeModel[]) => {
        const makeCheckList: any = {};
        const originData = prev[0];
        items.forEach((el: string) => {
          makeCheckList[el] = originData.checkList[el];
        })
        return [{ ...originData, checkList: makeCheckList }]
      })
    }

    if (type === 'sub' && clickedMajor) {
      setData((prev: TemplateTypeModel[]) => {
        const makeSubList: any = {};
        const originData = prev[0];
        items.forEach((el: string) => {
          console.log('el: ', el);
          if (el.includes('Filter Bag 차압 CHECK')) {
            initSubList.note = ['', '', '', '', '', '', '', '', ''];
            console.log('initSubList: ', initSubList);
            makeSubList[el] = initSubList;
            initSubList.note = '';
          } else {
            makeSubList[el] = initSubList;
          }
        })

        originData.checkList[clickedMajor] = makeSubList;
        console.log(originData.checkList)
        return [{ ...originData, checkList: originData.checkList }]
      })
    }
  }

  const handleClickAddMajor = (data: string) => {
    if (!data) return;
    setData((prev: TemplateTypeModel[]) => {
      const originData = prev[0];
      setIsAddMajorItem?.(false);
      setMajorName('');
      return [{
        ...originData,
        checkList: { ...originData.checkList, [data]: {} }
      }]
    })
  }

  const handleClickAddSub = (data: string) => {
    if (!data) return;
    setData((prev: TemplateTypeModel[]) => {
      const originData = prev[0];
      setIsAddSubItem?.(false);
      setSubName('');
      return [{
        ...originData,
        checkList: {
          ...originData.checkList,
          [clickedMajor!]: {
            ...originData.checkList[clickedMajor!],
            [data]: initSubList
          }
        }
      }]
    })
  }

  const handleDeleteList = (e: any, data: string) => {
    const checked = e.target.checked;
    if (type === 'major') {
      setDeleteMajorList?.((prev: string[]) => {
        return checked ? [...prev, data] : prev.filter(el => el !== data);

      })
    }
    if (type === 'sub') {
      setDeleteSubList?.((prev: string[]) => {
        return checked ? [...prev, data] : prev.filter(el => el !== data);
      })
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='customDndMode'>
        {provided => (
          <Wrap className='customDndMode' {...provided.droppableProps} ref={provided.innerRef}>
            {/* 여기에 움직일 컴포넌트 */}
            {data.map((item: any, idx: number) => (
              <Draggable draggableId={`${idx}`} index={idx} key={`${idx}`} isDragDisabled={isAddMajorItem || isAddSubItem}>
                {(provided, snapshot) => {
                  return (
                    <ListWrap
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      onClick={() => setClickedMajor && setClickedMajor(item)}
                    >
                      <input
                        type='checkbox'
                        checked={type === 'major' ? deleteMajorList?.includes(item) : deleteSubList?.includes(item)}
                        onChange={(e) => handleDeleteList(e, item)}
                      />
                      <span>{item}</span>
                    </ListWrap>
                  )
                }}
              </Draggable>
            ))}
            {data.length > 0 && isAddMajorItem && (
              <AddWrap>
                <AddIcon
                  sx={{ width: '24px', height: '24px' }}
                />
                <Input
                  placeholder='대분류 항목을 입력해주세요.'
                  size='sm'
                  value={majorName}
                  onChange={(e) => setMajorName(e.target.value)}
                  endDecorator={
                    <Button
                      size='sm'
                      sx={{ fontSize: '12px' }}
                      onClick={() => handleClickAddMajor(majorName)}
                    >
                      추가
                    </Button>
                  }
                />
              </AddWrap>
            )}
            {clickedMajor && isAddSubItem && (
              <AddWrap>
                <AddIcon sx={{ width: '24px', height: '24px' }} />
                <Input
                  placeholder='소분류 항목을 입력해주세요.'
                  size='sm'
                  value={subName}
                  onChange={(e) => setSubName(e.target.value)}
                  endDecorator={
                    <Button
                      size='sm'
                      sx={{ fontSize: '12px' }}
                      onClick={() => handleClickAddSub(subName)}
                    >
                      추가
                    </Button>
                  }
                />
              </AddWrap>
            )}
            {provided.placeholder}
          </Wrap>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  max-width: 331px;
`;

const ListWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #868e96;
  padding: 6px 4px;
  min-width: 300px;
  /* user-select: none; */
  
  > input {
    position: absolute;
    top: 9px;
    left: 8px;
    z-index: 99999;
  }

  > span {
    padding-left: 30px;
    padding-right: 30px;
  }

  cursor: pointer;
`;

const AddWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid #868e96;
  padding: 6px 8px;
`;

export default DndContainer;