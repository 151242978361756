import { ApiConfig } from '@core/models/api.model'
import { ApiMethod } from '@core/enums/api.enum'

/**
 * Api config for application
 */
const apis: ApiConfig = {
  AUTHORIZATION_APPLICATION: {
    path: '/user/signin',
    method: ApiMethod.POST
  },
  COMPANY: {
    path: '/company'
  },
  EQUIPMENT: {
    path: '/equipment'
  },
  FACTORY: {
    path: '/factory'
  },
  CHECKPLAN: {
    path: '/checkplan'
  },
  CHECKRESULT: {
    path: '/checkresult'
  },
  CHECK_TYPE: {
    path: '/checktype'
  },
  REPAIR_PLAN: {
    path: '/repairplan'
  },
  REPAIR_TARGET: {
    path: '/repairtarget'
  },
  FILTER: {
    path: '/filterbag'
  },
  Image: {
    path: '/image'
  },
  USER: {
    path: '/user'
  },
  REFRESH_TOKEN: {
    path: '/user/refresh-token',
    method: ApiMethod.POST
  },
  UPDATE_PASSWORD: {
    path: '/user/password',
    method: ApiMethod.PUT
  },
  FILTER_COMPANY: {
    path: '/company/filter',
    method: ApiMethod.GET
  },
  SYNC_IEMS: {
    path: '/sync/iems',
    method: ApiMethod.POST
  }
}

export { apis }
