import React, { FC, useEffect, useState } from 'react'
import '@core/configs/i18n.config'
import '@core/configs/interceptor.config'
import { useTranslation } from 'react-i18next'
import { LocalizationDateModel } from '@core/models/localization-date.model'
import { Builder } from '@core/helpers/builder.helper'
import { TranslateLanguageEnum } from '@core/enums/translate.enum'
import { getaApterLocale } from '@core/helpers/i18n.helper'
import AOS from 'aos'
import Navigator from '@core/components/navigator'
import PreloadingScreen from '@theme/components/loader'
import { LocalizationProvider, koKR, enUS } from '@mui/x-date-pickers'
import { koKR as coreKoKr, enUS as coreEnUS } from '@mui/material/locale'
import 'dayjs/locale/en'
import 'dayjs/locale/ko'
import { ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RecoilRoot } from 'recoil'
import '@theme/styles/theme.scss'
import ApplicationNotify from '@core/components/snackbar'
import CssBaseline from '@mui/joy/CssBaseline'
import { CssVarsProvider } from '@mui/joy/styles'
import themeConfig from '@core/configs/theme.config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { CookiesProvider } from 'react-cookie'
import ApplicationProvider from '@core/components/application-provider'

const baseTheme: any = createTheme(
  {
    palette: {
      mode: 'light'
    },
    typography: {
      fontFamily: ['Noto Sans KR', 'Roboto'].join(',')
    }
  },
  coreKoKr,
  koKR,
  coreEnUS,
  enUS
)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      useErrorBoundary: false
    },
    mutations: {
      useErrorBoundary: false
    }
  }
})

/**
 * Application component
 * @constructor
 */
const Application: FC = () => {
  const { i18n } = useTranslation()
  const [localization, setLocalization] = useState<LocalizationDateModel>(
    Builder<LocalizationDateModel>()
      .lang(TranslateLanguageEnum.KOREA_DATE_LOCALIZATION)
      .localTranslate(koKR)
      .build()
  )
  /**
   * Init animation
   */
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  }, [])

  useEffect(() => {
    setLocalization(getaApterLocale(i18n.language))
  }, [i18n.language])

  return (
    <>
      <CssVarsProvider disableTransitionOnChange theme={themeConfig}>
        <CssBaseline />
        <CookiesProvider>
          <RecoilRoot>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={baseTheme}>
                <ApplicationProvider>
                  <ApplicationNotify />
                  <PreloadingScreen />
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    localeText={
                      localization?.localTranslate.components.MuiLocalizationProvider.defaultProps
                        .localeText
                    }
                    adapterLocale={localization?.lang}>
                    <Navigator />
                  </LocalizationProvider>
                </ApplicationProvider>
              </ThemeProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </RecoilRoot>
        </CookiesProvider>
      </CssVarsProvider>
    </>
  )
}

export default Application
