import { FC, useEffect } from 'react'
import { Box, Container, Grid, Stack, Typography } from '@mui/joy'
import { Outlet, useNavigate } from 'react-router-dom'
import './style.scss'
import { isAuthenticated } from '@core/helpers/auth.helper'
import { TranslateEnum, TranslateLanguageEnum } from '@core/enums/translate.enum'
import { useTranslation } from 'react-i18next'
import { changeLanguage } from '@core/helpers/i18n.helper'
import { RouterLink } from '@core/enums/router.enum'

/**
 * Auth layout
 * @constructor
 */
const AuthLayout: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation([TranslateEnum.AUTH])

  /**
   * Initial Effect with set default focus
   */
  useEffect(() => {
    const isAuth = isAuthenticated()
    if (isAuth) {
      navigate(RouterLink.HOME)
    }
  }, [])
  return (
    <Box component="div" className={'auth-container'}>
      <Grid
        container
        spacing={2}
        direction="column"
        justifyContent={'center'}
        alignItems={'center'}>
        <Grid xs={12} className="outlet-container">
          <Grid container justifyContent="center" alignItems="center" sx={{ pt: 10 }}>
            <Grid xs={6}>
              <Outlet />
            </Grid>
          </Grid>

          <Grid xs={12} sx={{ mb: 0, pl: 20, pr: 20 }}>
            <Container maxWidth="xl">
              <Stack
                direction={'row'}
                sx={{ position: 'absolute', bottom: 15, width: '80%' }}
                justifyContent={'space-between'}
                spacing={2}
                textAlign={'inherit'}>
                <Typography
                  level={'body2'}
                  sx={{ fontWeight: 400, color: 'white' }}
                  component="span">
                  &copy; {t('auth.copyright')}
                  <strong>
                    <span>AERIX</span>
                  </strong>
                  . {t('auth.license')}
                </Typography>

                <Stack sx={{ float: 'right' }} direction={'row'} spacing={3} textAlign={'inherit'}>
                  <Typography
                    sx={{ fontWeight: 400, cursor: 'pointer', color: 'white' }}
                    level={'body2'}
                    onClick={() => changeLanguage(TranslateLanguageEnum.KOREA)}>
                    한국어
                  </Typography>
                  <Typography
                    component={'span'}
                    level={'body2'}
                    onClick={() => changeLanguage(TranslateLanguageEnum.ENGLISH)}
                    sx={{ fontWeight: 400, cursor: 'pointer', color: 'white' }}>
                    English
                  </Typography>
                </Stack>
              </Stack>
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AuthLayout
